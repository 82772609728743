import React, { useEffect, useState,useCallback, useMemo } from 'react'
import { connect } from 'react-redux';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../shared/utility';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import StatusModal from './StatusModal/StatusModal';
import { IDProgressLineContainer, IDProgressLineItem, IDProgressLineLabel, PLAction } from './IDProgressLine.styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { useTheme } from 'styled-components';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { CTooltip } from '../../UI/CTooltip/CTooltip'

function IDProgressLine(props) { 
    const {
        userType,
        entityFilter,
        status,
        editStatus,
        accessToken,
        error
    } = props
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    const theme = useTheme()
    const [showStatusModal, setShowStatusModal] = useState(false);
   
    // const statusList = useMemo(() => [
    //     {label: status === 'ERROR' ? 'Erreur' : "Importée", value: status === 'ERROR' ? 'ERROR' : "IMPORTED"},
    //     {label: "Contrôlée", value: "CONTROLLED"},
    //     {label: "Intégrée", value: "INTEGRATED"},
    //     {label: "À payer", value: "TO_BE_PAID"},
    //     {label: "Payée", value: "PAID"}
    // ], [status])
    const [infoStatus, setInfoStatus] = useState("");
    const dangerStatus = useMemo(() => [ 'ERROR', 'REJECTED', 'REFUSED', 'LITIGATION'], [])
    const [statusList, setStatusList] = useState([]);
    useEffect(() => {
        let statusListTmp = [];
        if(userType === 'client'){
            switch (status) {
                case 'ERROR':
                    statusListTmp = [
                        {label: 'Erreur', value: 'ERROR'},
                    ];
                    
                    break;
                case 'REJECTED':
                    statusListTmp = [
                        {label: "Rejetée", value: "REJECTED"}
                    ]
                    break;
                case 'REFUSED':
                    statusListTmp = [
                        {label: "Refusée", value: "REFUSED"}
                    ]
                    break;
                case 'LITIGATION':
                    statusListTmp = [
                        {label: "Reçue", value : "IMPORTED"},
                        {label: "Litige", value: "LITIGATION"}
                    ]
                    break;
                default:
                    statusListTmp = [
                        {label: "Reçue", value : "INTEGRATED"},
                        {label: "À payer", value: "TO_BE_PAID"},
                        {label: "Payée", value: "PAID"}
                    ];
                    break;
            }
            statusListTmp = [
                {label: "Reçue", value : "INTEGRATED"},
                {label: "À payer", value: "TO_BE_PAID"},
                {label: "Payée", value: "PAID"}
            ];
        }else{
            switch (status) {
                case 'ERROR':
                    statusListTmp = [
                        {label: 'Erreur', value: 'ERROR'},
                    ];
                    break;
                case 'REJECTED':
                    statusListTmp = [
                        {label: "Importée", value: "IMPORTED"},
                        {label: "Rejetée", value: "REJECTED"}
                    ]
                    break;
                case 'REFUSED':
                    statusListTmp = [
                        {label: "Importée", value : "IMPORTED"},
                        {label: "Contrôlée", value: "CONTROLLED"},
                        {label: "Refusée", value: "REFUSED"}
                    ]
                    break;
                case 'LITIGATION':
                    statusListTmp = [
                        {label: "Importée", value : "IMPORTED"},
                        {label: "Contrôlée", value: "CONTROLLED"},
                        {label: "Intégrée", value: "INTEGRATED"},
                        {label: "Litige", value: "LITIGATION"}
                    ]
                    break;
                default:
                    statusListTmp = [
                        {label: "Importée", value : "IMPORTED"},
                        {label: "Contrôlée", value: "CONTROLLED"},
                        {label: "Intégrée", value: "INTEGRATED"},
                        {label: "À payer", value: "TO_BE_PAID"},
                        {label: "Payée", value: "PAID"}
                    ];
                    break;
            }
            statusListTmp = [
                {label: "Importée", value : "IMPORTED"},
                {label: "Contrôlée", value: "CONTROLLED"},
                {label: "Intégrée", value: "INTEGRATED"},
                {label: "À payer", value: "TO_BE_PAID"},
                {label: "Payée", value: "PAID"}
            ];
        }
        setStatusList(statusListTmp)
    }, [status])
    let actionButton = null;

    const getProgresseLineIcon = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value === status)

        if(index <= statusIndex) return <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
        else return <PanoramaFishEyeIcon style={{fontSize: "1.25rem"}} />
    }

    const getStatusColors = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value === status)
        if(dangerStatus.includes(status)) {
            
            if(index <= statusIndex)
                return [theme.colors.secondary, " solid " + theme.colors.secondary]
            if(index === (statusIndex + 1))
                return [theme.colors.secondary, " dashed " + theme.colors.secondary]
        }

        if(index <= statusIndex)
            return [theme.colors.secondary, " solid " + theme.colors.secondary]
        if(index === (statusIndex + 1))
            return [theme.colors.secondary, " dashed " + theme.colors.secondary]
        return [theme.colors.secondary, " solid " + theme.colors.gray]
    }

    if((userType === "client" || userType === "owner") && !accessToken && !['ERROR', 'REJECTED', 'REFUSED'].includes(status) && editStatus ) {
        actionButton = (
            <PLAction onClick={() => setShowStatusModal(true)} >
                <span>
                    {"(Modifier le statut)"}
                </span>
                <EditIcon style={{fontSize: "1.25rem"}}/>
            </PLAction>
        )
    }  

    return ( 
        <IDProgressLineContainer>
            {
                statusList.map((item, index) => (
                    <IDProgressLineItem 
                        color={getStatusColors(item.value, index)[0]} 
                        border={getStatusColors(item.value, index)[1]} 
                    >
                        <IDProgressLineLabel>{item.label}</IDProgressLineLabel>
                        {/* <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
                        <PanoramaFishEyeIcon /> */}
                        {getProgresseLineIcon(item?.value, index)}
                    </IDProgressLineItem>
                ))
            
            }
            {
                error ? 
                    <CTooltip title={"Raison erreur"}>
                        <span style={{color:"#EE5A5A",padding:"0px 17px"}}><WarningAmberIcon style={{fontSize:"30px",margin:"0px 5px 0px 5px"}}/><small style={{fontSize:"small"}}>Erreur</small></span>
                    </CTooltip>
                : ( "REJECTED, LITIGATION, REFUSED".includes(status)) ? 
                    <CTooltip title={status}>
                        <span style={{color:"#EE5A5A",padding:"0px 17px"}}><WarningAmberIcon style={{fontSize:"30px",margin:"0px 5px 0px 5px"}}/><small style={{fontSize:"small"}}>Erreur</small></span>
                    </CTooltip>
                    : null

            } 
            {/* {actionButton} */}
            {
                showStatusModal ? (
                    <StatusModal
                        show={showStatusModal}
                        modalClosed={() => setShowStatusModal(false)}
                        statusVal={status}
                        statusList={statusList}
                        editStatus={editStatus}
                    />
                ) : null
            }
        </IDProgressLineContainer>
        )      
    }
    
const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // mandatUid: state.mandat.admData.uid,
    // status : state.mandat?.admData?.status,
    // connectedUser : state.mandat?.admData?.connectedUser,
    // pdfFile: state.mandat.admData?.pdfFile || null,
    // admData: state.mandat.admData,
    // admUpdate: state.mandat.admUpdate
})
const mapDispatchToProps = dispatch => ({
    // getMandatData: (mandatUid) => dispatch(actions.getMandatData(mandatUid)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    // createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive))
})
export default connect(mapStateToProps, mapDispatchToProps)(IDProgressLine)
