import styled from 'styled-components/macro';



export const PVToolbar  = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
`;

export const PVToolbarElements = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PVToolbarElementsActions = styled.div`
    display: flex;
    align-items: center;
    & svg{
        stroke: #2196F3 !important;
        
    }
`

export const PVToHideMobile = styled.div`
    display: block;
    padding: 0px 2px;
    & svg{
        stroke: #2196F3 !important;
        
    }
  
    

    @media (min-width: 320px) and (max-width: 767px) {
        display: none;
    }
`


// export const PVContainer = styled.div`
//     height: 100vh;

//     & .rpv-default-layout-body {
//         background-color: #ffffff;
//     }

//     & .rpv-default-layout-toolbar {
//         background-color: rgba(25, 98, 158, 0.3);
//     }

//     & .rpv-default-layout-toolbar svg {
//         stroke: ${({ theme }) => theme.colors.text};
//     }

//     & .rpv-default-layout-sidebar-headers{
//         background-color: rgba(25, 98, 158, 0.3);
//     }

//     & .rpv-default-layout-sidebar-headers svg {
//         stroke: ${({ theme }) => theme.colors.text};
//     }

//     @media (min-width: 320px) and (max-width: 767px) {
//         .rpv-default-layout-sidebar {
//             display: none;
//         }
//         .rpv-default-layout-container{
//             z-index: 100;
//         }
//     }
// `;
export const PVContainer = styled.div`
height: ${({commandLine}) => commandLine ? '25vh' : '100vh'};

& .rpv-default-layout-body {
    background-color: #ffffff;
}

& .rpv-default-layout-toolbar {
    background-color: rgba(25, 98, 158, 0.3);
}

& .rpv-default-layout-toolbar svg {
    stroke: ${({ theme }) => theme.colors.lightBlack};
}

& .rpv-default-layout-sidebar-headers{
    background-color: rgba(25, 98, 158, 0.3);
}
& .rpv-default-layout-container{
    border: 1px solid #809FB8;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    width: 100%;
    padding: 0.8rem 1.5rem;
    border-radius: 18px;
    }
& .rpv-default-layout-sidebar-headers svg {
    stroke: ${({ theme }) => theme.colors.lightBlack};
}

@media (min-width: 320px) and (max-width: 767px) {
    .rpv-default-layout-sidebar {
        display: none;
    }
    .rpv-default-layout-container{
        z-index: 100;
    }
}
`;

