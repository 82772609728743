import React, { useCallback, useEffect, useState } from 'react'
// import './RepertoireModal.css'
import SearchIcon from '@mui/icons-material/Search';
import Axios from '../../../axios-proas'
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
// import EntitiesTable from './EntitiesTable/EntitiesTable';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../../styles/Common';
import { FormRadioWrapper, FormLabel } from './ActionsModal.styled';
import enIE from 'date-fns/esm/locale/en-IE/index.js';

function ActionsModal({handleClose, show, addEventHandler, entityDocuments }) {

    const [entities , setEntities] = useState([]);
    const [entitiesType, setEntitiesType] = useState('client');
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null)
    const [allClients, setAllClients] = useState(false);
    const [allSuppliers, setAllSuppliers] = useState(false);
    const {t} = useTranslation();
    const documentType = ["IBAN", "FOREIGN", "KBIS", "URSSAF", "INSURANCE"] 
    const [existingDocumentType,  setExistingDocumentType] = useState()
    const documentTypeLabelList = {
        "IBAN" : "Coordonnées bancaires",
        "FOREIGN" : "Liste des salariés étrangers",
        "KBIS" : "Justificatif immatriculation KBIS",
        "URSSAF" : "Attestation sociale de vigilance",
        "INSURANCE" : "Attestation d'assurance"
    }
    const [event, setEvent] = useState({
        action: 1,
        rib: false,
        others:false,
        comment:"",
        type:"NEW_SUPPLIER_ACCOUNT"
    })

    const handleEvent = (e, value) => {
        //let temp = {...event}
        
        setEvent({...event, [e] : value})
    }


    useEffect(() => {
        let documents = ""
        entityDocuments.map(doc=> {
            documents += doc?.type + ", ";
        })
        setExistingDocumentType(documents)
        return (
            setEvent({
                action: 1,
                rib: false,
                others:false,
                comment:""
            })
        )
    }, [])
    


    const addClickHandler = () => { 
        addEventHandler(event)
        handleClose()
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc"  >
                <Modal.Header closeButton>
                <Modal.Title className="repertoireTitle ICDBlue" style={{width: "483px !important",minWidth: "483px", paddingBottom: "1rem"}}>Action sur le référencement </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="repertoireModal__entitiesHeader_container" style={{borderBottom: "2px dashed rgb(128, 159, 184)"}}>
                        <div className="" style={{width:"100%"}}>
                            <label for="entityType" style={{color:"#809FB8", fontSize:"19px"}}>Action : </label>
                            <select 
                                className="form-control form-select" 
                                value={event?.action}
                                name="action"
                                onChange={(e) => handleEvent(e.target.name, e.target.value)}
                            >
                                <option value="1">Demande des pièces complémentaires</option>
                                <option value="2">Refuser la demande de référencement</option>
                                <option value="3">Accepter la demande de référencement</option>
                            </select>
                        </div>
                       
                    </div>
                    {
                        event?.action == 1 &&
                            <div style={{margin: "25px 0px 23px 0px"}}>
                                {
                                    documentType?.map(doc => {
                                        if(!existingDocumentType?.includes(doc)) 
                                            return (
                                                <FormRadioWrapper>
                                                    <input 
                                                        style={{marginRight: "17px"}}
                                                        type="checkbox"
                                                        name={doc}
                                                        onChange={(e) => handleEvent(e.target.name, e.target.checked)} 
                                                        // checked={event?.rib}  
                                                        className="form-check-input"
                                                        id="exampleCheck1"
                                                    />
                                                    <FormLabel htmlFor="left">{documentTypeLabelList[doc]}</FormLabel>
                                                </FormRadioWrapper>
                                            )
                                    })
                                }
                                
                                <FormRadioWrapper>
                                    <input
                                        style={{marginRight: "17px"}}
                                        type="checkbox"
                                        name="others"
                                        onChange={(e) => handleEvent(e.target.name, e.target.checked)} 
                                        checked={event?.others}  
                                        className="form-check-input"
                                        id="exampleCheck1"
                                    />
                                    <FormLabel htmlFor="left">Autres documents</FormLabel>
                                </FormRadioWrapper>
                            </div>
                    }

                    <div className="">
                            <label for="entityType" style={{color:"#809FB8", fontSize:"19px"}}>Commentaire 
                            {
                                (event?.action == 1 || event?.action == 2) ? " (obligatoire)" : " (facultatif)"
                            }
                            : </label>
                            <textarea
                                rows="5" 
                                className="form-control" 
                                value={event?.comment}
                                name="comment"
                                onChange={(e) => handleEvent(e.target.name, e.target.value)}
                                style={{width:"100% !important"}}
                            />
                            
                        </div>


                    
                    
                </Modal.Body>
                <Modal.Footer>
                <PrimaryBtnOutlineAction variant="primary" 
                    onClick={() => addClickHandler()}
                    >
                    {t('default:associate', "Confirmer")} 
                </PrimaryBtnOutlineAction>
                <CancelBtnOutlineAction variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </CancelBtnOutlineAction>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default ActionsModal
