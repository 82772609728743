import React, { useCallback, useEffect, useState } from 'react'
// import './RepertoireModal.css'
import SearchIcon from '@mui/icons-material/Search';
import Axios from '../../../axios-proas'
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
// import EntitiesTable from './EntitiesTable/EntitiesTable';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../../styles/Common';
import { FormRadioWrapper, FormLabel } from './ActionsModal.styled';
import enIE from 'date-fns/esm/locale/en-IE/index.js';


function ActionsModal({ handleClose, show, addEventHandler }) {

    const [entities, setEntities] = useState([]);
    const [entitiesType, setEntitiesType] = useState('client');
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null)
    const [allClients, setAllClients] = useState(false);
    const [allSuppliers, setAllSuppliers] = useState(false);
    const { t } = useTranslation();
    const [statusListNew, setStatusListNew] = useState([]);
    const [event, setEvent] = useState({
        action: "LITIGATION",
        reason:null,
        valideur: null,
        comment: "",
        origin: "ACTION" 
    })
 useEffect(() =>{
    Axios.get("/invoice//ValidorUsersList")
    .then((response) => {
        setStatusListNew(response?.data.Users);
    })
    .catch((err) => {
      notyf.error("Une erreur s'est produite");
    });
       
       
        
    }, [])
    const handleEvent = (e, value) => {
        //let temp = {...event}
        setEvent({ ...event, [e]: value })
    }


    useEffect(() => {
        return (
            setEvent({
                action: "LITIGATION",
                reason:null,
                valideur: null,
                comment: "",
                origin: "ACTION"
            })
        )
    }, [])



    const addClickHandler = () => {
        
        addEventHandler(event)     
        handleClose()
      
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}     dialogClassName="myModal modal-dialog-centered"contentClassName="modal_w_mc"   >
                <Modal.Header closeButton>
                    <Modal.Title className="repertoireTitle ICDBlue" style={{ width: "483px !important", minWidth: "483px", paddingBottom: "1rem" }}>Action sur la facture </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="repertoireModal__entitiesHeader_container" style={{ borderBottom: "2px dashed rgb(128, 159, 184)" }}>
                        <div className="" style={{ width: "100%" }}>
                            <label for="entityType" style={{ color: "#809FB8", fontSize: "19px" }}>Action : </label>
                            <select
                                className="form-control form-select"
                                value={event?.action}
                                name="action"
                                onChange={(e) => handleEvent(e.target.name, e.target.value)}
                            >
                                <option value="LITIGATION">Litige</option>
                                <option value="TO_BE_VALIDATED">Demander une validation</option>
                                <option value="PAID">Accepter le bon à payer</option>
                            </select>
                        </div>

   
                    </div>
                    {
                        event?.action == "TO_BE_VALIDATED" ?
                            <div style={{ margin: "25px 0px 23px 0px" }}>
                                <div className="" style={{ width: "100%" }}>
                                    <label for="entityType" style={{ color: "#809FB8", fontSize: "19px" }}>Sélectionnez un valideur : </label>
                                    <select
                                        className="form-control form-select"
                                        value={event?.valideur}
                                        name="valideur"
                                        onChange={(e) => handleEvent(e.target.name, e.target.value)}
                                    >
                                        <option value=""></option>
                                        {
                                        statusListNew?.map(s => <option value={s.uid}>
                                            {s.first_name}
                                        </option>)
                                        }
                                    </select>
                                </div>
                            </div>
                            :
                            event?.action ==  2 && 
                            <div style={{ margin: "25px 0px 23px 0px" }}>
                                <div className="" style={{ width: "100%" }}>
                                    <label for="entityType" style={{ color: "#809FB8", fontSize: "19px" }}>Valideur : </label>
                                    <select
                                        className="form-control form-select"
                                        value={event?.validator}
                                        name="validator"
                                        onChange={(e) => handleEvent(e.target.name, e.target.value)}
                                    >
                                        <option value="buyer">Acheteur</option>
                                        {/* <option value="2">Demander une validation</option>
                                        <option value="3">Accepter le bon à payer</option> */}
                                    </select>
                                </div>
                            </div>
                    }

                    <div className="">
                        <label for="entityType" style={{ color: "#809FB8", fontSize: "19px" }}>Commentaire
                            {
                                (event?.action == 1 || event?.action == 2) ? " (obligatoire)" : " (facultatif)"
                            }
                            : </label>
                        <textarea
                            rows="5"
                            className="form-control"
                            value={event?.comment}
                            name="comment"
                            onChange={(e) => handleEvent(e.target.name, e.target.value)}
                            style={{ width: "100% !important" }}
                        />

                    </div>




                </Modal.Body>
                <Modal.Footer>
                    <PrimaryBtnOutlineAction variant="primary"
                        onClick={() => addClickHandler()}
                    >
                        {t('default:associate', "Confirmer")}
                    </PrimaryBtnOutlineAction>
                    <CancelBtnOutlineAction variant="secondary" onClick={handleClose}>
                        {t('default:cancel', "Annuler")}
                    </CancelBtnOutlineAction>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default ActionsModal
