// import React, { useEffect, useState } from 'react'
// import Axios from '../../../../../axios-proas'
// import { BtnSmOutlineAction, FormGroup, FormInput, FormLabel, FormSelect, PrimaryBtnOutlineAction, FormGroupInvInf, FormGroupInv, GroupCommande, FormGroupInvCommandes } from '../../../../../styles/Common'
// import { FContainer, FFormWrap, FHeader, FHInfos } from './Fields.styled'
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import frLocale from "date-fns/locale/fr";
// import { DPContainer } from '../../../../../containers/CampaignForm/CampaignForm.styled';
// import DatePicker from '@mui/lab/DatePicker';
// import TextField from '@mui/material/TextField';
// import { checkValidity, getNotyfObject } from '../../../../../shared/utility';
// import { useTranslation } from 'react-i18next';
// import { useTheme } from 'styled-components';
// import { Spinner } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import { formatDate } from "../../../../../shared/utility"
// import { listClasses } from '@mui/material';

// function Information({ invoiceData, setInvoiceData, InvoiceIntegratedDetail }) {
//   const notyf = getNotyfObject();
//   const { t } = useTranslation();
//   const theme = useTheme()
//   const navigate = useNavigate()
//   const [userEntities, setUserEntities] = useState([])
//   const [userClients, setUserClients] = useState([])
//   const [dueDateError, setDueDateError] = useState(false);
//   const [issuingDateError, setIssuingDateError] = useState(false);
//   const [errors, setErrors] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [existInvoice, setExistInvoice] = useState(false)

//   const getUserEntities = () => {
//     let errorsTmp = []
//     setErrors([])
//     Axios.get(`/user//connectedUserEntities`).then(res => {
//       let entities = res?.data?.entities ? res.data.entities : []
//       setUserEntities(entities)
//       if (invoiceData?.supplierUid && entities.length > 0) {
//         let supplier = entities.find(e => e.uid === invoiceData?.supplierUid)
//         if (!supplier) {
//           setInvoiceData({ ...invoiceData, supplierUid: null })
//           errorsTmp.push(`Le Fournisseur ${invoiceData.supplierName} ne figure pas dans votre liste d'entités`)
//         }
//       }
//       setErrors(errorsTmp)
//     }).catch(err => {
//       notyf.error("Une erreur s'est produite")
//     })
//   }

//   const getCurrencySigne = (c) => {
//     const currency = {
//       EUR : "€",
//       USD : "$"
//     }
//     if(currency[c]) return currency[c]
//     else return "€"
//   }

//   const getUserClients = () => {
//     let errorsTmp = []
//     setErrors([])
//     Axios.get(`/user//connectedUserClients`).then(res => {
//       let entities = res?.data?.entities ? res.data.entities : []
//       setUserClients(entities)
//       if (invoiceData?.clientUid && entities.length > 0) {
//         let client = entities.find(e => e.uid === invoiceData?.clientUid)
//         if (!client) {
//           setInvoiceData({ ...invoiceData, clientUid: null })
//           errorsTmp.push(`Le client ${invoiceData.clientName} ne figure pas dans votre liste de clients`)
//         }
//       }
//       setErrors(errorsTmp)
//     }).catch(err => {
//       notyf.error("Une erreur s'est produite")
//     })
//   }

//   const validateInvoice = () => {
//     Axios.put(`/invoice/${invoiceData?.invoiceUid}/validateInvoice`, invoiceData).then(res => {
//       notyf.success("La facture a été validée")
//       navigate(-1)
//     }).catch(err => {
//       notyf.error("Une erreur s'est produite")
//     }).finally(() => {
//       setLoading(false)
//     })
//   }

//   const checkExistInvoice = (number = null) => {
//     let errorsTmp = []
//     setErrors([])
//     setExistInvoice(false)
//     Axios.get(`/invoice/${invoiceData?.invoiceUid}/existInvoice`, {
//       params: {
//         number: number ? number : invoiceData.number,
//         supplierUid: invoiceData.supplierUid
//       }
//     }).then(res => {
//       let exist = res?.data?.exist ? true : false;
//       if (exist) {
//         errorsTmp.push("Une facture avec ce numéro existe déjà pour ce fournisseur")
//         setExistInvoice(true)
//       }
//       setErrors(errorsTmp)
//     }).catch(err => {
//       notyf.error("Une erreur s'est produite")
//     })
//   }

//   useEffect(() => {
//     let invoiceDataInit = {
//       ...invoiceData,
//       documentType: invoiceData.documentType ? invoiceData.documentType : "INV",
//       issuingDate: invoiceData.issuingDate ? +invoiceData.issuingDate : new Date().getTime(),
//       dueDate: invoiceData.dueDate ? +invoiceData.dueDate : new Date().getTime()
//     }
//     setInvoiceData(invoiceDataInit)
//     getUserEntities()
//     getUserClients()
//   }, [])

//   const fieldChangeHandler = (e) => {
//     const { name, value } = e.target
//     setInvoiceData({ ...invoiceData, [name]: value })
//     if (name === "number") {
//       checkExistInvoice(value)
//     }
//   }

//   const handleDueDateChange = (date) => {
//     if (date.toString() === "Invalid Date") {
//       setDueDateError(true)
//     } else
//       if (date.getFullYear <= 1900 || date.getFullYear > 2100) {
//         setDueDateError(true)
//       } else {
//         setDueDateError(false)
//       }
//     setInvoiceData({
//       ...invoiceData,
//       dueDate: date.getTime(),
//     })
//   };

//   const handleIssuingDateChange = (date) => {
//     if (date.toString() === "Invalid Date") {
//       setIssuingDateError(true)
//     } else
//       if (date.getFullYear <= 1900 || date.getFullYear > 2100) {
//         setIssuingDateError(true)
//       } else {
//         setIssuingDateError(false)
//       }
//     setInvoiceData({
//       ...invoiceData,
//       issuingDate: date.getTime(),
//     })
//   };

//   const checkInvoiceFormValidity = () => {
//     let errors = []
//     if (!invoiceData.hasOwnProperty('number') || invoiceData?.number === null || invoiceData?.number === "" || !checkValidity(invoiceData?.number, { required: true })) {
//       errors.push(t("invoiceDetail:error_number", "Le numéro de facture est obligatoire"));
//     }
//     if (!invoiceData.hasOwnProperty('documentType') || invoiceData?.documentType === null || invoiceData?.documentType === "" || !checkValidity(invoiceData?.documentType, { required: true })) {
//       errors.push(t("invoiceDetail:error_documentType", "Le type de document est obligatoire"));
//     }
//     if (!invoiceData.hasOwnProperty('exclusiveTaxAmount') || invoiceData?.exclusiveTaxAmount === null || parseFloat(invoiceData?.exclusiveTaxAmount) === 0 || !checkValidity(invoiceData?.exclusiveTaxAmount, { required: true })) {
//       errors.push(t("invoiceDetail:error_exclusiveTaxAmount", "Le montant HT est obligatoire"));
//     }

//     if (!invoiceData.hasOwnProperty('inclusiveTaxAmount') || invoiceData?.inclusiveTaxAmount === null || parseFloat(invoiceData?.inclusiveTaxAmount) === 0 || !checkValidity(invoiceData?.inclusiveTaxAmount, { required: true })) {
//       errors.push(t("invoiceDetail:error_inclusiveTaxAmount", "Le montant TTC est obligatoire"));
//     }
//     if (!invoiceData.hasOwnProperty('supplierUid') || invoiceData?.supplierUid === null || invoiceData?.supplierUid === "" || !checkValidity(invoiceData?.supplierUid, { required: true })) {
//       errors.push(t("invoiceDetail:error_supplierUid", "Le fournisseur est obligatoire"));
//     }
//     if (!invoiceData.hasOwnProperty('clientUid') || invoiceData?.clientUid === null || invoiceData?.clientUid === "" || !checkValidity(invoiceData?.clientUid, { required: true })) {
//       errors.push(t("invoiceDetail:error_clientUid", "Le client est obligatoire"));
//     }
//     return errors;
//   }

//   const validateInvoiceHandler = () => {
//     let checkErrors = checkInvoiceFormValidity();
//     if (existInvoice) {
//       checkErrors.push("Une facture avec ce numéro existe déjà pour ce fournisseur")
//     }
//     if (checkErrors && checkErrors.length > 0) {
//       setErrors(checkErrors)
//     } else {
//       setErrors([])
//       setLoading(true)
//       validateInvoice()
//     }
//   }

//   return (
//     <FContainer InvoiceIntegratedDetail={InvoiceIntegratedDetail}>
//       {/* <FHeader>
//         <FHInfos>Veuillez vérifier les données de la facture</FHInfos>
//         {
//           loading ? 
//             <Spinner /> 
//           :
//             <PrimaryBtnOutlineAction 
//               style={{height: "33px"}}
//               onClick={validateInvoiceHandler}
//             >
//               {t("invoiceDetail:validate", "Valider")}
//             </PrimaryBtnOutlineAction>
//         }
//       </FHeader>
//       {
//           errors && errors.length > 0 ? (
//                 <div className="alert alert-danger mt-2 p-1" role="alert" style={{fontSize: "0.8rem"}}>
//                   <ul>
//                       {
//                           errors.map(error => (<li key={error}>{error}</li>))
//                       }
//                   </ul>
//               </div>
//           ) : null
//       } */}
//       <FFormWrap style={{flexDirection: InvoiceIntegratedDetail ? "column" : "initial",display: "flex", justifyContent: "space-between"}}>
//         <div style={{width: InvoiceIntegratedDetail ? "100%" : "30%"}}>
//           <FormGroupInvInf>
//             <FormLabel htmlFor="number" >N° de facture </FormLabel>
//             <FormInput
//               id="numbtexter"
//               type="text"
//               name="number"
//               placeholder="Numéro"
//               value={invoiceData?.number || ""}
//             // onChange={(e) => fieldChangeHandler(e)}
//             />
//           </FormGroupInvInf>

//           {/* <FormGroupInvInf>
//             <FormLabel htmlFor="documentType" >Type</FormLabel>
//             <FormSelect 
//                 id="documentType"
//                 name="documentType"
//                 value={invoiceData?.documentType || ""}
//                 onChange={(e) => fieldChangeHandler(e)}
//             >
//                 <option value="INV">Facture</option>
//                 <option value="CRN">Avoir</option>
//             </FormSelect>
//         </FormGroupInvInf> */}
//           <FormGroupInvInf>

//             <FormLabel htmlFor="supplierUid" >Date de facture</FormLabel>
//             <FormInput
//               id="issuingDate"
//               type="text"
//               name="issuingDate"
//               placeholder="Numéro"
//               value={invoiceData?.dueDate ? formatDate(+invoiceData?.issuingDate) : ""}
//             // onChange={(e) => fieldChangeHandler(e)}
//             />
//           </FormGroupInvInf>
//           <FormGroupInvInf>
//             <FormLabel htmlFor="supplierUid" >Date d'échéance</FormLabel>
//             <FormInput
//               id="Date d'échéance"
//               type="text"
//               name="DateEchéance"
//               placeholder="Numéro"
//               value={invoiceData?.dueDate ? formatDate(+invoiceData?.dueDate) : ""}
//             // onChange={(e) => fieldChangeHandler(e)}
//             />
//           </FormGroupInvInf>
//           <FormGroupInvInf>
//             <FormLabel htmlFor="amount" >N° de commande</FormLabel>
//             <FormInput
//               type="text"
//               step="0.01"
//               name="commandId"
//               value={78526 || ""}
//             // onChange={(e) => fieldChangeHandler(e)}
//             />
//           </FormGroupInvInf>
//         </div>
//         <div style={{width: InvoiceIntegratedDetail ? "100%" : "30%"}}>
//           <FormGroupInvInf>
//             <FormLabel htmlFor="exclusiveTaxAmount" >Montant HT</FormLabel>
//             <FormInput
//               id="exclusiveTaxAmount"
//               type="text"
//               step="0.01"
//               name="exclusiveTaxAmount"
//               style={{color: InvoiceIntegratedDetail ? "#505050" : "#EE5A5A"}}
//               placeholder="Montant HT"
//               value={invoiceData?.exclusiveTaxAmount+""+getCurrencySigne(invoiceData?.currency) || ""}
//             // onChange={(e) => fieldChangeHandler(e)}
//             />
//           </FormGroupInvInf>
//           <FormGroupInvInf>
//             <FormLabel htmlFor="exclusiveTaxAmount" >Montant TVA</FormLabel>
//             <FormInput
//               id="exclusiveTaxAmount"
//               type="text"
//               step="0.01"
//               style={{color:InvoiceIntegratedDetail ? "#505050" : "#EE5A5A"}}
//               name="exclusiveTaxAmount"
//               placeholder="Montant HT"
//               value={ invoiceData?.exclusiveTaxAmount ? invoiceData?.exclusiveTaxAmount+""+getCurrencySigne(invoiceData?.currency) : "" }
//             // onChange={(e) => fieldChangeHandler(e)}
//             />
//           </FormGroupInvInf>
//           <FormGroupInvInf>
//             <FormLabel htmlFor="inclusiveTaxAmount" >Montant TTC</FormLabel>
//             <FormInput
//               id="inclusiveTaxAmount"
//               type="text"
//               step="0.01"
//               style={{color:InvoiceIntegratedDetail ? "#505050" : "#EE5A5A"}}
//               name="inclusiveTaxAmount"
//               placeholder="Montant TTC"
//               value={invoiceData?.inclusiveTaxAmount ? invoiceData?.inclusiveTaxAmount+""+getCurrencySigne(invoiceData?.currency) : ""}
//             // onChange={(e) => fieldChangeHandler(e)}
//             />
//           </FormGroupInvInf>
//           <FormGroupInvInf>
//             <FormLabel htmlFor="inclusiveTaxAmount" >Devise</FormLabel>
//             <FormInput
//               id="inclusiveTaxAmount"
//               type="text"
//               step="0.01"
//               name="currency"
//               value={invoiceData?.currency || ""}
//             // onChange={(e) => fieldChangeHandler(e)}
//             />
//         </FormGroupInvInf>

//         <FormGroupInvInf>
//             <FormLabel htmlFor="number" >N° Archivage (NUA) </FormLabel>
//             <FormInput 
//                 id="number"
//                 type="text"
//                 name="number"
//                 placeholder="Numéro"
//                 value={invoiceData?.number || ""}
//                 onChange={(e) => fieldChangeHandler(e)}
//             />
//           </FormGroupInvInf>
//           {/*<FormGroupInvInf>
//             <FormLabel htmlFor="clientUid" >Entité</FormLabel>
//             <FormInput
//               id="Date d'échéance"
//               type="text"
//               name="DateEchéance"
//               placeholder="Numéro"
//               value={invoiceData?.clientName ? invoiceData?.clientName : ""}
//             // onChange={(e) => fieldChangeHandler(e)}
//             />
//           </FormGroupInvInf>*/}
//       </div> 
        
//       </FFormWrap>
//     </FContainer>
//   )
// }

// export default Information

import React, { useEffect, useState } from 'react'
import Axios from '../../../../../axios-proas'
import { BtnSmOutlineAction, FormGroup, FormInput, FormLabel, FormSelect, PrimaryBtnOutlineAction, FormGroupInvInf,FormGroupInv,GroupCommande,FormGroupInvCommandes } from '../../../../../styles/Common'
import { FContainer, FFormWrap, FHeader, FHInfos } from './Fields.styled'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import frLocale from "date-fns/locale/fr";
import { DPContainer } from '../../../../../containers/CampaignForm/CampaignForm.styled';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { checkValidity, getNotyfObject } from '../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {formatDate}from "../../../../../shared/utility"
import { listClasses } from '@mui/material';

function Information({invoiceData, setInvoiceData}) {
  const notyf = getNotyfObject();
  const {t} = useTranslation();
  const theme = useTheme()
  const navigate = useNavigate()
  const [errors, setErrors] = useState([])
  const [existInvoice, setExistInvoice] = useState(false)


  

  const checkExistInvoice = (number = null) => {
    let errorsTmp = []
    setErrors([])
    setExistInvoice(false)
    Axios.get(`/invoice/${invoiceData?.invoiceUid}/existInvoice`, {
      params: {
        number: number ? number : invoiceData.number,
        supplierUid: invoiceData.supplierUid
      }
    }).then(res => {
      let exist = res?.data?.exist ? true : false;
      if(exist) {
        errorsTmp.push("Une facture avec ce numéro existe déjà pour ce fournisseur")
        setExistInvoice(true)
      }
      setErrors(errorsTmp)
    }).catch(err => {
      notyf.error("Une erreur s'est produite")
    })
  }

  useEffect(() => {
    let invoiceDataInit = {
      ...invoiceData,
      documentType : invoiceData.documentType ? invoiceData.documentType : "INV",
      issuingDate : invoiceData.issuingDate ? +invoiceData.issuingDate : new Date().getTime(),
      dueDate : invoiceData.dueDate ? +invoiceData.dueDate : new Date().getTime()
    }
    setInvoiceData(invoiceDataInit)
 
  }, [])

  const fieldChangeHandler = (e) => {
    const {name, value} = e.target
    setInvoiceData({...invoiceData, [name]: value})
    if(name === "number") {
      checkExistInvoice(value)
    }
  }

  
 
  
  const formatNumber = (number) => {
    return new Intl.NumberFormat('fr-FR').format(parseFloat(number).toFixed(2) )
}
  


  

  return (
    <FContainer>
    
      <FFormWrap>
        <FormGroupInvInf>
            <FormLabel htmlFor="number" >N° de facture </FormLabel>
            <FormInput 
                id="number"
                type="text"
                name="number"
                placeholder="Numéro"
                value={invoiceData?.number || ""}
                // onChange={(e) => fieldChangeHandler(e)}
            />
        </FormGroupInvInf>
    
      
          <FormGroupInvInf>
          
          <FormLabel htmlFor="supplierUid" >Date de facture</FormLabel>
        
              <FormInput 
                id="issuingDate"
                type="text"
                name="issuingDate"
                placeholder="Numéro"
                value={invoiceData?.dueDate ? formatDate(+invoiceData?.issuingDate) : null}
                // onChange={(e) => fieldChangeHandler(e)}
            />
        </FormGroupInvInf>
        <FormGroupInvInf>
        <FormLabel htmlFor="supplierUid" >Date d'échéance</FormLabel>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <DPContainer>
                <DatePicker
                    id="date-picker-dialog"
                    label="Date d'échéance"
                    format="dd/MM/yyyy"
                    // minDate={currentDate}
                    value={invoiceData?.dueDate ? new Date(+invoiceData?.dueDate) : new Date()}
                    
                    onChange={handleDueDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    invalidDateMessage="Format de date invalide"
                    // maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                    // minDateMessage="La date ne doit pas être antérieure à la date minimale"
                    renderInput={(params) => <TextField {...params} />}
                    // open={true} control open dialog state
                />
            </DPContainer>
          </LocalizationProvider> */}
           <FormInput 
                id="Date d'échéance"
                type="text"
                name="DateEchéance"
                placeholder="Numéro"
                value={invoiceData?.dueDate ? formatDate(+invoiceData?.dueDate) : null}
                // onChange={(e) => fieldChangeHandler(e)}
            />
        </FormGroupInvInf>

        <FormGroupInvInf>
            <FormLabel htmlFor="supplierUid" >Fournisseur</FormLabel>
            <FormInput 
                id="Date d'échéance"
                type="text"
                name="DateEchéance"
                placeholder="Numéro"
                value={invoiceData?.supplierName? invoiceData?.supplierName : null}
                // onChange={(e) => fieldChangeHandler(e)}
            />
            {/* <FormSelect 
                id="supplierUid"
                name="supplierUid"
                value={invoiceData?.supplierUid || ""}
                 onChange={(e) => fieldChangeHandler(e)}
            >
              <option value=""></option>
              {
                userEntities?.map((supplier, index) => {
                  return (
                    <option key={index} value={supplier.uid}>{supplier.name}</option>
                  )
                })
              }
            </FormSelect> */}
        </FormGroupInvInf>
        <FormGroupInv>
            <FormLabel htmlFor="clientUid" >Entité</FormLabel>
            <FormInput 
                id="Date d'échéance"
                type="text"
                name="DateEchéance"
                placeholder="Numéro"
                value={invoiceData?.clientName? invoiceData?.clientName: null}
                // onChange={(e) => fieldChangeHandler(e)}
            />
           
        </FormGroupInv>

        <FormGroupInvCommandes>
            <FormLabel htmlFor="amount" >N° de commande</FormLabel>
            {
            invoiceData?.commandNumbers?.split(',').map((item, index) => {
              return (
                <GroupCommande   key={index}>
                        <span>{item || ""}</span>
                </GroupCommande>
                
              )     
              

            })}
           
        </FormGroupInvCommandes>
        <FormGroupInvInf>
            <FormLabel htmlFor="exclusiveTaxAmount" >Montant HT</FormLabel>
            <FormInput 
                id="exclusiveTaxAmount"
                type="text"
                step="0.01"
                name="exclusiveTaxAmount"
                placeholder="Montant HT"
                value={formatNumber(+invoiceData?.exclusiveTaxAmount) + " €" }
                // onChange={(e) => fieldChangeHandler(e)}
            />
        </FormGroupInvInf>
        <FormGroupInvInf>
            <FormLabel htmlFor="exclusiveTaxAmount" >Montant TVA</FormLabel>
            <FormInput 
                id="exclusiveTaxAmount"
                type="text"
                step="0.01"
                name="exclusiveTaxAmount"
                placeholder="Montant HT"
                value={formatNumber(+invoiceData?.inclusiveTaxAmount - (+invoiceData?.exclusiveTaxAmount)) + " €" || ""}
                // onChange={(e) => fieldChangeHandler(e)}
            />
        </FormGroupInvInf>
        <FormGroupInv>
            <FormLabel htmlFor="inclusiveTaxAmount" >Montant TTC</FormLabel>
            <FormInput 
                id="inclusiveTaxAmount"
                type="text"
                step="0.01"
                name="inclusiveTaxAmount"
                placeholder="Montant TTC"
                value={(formatNumber(+invoiceData?.inclusiveTaxAmount) + " €")|| ""}
                // onChange={(e) => fieldChangeHandler(e)}
            />
        </FormGroupInv>
        {/* <FormGroupInvInf>
            <FormLabel htmlFor="clientUid" >Devise</FormLabel>
            <FormSelect 
                id="devise"
                name="devise"
                value={invoiceData?.devise || ""}
                onChange={(e) => fieldChangeHandler(e)}
            >
              <option value="EUR" key={2}>EUR</option>
              <option value="USD" key={3}>USD</option>
              <option value="GBP" key={4}>GBP</option>
        
            </FormSelect>
        </FormGroupInvInf> */}
      
      
        <FormGroupInvInf>
            <FormLabel htmlFor="docFile" >Fichier</FormLabel>
            <FormInput 
                id="docFile"
                type="text"
                name="docFile"
                placeholder="Fichier"
                value={invoiceData?.docFile || ""}
                onChange={(e) => fieldChangeHandler(e)}
                disabled
            />
        </FormGroupInvInf>

        <FormGroupInvInf>
            <FormLabel htmlFor="number" >N° Archivage(NUA) </FormLabel>
            <FormInput 
                id="number"
                type="text"
                name="number"
                placeholder="Numéro"
                value={invoiceData?.number || ""}
                onChange={(e) => fieldChangeHandler(e)}
            />
        </FormGroupInvInf>
      </FFormWrap>
    </FContainer>
  )
}

export default Information