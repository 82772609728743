import React, { useEffect, useState } from "react";
import Axios from "../../../../../axios-proas";
import {
  BtnSmOutlineAction,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  PrimaryBtnOutlineAction,
  FormGroupInvInf,
  FormGroupInv,
  GroupCommande,
  FormGroupInvCommandes,
  FormGroupInvInfSpec,
} from "../../../../../styles/Common";
import { FContainer, FFormWrap, FHeader, DPContainer } from "./Fields.styled";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { checkValidity, getNotyfObject } from "../../../../../shared/utility";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../../shared/utility";
import { listClasses } from "@mui/material";

function InformationCommand({
  invoiceData,
  setInvoiceData,
  InvoiceIntegratedDetail,
  commandesLinesData,
  updateAmount,
  setUpdateAmount,
  update,
  setUpdate,
}) {
  const currency = {
    EUR: "€",
    USD: "$",
  };
  const notyf = getNotyfObject();
  const { t } = useTranslation();
  const theme = useTheme();
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [MHT, setMHT] = useState();
  const [MTVA, setMTVA] = useState();
  const [MTTC, setMTTC] = useState();
  let NumberFormat = new Intl.NumberFormat("fr-FR");

  const [suppliers, setSuppliers] = useState([]);
  const [clients, setClients] = useState([]);

  const [supplierSelected, setSupplierSelected] = useState();
  const [clientSelected, setClientSelected] = useState();
  const [commandSelected, setCommandSelected] = useState();

  const [htTxt, setHtTxt] = useState();
  const [ttcTxt, setTtcTxt] = useState();
  const [tvaTxt, setTvaTxt] = useState();

  const [issuingDateTxt, setIssuingDateTxt] = useState();
  const [dueDateTxt, setDueDateTxt] = useState();

  useEffect(() => {
    getSuppliers();
    getClients();
    setSupplierSelected(invoiceData?.supplierUid);
    setClientSelected(invoiceData?.clientUid);
    setCommandSelected(invoiceData?.commandNumbers);
    setIssuingDateTxt(formatDate(+invoiceData?.issuingDate));
    setDueDateTxt(formatDate(+invoiceData?.dueDate));
  }, []);

  useEffect(() => {
    var someTvaCLines = 0;
    var someHTCLines = 0;
    var someTTCCLines = 0;
    console.log(commandesLinesData);
    commandesLinesData?.map((cls) => {
      someTvaCLines = someTvaCLines + +cls.tva;
      someHTCLines = someHTCLines + (isNaN(+cls?.eta) ? 0 : +cls?.eta);
      someTTCCLines = someTTCCLines + (isNaN(+cls?.ita) ? 0 : +cls?.ita);
    });
    // console.log(someTvaCLines);
    // console.log(someHTCLines);
    // console.log(someTTCCLines);
    setMTVA(someTvaCLines);
    setMHT(someHTCLines);
    setMTTC(someTTCCLines);
    let tva =
      +invoiceData?.inclusiveTaxAmount - +invoiceData?.exclusiveTaxAmount;
    setHtTxt(floatToText(textToFloat(invoiceData.exclusiveTaxAmount + "")));
    setTvaTxt(floatToText(textToFloat(tva + "").toFixed(2)));
    setTtcTxt(floatToText(textToFloat(invoiceData.inclusiveTaxAmount + "")));
  }, [commandesLinesData, updateAmount, update]);

  const isValidDate = (dateString) => {
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;
    var parts = dateString.split("/");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;
    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;
    return day > 0 && day <= monthLength[month - 1];
  };

  const dateToMilliseconds = (date) => {
    if (date) {
      let parts = date.split("/");
      let newDate = new Date(parts[2], parts[1] - 1, parts[0]);
      return newDate.getTime();
    } else {
      return null;
    }
  };

  const floatToText = (nb) => {
    if (nb) {
      let parts = nb.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(",");
    } else {
      return null;
    }
  };

  const textToFloat = (text) => {
    if (text) {
      return parseFloat(text.replace(",", ".").replace(" ", ""));
    } else {
      return null;
    }
  };

  const getSuppliers = () => {
    Axios.get("/entity//suppliers")
      .then((res) => {
        //console.log("supp", res.data.suppliers);
        setSuppliers(res.data.suppliers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClients = () => {
    Axios.get("/entity//clients")
      .then((res) => {
        //console.log("clients", res.data.clients);
        setClients(res.data.clients);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fieldChangeHandler = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "supplierUid":
        setSupplierSelected(value);
        break;
      case "clientUid":
        setClientSelected(value);
        break;
      case "exclusiveTaxAmount":
        setHtTxt(value);
        break;
      case "inclusiveTaxAmount":
        setTtcTxt(value);
        break;
      case "tva":
        setTvaTxt(value);
        break;
      case "issuingDate":
        setIssuingDateTxt(value);
        break;
      case "dueDate":
        setDueDateTxt(value);
        break;
      default:
        setInvoiceData({ ...invoiceData, [name]: value });
        break;
    }
  };

  const onBlurTva = (e) => {
    const { name, value } = e.target;
    let nb = textToFloat(value);
    if (typeof nb === "number") {
      setInvoiceData({ ...invoiceData, [name]: nb });
      setTvaTxt(floatToText(nb));
      setUpdateAmount(!updateAmount);
    } else {
      setTvaTxt(floatToText(invoiceData.taxAmount + ""));
      notyf.error("Valeur incorrecte");
    }
  };

  const onBlurExclusiveTaxAmount = (e) => {
    const { name, value } = e.target;
    let nb = textToFloat(value);
    if (typeof nb === "number") {
      setInvoiceData({ ...invoiceData, [name]: nb });
      setHtTxt(floatToText(nb));
      setUpdateAmount(!updateAmount);
    } else {
      setHtTxt(floatToText(invoiceData.exclusiveTaxAmount + ""));
      notyf.error("Valeur incorrecte");
    }
  };

  const onBlurInclusiveTaxAmount = (e) => {
    const { name, value } = e.target;
    let nb = textToFloat(value);
    if (typeof nb === "number") {
      setInvoiceData({ ...invoiceData, [name]: nb });
      setTtcTxt(floatToText(nb));
      setUpdateAmount(!updateAmount);
    } else {
      setTtcTxt(floatToText(invoiceData.inclusiveTaxAmount + ""));
      notyf.error("Valeur incorrecte");
    }
  };

  const onBlurIssuingDate = (e) => {
    const { name, value } = e.target;
    if (isValidDate(value)) {
      setInvoiceData({ ...invoiceData, [name]: dateToMilliseconds(value) });
      setIssuingDateTxt(value);
    } else {
      setIssuingDateTxt(formatDate(invoiceData.issuingDate));
      notyf.error("Date incorrecte");
    }
  };

  const onBlurDueDate = (e) => {
    const { name, value } = e.target;
    if (isValidDate(value)) {
      setInvoiceData({ ...invoiceData, [name]: dateToMilliseconds(value) });
      setDueDateTxt(value);
    } else {
      setDueDateTxt(formatDate(invoiceData.dueDate));
      notyf.error("Date incorrecte");
    }
  };

  const getCurrencySigne = (c) => {
    if (currency[c]) return currency[c];
    else return "€";
  };

  return (
    <>
      <FContainer>
        <FFormWrap
          style={{
            flexDirection: "initial",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "30%" }}>
            <FormGroupInvInf>
              <FormLabel htmlFor="number">N° de facture</FormLabel>
              <FormInput
                id="numbtexter"
                type="text"
                name="number"
                placeholder="Numéro"
                value={invoiceData?.number || ""}
                onChange={(e) => fieldChangeHandler(e)}
              />
            </FormGroupInvInf>
            <FormGroupInvInf>
              <FormLabel htmlFor="supplierUid">Date de facture</FormLabel>
              <FormInput
                id="issuingDate"
                type="text"
                name="issuingDate"
                placeholder="Numéro"
                value={issuingDateTxt}
                onChange={(e) => fieldChangeHandler(e)}
                onBlur={(e) => onBlurIssuingDate(e)}
              />
            </FormGroupInvInf>
            <FormGroupInvInf>
              <FormLabel htmlFor="supplierUid">Date d'échéance</FormLabel>

              <FormInput
                id="Date d'échéance"
                type="text"
                name="dueDate"
                placeholder="Numéro"
                value={dueDateTxt}
                onChange={(e) => fieldChangeHandler(e)}
                onBlur={(e) => onBlurDueDate(e)}
              />
            </FormGroupInvInf>
            <FormGroupInvInf>
              <FormLabel htmlFor="amount">N° de commande</FormLabel>
              {/* <FormInput
                id="amount"
                type="text"
                name="amount"
                placeholder="Numéro"
                value={invoiceData?.amount || ""}
                onChange={(e) => fieldChangeHandler(e)}
              /> */}
              {commandesLinesData?.length > 0 ? (
                <FormSelect
                  style={{ fontSize: "0.8rem" }}
                  id="commandNumber"
                  name="supplierUid"
                  onChange={(e) => fieldChangeHandler(e)}
                >
                  {commandesLinesData?.map((cmd) =>
                    cmd.uid !== supplierSelected ? (
                      <option key={cmd.uid} value={cmd.uid}>
                        {cmd.number}
                      </option>
                    ) : (
                      <option key={cmd.uid} value={cmd.uid} selected>
                        {cmd.number}
                      </option>
                    )
                  )}
                </FormSelect>
              ) : (
                <FormInput
                  id="amount"
                  type="text"
                  name="amount"
                  placeholder="Numéro"
                  value={invoiceData?.amount || ""}
                  onChange={(e) => fieldChangeHandler(e)}
                />
              )}
            </FormGroupInvInf>
          </div>
          <div style={{ width: "30%" }}>
            <FormGroupInvInf>
              <FormLabel htmlFor="exclusiveTaxAmount">Montant HT</FormLabel>
              <FormInput
                id="exclusiveTaxAmount"
                type="text"
                step="0.01"
                name="exclusiveTaxAmount"
                style={{
                  color:
                    MHT != +invoiceData?.exclusiveTaxAmount
                      ? theme.colors.danger
                      : "",
                }}
                placeholder="Montant HT"
                value={htTxt}
                onChange={(e) => fieldChangeHandler(e)}
                onBlur={(e) => onBlurExclusiveTaxAmount(e)}
              />
              <p
                style={{
                  fontSize: "0.8rem",
                  color:
                    MHT != +invoiceData?.exclusiveTaxAmount
                      ? theme.colors.danger
                      : "",
                }}
              >
                {" " + getCurrencySigne(invoiceData?.currency)}{" "}
              </p>
            </FormGroupInvInf>
            <FormGroupInvInf>
              <FormLabel htmlFor="exclusiveTaxAmount">Montant TVA</FormLabel>
              <FormInput
                id="exclusiveTaxAmount"
                type="text"
                step="0.01"
                style={{
                  color:
                    MTVA !=
                    (+invoiceData?.inclusiveTaxAmount -
                      +invoiceData?.exclusiveTaxAmount).toFixed(2)
                      ? theme.colors.danger
                      : "",
                }}
                name="tva"
                placeholder="Montant TVA"
                value={tvaTxt}
                onChange={(e) => fieldChangeHandler(e)}
                onBlur={(e) => onBlurTva(e)}
              />
              <p
                style={{
                  fontSize: "0.8rem",
                  color:
                    MTVA !=
                    (+invoiceData?.inclusiveTaxAmount -
                      +invoiceData?.exclusiveTaxAmount).toFixed(2)
                      ? theme.colors.danger
                      : "",
                }}
              >
                {" " + getCurrencySigne(invoiceData?.currency)}{" "}
              </p>
            </FormGroupInvInf>
            <FormGroupInvInf>
              <FormLabel htmlFor="inclusiveTaxAmount">Montant TTC</FormLabel>
              <FormInput
                id="inclusiveTaxAmount"
                type="text"
                step="0.01"
                style={{
                  color:
                    MTTC != +invoiceData?.inclusiveTaxAmount
                      ? theme.colors.danger
                      : "",
                }}
                name="inclusiveTaxAmount"
                placeholder="Montant TTC"
                value={ttcTxt}
                onChange={(e) => fieldChangeHandler(e)}
                onBlur={(e) => onBlurInclusiveTaxAmount(e)}
              />
              <p
                style={{
                  fontSize: "0.8rem",
                  color:
                    MTTC != +invoiceData?.inclusiveTaxAmount
                      ? theme.colors.danger
                      : "",
                }}
              >
                {" " + getCurrencySigne(invoiceData?.currency)}{" "}
              </p>
            </FormGroupInvInf>
            <FormGroupInvInf>
              <FormLabel htmlFor="devise">Devise</FormLabel>
              <FormSelect
                style={{ fontSize: "0.8rem" }}
                id="commandNumber"
                name="currency"
                value={invoiceData?.currency || ""}
                onChange={(e) => fieldChangeHandler(e)}
              >
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
              </FormSelect>
            </FormGroupInvInf>
          </div>
          <div style={{ width: "30%" }}>
            <FormGroupInvInf>
              <FormLabel htmlFor="number">Fournisseur </FormLabel>
              <FormSelect
                style={{ fontSize: "0.8rem" }}
                id="commandNumber"
                name="supplierUid"
                onChange={(e) => fieldChangeHandler(e)}
              >
                {suppliers?.map((supplier) =>
                  supplier.uid !== supplierSelected ? (
                    <option key={supplier.uid} value={supplier.uid}>
                      {supplier.name}
                    </option>
                  ) : (
                    <option key={supplier.uid} value={supplier.uid} selected>
                      {supplier.name}
                    </option>
                  )
                )}
              </FormSelect>
            </FormGroupInvInf>
            <FormGroupInvInf>
              <FormLabel htmlFor="number">Entité facturée </FormLabel>
              <FormSelect
                style={{ fontSize: "0.8rem" }}
                id="commandNumber"
                name="clientUid"
                onChange={(e) => fieldChangeHandler(e)}
              >
                {clients?.map((client) =>
                  client.uid !== clientSelected ? (
                    <option key={client.uid} value={client.uid}>
                      {client.name}
                    </option>
                  ) : (
                    <option key={client.uid} value={client.uid} selected>
                      {client.name}
                    </option>
                  )
                )}
              </FormSelect>
            </FormGroupInvInf>
          </div>
        </FFormWrap>
      </FContainer>
    </>
  );
}

export default InformationCommand;
