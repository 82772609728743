// import React from 'react'
// import StatHeader from '../../components/ClientHome/StatHeader/StatHeader';
// import {HomeContainer, HomeHeader, HeaderInfos, HeaderLocation, HeaderTitle, HeaderActions, HeaderAction, SearchInputContainer, SearchInput, ActionWrapper, ActionLabel, HomeBody, HomeCards, HomeStats, HomeInvoices} from '../Home/Home.styled'
// import BarChart from '../../components/ClientHome/StatBody/Charts/BarChart'
// import { connect } from 'react-redux';
// import Events from '../../components/Home/Events/Events';

// function ClientHome({userType}) {
//     return (
//         <>
//         <HomeContainer>
//             <HomeHeader>
//                 <HeaderInfos>
//                     <HeaderLocation>
//                         {userType === 'owner' ? 'KPI' : 'Accueil'} 
//                     </HeaderLocation>
//                     <HeaderTitle>
//                         {userType === 'owner' ? '' : 'Portail'}
//                     </HeaderTitle>
//                 </HeaderInfos>
//                 <HeaderActions>
//                     <HeaderAction>
//                         {/* <Select 
//                             options={options} 
//                             value={options.find(option => option.value === value)?.label} 
//                             onChange={selectChange} 
//                         /> */}
//                     </HeaderAction>
//                 </HeaderActions>
//             </HomeHeader>
//             <HomeBody>
//                 <HomeCards>
//                       <StatHeader userType={userType} />
//                 </HomeCards>
//                 <HomeStats style={{height:"380px"}}>
//                      <BarChart userType={userType}/>
//                      {
//                          userType === 'client' && 
//                             <Events />
//                      }
//                 </HomeStats>

            
//             </HomeBody>
//         </HomeContainer>
//     </>
//     )
// }

// const mapStateToProps = (state) => ({
//     userType : state.auth.userType,
//     login : state.auth.login
// })

// const mapDispatchToProps = dispatch => ({
// })
// export default connect(mapStateToProps, mapDispatchToProps) (ClientHome)
import React, { useEffect, useState } from 'react';
import Axios from '../../axios-proas'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { HomeContainer, HomeHeader, HeaderInfos, HeaderLocation, HeaderTitle, HeaderActions, FirstDiv ,RowDivContainer, SecendDiv,HeaderAction, SearchInputContainer, SearchInput, ActionWrapper, ActionLabel, HomeBody, HomeCards, HomeStats, ISNavContainer, HomeInvoices, NavTabsLi, NavTabsLink, NavTabsUl } from './HomeSupplier.styled'
import Select from '../../components/UI/Select/Select';
import Card from '../../components/PDPDashboard/Card/Card';
import LineChart from '../../components/PDPDashboard/LineChart/LineChart';
import Events from './Events/Events';
import Tasks from './Events/Tasks';


import { useTheme } from 'styled-components';
import { getNotyfObject } from '../../shared/utility';
import { Link } from 'react-router-dom';
import { SupplierLink, SupplierUsersLink } from '../ReferentielFournisseur/ReferentielFournisseur.Styled';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { formatDateEur } from '../../shared/utility'
import { BtnAction } from '../../styles/Common';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Home.css'
import StatisticsModal from './StatisticsModal/StatisticsModal'
import SortableList, { SortableItem } from "react-easy-sort";
import {arrayMoveImmutable} from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faDolly, faPersonRays, faUserGear } from '@fortawesome/free-solid-svg-icons'
import BarChart from './BarChart/BarChart';
import LastInvoicesClient from '../Home/LastInvoicesClient/LastInvoicesClient';
import LastInvoices from '../Home/LastInvoices/LastInvoices';
function ClientHome() {
    const theme = useTheme();
    const notyf = getNotyfObject()
    const [value, setValue] = useState('lastSevenDays');
 
    const [toBepaidInvoices, setToBePaidInvoices] = useState(0);
    const [litigationInvoices, setLitigationInvoices] = useState(0);
    const [horsDelaiIvoices, setHorsDelaiIvoices] = useState(0);
    const [totalToBePayed, setTotalToBePayed] = useState(0);
    

    const [showStatisticsModal, setShowStatisticsModal] = useState(false);
    const defaultTime = new Date();
    defaultTime.setFullYear(defaultTime.getFullYear() - 1);
    const [startDate, setStartDate] = useState(formatDateEur(defaultTime.getTime()));
    const [endDate, setEndDate] = useState(formatDateEur(new Date().getTime()));
    const [eventData, setEventData] = useState();
    const [invoicesInValidation,setInvoicesInValidation] = useState(0);
    const [invoicesInToBePaid,setInvoicesInToBePaid] = useState(0);
    const [arrayCards,setArrayCards]=useState(["Facture_a_payer", "Facture_en_litige", "Facture_hors_delais","Somme_des_créances"])
    const options = [
        { label: 'Ces 7 derniers jours', value: 'lastSevenDays' },
        { label: 'Ce mois-ci', value: 'thisMonth' },
        { label: 'Mois précédent', value: 'lastMonth' },
    ];
    const [showLastSupplpier, setShowLastSupplpier] = useState(false);  

    const interval = (date) => {

        return formatDateEur(date)
    }

    const selectChange = (value) => {
        const Landing = new Date();
        const dateNow = Landing.getTime();

        setValue(value);
        switch (value) {
            case "lastMonth":
                Landing.setFullYear(Landing.getFullYear() - 4);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            case "thisMonth":
                Landing.setDate(Landing.getDate() - 30);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            case "lastSevenDays":
                Landing.setDate(Landing.getDate() - 7);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            default:
                break;
        }

    }

    const horsDelaisInvoice = () => {
        Axios.get("/invoice//horsDelaisInvoice", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            setHorsDelaiIvoices(res?.data?.total)
            if (res?.data?.total) {
                setHorsDelaiIvoices(res.data.total)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }

    const litigInvoice = () => {
        Axios.get("/invoice//litigInvoice", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            if (res?.data?.total) {
                setLitigationInvoices(res.data.total)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }
  
    const toBePaidInvoice = () => {
        Axios.get("/invoice//toBePaidInvoice", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            if (res?.data?.total) {
                setToBePaidInvoices(res.data.total)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }
    const totalToBePaid = () => {
        Axios.get("/invoice//totalToBePaid", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            if (res?.data?.total) {
                setTotalToBePayed(res.data.total)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }

    useEffect(() => {
        totalToBePaid();
        toBePaidInvoice();
        litigInvoice()
        horsDelaisInvoice()
       
    }, [value]);

    const onChangeHandler = () => {

    }
   
    const onSortEnd = (oldIndex, newIndex) => {
        setArrayCards((array) => arrayMoveImmutable(array, oldIndex, newIndex));
        
      };
    //   const getTauxLitige = (allInvoices,litigationInvoices) => {
    //     if (allInvoices === 0) {
    //         return 0;
    //     } else {
    //         return (litigationInvoices / allInvoices) * 100;
    //     }

    //   }
    
    const getCardByItem = (item) => {
        switch (item) {
                case "Facture_a_payer":
                    return (  <Card
                                icon={<FontAwesomeIcon icon={faDolly} style={{ color: "#809FB8" }}/>}
                                title="Factures à payer"
                                value= {toBepaidInvoices}
                            />)
                case "Facture_en_litige":
                    return  (<Card
                                icon={<FontAwesomeIcon icon={faDolly} style={{ color: "#809FB8" }}/>}
                                title="Facture en litige"
                                value={litigationInvoices}
                            />)
                case "Facture_hors_delais":
                    return  (<Card
                                icon={<FontAwesomeIcon icon={faDolly} style={{ color: "#809FB8" }}/>}
                                title="Factures hors délais"
                                value={horsDelaiIvoices}
                            />)
                case "Somme_des_créances":
                    return (<Card
                                icon={<FontAwesomeIcon icon={faPersonRays} style={{ color: "#809FB8" }}/>}
                                title="Somme des créances"
                           
                                value={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Math.round(totalToBePayed))}
                            />)
                default:
      }}
    return (
        <>
            <HomeContainer>
                <HomeHeader>
                    <HeaderInfos>
                        <HeaderLocation>Accueil</HeaderLocation>
                        <HeaderTitle>Portail </HeaderTitle>
                    </HeaderInfos>
                    <HeaderActions>
                        <HeaderAction>
                            <Select
                                width="210px"
                                options={options}
                                value={options.find(option => option.value === value)?.label}
                                onChange={selectChange}
                            />
                        </HeaderAction>
                        {/* <HeaderAction>
                        <BtnAction
                            onClick={lunchOcr}
                            color={theme.colors.success}
                        >
                            Lancer l'OCR
                        </BtnAction>
                    </HeaderAction> */}
                    </HeaderActions>
                </HomeHeader>
                <HomeBody> 
                    <RowDivContainer>
                        <FirstDiv>
                            {/* <HomeStats> */}
                            <Tasks title={"Mes tâches"} events={eventData} others={false} />
                            <Events title={"Evènements"} events={eventData} others={true}/>
                            {/* </HomeStats> */}
                        </FirstDiv>
                        <SecendDiv >
                            <div style={{ display: 'flex', width: '100%'}}>
                                <p style={{ textAlign: 'center', color: '#2174B9', width: "inherit", fontSize: "1rem" }}>Mes statistiques</p>
                                    {/* <PersonAddIcon className="iconStyle" onClick={(e) => setShowStatisticsModal(!showStatisticsModal)}/> */}
                                    <FontAwesomeIcon icon={faUserGear} className="iconStyle" onClick={(e) => setShowStatisticsModal(!showStatisticsModal)}/>
                            </div>
                            <HomeCards> 
                                <SortableList
                                    onSortEnd={onSortEnd}
                                    className="list"
                                    draggedItemClassName="dragged"
                                    >
                                            {/* arrayCards=["Facture_a_payer", "Facture_en_litige", "Facture_en_validation","Factures_à_régler"] */}
                                            {arrayCards.map((item) => (
                                                    <SortableItem key={item} style={{display:"flex",width:"100%"}}>
                                                        <div style={{display:"flex"}}>
                                                            {getCardByItem(item)}
                                                        </div>
                                                    </SortableItem>)
                                               
                                              
                                            // 
                                            )}
                                    </SortableList>
                               
                            </HomeCards>
                            <div style={{ display: 'flex' }}>
                                <select
                                    className="selectForm selectStyle"
                                    name="family"
                                    onChange={e => onChangeHandler(e)}
                                // value={}
                                >
                                    {/* <option value="" key={0}>--- Selectionner un type ---</option> */}
                                    <option value="" key={1}>Statistique : Nombre de factures en litige suivant périod</option>
                                    {/* <option value="" key={2}>Analyse des canaux de réception</option>
                                    <option value="" key={3}>Analyse des délais de réception facture</option> */}
                                    <option value="" key={4}>Analyse des risques aux paiements</option>
                                    <option value="" key={5}>Analyse du taux d'extraction</option>
                                    <option value="" key={6}>Analyse des typologies</option>
                                </select>
                                <OpenInFullIcon className="iconStyle" />
                            </div>
<BarChart/>
                            {/* <div style={{ padding: "23px 5px" }}>
                                <div className="typeLine"  >
                                    <p className="space">20/11/2022</p>
                                    <p style={{ color: "#ffffff", width: "113px" }} className="label_add">2 545 €</p>
                                    <p style={{ color: "rgb(200, 176, 230)" }} className="space specElement" >Novembre : 20 810 €</p>
                                </div>
                                <div className="typeLine">
                                    <p className="space">30/11/2022</p>
                                    <p style={{ color: "#ffffff", width: "700px" }} className="label_add">18 265 €</p>
                                    <p className="space"></p>
                                </div>
                                <div className="typeLine">
                                    <p className="space">08/12/2022</p>
                                    <p style={{ backgroundColor: theme.colors.gray, color: "#505050", width: "230px" }} className="label_add ">5 435 €</p>
                                    <p className="space specElement">Décembre : 20 810 €</p>
                                </div>
                                <div className="typeLine">
                                    <p className="space">15/12/2022</p>
                                    <p style={{ backgroundColor: theme.colors.gray, color: "#505050", width:"330px" }} className="label_add">7 800 €</p>
                                    <p className="space"></p>
                                </div>
                                <div className="typeLine">
                                    <p className="space">31/12/2022</p>
                                    <p style={{ backgroundColor: theme.colors.gray, color: "#505050", width:"900px" }} className="label_add">22 376 €</p>
                                    <p className="space"></p>
                                </div>
                            </div> */}

                        </SecendDiv>
                    </RowDivContainer>
                    <HomeInvoices active={showLastSupplpier}>
                        <div style={{color:"#2174B9",textAlign: "center"}}>
                            <span onClick={e => setShowLastSupplpier(!showLastSupplpier)} style={{cursor:"pointer"}}>
                                Dernières factures <ExpandMoreIcon />
                            </span>
                            {
                                showLastSupplpier && 
                                <>
                                    
                                     <LastInvoicesClient  invoiceCheckBox ={true} entityFilter={"client"}keyPath={"invoices"} NoshowTitel={true}/>
                                
                                </>
                            }
                        </div>
                    </HomeInvoices>
                    {/* <HomeInvoices style={{ marginTop: "10px" }}>
                        <div>
                            <span onClick={e => setShowLastSupplpier(!showLastClient)}>click me</span>
                            {
                                showLastClient &&
                                    <LastInvoicesClient invoiceCheckBox={true} entityFilter={"client"} />
                            }
                        </div>
                    </HomeInvoices> */}

                </HomeBody>
                {
                    showStatisticsModal &&
                        <StatisticsModal 
                            show={showStatisticsModal}
                            handleClose={() => setShowStatisticsModal(false)}
                            // addRecipientsHandler={addRecipientsHandler}
                        />
                }

            </HomeContainer>
        </>
    );
}

export default ClientHome;
