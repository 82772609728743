import React, { useEffect, useState } from 'react'

import Axios from '../../axios-proas';

import Grid from '@mui/material/Grid';

import DropDownInfo from '../../components/ExtractionForm/DropDownInfo/DropDownInfo';
import { formatDate, formatNumber, getNotyfObject } from '../../shared/utility';
import Spinner from 'react-bootstrap/Spinner'

import frLocale from "date-fns/locale/fr";
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { CFFormGroup,SousContent,Content, EFContainer ,EFCol6, EntFormItem, EntFormList, EntFormSection, EFRow, EFBody, EFHeaderTitle} from './ExtractionForm.Styled'
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle,PrimaryBtnOutlineAction, BtnAction, FormGroup, FormInput, FormLabel, FormSelect, FormIntervalLabel, FormIntervalValues, FormIntervalStart, FormInterval, FormIntervalEnd, SecondaryBtnOutlineAction } from '../../styles/Common';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DPContainer } from '../CampaignForm/CampaignForm.styled';
import MandatoryMarker from '../../components/UI/MandatoryMarker';

function ExtractionForm() {
    let notyf = getNotyfObject();
    const navigate = useNavigate();
    const [options, setOptions] = useState(null)
    const currentDate = new Date();
    const [startIssuingDateError, setStartIssuingDateError] = useState(false);
    const [endIssuingDateError, setEndIssuingDateError] = useState(false);
    const [startCreationDateError, setStartCreationDateError] = useState(false);
    const [endCreationDateError, setEndCreationDateError] = useState(false);
    const [errors, setErrors] = useState(null);
    const [createEctractionLoading, setCreateExtractionLoading] = useState(false)
    const [extractionData, setExtractionData] = useState(null);


    let location = useLocation(); 
    let {uid} = useParams(); 

    useEffect(() => {
        if(uid !=null){
            Axios.get("extraction/"+uid+"/extractionByUid").then(response => {
                setExtractionData(response.data.result)
            }).catch(response => {
                notyf.error("Une erreur s'est produite")
            })
        }
    }, [])

   

    const [extraction, setExtraction] = useState({
        invoiceType: "sent",
        numberStart: null,
        numberEnd: null,
        issuingDateStart: currentDate.getTime(),
        issuingDateEnd: new Date(new Date().setFullYear(new Date().getFullYear() , 12, 0 )).getTime(),
        creationDateStart: currentDate.getTime(),
        creationDateEnd: new Date(new Date().setFullYear(new Date().getFullYear() , 12, 0 )).getTime(),
        exclusiveTaxAmountStart: null,
        exclusiveTaxAmountEnd: null,
        inclusiveTaxAmountStart: null,
        inclusiveTaxAmountEnd: null,
        supplierCode: null,
        supplierName: null,
        supplierVAT: null,
        supplierSiret: null,
        clientCode: null,
        clientName: null,
        clientVAT: null,
        clientSiret: null
    });

    useEffect(() => {
        Axios.get('extraction//extractionOptions').then(response => {
            let optionsTemp = response?.data?.options;
            if(optionsTemp){
                setOptions(optionsTemp)
                setExtraction({
                    ...extraction,
                    issuingDateStart: +optionsTemp?.issuingDateMin,
                    issuingDateEnd: +optionsTemp?.issuingDateMAx,
                    creationDateStart: +optionsTemp?.creationDateMin,
                    creationDateEnd: +optionsTemp?.creationDateMAx
                })
            }else{
                notyf.error("Une erreur s'est produite")
            }
        }).catch(response => {
            notyf.error("Une erreur s'est produite")
        })
    }, [])

    //issuing date
    const handleStartIssuingDateChange = (date) => {
        if (date.toString() === "Invalid Date"){
            setStartIssuingDateError(true)
        } else 
        if(date.getFullYear <= 1900 || date.getFullYear > 2100){
            setStartIssuingDateError(true)
        }else {
            setStartIssuingDateError(false)
        }
        // setSelectedStartIssuingDate(date);
        setExtraction({
            ...extraction,
            issuingDateStart: date.getTime()
        })
    };

    const handleEndIssuingDateChange = (date) => {
        if (date.toString() === "Invalid Date"){
            setEndIssuingDateError(true)
        } else 
        if(date.getTime() < extraction.issuingDateStart || date.getFullYear > 2100 ){
            setEndIssuingDateError(true)
        }else {
            setEndIssuingDateError(false)
        }
        // setSelectedEndIssuingDate(date);
        setExtraction({
            ...extraction,
            issuingDateEnd: date.getTime()
        })
    };

    const generateMaxEndIssuingDate = () => {
        let startDate = new Date(extraction.issuingDateStart);
        if(options?.issuingDateMAx) 
            return new Date(+options?.issuingDateMAx) 
        else
            return new Date( startDate.setFullYear(startDate.getFullYear() , 12, 0 ))
    }

    //creation date
    const handleStartCreationDateChange = (date) => {
        if (date.toString() === "Invalid Date"){
            setStartCreationDateError(true)
        } else 
        if(date.getFullYear <= 1900 || date.getFullYear > 2100){
            setStartCreationDateError(true)
        }else {
            setStartCreationDateError(false)
        }
        // setSelectedStartCreationDate(date);
        setExtraction({
            ...extraction,
            creationDateStart: date.getTime()
        })
    };

    const handleEndCreationDateChange = (date) => {
        if (date.toString() === "Invalid Date"){
            setEndCreationDateError(true)
        } else 
        if(date.getTime() < extraction.creationDateStart || date.getFullYear > 2100 ){
            setEndCreationDateError(true)
        }else {
            setEndCreationDateError(false)
        }
        // setSelectedEndCreationDate(date);
        setExtraction({
            ...extraction,
            creationDateEnd: date.getTime()
        })
    };

    const generateMaxEndCreationDate = () => {
        let startDate = new Date(extraction.creationDateStart);
        if(options?.creationDateMAx) 
            return new Date(+options?.creationDateMAx) 
        else
            return new Date( startDate.setFullYear(startDate.getFullYear() , 12, 0 ))
    }

    const invoiceTypeChangeHandler = (type) => {
        setExtraction({
            ...extraction,
            invoiceType: type
        })
    }

    const invoiceNumberChangeHandler = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setExtraction({
            ...extraction,
            [field] : value
        })
    }

    const amountChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setExtraction({
            ...extraction,
            [name] : value
        })
        // if(parseFloat(value) < parseFloat(options.exclusiveAmountMin) || parseFloat(value) > parseFloat(options.exclusiveAmountMAx) ) {
        //     setExclusiveAmountError(`Le montant doit étre entre ${options.exclusiveAmountMin} et ${options.exclusiveAmountMAx}`);
        // }
    }

    const entityFilterChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setExtraction({
            ...extraction,
            [name] : value
        })
    }

    const checkExtractionValidity = () => {
        let errorsTemp = [];
        if(startIssuingDateError || endIssuingDateError){
            errorsTemp.push(`Veuillez saisir une date de facture entre ${formatDate(+options.issuingDateMin)} et ${formatDate(+options.issuingDateMAx)}`)
        }
        if(startCreationDateError || endCreationDateError){
            errorsTemp.push(`Veuillez saisir une date de facture entre ${formatDate(+options.creationDateMin)} et ${formatDate(+options.creationDateMAx)}`)
        }
        if((extraction.exclusiveTaxAmountStart && !extraction.exclusiveTaxAmountEnd) || (!extraction.exclusiveTaxAmountStart && extraction.exclusiveTaxAmountEnd)){
            errorsTemp.push("vous devez saisir une valeur minimale et maximale pour le champ mantant HT")
        }
        if(extraction.exclusiveTaxAmountStart 
            && ((
                parseFloat(extraction.exclusiveTaxAmountStart) < parseFloat(options.exclusiveAmountMin) 
                || parseFloat(extraction.exclusiveTaxAmountStart) > parseFloat(options.exclusiveAmountMAx)
            ) 
            || (
                parseFloat(extraction.exclusiveTaxAmountEnd) < parseFloat(extraction.exclusiveTaxAmountStart) 
                || parseFloat(extraction.exclusiveTaxAmountEnd) > parseFloat(options.exclusiveAmountMAx)
            ))
        ) {
            errorsTemp.push(`Le montant HT doit étre entre ${options.exclusiveAmountMin} et ${options.exclusiveAmountMAx}`);
        }
        // if(extraction.exclusiveTaxAmountEnd 
        //     && (
        //         parseFloat(extraction.exclusiveTaxAmountEnd) < parseFloat(extraction.exclusiveTaxAmountStart) 
        //         || parseFloat(extraction.exclusiveTaxAmountEnd) > parseFloat(options.exclusiveAmountMAx)
        //     ) 
        // ) {
        //     errorsTemp.push(`Le montant HT doit étre entre ${options.exclusiveAmountMin} et ${options.exclusiveAmountMAx}`);
        // }
        if((extraction.inclusiveTaxAmountStart && !extraction.inclusiveTaxAmountEnd) || (!extraction.inclusiveTaxAmountStart && extraction.inclusiveTaxAmountEnd)){
            errorsTemp.push("vous devez saisir une valeur minimale et maximale pour le champ mantant HT")
        }
        if(extraction.inclusiveTaxAmountStart 
            && ((
                parseFloat(extraction.inclusiveTaxAmountStart) < parseFloat(options.inclusiveAmountMin) 
                || parseFloat(extraction.inclusiveTaxAmountStart) > parseFloat(options.inclusiveAmountMax)
            ) 
            ||
            (
                parseFloat(extraction.inclusiveTaxAmountEnd) < parseFloat(extraction.inclusiveTaxAmountStart) 
                || parseFloat(extraction.inclusiveTaxAmountEnd) > parseFloat(options.inclusiveAmountMax)
            ))
        ) {
            errorsTemp.push(`Le montant TTC doit étre entre ${options.inclusiveAmountMin} et ${options.inclusiveAmountMax}`);
        }
        return errorsTemp;
    }

    const createExtraction = () => {
        setCreateExtractionLoading(true)
        Axios.post('extraction', extraction).then(response => {
            setCreateExtractionLoading(false);
            if(response?.data?.success){
                notyf.success("La demande d'extraction a bien été crée")
                // setCampaign({
                //     startDate: selectedStartDate.getTime(),
                //     endDate: selectedEndDate.getTime(),
                //     attachments: [],
                //     recipients: [],
                //     title: '',
                //     text: ''
                // })
                // history.goBack();
            }else if(parseInt(response?.data?.invoices) === 0){
                setErrors(["Aucune facture trouvée avec ces filtres"])
            }
        }).catch(response => {
            notyf.error("Une erreur s'est produite")
            setCreateExtractionLoading(false)
        })
    }
    const submitExtractionClickHandler = () => {
        let errorsTemp = checkExtractionValidity();
        if(errorsTemp.length > 0){
            setErrors(errorsTemp)
        }else {
            setErrors([])
            createExtraction()
        }
    }
    

            

          

    return (

    <EFContainer>
        <Header>
            <HeaderInfos>
                <EFHeaderTitle > {extractionData?.uid? <span>Édition demande d’extraction</span> : <span>Nouvelle demande d’extraction</span>}
                    </EFHeaderTitle>
            </HeaderInfos>
            <HeaderActions>
            {!extractionData?.uid ?
                <PrimaryBtnOutlineAction
                    onClick={() => submitExtractionClickHandler()}
                >
                    <span>Demander</span>
                </PrimaryBtnOutlineAction>
                : 
                <PrimaryBtnOutlineAction
                >
                    <span>Enregistrer</span>
                </PrimaryBtnOutlineAction>

            }
                <SecondaryBtnOutlineAction
                    onClick={() => navigate(-1)}
                >
                    <span>Retour</span>
                </SecondaryBtnOutlineAction>      
            </HeaderActions>
        </Header>
        <EFBody>
            {
                errors && errors.length > 0 ? (
                    <HeaderTitle className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                        <ul>
                            {
                                errors.map(error => (<li key={error}>{error}</li>))
                            }
                        </ul>
                    </HeaderTitle>
                ) : null
            }
            <EFRow>
                <EFCol6>
                    <Content>
                    
                        <FormGroup className="mb-4">
                            <FormLabel htmlFor="family" >Type de facture</FormLabel>
                            <FormSelect 
                                id="family"
                                name="family"
                                value={extractionData?.invoiceType || extraction.invoiceType || ""}
                                onChange={(e) => invoiceTypeChangeHandler(e.target.value)}
                            >
                                    <option value="sent">Émises</option>
                                    <option value="recieved">Reçues</option>
                            </FormSelect>
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <FormLabel htmlFor="titel" >Titre<MandatoryMarker/></FormLabel>
                            <FormInput 
                                        id="titel"
                                        style={{textAlign: 'end'}}
                                        type="text" 
                                        name="titel" 
                                        value={extractionData?.titel || extraction.titel || ''} 
                                        onChange={(e) => invoiceNumberChangeHandler(e)}      
                                    />
                        </FormGroup>
                        <FormInterval>
                            <FormIntervalLabel >
                                Numéro de la factures
                                <DropDownInfo min={options?.invoiceNumberMin || ''} max={options?.issuingDateMAx || ''} />
                            </FormIntervalLabel>
                            <FormIntervalValues>
                                <FormIntervalStart>
                                    <FormLabel className="mb-2" htmlFor="numberStart" >Début</FormLabel>
                                    <FormInput 
                                        id="numberStart"
                                        style={{textAlign: 'start'}}
                                        type="text" 
                                        name="numberStart" 
                                        value={extractionData?.numberStart || extraction.numberStart || ''} 
                                        onChange={(e) => invoiceNumberChangeHandler(e)}      
                                    />
                                </FormIntervalStart>
                                <FormIntervalEnd>
                                    <FormLabel className="mb-2" htmlFor="numberEnd" >Fin</FormLabel>
                                    <FormInput 
                                        id="numberEnd"
                                        style={{textAlign: 'start'}}
                                        name="numberEnd" 
                                        value={extractionData?.numberEnd ||extraction.numberEnd || ''} 
                                        onChange={(e) => invoiceNumberChangeHandler(e)}
                                    />
                                </FormIntervalEnd>
                            </FormIntervalValues>
                        </FormInterval>
                        <FormGroup className="mb-4">
                            <FormLabel htmlFor="name" >Date de la facture</FormLabel>
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                <DPContainer> 
                                    <DatePicker
                                            //margin="normal"
                                            className="extractionForm__datepicker"
                                            id="date-picker-dialog"
                                            label="Début"
                                            format="dd/MM/yyyy"
                                             minDate={new Date(+options?.issuingDateMin) || currentDate}
                                             maxDate={generateMaxEndIssuingDate()}
                                            value={extractionData?.uid ?new Date(+extractionData?.issuingDateStart) : new Date(extraction.issuingDateStart)}
                                            onChange={handleStartIssuingDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            invalidDateMessage="Format de date invalide"
                                            maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                            minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </DPContainer> 
                                <DPContainer> 
                                    <DatePicker
                                        //margin="normal"
                                        className="extractionForm__datepicker"
                                        id="date-picker-dialog"
                                        label="Fin"
                                        format="dd/MM/yyyy"
                                        value={extractionData?.uid ? new Date(+extractionData?.issuingDateEnd) : new Date(extraction.issuingDateEnd)}
                                        onChange={handleEndIssuingDateChange}
                                        KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                        }}
                                        minDate={new Date(extraction.issuingDateStart)}
                                        maxDate={generateMaxEndIssuingDate()}
                                        invalidDateMessage="Format de date invalide"
                                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </DPContainer> 
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className="mb-4">
                                <FormLabel htmlFor="name" >Date de la constitution</FormLabel>         
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                <DPContainer>
                                    <DatePicker
                                        //margin="normal"
                                        className="extractionForm__datepicker"
                                        id="date-picker-dialog"
                                        label="Début"
                                        format="dd/MM/yyyy"
                                        minDate={new Date(+options?.creationDateMin) || currentDate}
                                        maxDate={generateMaxEndCreationDate()}
                                        value={extractionData?.uid ? new Date(+extractionData?.creationDateStart) : new Date(extraction.creationDateStart)}
                                        onChange={handleStartCreationDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        invalidDateMessage="Format de date invalide"
                                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </DPContainer>
                                <DPContainer>
                                    <DatePicker
                                        //margin="normal"
                                        className="extractionForm__datepicker"
                                        id="date-picker-dialog"
                                        label="Fin"
                                        format="dd/MM/yyyy"
                                        value={extractionData?.uid ? new Date(+extractionData?.creationDateEnd):new Date(extraction.creationDateEnd)}
                                        onChange={handleEndCreationDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        minDate={new Date(extraction.creationDateStart)}
                                        maxDate={generateMaxEndCreationDate()}
                                        invalidDateMessage="Format de date invalide"
                                        maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                        minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </DPContainer>
                            </LocalizationProvider>
                        </FormGroup>
                        <FormInterval>
                            <FormIntervalLabel >
                                Montant HT
                                <DropDownInfo 
                                        min={formatNumber(parseFloat(options?.exclusiveTaxAmountStart)) || 0} 
                                        max= {formatNumber(parseFloat(options?.exclusiveTaxAmountStart)) || 0}  />
                            </FormIntervalLabel>
                            <FormIntervalValues>
                                <FormIntervalStart>
                                    <FormLabel className="mb-2" htmlFor="exclusiveTaxAmountStart" >Minimum</FormLabel>
                                    <FormInput   
                                        id="exclusiveTaxAmountStart" 
                                        style={{textAlign: 'start'}}
                                        type="number" 
                                        name="exclusiveTaxAmountStart" 
                                        value={extractionData?.exclusiveTaxAmountStart||extraction.exclusiveTaxAmountStart} 
                                        onChange={(e) => amountChangeHandler(e)}  
                                        step="any"  
                                    />
                                </FormIntervalStart>
                                <FormIntervalEnd>
                                    <FormLabel className="mb-2" htmlFor="exclusiveTaxAmountEnd" >Maximum</FormLabel>
                                    <FormInput    
                                        id="exclusiveTaxAmountEnd"
                                        style={{textAlign: 'start'}}
                                        type="number" 
                                        name="exclusiveTaxAmountEnd" 
                                        value={extractionData?.exclusiveTaxAmountEnd||extraction.exclusiveTaxAmountEnd} 
                                        onChange={(e) => amountChangeHandler(e)} 
                                        step="any" 
                                    />
                                </FormIntervalEnd>
                            </FormIntervalValues>
                        </FormInterval>
                        <FormInterval>
                            <FormIntervalLabel >
                                Montant TTC
                                <DropDownInfo 
                                        min={formatNumber(parseFloat(options?.inclusiveAmountMin)) || 0} 
                                        max= {formatNumber(parseFloat(options?.inclusiveAmountMax)) || 0}  />
                            </FormIntervalLabel>
                            <FormIntervalValues>
                                <FormIntervalStart>
                                    <FormLabel className="mb-2" htmlFor="inclusiveTaxAmountStart" >Minimum</FormLabel>
                                    <FormInput    
                                        id="inclusiveTaxAmountStart"
                                        type="number"
                                        style={{textAlign: 'start'}} 
                                        name="inclusiveTaxAmountStart" 
                                        value={extractionData?.inclusiveTaxAmountStart||extraction.inclusiveTaxAmountStart} 
                                        onChange={(e) => amountChangeHandler(e)} 
                                        step="any" 
                                    />
                                </FormIntervalStart>
                                <FormIntervalEnd>
                                    <FormLabel className="mb-2" htmlFor="inclusiveTaxAmountEnd" >Maximum</FormLabel>
                                    <FormInput 
                                        id="inclusiveTaxAmountEnd" 
                                        type="number" 
                                        style={{textAlign: 'start'}}
                                        name="inclusiveTaxAmountEnd" 
                                        value={extractionData?.inclusiveTaxAmountEnd||extraction.inclusiveTaxAmountEnd} 
                                        onChange={(e) => amountChangeHandler(e)} 
                                        step="any"
                                    />
                                </FormIntervalEnd>
                            </FormIntervalValues>
                        </FormInterval>
                    </Content>
                </EFCol6>
                <EFCol6>
                    <SousContent>
                        <FormGroup>
                            <FormLabel >Vendeur</FormLabel>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="supplierCode" >Identifiant EDI</FormLabel>
                            <FormInput 
                                id="supplierCode"
                                type="text" 
                                name="supplierCode" 
                                value={extractionData?.supplierCode||extraction?.supplierCode} 
                                onChange={(e) => entityFilterChangeHandler(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="supplierName" >Nom</FormLabel>
                                <FormInput 
                                    id="supplierName"
                                    type="text" 
                                    name="supplierName"
                                    value={extractionData?.supplierName||extraction?.supplierName} 
                                    onChange={(e) => entityFilterChangeHandler(e)}
                                
                                />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="supplierVAT" >Numéro TVA</FormLabel>
                                <FormInput 
                                    id="supplierVAT"
                                    type="text" 
                                    name="supplierVAT" 
                                    value={extractionData?.supplierVAT||extraction.supplierVAT} 
                                    onChange={(e) => entityFilterChangeHandler(e)}
                                
                                />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="supplierSiret" >Numéro SIRET</FormLabel>
                            <FormInput 
                                id="supplierSiret"
                                type="text" 
                                name="supplierSiret" 
                                value={extractionData?.supplierSiret||extraction.supplierSiret} 
                                onChange={(e) => entityFilterChangeHandler(e)}
                            
                            />
                        </FormGroup>
                    </SousContent>
                    <SousContent>
                        <FormGroup>
                            <FormLabel >Acheteur</FormLabel>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="clientCode" >Identifiant EDI</FormLabel>
                            <FormInput   
                                id="clientCode" 
                                type="text" 
                                name="clientCode" 
                                value={extractionData?.clientCode||extraction?.clientCode} 
                                onChange={(e) => entityFilterChangeHandler(e)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="clientName" >Nom</FormLabel>
                            <FormInput    
                                id="clientName"
                                type="text" 
                                name="clientName" 
                                value={extractionData?.clientName||extraction?.clientName} 
                                onChange={(e) => entityFilterChangeHandler(e)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="clientVAT" >Numéro TVA</FormLabel>
                            <FormInput    
                                id="clientVAT"
                                type="text" 
                                name="clientVAT" 
                                value={extractionData?.clientVAT|| extraction.clientVAT} 
                                onChange={(e) => entityFilterChangeHandler(e)} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="clientSiret" >Numéro SIRET</FormLabel>
                            <FormInput   
                                id="clientSiret" 
                                type="text"
                                name="clientSiret" 
                                value={extractionData?.clientSiret||extraction?.clientSiret} 
                                onChange={(e) => entityFilterChangeHandler(e)}
                            />
                        </FormGroup>
                    </SousContent>
                </EFCol6>
            </EFRow>
        </EFBody>
    </EFContainer>
        // 
        //             
        //             <div className="extractionForm__entityFilter_container">
        //                 <div className="extractionForm__entityFilter_title">Acheteur</div>
        //                 <div className="extractionForm__entityFilter_filters">
        //                     <input type="text" name="clientCode" value={extraction.clientCode} onChange={(e) => entityFilterChangeHandler(e)} className="form-control" placeholder="Identifiant EDI" />
        //                     <input type="text" name="clientName" value={extraction.clientName} onChange={(e) => entityFilterChangeHandler(e)} className="form-control" placeholder="Nom" />
        //                     <input type="text" name="clientVAT" value={extraction.clientVAT} onChange={(e) => entityFilterChangeHandler(e)} className="form-control" placeholder="Numéro TVA" />
        //                     <input type="text" name="clientSiret" value={extraction.clientSiret} onChange={(e) => entityFilterChangeHandler(e)} className="form-control" placeholder="Numéro Siret" />
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="extractionForm__footer">
        //             <div className="extractionForm__btn_container">
        //                 {
        //                     createEctractionLoading ? (
        //                         <div style={{
        //                             width: "100%",
        //                             display: "flex",
        //                             justifyContent: "center"
        //                         }}>
        //                             <Spinner animation="border" variant="primary" />
        //                         </div>
        //                     ) 
        //                     : <button className="btn btn-outline-primary" onClick={() => submitExtractionClickHandler()} >Demander</button>
        //                 }
        //                 <button className="btn btn-secondary" onClick={() => history.goBack()} >Annuler</button>
        //             </div>
        //         </div>
        //     </div>
        // </div>
      
    )
}

export default ExtractionForm
