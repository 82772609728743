import React, { useState, useRef,useEffect } from 'react';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import Axios from "../../axios-proas";
import {Notyf} from "notyf";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Viewer, pdfjs, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import InfoModal from '../../components/UI/InfoModal/InfoModal';
import { BtnAction, FormGroup, FormInput, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { useTheme } from 'styled-components';
import { DocVisu, UIBody, UICol, UIContainer, UISection,DPContainer } from './UploadDoc.styled';
import FileUploaderBtn from '../../components/UI/FileUploaderBtn/FileUploaderBtn';
import { TD, FilterContainer, SelectFilter, Option, InputFilter } from '../../styles/Table.styled'
import DatePicker from '@mui/lab/DatePicker';
import frLocale from "date-fns/locale/fr";
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { connect } from 'react-redux';
function UploadDoc({userType}) {
    const theme = useTheme()
  
    const navigate = useNavigate();
    const notyf = new Notyf();
    const {t} = useTranslation();
    const uploader = useRef(null);
    const uploaderAttach = useRef(null);
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [pdfPages, setPdfPages] = useState(0)
    const [scrolledEnd, setScrolledEnd] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [url, setUrl] = useState('');
    const [fields, setFields] = useState();
    const [navTabsList, setNavTabsList] = useState([
        {label: 'kbis', value: 'KBIS'},
        {label: 'iban', value: 'IBAN'},
        {label: 'urssaf', value: 'URSSAF'},
        {label: 'assurance', value: 'INSURANCE'},
        {label: 'étrangére', value: 'FOREIGN'},
    ]) 
    const [entity, setEntity] = useState({
        countryCode : "fr",
        family : "ADMINISTRATION",
        lang: "fr"
    });
    let {uid} = useParams();
    // useEffect(() => {
  
    //      Axios.get('/entity/'+Uid+'/object').then((response) => {
          
    //              setEntity(response.data.entity);
    //          }).catch(err => {
    //              notyf.error("Une erreur s'est produite!");
    //          })
             
             
    //  },[uid])
    const open_file = () => {
        document?.getElementById('getFile')?.click();
    }

    const handleFileChange = e => {
        if (e.target.files && e.target.files[0] && e.target.name === "file"){
            setUrl(URL.createObjectURL(e.target.files[0]))
            setFile(e.target.files[0].name)
            setFileName(e.target.files[0].name);
            setPdfPages(e.target.files[0].numPages)
            setScrolledEnd(e.target.files[0].numPages == 1 ? false : true)
            let tmpDoc = {
                ...fields,
                formData: e?.target.files[0],
                fileName: e?.target.files[0].name,
                fileType: e?.target.files[0].type
            }
            setFields(tmpDoc)
        }
    }

    const confirmClickHandler = () => {
       
        
        const formData = new FormData();
         formData.append('file', fields.formData);
         formData.append('expirationDate', fields.DateExpiration);
         formData.append('type', fields.documentType);
         formData.append('typeUser', userType);
        
        Axios.post("/entity/"+uid+"/legalDocument", formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(response => {
            if (response.data.success) {
                notyf.success(t("invoiceList:uploadSuccess"));
               
                navigate("/entity/"+uid)
                // const newDocuments = [...entity.documents];
                // const index = newDocuments.findIndex(d => d.document === response.data.document.document);
                // if (index !== -1)
                //     newDocuments[index] = response.data.document;
                // else
                //     newDocuments.push(response.data.document);
                // setEntity({...entity, documents: newDocuments});
            }
        }).catch(err => {
            notyf.error(t("invoiceList:uploadFailed"));
        }).finally(() => {
            setFile(null);
          
            // setExpirationDate(null);
            // uploader.current.value = "";
        })
    }
    const handleDateChange = (date) => {
       
        // setSelectedStartIssuingDate(date);
        setFields({
            ...fields,
            DateExpiration: date.getTime()
        })
    };

    const inputChangeHandler = (e) => {
        const key = e.target.name;
        const newForm = {
            ...fields,
            [key]: e.target.value
        }
        setFields(newForm);
    }

    return (
        <>
            <UIContainer>
                <TitleHelmet title={"Démo Portail | Envoyer un document"}  />
                <Header>
                    <HeaderInfos>
                        <HeaderLocation>{t("global:addDocument", "Ajouter un document ")}</HeaderLocation>
                        <HeaderTitle>Ajouter un document</HeaderTitle>
                    </HeaderInfos>
                    <HeaderActions>
                        <BtnAction
                            color={theme.colors.success}
                            bg={theme.colors.greenLight}
                            onClick={() => confirmClickHandler()}
                        >
                            Ajouter
                        </BtnAction>
                    </HeaderActions>
                </Header>
                <UIBody>
                    <UISection>
                        {
                            url ? (
                                <DocVisu>
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
                                    <Viewer fileUrl={url} />
                                </DocVisu>
                            ) : (
                                <DocVisu>
                                    {t('global:previewArea')}
                                </DocVisu>
                            )
                        }

                        <UICol>
                            <FormGroup>
                                <FileUploaderBtn
                                    mandatory={false}
                                    btnLabel="Choisir un fichier"
                                    handleChange={handleFileChange}
                                    name="file"
                                />
                            </FormGroup>
                            <FormGroup>
                            
                                    <FilterContainer>
                                    <FormLabel htmlFor="exampleFormControlSelect2" >Type de document</FormLabel>
                                    <SelectFilter 
                                        onChange={(e) => inputChangeHandler(e)} 
                                        value={fields?.documentType || ''} 
                                        name="documentType" 
                                        id="exampleFormControlSelect2"
                                        >
                                             <Option value=""></Option>
                                             {navTabsList.map(e=>(
                                            <Option value={e.value}>{e.label}</Option>
                                        ))}
                                       
                                    </SelectFilter>
                                </FilterContainer>
                            </FormGroup>
                            <FormGroup>
                               
                                <FormLabel htmlFor="field2" >Date d'expiration</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                    <DPContainer> 
                                        <DatePicker
                                            //margin="normal"
                                            
                                         
                                            format="dd/MM/yyyy"
                                            
                                            onChange={handleDateChange}
                                            value={new Date(fields?.DateExpiration)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',}}
                                    
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </DPContainer> 
                                    </LocalizationProvider>
                            </FormGroup>
                            {/* <FormGroup>
                                <FormLabel htmlFor="field3" >Champ 3</FormLabel>
                                <FormInput 
                                    id="field3"
                                    type="text" 
                                    placeHolder="Champ 3"
                                    value={fields.field3} 
                                    onChange={e => inputChangeHandler(e)} 
                                    name="field3" 
                                />
                            </FormGroup> */}
                            {/* <FormGroup>
                                <FileUploaderBtn
                                    btnLabel="Piéce jointe"
                                    handleChange={handleFileChange}
                                    name="attachment"
                                />
                            </FormGroup> */}
                        </UICol>
                    </UISection>
                </UIBody>
                <InfoModal show={showModal} handleClose={() => setShowModal(false)} title={"Document importée"} content={"Votre document est en cours de traitement, vous pourrez visualiser son cycle de vie dans le suivi des documents."}></InfoModal>
            </UIContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    login : state.auth.login
  })
  const mapDispatchToProps = dispatch => ({
  })
  export default connect(mapStateToProps, mapDispatchToProps) (UploadDoc)