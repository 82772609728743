import styled from 'styled-components/macro';

export const IDContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const IDHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`;

export const IDHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 2rem;
    
    @media ${({theme}) => theme.device.laptop} {
        margin-right: 1rem;
    }
`;

export const IDHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const IDHeaderTitle = styled.h4`
    font-size: 1.5rem;
    color: ${({theme}) => theme.colors.primary};
    @media ${({theme}) => theme.device.laptop} {
        font-size: 1.5rem;
    }
`
export const IDHeaderInvoiceDate = styled.h5`
    font-size: 1rem;
    margin-bottom: 0.1rem;
    color: ${({theme}) => theme.colors.primary};
    @media ${({theme}) => theme.device.laptop} {
        font-size: 1rem;
    }
`

export const IDProgressLineWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: -7px;
`

export const IDInvoiceDate = styled.div`
    display: flex;
    font-size: 2rem;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 0 1.6rem 0rem;
    color: ${({theme}) => theme.colors.secondary};

    @media ${({theme}) => theme.device.laptop} {
        padding: 0 0 1.6rem 0rem;
    }
`

export const IDInvoiceDateLabel = styled.h4`
    font-size: 1.2rem;
    margin: 0 0.25rem 0 0;
    white-space: nowrap;

    @media ${({theme}) => theme.device.laptop} {
        font-size: 1rem;
    }
`

export const IDInvoiceDateValue = styled.h4`
    font-size: 1.2rem;
    margin: 0;
    white-space: nowrap;
    @media ${({theme}) => theme.device.laptop} {
        font-size: 0.9rem;
    }
`


export const IDHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`

export const IDBody = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`