import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import TimeLine from "./Timeline/TimeLine";
import ModificationsChat from "./ModificationsChat/ModificationsChat";
import { getNotyfObject } from "../../../../shared/utility";
import { useTranslation } from "react-i18next";
import {
  NavTabsLi,
  NavTabsLink,
  NavTabsUl,
} from "../../../../containers/Entity/Entity.styled";
import { useParams, useLocation } from "react-router-dom";
import {
  ISContainer,
  ISNavContainer,
  ISNavContent,
} from "./InvoiceSide.styled";
import Fields from "./Fields/Fields";
import Information from "./Information/Information";
import InformationCommand from "./Information/InformationCommand";

function InvoiceSideCommand(props) {
  const {
    tabSideActive,
    setTabSideActive,
    invoiceData,
    setInvoiceData,
    invoiceIntegratedDetail,
    userType,
    isCommand,
    openModal,
    openModalChat,
    commandesLinesData,
    updateAmount,
    setUpdateAmount,
    update,
    setUpdate,
  } = props;
  let notyf = getNotyfObject();
  const { t } = useTranslation();

  useEffect(() => {
    // if(invoiceData && (invoiceData?.status === 'ERROR' && userType === 'supplier')) {
    //     setTabSideActive('fields')
    // }
    // if(invoiceIntegratedDetail) {
    // }
    setTabSideActive("information");
  }, []);

  return (
    <ISContainer isCommand={isCommand}>
      <ISNavContainer>
        <NavTabsUl isCommand={isCommand}>
          {/* {invoiceIntegratedDetail && */}
          <NavTabsLi>
            <NavTabsLink
              active={tabSideActive === "information"}
              onClick={() => setTabSideActive("information")}
              to="#"
            >
              {t("mandat:information", "Informations")}
            </NavTabsLink>
          </NavTabsLi>

          <NavTabsLi>
            <NavTabsLink
              active={tabSideActive === "comments"}
              onClick={() => {
                setTabSideActive("comments"), openModalChat();
              }}
              to="#"
            >
              {t("mandat:comments", "Commentaires")}
            </NavTabsLink>
          </NavTabsLi>
          <NavTabsLi>
            <NavTabsLink
              active={tabSideActive === "timeline"}
              onClick={() => {
                setTabSideActive("timeline"), openModal();
              }}
              to="#"
            >
              {t("mandat:timeline", "TimeLine")}
            </NavTabsLink>
          </NavTabsLi>
        </NavTabsUl>
      </ISNavContainer>
      <ISNavContent>
        {/* {tabSideActive === "fields" && invoiceData && (invoiceData?.status === 'ERROR') && userType === 'supplier' && (
                    <Fields
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                    />

                )} */}
        {"information,timeline,comments".includes(tabSideActive) &&
          isCommand && (
            <InformationCommand
              commandesLinesData={commandesLinesData}
              InvoiceIntegratedDetail={invoiceIntegratedDetail}
              invoiceData={invoiceData}
              updateAmount={updateAmount}
              setInvoiceData={setInvoiceData}
              setUpdateAmount={setUpdateAmount}
              update={update}
              setUpdate={setUpdate}
            />
          )}
        {tabSideActive === "information" && !isCommand && (
          <Information
            InvoiceIntegratedDetail={invoiceIntegratedDetail}
            invoiceData={invoiceData}
            setInvoiceData={setInvoiceData}
            updateAmount={updateAmount}
            setUpdateAmount={setUpdateAmount}
          />
        )}
        {/* {tabSideActive === "timeline" && <TimeLine />}
        {tabSideActive === "comments" && <ModificationsChat />} */}
      </ISNavContent>
    </ISContainer>
  );
}

const mapStateToProps = (state) => ({
  userType: state.auth.userType,
  tabSideActive: state.invoiceDetail.tabSideActive,
  invoiceData: state.invoiceDetail.invoiceData,
});
const mapDispatchToProps = (dispatch) => ({
  setTabSideActive: (tabSideActive) =>
    dispatch(actions.setTabSideActive(tabSideActive)),
  setInvoiceData: (invoiceData) =>
    dispatch(actions.invoiceDataSuccess(invoiceData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSideCommand);
