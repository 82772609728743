import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './UsersModal.css';
import { Button, Modal } from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';
// import { getStatusWithKey } from '../../../shared/utility';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../../../../../styles/Common';


function UsersModal(props) {
    const { show, modalClosed, confirm, usersList } = props;
    const [comment, setComment] = useState(null);

    useEffect(() => {
    }, [])


    const getUserRole = (level) => {
        if (level == 'admin' || level == 'standard') return 'Admin'
        if (level == 'superAccountant') return 'DAF'
        if (level == 'accountant') return 'Comptable'
    }

    return (
        <div style={{ width: "700px" }}>
            <Modal show={show} onHide={modalClosed}  >
                <Modal.Header closeButton style={{borderBottom: "1px solid #D9E1E7"}}>
                    <Modal.Title className="ICDBlue" style={{width: "-webkit-fill-available",textAlign: "center", fontWeight:"normal"}}>
                        Liste de contacts
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body">
                    <div className=" input-group input-group-sm">
                        <input
                            style={{color: "#505050"}}
                            type="text"
                            className="form-control"
                            placeholder="Rechercher ..."
                            // onChange={(e) => setSearchQuery(e.target.value)}
                            // value={searchQuery || ''}
                        />
                        {/* <div className="input-group-append" >
                            <span className="input-group-text" id="basic-addon2"><SearchIcon className="ICDBlue" /> </span>
                        </div> */}
                    </div>
                    <div style={{padding: "0px 13px", maxHeight: "200px", height: "200px", overflowY: "auto"}}>
                        <div className="row tableHeader">
                            <div style={{fontWeight: "normal"}} className="col-md-3">Nom</div>
                            <div style={{fontWeight: "normal"}} className="col-md-3">Rôle</div>
                            <div style={{fontWeight: "normal"}} className="col-md-3">Téléphone</div>
                            <div style={{fontWeight: "normal"}} className="col-md-3">Email</div>
                        </div>
                        {
                            usersList?.map(user => {
                                return (
                                    <div className="row tableBody">
                                        <div className="col-md-3">{user?.first_name + " " + user?.last_name}</div>
                                        <div className="col-md-3">{getUserRole(user?.level)}</div>
                                        <div className="col-md-3">{user?.telephone_numbe}</div>
                                        <div className="col-md-3">{user?.login}</div>
                                    </div>

                                )

                            })
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer style={{borderTop: "1px solid #D9E1E7"}}>
                    {/* <PrimaryBtnOutlineAction 
                    variant="primary" 
                    onClick={confirm}
                    // disabled={statusValue === "LITIGATION" && !comment}
                >
                    Confirmer
                </PrimaryBtnOutlineAction> */}
                    <SecondaryBtnOutlineAction variant="secondary" onClick={modalClosed}>
                        Retour
                    </SecondaryBtnOutlineAction>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UsersModal;
