import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {  EntInfosContainer, documentsList,EIFormCol,EIContainer,FormInputBanque,EICol,EngroupDiv,IconDiv,FormGroup} from './InfosPPF.styled'
import fr from 'react-phone-input-2/lang/fr.json'
import { EIFormContainer, EntFormItem, EntFormList, EntFormSection } from '../../../containers/Entity/Entity.styled';
import EntitiesDropDown from '../EntitiesDropDown/EntitiesDropDown';
import { FormECILabel, FormECItem, FormECIValue, FormECLabel, FormECListContainer, FormEntCanal, FormInput, FormLabel, FormSelect, TelInput } from '../../../styles/Common';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import {TH ,THead ,TBody ,Table,TR,TD} from '../../../styles/Table.styled';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import MandatoryMarker from '../../UI/MandatoryMarker'; 
import DropZone from '../../UI/DropZone/DropZone'
import PopoverComp from '../../UI/PopoverComp/PopoverComp'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import { convertFileToBase64 ,formatDate} from "../../../shared/utility";
import FileUploaderBtn from '../../../components/UI/FileUploaderBtn/FileUploaderBtn';
import Axios from '../../../axios-proas'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { EntContainer, EntContent, EntHeader, EntHeaderAction, EntHeaderActions, EntHeaderTitle, EntHeaderWrapper, NavTabsLi, NavTabsLink, NavTabsUl} from '../../../containers/EntityPPF/EntityPPF.styled'

import InfoIcon from '@mui/icons-material/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faDolly, faPersonRays, faUserGear,faCircleInfo,faCirclePlus} from '@fortawesome/free-solid-svg-icons'  

function InfosPPF({setBanquesToDelete,setAttachmentsToDelete,entity,setEntity,bank,editEntityclickHandler, handleInputChange,handleInputChangeType, entitiesType, entities, addEntity, deleteEntity, canalList, canalChangeHandler, supplierCanalChangeHandler, supplierCanalList, handlePhoneInputChange}) {
    const theme = useTheme();
    console.log("bank",bank?.length)
    const { t } = useTranslation(); 
    const [banques,setBanques] = useState()
  
    const [attachments,setAttachments] = useState([])
    const [isIban,setIsIban]=useState(false)
    const [isBic, setIsBic] = useState(false)
    const [isBanque, setIsBanque] = useState(false)
 
    useEffect(() => {
        setBanques(entity?.banques?.length>0?Object.values(entity?.banques):[])
    }, [entity])
        
    useEffect(() => {
        setAttachments(entity?.documents?.length>0?Object.values(entity?.documents):[])
    }, [entity])

 const GetDocumentFile = (uid) => {
        return dispatch => {
            Axios.get('/entitypdp/'+uid+'/documentFile', {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            .then(response => {
                if(response.status === 200){
                    let blob = new Blob([response.data], { type: response.request.getResponseHeader("Content-Type") })
                                let filename =""
                                const disposition = response.request.getResponseHeader('Content-Disposition')
                                if (disposition && disposition.indexOf('attachment') !== -1) {
                                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                    var matches = filenameRegex.exec(disposition);
                                    if (matches!== null && matches[1]) { 
                                    filename = matches[1].replace(/['"]/g, '');
                                    }
                                }
                                const downloadUrl = URL.createObjectURL(blob)
                                let a = document.createElement("a"); 
                                a.href = downloadUrl;
                                a.download = filename;
                                document.body.appendChild(a);
                                a.click();
                }else {
                    // notyf.error("document non trouvé");
                }
                
            }).catch(err => {
                notyf.error('Une erreur s\'est produite !')
            })
        }
        
    }
	const handleFileChange = (e,index) => {
        if (e.target.files && e.target.files[0] && e.target.name === "file"){
            convertFileToBase64(e.target.files[0]).then(result => {
                let  fileB64 = result;
            setAttachments((prevState) => {
                let newAttachments = [...prevState]
                newAttachments[index] = {name: e.target.files[0].name, creationDate: Date.now(),type: e.target.files[0].type,content: fileB64,new:true}
                setEntity({...entity,documents: newAttachments})
                return newAttachments
            })
        }).catch(err => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
        }}


    const chekBic=(e)=>{
        let bic = e
        let bicRegex = new RegExp(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/)
        let isValid = bicRegex.test(bic)
        if(isValid){
            setIsBic(true)
        }else{
            setIsBic(false)
        }}
    const chekBanque=(e)=>{
        let banque = e
        let banqueRegex = new RegExp(/^[A-Za-z0-9\s]+$/)
        let isValid = banqueRegex.test(banque)
        if(isValid){
            setIsBanque(true)
        }else{
            setIsBanque(false)
        }  }

    const chekIbane = (e) => {
        let iban = e
        let ibanRegex = new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/)
        let isValid = ibanRegex.test(iban)
        if(isValid){
            setIsIban(true)
        }else{
            setIsIban(false)
        } } 
 

    const addNewPivots = () => {
        if(attachments.some(attachment => attachment.new==true && attachment.name==null)){
            console.log("dfdcfc")
        }else{
        let p= {name:null,creationDate:Date.now(),type: null,content: null,new:true};
        let attachementTemp = [...attachments];
        attachementTemp.push(p);
        setAttachments(attachementTemp);
        setEntity({...entity,documents:attachementTemp})
        }
      }
   
    const addNewBanque = () => {
        if(banques.some(banque => banque.new==true && (!isIban||!isBic||!isBanque))){
            console.log("dfdcfc")}else{
                let p= {iban:null,bic:null,banque: null,new:true};
                let banquesTemp = [...banques];
                banquesTemp.push(p);
                setBanques(banquesTemp);
                setEntity({...entity,banques:banquesTemp})
            }
    }

    const deleteAttachment = (index) => {   
        let tempAttachments =[...attachments]
        if(!tempAttachments[index].hasOwnProperty("new")){
            setAttachmentsToDelete((prevState) => {
                let newAttachmentsToDelete = [...prevState]
                newAttachmentsToDelete.push(attachments[index].uid)
                return newAttachmentsToDelete
            })
        }
        console.log(tempAttachments)
        tempAttachments.splice(index, 1)
        setAttachments(tempAttachments)
        setEntity({...entity,documents:tempAttachments})
    }

    const deleteBanque = (index) => {   
        let tempAttachments =[...banques]
        if(!tempAttachments[index].hasOwnProperty("new")){
          setBanquesToDelete((prevState) => {
            let newAttachmentsToDelete = [...prevState]
            newAttachmentsToDelete.push(banques[index].uid)
            return newAttachmentsToDelete
         })
         }
        tempAttachments.splice(index, 1)
        setBanques(tempAttachments)
        setEntity({...entity,banques:tempAttachments})
    
    }
    const inputChangeHandler = (e,index) => {
        setBanques((prevState) => {
            let newBanques = [...prevState]
            newBanques[index] = {...newBanques[index], [key]: e.target.value}
            setEntity({...entity,banques:newBanques})
            return newBanques
        })
         const key = e.target.name;
            switch (key) {
                case "iban":
                chekIbane(e.target.value)
                break;
                case "bic":
                chekBic(e.target.value)
                break;
                case "bank":
                chekBanque(e.target.value)
                break;
                default:
                break;

            }
      }



    const body=useMemo(() => attachments?.map((file, index) => (
        <TBody> 
            <tr style={{width:"100%", display: "flex",justifyContent: "space-between"}} > 
                        <TD style={{minWidth:"62%",display: "flex",justifyContent: "space-between"}}>
                       {file.name? <> <span >{file.name}   </span> {!file.new&&<DownloadForOfflineIcon style={{fontSize:"1.2rem",color:"#809FB8"}} onClick={GetDocumentFile(file?.uid)}/>} </>:	
                                <FileUploaderBtn
                                    
                                    label={true}
                                    btnLabel="Cliquez pour ajouter un fichier"
                                    handleChange={(e)=>handleFileChange(e,index)}
                                    name="file"
                                />}
                            
                        </TD>
                        <TD style={{minWidth:"30%"}} >
                        
                            <span>{formatDate(+file?.creationDate)}</span>
                        </TD>
                        <TD  style={{minWidth:"7%"}}>
                            
                            <CancelIcon onClick={e => deleteAttachment(index)} style={{ color: "#EE5A5A", cursor: "pointer", fontSize: "20px" }} />
    
                            
                        </TD>
            </tr>
        </TBody>    
            )), [attachments])

    const body2=useMemo(() => banques?.map((file, index) => (
        <TBody> 
            <tr style={{width:"100%", display: "flex",justifyContent: "space-between"}} > 
                        <TD style={{minWidth:"41%"}}>
                            {!file.new?<span >{file.iban}</span>:	
                                    <FormInputBanque 
                                        id="iban"
                                        type="text"
                                        name="iban"
                                        err={isIban}
                                        placeholder="Ex: FR76 3000 6005 0500 0100 3M02 606"
                                        value={file?.iban || ""}
                                        onChange={(e)=>inputChangeHandler(e,index)}/>
                            }
                            
                        </TD>
                        <TD style={{minWidth:"26%"}} >
                        
                            {!file.new?<span >{file.bic}</span>:	
                                        <FormInputBanque 
                                            id="bic"
                                            type="text"
                                            name="bic"
                                            err={isBic}
                                            placeholder="EX: CMCIFRPP"
                                            value={file?.bic || ""}
                                            onChange={(e)=>inputChangeHandler(e,index)}/>
                                }
                        </TD>
                        <TD style={{minWidth:"26%"}} >
                        
                            {!file.new?<span >{file.bank}</span>:	
                                        <FormInputBanque 
                                            id="bank"
                                            type="text"
                                            err={isBanque}
                                            name="bank"
                                            placeholder="Ex: Crédit Mutuel"
                                            value={file?.bank || ""}
                                            onChange={(e)=>inputChangeHandler(e,index)}/>
                                }
                        </TD>
                        <TD  style={{minWidth:"6%"}}>
                            
                            <CancelIcon onClick={e => deleteBanque(index)} style={{ color: "#EE5A5A", cursor: "pointer", fontSize: "20px" }} />

                            
                        </TD>
            </tr>
        </TBody>  
            )), [banques,bank])
            // icon={<FontAwesomeIcon icon={faCircleInfo} style={{ color: "#809FB8" }}/>}
  return (
      <>
        <EntInfosContainer>
            <EIFormContainer>
                <EIContainer>
                <EntHeader>
                    <EntHeaderTitle>
                            <span style={{ display: "flex"}}>Informations de l'annuaire <IconDiv><FontAwesomeIcon icon={faCircleInfo}style={{marginLeft:"5px",marginRight:"2px"}} />
                                     <EICol>   
                                        <span style={{ color: "#505050",fontSize:"0.8rem"}}>Ces champs sont modifiables en fonction de vos droits. Ils mettront à jour l'annuaire de la PPF lors de la prochaine itération de synchronisation. </span>
                                        
                                   </EICol>
                                   </IconDiv></span>
                        </EntHeaderTitle>
                      </EntHeader>
                    <EngroupDiv>
                    <EIFormCol>
                        <FormGroup>
                            <FormLabel htmlFor="code" >Identifiant</FormLabel>
                            <FormInput 
                                id="code"
                                type="text"
                                name="code"
                                // placeholder="Code"
                                value={entity?.code || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup> 

                        <FormGroup>
                            <FormLabel htmlFor="name" >Raison sociale<MandatoryMarker color={"#809FB8"}/></FormLabel>
                            <FormInput 
                                id="name"
                                type="text"
                                name="name"
                                // placeholder="Nom"
                                value={entity?.name || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                                    <FormLabel htmlFor="siren" >SIREN<MandatoryMarker color={"#809FB8"}/></FormLabel>
                                    <FormInput 
                                        id="siren"
                                        type="number"
                                        name="siren"
                                        // placeholder="Identifiant 2: N° Siren"
                                        value={entity?.siren || ""}
                                        onChange={handleInputChange}
                                    />
                        </FormGroup>

                        <FormGroup>
                                <FormLabel htmlFor="siret" >SIRET<MandatoryMarker color={"#809FB8"}/></FormLabel>
                                <FormInput 
                                    id="siret"
                                    type="number"
                                    name="siret"
                                    // placeholder="Identifiant 1: N° Siret"
                                    value={entity?.siret || ""}
                                    onChange={handleInputChange}
                                />
                        </FormGroup>
                   

                        <FormGroup>
                            <FormLabel htmlFor="typeEntity" >Type d'entité<MandatoryMarker color={"#809FB8"}/></FormLabel>
                            <FormSelect 
                                id="typeEntity"
                                name="typeEntity"
                                value={entity?.typeEntity || ""}
                                onChange={handleInputChange}
                            >
                                <option value="PPF">Etablissement</option>
                                <option value="PDP">Société privée </option>
                            </FormSelect>
                        </FormGroup>
                        
                    </EIFormCol>


                    <EIFormCol>
                        <FormGroup>
                            <FormLabel htmlFor="email" >Email</FormLabel>
                            
                               
                                    <FormInput 
                                        id="email"
                                        type="email"
                                        name="email"
                                        placeholder="Contact administrateur"
                                        value={entity?.email || ""}
                                        onChange={handleInputChange}
                                    />
                          
                        </FormGroup>

                        <FormGroup>
                            <FormLabel htmlFor="telephone" >Téléphone</FormLabel>
                            <TelInput
                                style={{color: "#505050 !important"}}
                                id="telephone"
                                localization={fr}
                                country={'fr'}
                                dropdownStyle={{width: "auto"}}
                                preferredCountries={['fr', 'us']}
                                enableSearch={false}
                                name="telephone"
                                inputProps={{name: "telephone"}}
                                placeholder={"33 0 00 00 00 00"}
                                value={entity?.telephone?.substr(2) || ''}
                                onChange={(phone) => handlePhoneInputChange("00" + phone)} 
                            />
                            {/* <FormInput 
                                id="telephone"
                                type="text"
                                name="telephone"
                                // placeholder="Téléphone"
                                value={entity?.telephone || ""}
                                onChange={handleInputChange}
                            /> */}
                        </FormGroup>  

                        <FormGroup>
                            <FormLabel htmlFor="countryCode" >Pays<MandatoryMarker color={"#809FB8"}/></FormLabel>
                            <FormSelect 
                                id="countryCode"
                                name="citcountryCodey"
                                value={entity?.countryCode || ""}
                                onChange={handleInputChange}
                            >
                                <option value="fr">FRANCE</option>
                            </FormSelect>
                        </FormGroup>
                            
                        <FormGroup>
                            <FormLabel htmlFor="channel" >Type de plateforme<MandatoryMarker color={"#809FB8"}/></FormLabel>
                            <FormSelect 
                                id="channel"
                                name="channel"
                                value={entity?.channel|| ""}
                                onChange={handleInputChange}
                            >
                                <option value="PPF">PPF</option>
                                <option value="PDP">PDP</option>
                            </FormSelect>
                        </FormGroup>
                    </EIFormCol>
                    </EngroupDiv>
                </EIContainer>


            </EIFormContainer>
            <EIFormContainer>
                <EIContainer>
                <EntHeader>
                    <EntHeaderTitle>
                    <span style={{ display: "flex"}}>Informations complémentaires <IconDiv><FontAwesomeIcon icon={faCircleInfo}style={{marginLeft:"5px",marginRight:"2px"}} /> <EICol >   
                    <span style={{ color: "#505050",fontSize:"0.8rem"}}>Ces informations sont modifiables et sa modification n'impacte pas l'annuaire de la PPF.</span>

                                   </EICol>
                                   </IconDiv></span>
                           
                        </EntHeaderTitle>
                      </EntHeader>
                    <EngroupDiv>
                        <EIFormCol>                    
                            {/* <FormGroup>
                                    <FormLabel htmlFor="siren" >SIREN<MandatoryMarker color={"#809FB8"}/></FormLabel>
                                    <FormInput 
                                        id="siren"
                                        type="number"
                                        name="siren"
                                        // placeholder="Identifiant 2: N° Siren"
                                        value={entity?.siren || ""}
                                        onChange={handleInputChange}
                                    />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel htmlFor="siret" >SIRET<MandatoryMarker color={"#809FB8"}/></FormLabel>
                                <FormInput 
                                    id="siret"
                                    type="number"
                                    name="siret"
                                    // placeholder="Identifiant 1: N° Siret"
                                    value={entity?.siret || ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup> */}
                            <FormGroup>
                            <FormLabel htmlFor="address" >Adresse</FormLabel>
                            <FormInput 
                                id="address"
                                type="text"
                                name="address"
                                // placeholder="Adresse"
                                value={entity?.address || ""}
                                onChange={handleInputChange}
                            />
                            </FormGroup>   
                    
                            <FormGroup>
                                <FormLabel htmlFor="zipCode" >Code postal</FormLabel>
                                <FormInput 
                                    id="zipCode"
                                    type="number"
                                    name="zipCode"
                                    // placeholder="Code postal"
                                    value={entity?.zipCode || ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel htmlFor="city" >Ville</FormLabel>
                                <FormInput 
                                    id="city"
                                    type="text"
                                    name="city"
                                    // placeholder="Ville"
                                    value={entity?.city || ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="vat" >TVA Intracommunautaire</FormLabel>
                                <FormInput 
                                    id="vat"
                                    type="text"
                                    name="vat"
                                    // placeholder="Identifiant 3: N° TVA"
                                    value={entity?.vat || ""}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel htmlFor="legalForm" >Forme juridique</FormLabel>
                                <FormSelect 
                                    id="type"
                                    name="legalForm"
                                    value={entity?.legalForm || ""}
                                    onChange={handleInputChange}
                                >
                                    <option value="EL">EL</option>
                                    <option value="EURL">EURL</option>
                            
                                    <option value="SARL">SARL</option>
                                    <option value="SASU">SASU</option>
                                    <option value="SAS">SAS</option>
                                    <option value="SA">SA</option>
                                    <option value="SNC">SNC</option>
                                    <option value="SCS">SCS</option>
                                    <option value="SCA">SCA</option>
                                
                                </FormSelect>
                            </FormGroup>
                            
                            <FormGroup>
                                <FormLabel htmlFor="countryCode" >Coordonnées bancaires <div><FontAwesomeIcon icon={faCirclePlus}style={{marginLeft:"5px",marginRight:"2px"}} onClick={e => addNewBanque()}/></div> </FormLabel>
                                    <Table style={{marginTop: "10px"}}>
                                        <THead>
                                            <tr style={{width:"100%", display: "flex",justifyContent: "space-between"}}>
                                                <TH style={{width:"40%",background:'#809FB8',color:"#F1F4F9",paddingLeft:"8px"}}>IBAN *</TH>
                                                <TH style={{width:"26%",background:'#809FB8',color:"#F1F4F9",paddingLeft:"8px"}}>BIC *</TH>
                                                <TH style={{width:"26%",background:'#809FB8',color:"#F1F4F9",paddingLeft:"8px"}}>Banque *</TH>
                                                <TH style={{width:"7%"}}></TH>
                                            </tr>
                                        </THead>
                                        {body2}
                                    </Table>
                            </FormGroup>
                            
                        </EIFormCol>
                        <EIFormCol>
                            <FormGroup style={{position:"relative"}}>
                                <FormLabel htmlFor="countryCode" >Schémas pivots  <IconDiv><FontAwesomeIcon icon={faCircleInfo}style={{marginLeft:"5px",marginRight:"2px"}} /> <EICol >   
                                        <span>Information manquante</span>
                                
                                   </EICol>
                                   </IconDiv> 
                                   <div>
                                   <FontAwesomeIcon icon={faCirclePlus}style={{marginLeft:"5px",marginRight:"2px"}} onClick={e => addNewPivots()}/>  </div> </FormLabel>
                                 
                                    <Table style={{marginTop: "10px"}}>
                                        <THead>
                                            <tr style={{width:"100%", display: "flex",justifyContent: "space-between"}}>
                                                <TH style={{width:"62%",background:'#809FB8',color:"#F1F4F9",paddingLeft:"8px"}}>Nom fichier</TH>
                                                <TH style={{width:"30%",background:'#809FB8',color:"#F1F4F9",paddingLeft:"8px"}}>Date de dépôt</TH>
                                                <TH style={{width:"7%"}}></TH>
                                            </tr>
                                        </THead>
                                        {body}
                                    </Table>
                                   
                            </FormGroup>                                  
                        </EIFormCol>
                    </EngroupDiv>
                </EIContainer>
            </EIFormContainer>
        </EntInfosContainer>
      </>
  );
}

export default InfosPPF;
