import React, { useEffect, useState } from 'react';
import { Notyf } from 'notyf';
import Axios from '../../axios-proas'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatNumber, getNotyfObject } from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../styles/Common';
import { IDBody, IDContainer, IDHeader, IDHeaderActions, IDHeaderContainer, IDHeaderTitle, IDHeaderInvoiceDate, IDHeaderWrapper, IDInvoiceDate, IDInvoiceDateLabel, IDInvoiceDateValue, IDProgressLineWrapper } from './PaymentSignatureDetail.styled';
import IDProgressLine from '../../components/PaymentSignatureDetail/IDProgressLine/IDProgressLine';
import InvoiceView from '../../components/PaymentSignatureDetail/InvoiceBody/InvoiceMain/InvoiceView/InvoiceView';
import InvoiceSide from '../../components/PaymentSignatureDetail/InvoiceBody/InvoiceSide/InvoiceSide';
import ActionsModal from '../../components/PaymentSignatureDetail/ActionsModal/ActionsModal';
import { convertFileToBase64 } from "../../shared/utility"

function PaymentSignatureDetail(props) {
    const {
        loadingData,
        getInvoiceDataByToken,
        invoiceData,
        invoiceError,
        getInvoiceData,
        userLevel,
        resetAdmToInitialState
    } = props
    const theme = useTheme()
    let { uid, token } = useParams();
    const notyf = getNotyfObject();
    const { t } = useTranslation();
    let location = useLocation();
    let navigate = useNavigate()

    const [paymentSignature, setPaymentSignature] = useState()
    const [paymentSignatureInvoices, setPaymentSignatureInvoices] = useState()
    const [invoiceDocument, setInvoiceDocument] = useState()

    const [navTabsList, setNavTabsList] = useState([])
    const [showActionsModal, setShowActionsModal] = useState(false)
    const [eventUpdate, setEventUpdate] = useState(false)
    const [formErrors, setFormErrors] = useState(false)
    const [formErrorsList, setFormErrorsList] = useState([])

    const typeInvoiceToNavListe = (xml, edi, pdf, chorus) => {
        var list = [];
        // if(pdf!==null)
        list = ([...list,
        { label: 'PDF', value: 'pdf' }
        ])
        setNavTabsList(list)
    }

    useEffect(() => {
        setFormErrors(false)
        setFormErrorsList([])
        loadContent()
    }, [])

    const loadContent = () => {
        
        let list = [{ label: 'PDF', value: 'pdf' }]
        setNavTabsList(list)

        Axios.get('/paymentsignature/' + uid + '/paymentSignaturedetail').then(response => {
            setPaymentSignatureInvoices(response?.data?.paymentSignatureinvoices)
            setPaymentSignature(response?.data?.paymentSignature)
            setInvoiceDocument(response?.data?.paymentSignatureinvoices[0])
        }).catch(err => {
            notyf.error("Une erreur s'est produite")
        })
    }

    const editStatus = (status, comment = null, reason = null) => {
        Axios.put('/invoice//updateStatus',
            {
                "InvoiceId": uid,
                "status": status,
                reason,
                comment
            }).then(response => {
                getInvoiceData(uid)
                notyf.success("Statut modifié")
            }).catch(err => {
                notyf.error("Une erreur s'est produite")
            })
    }

    const addEventHandler = (event) => {
        Axios.put('/paymentsignature/' + uid + '/addPaymentSignatureEvent',
            { event }).then(response => {
                loadContent()
                notyf.success("Evénement ajouté")
                // setEventUpdate(!eventUpdate)
                navigate(-1)
            }).catch(err => {
                notyf.error("Une erreur s'est produitessss")
            })
    }

    
    const selectRowHandler = (e, uid) => {
        const paid = e.target.checked
        Axios.put('/paymentsignature/'+uid+'/paymentSignatureLinkStatus',
            {
                tobepaid: paid
            }).then(response => {
                
                loadContent()
                // notyf.success("Statut modifié")
        }).catch(err => {
            notyf.error("Une erreur s'est produite")
        })

    }

    const checkRequestForm = () => {
        // const formFields = entity
        // let errors = []
        // // if( ("INSURANCE, URSSAF").includes( formFields.) ) errors.push("Canal d'échange")
        // if (formFields.channel.length == 0) errors.push("Canal d'échange")
        // if (formFields.documents.filter(doc => doc.type == "IBAN").length > 1 ||
        //     formFields.documents.filter(doc => doc.type == "URSSAF").length > 1 ||
        //     formFields.documents.filter(doc => doc.type == "INSURANCE").length > 1 ||
        //     formFields.documents.filter(doc => doc.type == "FOREIGN").length > 1)
        //     errors.push("Type document en double")
        // if (formFields.owners.filter(owner => (owner?.selected == false)).length == formFields.owners.length) errors.push("Entité facturée")
        // if (formFields.documents.filter(doc => (doc?.type == null || (doc?.type == "OTHER" && (doc?.othertypename == "" || doc?.othertypename == null)))).length > 0) errors.push("Type documents")

        // if (errors.length > 0) {
        //     setFormErrors(true)
        //     setFormErrorsList(errors)
        // } else {
        //     setFormErrors(false)
        //     setFormErrorsList([])
        // }
    }

    let paymentSignatureJsx = null;
    if (loadingData) paymentSignatureJsx = (<Spinner />)
    else if (paymentSignature)
        paymentSignatureJsx = (
            <>
                <IDHeaderWrapper>
                    <IDHeaderContainer>
                        <IDHeader>
                            <div>
                                <IDHeaderTitle>
                                    Parapheur : {paymentSignature?.paymentNumber}
                                </IDHeaderTitle>
                                <IDInvoiceDate>
                                    <IDInvoiceDateLabel>Planifié au paiment le : {formatDate(+paymentSignature?.paymentDate)}</IDInvoiceDateLabel>
                                    {/* <IDInvoiceDateValue>{formatDate(+invoiceData?.issuingDate)}</IDInvoiceDateValue> */}
                                </IDInvoiceDate>
                            </div>
                            <IDHeaderActions>
                                <div>
                                    {/* {
                                    formErrors &&
                                        formErrorsList?.map(error => {
                                            return <li>{error}</li>
                                        })
                                } */}
                                </div>
                                {
                                    ('TO_CONTROL, PENDING'.includes(paymentSignature?.status) && userLevel == 'accountant') || 
                                    ('TO_VALIDATE'.includes(paymentSignature?.status) && userLevel == 'superAccountant')  ? 
                                        <PrimaryBtnOutlineAction
                                            onClick={() => setShowActionsModal(true)}
                                        >
                                            Actions
                                        </PrimaryBtnOutlineAction>
                                    : null
                                }

                                <SecondaryBtnOutlineAction
                                    onClick={() => navigate(-1)}
                                >
                                    Retour
                                </SecondaryBtnOutlineAction>
                            </IDHeaderActions>
                        </IDHeader>
                        <IDProgressLineWrapper>
                            <IDProgressLine
                                // lastEvent={lastEvent}
                                status={paymentSignature?.status}
                                editStatus={editStatus}
                            />
                        </IDProgressLineWrapper>
                    </IDHeaderContainer>

                </IDHeaderWrapper>

                <IDBody >
                    <InvoiceView document={invoiceDocument} navTabsList={navTabsList} />
                    {
                        !token ?
                            <InvoiceSide
                                selectRowHandler={selectRowHandler}
                                setInvoiceDocument={setInvoiceDocument}
                                addEventHandler={addEventHandler}
                                paymentSignature={paymentSignature} 
                                paymentSignatureInvoices={paymentSignatureInvoices}
                                setPaymentSignature={setPaymentSignature}
                                eventUpdate={eventUpdate}
                                status={paymentSignature?.status}
                            />
                            : null
                    }
                </IDBody>
            </>
        )

    return (
        <>
            <IDContainer>
                {paymentSignatureJsx}
            </IDContainer>
            {
                showActionsModal &&
                <ActionsModal
                    status={paymentSignature?.status}
                    userLevel={userLevel}
                    show={showActionsModal}
                    handleClose={() => setShowActionsModal(false)}
                    addEventHandler={addEventHandler}
                />
            }
        </>
    )
}
const mapStateToProps = (state) => ({
    userLevel: state.auth.userLevel
    // role: state.auth.role,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // creatorName : state.mandat?.admData?.creatorName
})
const mapDispatchToProps = dispatch => ({
    // getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
    // getInvoiceDataByToken: (invoiceToken) => dispatch(actions.getInvoiceDataByToken(invoiceToken)),
    // // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    // // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    // resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState())
})
// export default PaymentSignatureDetail
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSignatureDetail)
