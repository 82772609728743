import React from 'react'
import { Link } from 'react-router-dom';
// import logo from '../../assets/images/ICD-international-logo.png'
import logo from '../../assets/images/rotiflex.png'
import './Signin.css';
import { Trans, useTranslation } from 'react-i18next';
import LanguageList from '../LanguageList/LanguageList';

const Signin = props => {
    const { t } = useTranslation();

    return (
        <div className="signin__container">
            <div className="signin__FromWrap">
                <div className="signin__fromContent">
                    <form className="signin__form" action="#">
                        <div className="signin_language_container">
                            <LanguageList />
                        </div>
                        <div className="signin__header">
                            <Link to="/" className="signin__Icon">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        {
                            props.signinError ? 
                                <span className="signin__error"> 
                                    Login ou mot de passe incorrect 
                                </span>
                            : null
                        } 
                        <div className="signin__formGroup">
                            {/* <label className="signin__formLabel" htmlFor='username'>{t("signin:login", "Login")}</label> */}
                            <input 
                                className="signin__formInput" 
                                type="email" 
                                name="email" 
                                placeholder={t("signin:login", "Login")}
                                onChange={props.changed} 
                                required 
                                autoFocus 
                            />
                        </div>
                        <div className="signin__formGroup">
                            {/* <label className="signin__formLabel" htmlFor='password'>{t("signin:password", "Mot de passe")}</label> */}
                            <input 
                                className="signin__formInput" 
                                type="password" 
                                name="password" 
                                placeholder={t("signin:password", "Mot de passe")}
                                onChange={props.changed} 
                                required 
                            />
                        </div>
                        <div className="signin__formBtnGroup">
                            <button 
                                className="signin__formButton" 
                                type="submit" 
                                onClick={props.submited}
                            >
                                {t("signin:signInBtn", "Se connecter")}
                            </button>
                            <Link to="/forgotPassword" className="forgotPWD__link">{t("authentication:forgottenPsw")}</Link>
                        </div>

                        <Link to="/newAccountRequest" className="newAccount__link">{t("authentication:newAccountRequest")}</Link>

                        <div className="signin__footer">
                            <p>
                                {t("default:copyright", `© ${new Date().getFullYear()} ICD International`)}
                            </p>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    )
}

export default Signin
