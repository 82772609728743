import styled from 'styled-components/macro';

export const IVWrapper = styled.div`
    display: flex;
    flex-direction: column;
    // height: -webkit-fill-available;
    // border: 1px solid #809FB8;
    border-radius: 18px 
`

export const IVContainer = styled.div`
    // height: 878px;
    // overflow: auto;
    // width: 99%;
    // display: flex;
`;
export const EntHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`;
export const XmlDiv = styled.div`
     width: 100%;
    height: 600px;
    overflow: auto;
`;

export const IVDownloadContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border: 1px solid;
`

export const IVDownloadIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;

    &:hover {
        color: ${({theme}) => theme.colors.primary};
    }
`