import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { TableAction, TableActionLink, TAContainer } from '../../../styles/Table.styled.js';
import { useTheme } from 'styled-components';


function TableActions(props) {
    const theme = useTheme();
    const {resetFilters, setShowFilters, showFilters, entityFilter, userType} = props;
    const {t} = useTranslation();

    return (
        <TAContainer>
            <TableAction active={showFilters} onClick={() => setShowFilters(!showFilters)}>
                <span>{t("global:filters")} </span>
                <FilterListIcon />
            </TableAction>
            {
                userType!='supplier' ? (
                    <TableActionLink 
                        color={theme.colors.primary} 
                        to="/entity"
                        state={{
                            entityFilter: entityFilter,
                            entityType: 'supplier'
                        }} 
                    >
                                <span>{t('tableAction:addEntity',`Ajouter un ${entityFilter === "client" ? "client" : "fournisseur"}`)}</span>
                        <AddIcon />
                    </TableActionLink>
                ) : null
            }
        </TAContainer>
    )
}

export default TableActions
