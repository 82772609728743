import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2'

export const UFContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
`

export const UFBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0.7rem;
`

export const UFHeaderTitle = styled.h4`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${({theme}) => theme.colors.lightBlue};
`

export const TelInput = styled(PhoneInput)`
    width: 180px;

    input {
        width: 100% !important;
    }
`