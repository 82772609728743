import React, { useEffect, useState } from "react";
import { Notyf } from "notyf";
import Axios from "../../axios-proas";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { formatDate, formatNumber, getNotyfObject } from "../../shared/utility";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { useTheme } from "styled-components";
import ActionsModal from "../../components/InvoiceDetail/ActionsModal/ActionsModal";
import {
  PrimaryBtnOutlineAction,
  SecondaryBtnOutlineAction,
} from "../../styles/Common";
import {
  IDBody,
  IDContainer,
  IDHeader,
  IDHeaderActions,
  IDHeaderContainer,
  IDHeaderTitle,
  IDHeaderInvoiceDate,
  IDHeaderWrapper,
  IDInvoiceDate,
  IDInvoiceDateLabel,
  IDInvoiceDateValue,
  IDProgressLineWrapper,
} from "./InvoiceCommand.style";
import IDProgressLine from "../../components/InvoiceDetail/IDProgressLine/IDProgressLine";
import InvoiceSide from "../../components/InvoiceDetail/InvoiceBody/InvoiceSide/InvoiceSideCommand";
import InvoiceView from "../../components/InvoiceDetail/InvoiceBody/InvoiceMain/InvoiceViewCommands/InvoiceView";
import InvoiceListCommand from "../../components/InvoiceDetail/InvoiceBody/InvoiceMain/InvoiceListCommand/InvoiceListCommand";
import TimeLineModal from "./InvoiceCommandModal/TimeLineModal";
import ModificationsChatModal from "./InvoiceCommandModal/ModificationsChatModal";

function InvoiceCommand(props) {
  const {
    loadingData,
    getInvoiceDataByToken,
    invoiceData,
    invoiceError,
    getInvoiceData,
    resetAdmToInitialState,
    setTabSideActive,
  } = props;
  const [lastEvent, setLastEvent] = useState();
  const theme = useTheme();
  let { uid, token } = useParams();
  const notyf = getNotyfObject();
  const { t } = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();
  const [commandesLinesData, setCommandesLinesData] = useState();
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [navTabsList, setNavTabsList] = useState([
    { label: "PDF", value: "pdf" },
  ]);
  const [modalIsOpenTimeLine, setModalIsOpenTimeLine] = useState(false);
  const [modalIsOpenChat, setModalIsOpenChat] = useState(false);
  const [updateAmount, setUpdateAmount] = useState(false);

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    Axios.get(`/invoice/${uid}/commandesData`).then((response) => {
      setCommandesLinesData(response?.data);
    });
    getLastInvoiceAction();
  }, [updateAmount, update]);


  const getLastInvoiceAction = () => {
    Axios.get("/invoice/" + uid + "/lastInvoiceAction")
      .then((response) => {
        setLastEvent(response?.data.lastAction);
      })
      .catch((err) => {
        notyf.error("Une erreur s'est produite");
      });
  };


  useEffect(() => {
    if (token) {
      getInvoiceDataByToken(token);
    } else {
      getInvoiceData(uid);
    }

    return () => {
      resetAdmToInitialState();
    };
  }, [uid, token]);


  const editStatus = (origin, status, comment, reason, valideur) => {
    Axios.put("/invoice//updateStatus", {
      InvoiceId: uid,
      status: status,
      reason,
      comment,
      origin,
      valideur,
    })
      .then((response) => {
        navigate("/");
        //getInvoiceData(uid);
        notyf.success("Statut modifié");
      })
      .catch((err) => {
        notyf.error("Une erreur s'est produite");
      });
  };

  const openModal = () => {
    setModalIsOpenTimeLine(true);
    setModalIsOpenChat(false);
  };

  const closeModal = () => {
    setModalIsOpenTimeLine(false);
    setTabSideActive("information");
  };

  const openModalChat = () => {
    setModalIsOpenTimeLine(false);
    setModalIsOpenChat(true);
  };

  const closeModalChat = () => {
    setModalIsOpenChat(false);

    setTabSideActive("information");
  };

  const onSaveButton = () => {
    Axios.put("/invoice/" + invoiceData.invoiceUid + "/information", {
      number: invoiceData?.number,
      exclusiveTaxAmount: invoiceData?.exclusiveTaxAmount,
      inclusiveTaxAmount: invoiceData.inclusiveTaxAmount,
      supplierUid: invoiceData?.supplierUid,
      clientUid: invoiceData?.clientUid,
    })
      .then((response) => {
        notyf.success("Modifications enregistrées");
        setUpdateAmount(!updateAmount);
        navigate("/fournisseurInvoicesConciliation")
      })
      .catch((err) => {
        notyf.error("Une erreur s'est produites");
      });
  };
  const onSaveButton2 = () => {
    Axios.put("/invoice/" + invoiceData.invoiceUid + "/information", {
      number: invoiceData?.number,
      exclusiveTaxAmount: invoiceData?.exclusiveTaxAmount,
      inclusiveTaxAmount: invoiceData.inclusiveTaxAmount,
      supplierUid: invoiceData?.supplierUid,
      clientUid: invoiceData?.clientUid,
    })
      .then((response) => {
        notyf.success("Modifications enregistrées");
        setUpdateAmount(!updateAmount);
     
      })
      .catch((err) => {
        notyf.error("Une erreur s'est produites");
      });
  };

  const addEventHandler = (event) => {
    editStatus(
      event.origin,
      event.action,
      event.comment,
      event.reason,
      event.valideur
    );
    onSaveButton2()
  };

  let mandatJsx = null;


  if (loadingData) mandatJsx = <Spinner />;
  else if (invoiceData)
    mandatJsx = (
      <>
        <IDHeaderWrapper>
          <IDHeaderContainer>
            <IDHeader>
              <IDHeaderTitle>
                Facture : {invoiceData?.number}&nbsp; (
                {invoiceData?.supplierName} - {invoiceData?.clientName})
              </IDHeaderTitle>
              {invoiceData?.status !== "ERROR" && (
                <>
                  <IDHeaderInvoiceDate>
                    {formatNumber(invoiceData?.inclusiveTaxAmount)} TTC
                  </IDHeaderInvoiceDate>
                  <IDInvoiceDate>
                    <IDInvoiceDateLabel>
                      Date de facturation :{" "}
                    </IDInvoiceDateLabel>
                    <IDInvoiceDateValue>
                      {formatDate(+invoiceData?.issuingDate)}
                    </IDInvoiceDateValue>
                  </IDInvoiceDate>
                </>
              )}
            </IDHeader>
            <IDProgressLineWrapper>
              <IDProgressLine
                error={+invoiceData?.error}
                status={invoiceData?.status}
                message={lastEvent?.message ? lastEvent?.message : null}
                // editStatus={editStatus}
                entityFilter={location?.state?.entityFilter}
                accessToken={token}
              />
            </IDProgressLineWrapper>
          </IDHeaderContainer>
          {!token && (
            <IDHeaderActions>
              <PrimaryBtnOutlineAction
                onClick={() => setShowActionsModal(true)}
              >
                Actions
              </PrimaryBtnOutlineAction>
              <PrimaryBtnOutlineAction onClick={() => onSaveButton()}>
                Sauvegarder
              </PrimaryBtnOutlineAction>
              <SecondaryBtnOutlineAction onClick={() => navigate(-1)}>
                Retour
              </SecondaryBtnOutlineAction>
            </IDHeaderActions>
          )}
        </IDHeaderWrapper>

        <IDBody accessToken={token} isCommand={true}>
          <div
            style={{
              position: "sticky",
              top: "0px",
              width: "100%",
            }}
          >
            <InvoiceView
              style={{ backgroundColor: "white" }}
              // pdfFile={invoiceData?.pdfFile}
              // pdfFileName={invoiceData?.docFile}
              commandLine={true}
              invoiceData={invoiceData}
              navTabsList={navTabsList}
              isCommand={true}
            />
          </div>
          <InvoiceSide
            openModal={openModal}
            openModalChat={openModalChat}
            isCommand={true}
            updateAmount={updateAmount}
            setUpdateAmount={setUpdateAmount}
            commandesLinesData={commandesLinesData}
            update={update}
            setUpdate={setUpdate}
          />
          <InvoiceListCommand
            uid={uid}
            updateAmount={updateAmount}
            update={update}
            setUpdate={setUpdate}
          />
        </IDBody>
      </>
    );

  return (
    <>
      <IDContainer>
        <div>
          {/* style={{ height: "1000px" }} */}
          {mandatJsx}
          {modalIsOpenTimeLine && (
            <TimeLineModal closeModal={closeModal} openModal={openModal} />
          )}
          {modalIsOpenChat && (
            <ModificationsChatModal
              closeModal={closeModalChat}
              openModal={openModalChat}
            />
          )}
        </div>
      </IDContainer>
      {showActionsModal && (
        <ActionsModal
          show={showActionsModal}
          handleClose={() => setShowActionsModal(false)}
          addEventHandler={addEventHandler}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,
  tabSideActive: state.invoiceDetail.tabSideActive,
  // role: state.auth.role,
  // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
  // creatorName : state.mandat?.admData?.creatorName
});
const mapDispatchToProps = (dispatch) => ({
  getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
  getInvoiceDataByToken: (invoiceToken) =>
    dispatch(actions.getInvoiceDataByToken(invoiceToken)),
  setTabSideActive: (tabSideActive) =>
    dispatch(actions.setTabSideActive(tabSideActive)),
  // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
  // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
  resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState()),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCommand);

//ValidorUsersList
