import React, { Fragment, useState } from 'react'
import DraftsIcon from '@mui/icons-material/Drafts';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { CTooltip } from '../../UI/CTooltip/CTooltip'
import { useTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import pdfIcon from '../../../assets/images/pdf.png';
import xmlIcon from '../../../assets/images/xml.png';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Axios from '../../../axios-proas'
import { Link} from 'react-router-dom';
import StatusReasonModal from './StatusReasonModal/StatusReasonModal';
import { getNotyfObject, getStatusWithKey, getChannelWithKey,showColumn, getAlignPosition, getStatusBgColor } from '../../../shared/utility';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { InvoiceLink, SelectStatus, StatusOption, TD } from '../../../styles/Table.styled';
import CodeIcon from '@mui/icons-material/Code';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

function TableBody(props) {
    const {data, formatDate, setViewed, selectedRows, setSelectedRows, userType, userLevel, editStatus, disableStatus, entityFilter,columns,statusFilter} = props;

    const {t} = useTranslation();
    let notyf = getNotyfObject();
    const [showStatusReasonModal, setShowStatusReasonModal] = useState(false)
    const [statusToEditData, setStatusToEditData] = useState(null);
    const [invoiceToEdit, setInvoiceToEdit] = useState(null);

    //console.log("Entity Filter: " + entityFilter);

    const formatNumber = (number) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.',' ')
    }

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e) => {
        let id = e.target.name;
        let val = e.target.checked;
        setSelectedRows(id, val)
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        return hour + ":" + minutes
    }

    const clickedHandler = (e, uid) => {
        e.preventDefault()
        props.getDocumentFile(uid)
    }
    const clickedHandlerEdi = (e, uid) => {
        e.preventDefault()
        props.getinvoiceGetDocumentEdi(uid)
    }

    
    const clickedHandlerXml = (e, uid) => {
        e.preventDefault()
        props.getinvoiceGetDocumentXml(uid)
    }

    const clickedHandlerChorus = (e, uid) => {
        e.preventDefault()
        props.getinvoiceGetDocumentChorus(uid)
    }
    
    const clickAttachmentHandler = (e, uid) => {
        e.preventDefault()
        Axios.get('/invoice/'+uid+'/attachmentFile', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if(response.status === 200){
                const blob = new Blob([response.data], { type: response.request.getResponseHeader("Content-Type") })
                let filename = "";
                const disposition = response.request.getResponseHeader('Content-Disposition')
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches !== null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');
                }
                const downloadUrl = URL.createObjectURL(blob)
                const a = document.createElement("a"); 
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            } else
                notyf.error("Pièce jointe non trouvée");
        }).catch(err => {
            notyf.error('Une erreur s\'est produite !')
        })
    }

    const inputChangeStatus=(status, invoiceUid)=>{
        if(status && !disableStatus){
            if(status === "LITIGATION"){
                setInvoiceToEdit(invoiceUid)
                setShowStatusReasonModal(true);
            } else 
                editStatus(invoiceUid, status)
         }
    }
   
    const markAsRead = ( invoiceUid, firstAccessDate = null) => {
        if(!firstAccessDate){
            Axios.put('/invoice/'+invoiceUid+'/setInvoiceAsViewed', 
                  {
            }).then(response => {
                if(response?.data?.success)
                notyf.success("Facture marquée comme lue")
            }).catch(err => {
                notyf.error("Une erreur s'est produite")
            })
        }
    }

    const diffToDys = (date2) => {
        let date1 = Date.now();
        let diff = Math.abs(date1 - date2);
        return Math.ceil(diff / (1000 * 3600 * 24));
    }


    return (
        <>           
            {

                data.map(row => (
                    <tr key={row.id} >
                        <TD>
                            <input type="checkbox" name={row.uid} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row.uid)}  className="form-check-input"  id="exampleCheck1" />
                        </TD>
                           <TD style={{display: !showColumn(columns,"clientName") && 'none', textAlign: getAlignPosition(columns, "clientName")}}title={row.clientName}>{row.clientName}</TD>

                        <TD style={{display: !showColumn(columns,"number") && 'none', textAlign: getAlignPosition(columns, "number")}}>
                            <InvoiceLink 
                                to={"/CommandesDeadlineExceededDetail/"+row.uid}
                                state={{entityFilter}}
                                status={getStatusBgColor(row?.status)}
                                onClick={() => markAsRead(row.uid, +row.firstAccessDate)}
                            >
                                <span title={row.number} >{row.number}</span>
                               
                            </InvoiceLink>    
                        </TD>
                        <TD style={{display: !showColumn(columns,"issuingDate")&& 'none', textAlign: getAlignPosition(columns, "issuingDate")}}>{formatDate(+row.issuingDate)}</TD> 
                        <TD style={{display: !showColumn(columns,"dueDate") && 'none', textAlign: getAlignPosition(columns, "dueDate")}}>{diffToDys(+row.dueDate) } Jour(s)</TD>

                     
                        <TD style={{display: !showColumn(columns,"numberReminds") && 'none', textAlign: getAlignPosition(columns, "numberReminds")}}>{(+row.numberReminds)}</TD>
                          
                    </tr>
                ))
                
            }
            {
                showStatusReasonModal && 
                    <StatusReasonModal
                        show={showStatusReasonModal}
                        handleClose={() => setShowStatusReasonModal(false)}
                        invoiceToEdit={invoiceToEdit}
                        editStatus={editStatus}
                    />
            }
        </>
    )
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = dispatch => ({
    getDocumentFile: (uid) => dispatch(actions.invoiceGetDocumentFile(uid)),
    getinvoiceGetDocumentXml: (uid) => dispatch(actions.invoiceGetDocumentXml(uid)),
    getinvoiceGetDocumentEdi: (uid) => dispatch(actions.invoiceGetDocumentEdi(uid)),
    getinvoiceGetDocumentChorus:(uid)=>dispatch(actions.invoiceGetDocumentChorus(uid)),
})
export default connect(mapStateToProps, mapDispatchToProps) (TableBody)
