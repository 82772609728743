import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Axios from '../../axios-proas';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import { useTranslation } from 'react-i18next';
import { checkValidity, getNotyfObject } from '../../shared/utility';
import { useNavigate, useLocation , useParams} from 'react-router-dom';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction, FormGroup, FormInput, FormLabel, FormSelect, Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { useTheme } from 'styled-components';
import { Modal } from 'react-bootstrap';
import { TelInput, UFBody, UFContainer, UFHeaderTitle } from './UserForm.styled';
import { EIFormCol, FormColModal, EIFormContainer, EntFormItem, EntFormList, EntFormSection } from '../Entity/Entity.styled';
import EntitiesDropDown from '../../components/Entity/EntitiesDropDown/EntitiesDropDown';
import CloseIcon from '@mui/icons-material/Close';
// import EntitiesComponent from '../../component/UserForm/EntitiesComponent/EntitiesComponent';

function UserForm({show,newUserModal, setConfirmationUserModalShow,userType, title, message, Type,userUid, contact, setContact, contactEntity, usersManagementUpdate,userFormDataCopie, setUsersManagementUpdate}) {

    // const [user, setUser] = useState(contact)
    const [entity, setEntity] = useState(contactEntity);
    const [showEntityModal, setShowEntityModal] = useState(false);
    const [errors, setErrors] = useState(null);
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    let location = useLocation();
    let params = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const [entitiesForMap, setEntitiesForMap] = useState([]);
    console.log("contact", contact)
    const closeModal=()=>{
        setContact(userFormDataCopie)
        setErrors(null)
        setConfirmationUserModalShow(false)
    }
    // useEffect(() => {
    //     console.log(contact)
    //     if(contact != undefined){
    //         //const userUid = params?.uid;
    //         //Axios.get('/user/'+userUid+'/user').then((response) => {
    //             setUser(contact);
    //             console.log(contact)
    //         // }).catch(err => {
    //         //     console.log(err);
    //         // })
    //     }
    //     // if(!contactEntity){
    //     //     const entityUid = location.state.entityUid;
    //     //     Axios.get('/entity/'+entityUid+'/object').then((response) => {
    //     //         setEntity(response.data?.entity);
    //     //         let newUser = {}
    //     //         if(response.data?.entity?.type=='owner')
    //     //             newUser.entities = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid }]
    //     //         else if(response.data?.entity?.type=='supplier')
    //     //             newUser.suppliers = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid }]
    //     //         else if(response.data?.entity?.type=='client')
    //     //             newUser.clients = [{ name: response.data?.entity?.name, uid: response.data?.entity?.uid }]
    //     //         newUser.type = response.data?.entity?.type
    //     //         newUser.level = 'standard'
    //     //         setUser(newUser)
    //     //     }).catch(err => {
    //     //         console.log(err);
    //     //     })
    //     // }
    // },[])

    // useEffect(() => {
    //     return () => {
    //         setContact(userFormDataCopie)
    //         setErrors(null)
    //     }
    // },[contact])
 
    const inputChangeHandler = (e) => {
        const name = e.target.name;
        let value =  e.target.value;

        let userTemp = {
            ...contact,
            [name]: value
        }
        setContact(userTemp)
    }

    const phoneInputChangeHandler = (value) => {
        let userTemp = {
            ...contact,
            telephone_number : value
        }
        setContact(userTemp)
    }

    const entitiesRadioChangeHandler = (name, value) => {
        let userTemp = {
            ...contact,
            [name]: +value
        }
        setContact(userTemp)
    }

    const entitiesChangeHandler = (entitiesList) => {
        let userTemp = {
            ...contact,
            entities : entitiesList
        }
        setContact(userTemp)
    }

    const clientsChangeHandler = (clientsList) => {
        let userTemp = {
            ...contact,
            clients : clientsList
        }
        setContact(userTemp)
    }

    const contactsChangeHandler = (suppliersList) => {
        switch (contact?.type){
            case 'owner':
                let userTemp = {
                    ...contact,
                    entities : suppliersList
                }
                setContact(userTemp)
                break;
            case 'client':
                let userTemp2 = {
                    ...contact,
                    clients : suppliersList
                }
                setContact(userTemp2)
                break;
            case 'supplier':
                let userTemp3 = {
                    ...contact,
                    suppliers : suppliersList
                }
                setContact(userTemp3)
                break;
                default:
                    let userTemp4 = {
                        ...contact,
                        entities : suppliersList
                    }
                    setContact(userTemp4)
                    break;
        }
 
      
    }
    
    const deleteEntityclickHandler = (entity) => {
        let userTemp = {...contact}
        if(contact?.id && !entity?.new ){
            userTemp = {
                ...userTemp,
                entitiesToDelete : userTemp?.entitiesToDelete ? [...userTemp?.entitiesToDelete, entity.uid] : [entity.uid]
            }
        }
        let userEntities = [...contact?.entities]
        let entityIndex = contact?.entities.findIndex(ent => ent.uid === entity.uid);
        if(entityIndex >=0 ){
            userEntities.splice(entityIndex, 1)
        }
        setContact({
            ...userTemp,
            entities: userEntities
        })
    }

    const deleteClientClickHandler = (client) => {
        let userTemp = {...contact}
        if(contact?.id && !client?.new ){
            userTemp = {
                ...userTemp,
                clientsToDelete : userTemp?.clientsToDelete ? [...userTemp?.clientsToDelete, client.uid] : [client.uid]
            }
        }
        let userClients = [...contact?.clients]
        let clientIndex = contact?.clients.findIndex(ent => ent.uid === client.uid);
        if(clientIndex >=0 ){
            userClients.splice(clientIndex, 1)
        }
        setContact({
            ...userTemp,
            clients: userClients
        })
    }

    const deleteSupplierClickHandler = (supplier) => {
        let userTemp = {...contact}
        if(contact?.id && !supplier?.new ){
            userTemp = {
                ...userTemp,
                suppliersToDelete : userTemp?.suppliersToDelete ? [...userTemp?.suppliersToDelete, supplier.uid] : [supplier.uid]
            }
        }
        let userSuppliers = [...contact?.suppliers]
        let supplierIndex = contact?.suppliers.findIndex(ent => ent.uid === supplier.uid);
        if(supplierIndex >=0 ){
            userSuppliers.splice(supplierIndex, 1)
        }
        setContact({
            ...userTemp,
            suppliers: userSuppliers
        })
    }


    const returnFunctionDelete=(entity)=>{
        switch (contact?.type) {
            case "supplier":
                return  deleteSupplierClickHandler(entity);
            case "client":
                return deleteClientClickHandler(entity);
            case "owner":
                return deleteEntityclickHandler(entity);
            default:
                return  deleteEntityclickHandler(entity);
        }
    
    }
    const checkUserFormValidity = (contact) => {
        let errors = []
        if(!contact.hasOwnProperty('type') || !checkValidity(contact?.type, {required: true})){
            errors.push(t("masterData:error_userType", "Veuillez sélectionner un type pour l'utilisateur")) ;
        }
        if(!contact.hasOwnProperty('level') || !checkValidity(contact?.level, {required: true})){
            errors.push(t("masterData:error_userRole", "Veuillez sélectionner un role pour l'utilisateur")) ;
        }
        if(!contact.hasOwnProperty('login') || !checkValidity(contact?.login, {required: true, isEmail: true})){
            errors.push(t("masterData:error_userEmail", "Le champ 'Email' est vide ou non valide")) ;
        }
        if(showEntitiesListComponent() && +contact?.accessOwner !== 1 && (!contact.hasOwnProperty('entities') || contact?.entities.length == 0)){
            errors.push(t("masterData:error_userEntity", "Aucune entité interne n'a été sélectionée ! ")) ;
        }
        /*
        if(showClientsListComponent() && +contact?.accessClient !== 1 && (!contact.hasOwnProperty('clients') || contact?.clients.length == 0)){
            errors.push(t("masterData:error_userClients", "Aucun client n'a été sélectioné ! ")) ;
        }
        */
        if(showSuppliersListComponent() && +contact?.accessSupplier !== 1 && (!contact.hasOwnProperty('suppliers') || contact?.suppliers.length == 0)){
            errors.push(t("masterData:error_userSupplier", "Aucun fournisseur n'a été sélectioné ! ")) ;
        }
        return errors;
    }

    const addUserclickHandler = () => {
        let checkErrors = checkUserFormValidity(contact);
        if(checkErrors && checkErrors.length > 0){
            setErrors(checkErrors)
        }else{
            setErrors(null)
            Axios.post('user', contact).then(response => {
                if(response.data.hasOwnProperty('error')){
                    notyf.error(t("default:emailAlreadyExist", "Cet email existe déja"));
                }else if (response.data.hasOwnProperty('success')) {
                    notyf.success(t("default:userAdded", "L'utilisateur a bien été ajouté"))
                    setContact(null);
                    closeModal()
                    setUsersManagementUpdate(!usersManagementUpdate)
                }
            }).catch(response => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
        }
    }
   

    const editUserclickHandler = () => {
        let checkErrors = checkUserFormValidity(contact);
        if(checkErrors && checkErrors.length > 0){
            setErrors(checkErrors)
        }else{
            setErrors(null)
            Axios.put(`user/${contact?.uid}/user`, contact).then(response => {
                if (response.data.hasOwnProperty('success')) {
                    notyf.success(t("default:userAdded", "L'utilisateur a bien été modifié"))
                    setContact(null);
                    closeModal()
                    setUsersManagementUpdate(!usersManagementUpdate)
                }
            }).catch(response  => {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            })
        }
    }

    const saveClickHandler = () => {
        if(contact?.id){
            editUserclickHandler()
        }else{
            addUserclickHandler()
            
        }
    }
 
    const showEntitiesListComponent = () => {
        return (contact?.type === "owner")
    }

    const showClientsListComponent = () => {
        return (contact?.type === "owner" && contact?.level === "standard")
                || (contact?.type === "client")
    }

    const showSuppliersListComponent = () => { 
        return (contact?.type === "owner" && contact?.level === "standard")
                || (contact?.type === "supplier")
    }
const returnUserEntities = () => { 

    switch (contact?.type) {
        case "supplier":
            return  contact?.suppliers?.length > 0 ? contact?.suppliers : [];
        case "client":
            return contact?.clients?.length > 0 ? contact?.clients   : [];
        case "owner":
            return contact?.entities?.length > 0 ? contact?.entities : [];
        default:
            return contact?.entities?.length > 0 ? contact?.entities : [];

    }
 

}

const returnEntitiesForMap = useMemo(() =>{

    let adm=null;
    switch (contact?.type) {
        case "supplier":
            return(
                contact?.suppliers?.length  > 0 ? contact?.suppliers?.map(entity => (
                    <EntFormItem key={entity.uid} >
                        <span>{entity.name}</span>
                        <CloseIcon style={{fontSize: "0.7rem", cursor: "pointer"}} onClick={() => returnFunctionDelete(entity)} />
                    </EntFormItem>
                )) : null
            )          
        case "client":
            return(
                contact?.clients?.length  > 0 ? contact?.clients?.map(entity => (
                    <EntFormItem key={entity.uid} >
                        <span>{entity.name}</span>
                        <CloseIcon style={{fontSize: "0.7rem", cursor: "pointer"}} onClick={() => returnFunctionDelete(entity)} />
                    </EntFormItem>
                )) : null
            )   
        default:
            return(
                contact?.entities?.length  > 0 ? contact?.entities?.map(entity => (
                    <EntFormItem key={entity.uid} >
                        <span>{entity.name}</span>
                        <CloseIcon style={{fontSize: "0.7rem", cursor: "pointer"}} onClick={() => returnFunctionDelete(entity)} />
                    </EntFormItem>
                )) : null
            )       
     }
    }
, [contact,userFormDataCopie])

const jsk=useMemo(() => 
        ( <> 
        {userType=="owner"&&
                <EntitiesDropDown 
                    entitiesType={contact?.type}
                    userEntities={returnUserEntities()}
                    addEntitiesUser={contactsChangeHandler}
                />
        }
                <EntFormList>
                    {returnEntitiesForMap}
                </EntFormList>
         </>
    )
, [contact,userFormDataCopie])
    return (
        <>
        <Modal show={show} onHide={closeModal} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title className="ICDBlue"> {title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px",padding: "0 2rem 0.5rem 2rem" }}>
            
                    <UFContainer>


                        {/* <Header>
                            <HeaderInfos>
                                <UFHeaderTitle >{t("global:ficheUser", "Fiche Utilisateur : ")}</UFHeaderTitle>
                                <HeaderTitle>Informations administrative</HeaderTitle>
                            </HeaderInfos>
                            <HeaderActions>
                                
                            </HeaderActions>
                        </Header> */}


                        <UFBody>
                            {
                                errors && errors.length > 0 ? (
                                    <div className="alert alert-danger mt-2" role="alert">
                                        <ul>
                                            {
                                                errors.map(error => (<li key={error}>{error}</li>))
                                            }
                                        </ul>
                                    </div>
                                ) : null
                            }
                            <EntFormSection>
                              {jsk}
                            </EntFormSection>
                            <EIFormContainer>
                                <FormColModal>
                                    <FormGroup>
                                        <FormLabel htmlFor="first_name" >Prénom</FormLabel>
                                        <FormInput 
                                            id="first_name"
                                            type="text"
                                            name="first_name"
                                            placeholder="Prénom"
                                            value={contact?.first_name || ""}
                                            onChange={(e) => inputChangeHandler(e)} 
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="last_name" >Nom</FormLabel>
                                        <FormInput 
                                            id="last_name"
                                            type="text"
                                            name="last_name"
                                            placeholder="Nom"
                                            value={contact?.last_name || ""}
                                            onChange={(e) => inputChangeHandler(e)} 
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="login" >Email</FormLabel>
                                        <FormInput 
                                            id="login"
                                            type="text"
                                            name="login"
                                            placeholder="Email"
                                            value={contact?.login || ""}
                                            onChange={(e) => inputChangeHandler(e)} 
                                        />
                                    </FormGroup>
                                
                                
                                    <FormGroup>
                                        <FormLabel htmlFor="telephone_number" >Numéro de téléphone</FormLabel>
                                        <TelInput
                                            localization={fr}
                                            country={'fr'}
                                            dropdownStyle={{width: "auto"}}
                                            preferredCountries={['fr', 'us']}
                                            enableSearch={false}
                                            inputProps={{name: "telephone_number"}}
                                            placeholder={"33 0 00 00 00 00"}
                                            value={contact?.telephone_number?.substr(2) || ''}
                                            onChange={(phone) => phoneInputChangeHandler("00" + phone)} 
                                        />
                                    </FormGroup>
                                    {!(userType == "client") && 
                                    
                                    <FormGroup>
                                          
                                        <FormLabel htmlFor="type" >Type</FormLabel>
                                        <FormSelect 
                                            id="type"
                                            name="type"
                                            value={ contact?.type || ""}
                                            onChange={(e) => inputChangeHandler(e)} 
                                            disabled={ contact?.id || entity }
                                        >
                                            <option value="owner">Interne</option>
                                            <option value="client">Client</option>
                                            <option value="supplier">Fournisseur</option>
                                        </FormSelect>
                                        
                                        
                                    </FormGroup>
                                                }

                                    {!(userType == "client") && 
                                    <FormGroup>
                                        <FormLabel htmlFor="level" >Rôle</FormLabel>
                                        <FormSelect 
                                            id="level"
                                            name="level"
                                            value={contact?.level || ""}
                                            onChange={(e) => inputChangeHandler(e)} 
                                        >
                                            <option value="admin">Admin</option>
                                            <option value="standard">Standard</option>
                                        </FormSelect>
                                    </FormGroup>
                                                 }
                                </FormColModal>
                            </EIFormContainer>
                        </UFBody>


                    </UFContainer>
                </Modal.Body>
                <Modal.Footer >
                    <PrimaryBtnOutlineAction
                        color={theme.colors.success}
                        border={theme.colors.success}
                        onClick={() => saveClickHandler()}
                       
                    >
                        Confirmer
                       
                    </PrimaryBtnOutlineAction>
                    <CancelBtnOutlineAction
                        color={theme.colors.danger}
                        border={theme.colors.danger}
                        onClick={closeModal}
                    >
                        Annuler
                    </CancelBtnOutlineAction>
               </Modal.Footer>
           </Modal>

        </>
    )
}

export default UserForm
