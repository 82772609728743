
import styled from 'styled-components/macro';

export const FormRadioWrapper = styled.div`
display: flex;
align-items: baseline;
position: relative;
border-radius: 2px;
`;

export const FormLabel = styled.label`
    cursor: pointer;
    display: flex;
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: pre-wrap;
    color: #505050;
    font-size: 0.9rem;
`;