import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import Axios from '../../../../../axios-proas'
import Spinner from 'react-bootstrap/Spinner'
import MessageEvent from './TimelineCards/MessageEvent/MessageEvent';
import StatusEvent from './TimelineCards/StatusEvent/StatusEvent';
import { TLContainer } from './Timeline.styled';
import { kk } from 'date-fns/locale';

function TimeLine(props) {
    const {
        userUid,
        userType,
        paymentSignatureUid,
        eventUpdate
    } = props 
    let {uid} = useParams();
    const notyf = getNotyfObject();
    const {t} = useTranslation();
    const [timelineData, setTimelineData] = useState(null);
    const [loading , setLoading] = useState(false)

    const getTimelineData = () => {
        setLoading(true)
        Axios.get("paymentsignature/"+paymentSignatureUid+"/paymentSignatureEvent",{
            params: {
                type: "PAYMENT_SIGNATURE"
            }}
        ).then(response => {
            setLoading(false)
            setTimelineData(response?.data?.events)
           let dd=response?.data?.events;
          console.log(dd)

        }).catch(response => {
            setLoading(false)
            notyf.error(t("mandat:generatedErrorMessage", "une erreur s'est produite"));
        })
    }

    useEffect(() =>{
        getTimelineData()
    }, [uid, eventUpdate])

    let timelineJsx = null;

    if(loading) 
        timelineJsx = (<Spinner />)
    else if (timelineData && timelineData?.length > 0){
        timelineJsx = timelineData?.map((timelineEvent, index) => {
                var eventJsx = null;
                let isLast = index === 0
                let isCreator = userUid === timelineEvent?.user?.userUid;
                switch (timelineEvent?.type) {
                    case "PAYMENT_SIGNATURE":
                        if(userType === 'client'){
                            if(['IMPORTED', 'CONTROLLED'].includes(timelineEvent?.snapshot))
                                break;
                        }
                        eventJsx = (<StatusEvent
                                        userType={userType}
                                        eventData={timelineEvent}
                                        isCreator={isCreator}
                                        isLast={isLast}
                                    />)
                        break; 
                    case "PAYMENT_SIGNATURE_CHAT":
                        eventJsx = (<MessageEvent
                                        eventData={timelineEvent}
                                        isLast={isLast}
                                    />)
                        break;
                    default:
                        break;
                }
               return eventJsx
            })
    }

    return ( 
        <TLContainer>
            {timelineJsx}
        </TLContainer>
    )
}
const mapStateToProps = (state) => ({
    userUid : state.auth.userUid,
    userType: state.auth.userType,
    // loading: state.mandat.loadingTimeline,
    // timelineData: state.mandat.timelineData,
    // error: state.mandat.errorTimeline,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid
})
const mapDispatchToProps = dispatch => ({
    // getMandatTimelineEvents: (mandatUid) => dispatch(actions.getMandatTimelineEvents(mandatUid)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status))
})
export default connect(mapStateToProps, mapDispatchToProps)(TimeLine)