import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "styled-components";
import { Notyf } from "notyf";
import {
  IDContainer,
  IDContainerTable,
  ButtonCommand,
  MySelect,
  ActionButton,
} from "./InvoiceListCommand.styled";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CommandLineModal from "./CommandModal/CommandLineModal";
import AdditionalLineModal from "./CommandModal/AdditionalLineModal";
import CommandTable from "./CommandTable";
import Axios from "../../../../../axios-proas";
function TableCommand(props) {
  const { loadingData, invoiceData, invoiceError, uid, updateAmount, update, setUpdate } = props;
  const theme = useTheme();
  const [isOpenCL, setIsOpenCL] = useState(false);
  const [commandModalShow, setCommandModalShow] = useState(false);
  const [isCommandModal, setIsCommandModal] = useState(true);
  const [commandLineSelected, setCommandLineSelected] = useState([]);
  const [deleteLine, setDeleteLine] = useState(false);

  const [MHT, setMHT] = useState();
  const [MTVA, setMTVA] = useState();
  const [MTTC, setMTTC] = useState();

  const [MHTINV, setMHTINV] = useState();
  const [MTVAINV, setMTVAINV] = useState();
  const [MTTCINV, setMTTCINV] = useState();

  // useEffect(() => {
  //   console.log(invoiceData);

  // }, [invoiceData]);

  // useEffect(() => {
  //   getCommandesDataByInvoiceUid(uid);
  //   //getAdditionalLineById();
  // }, [uid, commandLineSelected, commandModalShow]);

  useEffect(() => {
    var someTva = 0;
    var someHT = 0;
    var someTTC = 0;
    console.log("update ammount cons");
    Axios.get(`/invoice/${uid}/commandesData`)
      .then((response) => {
        //dispatch(invoiceDataSuccess(response.data))
        //setCommandLineData(response?.data);
        if (response?.data?.length > 0) {
          response?.data?.map((cls) => {
            someTva = someTva + +cls.tva;
            someHT = someHT + (isNaN(+cls?.eta) ? 0 : +cls?.eta);
            someTTC = someTTC + (isNaN(+cls?.ita) ? 0 : +cls?.ita);
          });
        }
        setMTVA(someTva);
        // console.log(someTva)
        setMHT(someHT);
        setMTTC(someTTC);
        setCommandLineSelected(response?.data);
      })
      .catch((err) => {
        // Notyf.error("Une erreur s'est produite !");
        //dispatch(invoiceDataFail(err.response?.data?.error))
      });
    setMHTINV(+invoiceData?.exclusiveTaxAmount);
    setMTVAINV(
      (+invoiceData?.inclusiveTaxAmount - +invoiceData?.exclusiveTaxAmount).toFixed(2)
    );
    setMTTCINV(+invoiceData?.inclusiveTaxAmount);
    // console.log((+invoiceData?.inclusiveTaxAmount - +invoiceData?.exclusiveTaxAmount).toFixed(2))
  }, [update, updateAmount]);

  const openModal = (isCommandM) => {
    setIsOpenCL(!isOpenCL);
    setIsCommandModal(isCommandM);
    setCommandModalShow(!commandModalShow);
  };

  const deleteCommandLine = () => {
    // setDeleteLine(!deleteLine);
    let tmp = commandLineSelected.filter((cls) => +cls.checked);
    if (tmp.length > 0) {
      Axios.put("/invoice//deleteCommandLineById", tmp)
        .then((response) => {
          setUpdate(!update);
        })
        .catch((err) => {
          Notyf.error("Une erreur s'est produite !");
        });
    }
  };
  const selectedAll = () => {
    if (commandLineSelected.some((cls) => !cls.checked)) {
      setCommandLineSelected(
        commandLineSelected.map((cls) => ({ ...cls, checked: true }))
      );
    } else {
      setCommandLineSelected(
        commandLineSelected.map((cls) => ({ ...cls, checked: false }))
      );
    }
    // console.log(commandLineSelected)
    // commandLineSelected.map((index,line)=>{
    //   console.log(index,line)

    //   let jsk = commandLineSelected[0];
    // console.log(jsk)

    // })
  };

  const closeModalAction = () => {
    setCommandModalShow(false);
    // setUpdate(!update)
  };

  return (
    <IDContainer>
      <IDContainerTable>
        {isCommandModal ? (
          <CommandLineModal
            update={update}
            setUpdate={setUpdate}
            // getCommandesDataByInvoiceUid={getCommandesDataByInvoiceUid}
            show={commandModalShow}
            modalClosed={() => closeModalAction()}
            cancel={() => closeModalAction()}
            invoiceData={invoiceData}
            commandLineData={commandLineSelected}
            uid={uid}
          />
        ) : (
          <AdditionalLineModal
            update={update}
            setUpdate={setUpdate}
            // getCommandesDataByInvoiceUid={getCommandesDataByInvoiceUid}
            show={commandModalShow}
            modalClosed={() => closeModalAction()}
            cancel={() => closeModalAction()}
            invoiceData={invoiceData}
            commandLineData={commandLineSelected}
            uid={uid}
          />
        )}

        <ButtonCommand>
          <div>
            <CheckIcon
              onClick={selectedAll}
              fontSize="medium"
              style={{ marginLeft: "14px", marginRight: "10px" }}
            />
            <AddCircleIcon
              style={{
                marginLeft: "0.25rem",
                color: theme.colors.primary,
                cursor: "pointer",
              }}
              fontSize="medium"
              onClick={() => setIsOpenCL(!isOpenCL)}
            />
            {isOpenCL && (
              <MySelect>
                <ActionButton onClick={() => openModal(true)}>
                  Ajouter des lignes de commande
                </ActionButton>
                <ActionButton onClick={() => openModal(false)}>
                  Ajouter une ligne additionnelle
                </ActionButton>
              </MySelect>
            )}

            <CancelIcon
              style={{
                marginLeft: "0.25rem",
                color: theme.colors.danger,
                cursor: "pointer",
              }}
              fontSize="medium"
              onClick={() => deleteCommandLine()}
            />
          </div>
          <div>
            <span
              style={{
                marginRight: "19px",
                fontSize: "0.9rem",
                color: MHT != MHTINV ? theme.colors.danger : "",
              }}
            >
              {new Intl.NumberFormat("fr-FR").format(
                parseFloat(MHT).toFixed(2)
              )}{" "}
              € HT
            </span>
            <span
              style={{
                marginRight: "19px",
                fontSize: "0.9rem",
                color: MTVA != MTVAINV ? theme.colors.danger : "",
              }}
            >
              {new Intl.NumberFormat("fr-FR").format(
                parseFloat(MTVA).toFixed(2)
              )}{" "}
              € TVA
            </span>
            <span
              style={{
                fontSize: "0.9rem",
                color: MTTC != MTTCINV ? theme.colors.danger : "",
              }}
            >
              {new Intl.NumberFormat("fr-FR").format(
                parseFloat(MTTC).toFixed(2)
              )}{" "}
              € TTC
            </span>
          </div>
        </ButtonCommand>
        <CommandTable
          // commandLineData={commandLineData}
          setCommandLineSelected={setCommandLineSelected}
          commandLineSelected={commandLineSelected}
          // additionalLineData={additionalLineData}
        />
      </IDContainerTable>
    </IDContainer>
  );
}
const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TableCommand);
