import styled from 'styled-components';


export const PLineStatus = styled.div`
    text-align: center;
    padding-top: 10px;
`

export const PLineContainer = styled.div`
    text-align: center;
    padding-top: 10px;
    width: 16%;
`
export const PLineContent = styled.div`
    display:flex
`


export const PLineBar = styled.p`
    width: 0px; 
    height: 27px; 
    border-left: 5px solid ${(props) => props?.color ? props.color : '#D9E1E7'}};
    margin: 0px auto 10px auto;
    color: ${(props) => props?.color ? props.color : '#D9E1E7'}};
`
export const PLineLabel = styled.p`
    color: ${(props) => props?.color ? props.color : '#D9E1E7'}};
`

export const PLineRequestInf = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 22px 80px 0px 34px;
    @media (max-width: 1200px) {
        display: block
    }
`



export const PLineCircle = styled.p`
    width: 100px; 
    height: 43px; 
    border-radius: 50%; 
    background-color: ${(props) => props?.color ? props.color : '#D9E1E7'}}; 
    margin: auto;
`

export const FormGroup = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 0.25rem;

    &:not(:last-child) {
        margin-bottom: 0.7rem;
    }

    &.fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }

`

export const FormLabelInf = styled.label`
    display: flex;
    margin: 0 0 0 0;
    padding: 0;
    width: 69px;
    min-width: 59px;
    color: ${({theme}) => theme.colors.secondary};
`;
export const FormLabelValues = styled.label`
    display: flex;
    margin: 0 0rem 0 0;
    padding: 0;
    white-space: nowrap;
    color: ${({theme}) => theme.colors.lightBlack};
`;

export const PLine = styled.p`
    border-top: 5px solid ${(props) => props?.border ? props.border : '#D9E1E7'}};
    margin: auto;
    width: 100%;
`
export const PLineHiden = styled.p`
    border-top: 5px solid #ffff;
    margin: auto;
    width: 100%;
`

