import styled from 'styled-components/macro';

export const EntInfosContainer = styled.div`
    width: 100%;
    font-size: 0.9rem;
    margin-top: 1rem;
`;




export const EIFormContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: flex-start;
padding-top : 0.7rem ;
`;



export const IDHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const IDHeaderTitle = styled.h4`
    font-size: 1.5rem;
    
    color: ${({theme}) => theme.colors.primary};
    @media ${({theme}) => theme.device.laptop} {
        font-size: 1.5rem;
    }

`
export const IDInvoiceDate = styled.div`
    display: flex;
    font-size: 2rem;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 0 1.6rem 0rem;
    color: ${({theme}) => theme.colors.secondary};

    @media ${({theme}) => theme.device.laptop} {
        padding: 0 0 1.6rem 0rem;
    }
`

export const IDInvoiceDateLabel = styled.h4`
    font-size: 1.2rem;
    margin: 0 0.25rem 0 0;
    white-space: nowrap;

    @media ${({theme}) => theme.device.laptop} {
        font-size: 1rem;
    }
`

export const IDInvoiceDateValue = styled.h4`
    font-size: 1.2rem;
    margin: 0;
    white-space: nowrap;
    @media ${({theme}) => theme.device.laptop} {
        font-size: 0.9rem;
    }
`


export const IDHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`



export const ItemTimeline = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0px;
`

export const ItemComment = styled.div`
    display: flex;
    flex-direction:column ;
    margin-left: 50px;
    border-left: 2px solid ${({theme}) => theme.colors.secondary};
    padding-left: 10px;
`

export const ColContainer= styled.div`
    width :49.5%;
    // height: fit-content; 
    height: 551px;
    border: 1px solid #809FB8;
    border-radius: 16px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;


    & canvas {
        max-width: 100% !important;
        max-height: 80% !important;
    }

    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: 100%;
    }
`
export const HeadEnt = styled.div`

padding-bottom:1.1rem;
color: #2174B9;
font-size: 1.2rem;
`

export const TimelinItem = styled.div`
display: flex

`
export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0rem ;
  border: ${({border}) => border ? `10px ${border}` : 'none'};

  
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }

  &.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
  }
`