import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './StatusModal.css';
import { Button, Modal } from 'react-bootstrap';
// import { getStatusWithKey } from '../../../shared/utility';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../../styles/Common';


function StatusModal(props) {
    const {show, modalClosed, id, statusVal, editStatus, statusList} = props;
    // const statusList = useMemo(() => ["NEW", "TO_BE_PAID", "PAID", "LITIGATION"], [])
    const [statusListNew, setStatusListNew] = useState([]);
    const [statusValue, setStatusVal] = useState(statusVal)
    const [reason, setReason] = useState(null);
    const [comment, setComment] = useState(null);
    const [error, setError] = useState(null);
    const [obligatoryComment, setObligatoryComment] = useState();

    useEffect(() =>{
        // let existLitige = statusListNew.find(item => item.value === "LITIGATION");
        // if(!existLitige){
        //     setStatusListNew([...statusListNew, {label: "Litige", value: "LITIGATION"}])
        // }
        let statusListTmp = [];
        switch (statusVal) {
            case 'ERROR':
                statusListTmp = [
                  
                ];
                break;
          
            case 'INTEGRATED':
                statusListTmp = [
                
                    {label: "Archiver la relance ", value : "PAID"},
                
                ];
          
                break;
            default:
                setStatusListNew(statusListTmp)
                break;
        }
        setStatusListNew(statusListTmp)


    }, [])

    // const inputChangeHandler = (e) => {
    //     let value = e.target.value;
    //     console.log("ososo")
    //     setStatusVal(value)
        
    // }

    const inputChangeHandler = (e) => {
        // let value = e.target.value;
        // console.log("ososo")
        setStatusVal(e.target.value)
        setObligatoryComment(e.target.value)
        
    }

    // const confirmClickHandler = () => {
    //     setError(null)
    //     if(statusVal !== statusValue){
    //         if(statusValue === "LITIGATION" && (!reason || !comment)){
    //             setError("Le motif et le commentaire sont obligatoire pour mettre cette facture en litige")
    //         }else {
    //             editStatus(statusValue, comment, reason)
    //         }
    //     }else{
    //         setError("Veuillez choisir un statut différent du statut actuel")
    //     }
    // }

    return (
        <div>
            <Modal show={show} onHide={modalClosed} >
                <Modal.Header closeButton>
                <Modal.Title className="ICDBlue">
                    Action sur l'échange
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body">
                    {
                        error && (
                            <div className="alert alert-danger" role="alert">
                                <p>
                                    {error}
                                </p>
                            </div>)
                    }
                    <div className="statusModal__status_conatiner">
                        <label for="status" className="statusModal__status_label">Action</label>
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={statusValue || ''} name="status" id="status">
                            {/* {
                                )
                                // statusListNew.map(s => 
                            } */}
                            <option value={0}>{"Sélectionnez votre choix"}</option>
                            <option value={1}>Rejet définitif de l'échange</option>
                            <option value={2}>Nouvelle tentative d'intégration</option>
                            
                        </select>
                    </div>

                    <div className="statusModal__reason_container">
                        {
                            statusValue === "LITIGATION" ? (
                                <div className="statusModal__motif_container">
                                    <label className="statusModal__status_label" for="motif">Motif</label>
                                    <select 
                                        className="form-control " 
                                        id="motif"
                                        value={reason || ''}
                                        onChange={(e) => setReason(e.target.value)}
                                    >
                                        <option value="">
                                            Sélectionnez un motif
                                        </option>
                                        <option value="Numéro de commande incomplet">
                                            Numéro de commande incomplet
                                        </option>
                                        <option value="Ecart quantité">
                                            Ecart quantité
                                        </option>
                                        <option value="Article non référencé">
                                            Article non référencé
                                        </option>
                                        <option value="Montant erroné">
                                            Montant erroné
                                        </option>
                                    </select>
                                </div>
                            ) : null
                        }
                        <div >
                            <label className="statusModal__status_label" for="comment" >{"Commentaire" + (obligatoryComment == 2 ? " (obligatoire)" : " (facultatif)")}</label>
                            <textarea 
                                className="form-control" 
                                id="comment" 
                                value={comment || ''}
                                onChange={(e) => setComment(e.target.value)}
                                rows="3"></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <PrimaryBtnOutlineAction 
                    variant="primary" 
                    onClick={modalClosed}
                    disabled={statusValue === "LITIGATION" && !comment}
                >
                    Confirmer
                </PrimaryBtnOutlineAction>
                <CancelBtnOutlineAction variant="secondary" onClick={modalClosed}>
                    Annuler
                </CancelBtnOutlineAction>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default StatusModal;
