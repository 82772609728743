import React from "react";
import { Modal } from "react-bootstrap";
import {
  CancelBtnOutlineAction,
  PrimaryBtnOutlineAction,
} from "../../../../styles/Common";
import {
  BodyModal,
  CheckContentModal,
  DateInput,
  CheckInput,
  DPContainer,
} from "../../../../containers/ConsultationLogs/ConsultationLogs.styled";
import './ModalDownload.css'
import frLocale from "date-fns/locale/fr";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faDolly, faPersonRays, faUserGear,faCircleInfo,faCirclePlus,faCalendarDays } from '@fortawesome/free-solid-svg-icons'  
function ModalDownload(props) {
  const { showDownloadLogsModal, setShowDownloadLogsModal, handleClose } =
    props;
    const [selectedStartDate, setSelectedStartDate] = React.useState(null);
    const handleStartDateChange = (date) => {
      setSelectedStartDate(date.getTime());
 
    };
    const renderIcon = () => {
      return (<FontAwesomeIcon icon={faCalendarDays}/>);
    }
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showDownloadLogsModal}
      onHide={handleClose}
      dialogClassName="modal_w_mc"
      contentClassName="modal_w_mc"
    >
      <Modal.Header closeButton>
        <Modal.Title className="repertoireTitle ICDBlue">
          Téléchargements des logs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="repertoireModal__body" style={{ width: "640px" }}>
        <div className="repertoireModal__body__content">
          <h3 style={{ fontSize: "18px", color: "#505050" }}>
            Télécharger tous les logs depuis:{" "}
          </h3>
          <BodyModal style={{ alignItems: "left" }}>
            <div style={{ width: "100%" }}>
              {
                // <DateInput
                //   type="date"
                //   name="begin" 
                //   placeholder="dd-mm-yyyy" 
                //   style={{ textAlign: "end", marginLeft: "-7px" }}
                // />
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={frLocale}
                  >
                      <DPContainer>
                      <DatePicker
                       components={{
                        OpenPickerIcon: renderIcon
                      }}
                        className="extractionForm__datepicker"
                        label=""
                        format="JJ/MM/AAAA"
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder:"JJ/MM/AAAA"
                              }}
                            />
                          );
                        }}
                      // open={true} control open dialog state
                    />
                    </DPContainer>
              </LocalizationProvider>
              }
              {/*<DatePicker />*/}
            </div>
            <CheckContentModal>
              <CheckInput type="checkbox" className="form-check-input" />
              <span style={{ fontSize: "16px", color: "#505050" }}>
                Cocher si vous ne souhaitez que les logs de cette journée.
              </span>
            </CheckContentModal>
          </BodyModal>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryBtnOutlineAction variant="primary" onClick={handleClose}>
          Confirmer
        </PrimaryBtnOutlineAction>
        <CancelBtnOutlineAction variant="secondary" onClick={handleClose}>
          Annuler
        </CancelBtnOutlineAction>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDownload;
