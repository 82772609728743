// import React, { useEffect, useState } from 'react';
// import Axios from '../../axios-proas'
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import DescriptionIcon from '@mui/icons-material/Description';
// import BusinessIcon from '@mui/icons-material/Business';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import Select from '../../components/UI/Select/Select';
// import Card from '../../components/Home/Card/Card';
// import { useTheme } from 'styled-components';
// import { formatNumber, getNotyfObject } from '../../shared/utility';
// import { Link } from 'react-router-dom';
// import { SupplierLink } from '../ReferentielFournisseur/ReferentielFournisseur.Styled';
// import {formatDateEur} from '../../shared/utility'
// import { HomeSBody, HomeSCards, HomeSContainer, HomeSHeader, HomeSStats } from './HomeSupplier.styled';
// import { HeaderAction, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
// import InvoicesPerStatus from '../../components/HomeSupplier/InvoicesPerStatus/InvoicesPerStatus';
// import InvoicesPerCanal from '../../components/HomeSupplier/InvoicesPerCanal/InvoicesPerCanal';
// import { connect } from 'react-redux';
// import Events from '../../components/Home/Events/Events';

// function HomeSupplier({userType}) {
//     const theme = useTheme();
//     const notyf = getNotyfObject()
//     const [period, setPeriod] = useState('');
//     const [sentInvoicesCount, setSentInvoicesCount] = useState(0);
//     const [uploadedDocsCount, setUploadedDocsCount] = useState(0);
//     const [invoicedEntitiesCount, setInvoicedEntitiesCount] = useState(0);
//     const [totalSales, setTotalSales] = useState(0);

//     const options = [
//         {label: 'Tout', value: ''},
//         {label: 'Cette année', value: 'y'},
//         {label: 'Ce mois', value: 'm'}
//     ];

//     const selectChange = (val) => {
//         setPeriod(val);
//     }

//     const getSentInvoicesCount = () => {
//         Axios.get("/invoice//supplierSentInvoices", {
//             params: {
//                 period : period
//             },
//         }).then((response) => {
//             let count = response?.data?.totalDocs ? response.data.totalDocs : 0;
//             setSentInvoicesCount(count);
//         }).catch(res => {
//           notyf.error("Une erreur s'est produite!");
//         })
//     };

//     const getUploadedInvoicesCount = () => {
//         Axios.get("/invoiceupload//uploadedInvoicesCount", {
//             params: {
//                 period : period
//             },
//         }).then((response) => {
//             let count = response?.data?.totalDocs ? response.data.totalDocs : 0;
//             setUploadedDocsCount(count);
//         }).catch(res => {
//           notyf.error("Une erreur s'est produite!");
//         })
//     };

//     const getInvoicedEntitiesCount = () => {
//         Axios.get("/invoice//supplierEntitiesCount", {
//             params: {
//                 period : period
//             },
//         }).then((response) => {
//             let count = response?.data?.totalEntities ? response.data.totalEntities : 0;
//             setInvoicedEntitiesCount(count);
//         }).catch(res => {
//           notyf.error("Une erreur s'est produite!");
//         })
//     };

//     const getTotalSales = () => {
//         Axios.get("/invoice//supplierTotalSales", {
//             params: {
//                 period : period
//             },
//         }).then((response) => {
//             let count = response?.data?.totalSales ? response.data.totalSales : 0;
//             setTotalSales(count);
//         }).catch(res => {
//           notyf.error("Une erreur s'est produite!");
//         })
//     };

//     useEffect(() => {
//         getSentInvoicesCount();
//         getUploadedInvoicesCount();
//         getInvoicedEntitiesCount();
//         getTotalSales();
//     }, [period]);

//   return (
//       <>
//         <HomeSContainer>
//             <HomeSHeader>
//                 <HeaderInfos>
//                     <HeaderLocation>
//                         {userType === 'owner' ? 'KPI' : 'Accueil'} 
//                     </HeaderLocation>
//                     <HeaderTitle>
//                         {userType === 'owner' ? '' : 'Portail'} 
//                     </HeaderTitle>
//                 </HeaderInfos>
//                 <HeaderActions>
//                     <HeaderAction>
//                         <Select 
//                             options={options} 
//                             value={options.find(option => option.value === period)?.label} 
//                             onChange={selectChange} 
//                         />
//                     </HeaderAction>
//                 </HeaderActions>
//             </HomeSHeader>
//             <HomeSBody>
//                 <HomeSCards>
//                     <Card 
//                         icon={<DescriptionIcon style={{color: "#2174B9"}} />} 
//                         title={`Factures ${userType === 'owner' ? 'reçues' : 'émises'}`}
//                         value={sentInvoicesCount}
//                     />
//                     <Card 
//                         icon={<DescriptionIcon style={{color: "#2174B9"}} />} 
//                         title="Factures en cours de traitement" 
//                         value={uploadedDocsCount}
//                     />
//                     <Card 
//                         icon={<BusinessIcon style={{color: "#2174B9"}} />} 
//                         title="Entités facturées" 
//                         value={invoicedEntitiesCount}
//                     />
//                     <Card 
//                         icon={<DateRangeIcon style={{color: "#2174B9"}} />} 
//                         title={`Total des ${userType === 'owner' ? 'achats' : 'ventes'}`}
//                         value={formatNumber(+totalSales)}
//                     />
//                 </HomeSCards>
//                 <HomeSStats>
//                     <InvoicesPerStatus period={period} userType={userType} />
//                     <InvoicesPerCanal period={period} userType={userType} />
//                     {
//                         userType === 'supplier' && <Events />
//                     }
//                 </HomeSStats>
//             </HomeSBody>
//         </HomeSContainer>
//       </>
//   );
// }

// const mapStateToProps = (state) => ({
//     userType : state.auth.userType,
//     login : state.auth.login
// })

// const mapDispatchToProps = dispatch => ({
// })
// export default connect(mapStateToProps, mapDispatchToProps) (HomeSupplier)
  




// import React, { useEffect, useState } from 'react';
// import Axios from '../../axios-proas'
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import CreditCardIcon from '@mui/icons-material/CreditCard';
// import ReportProblemIcon from '@mui/icons-material/ReportProblem';
// import {HomeContainer, HomeHeader, HeaderInfos, HeaderLocation, HeaderTitle, HeaderActions, HeaderAction, SearchInputContainer, SearchInput, ActionWrapper, ActionLabel, HomeBody, HomeCards, HomeStats, HomeInvoices} from './Home.styled'
// import Select from '../../components/UI/Select/Select';
// import Card from '../../components/Home/Card/Card';
// import LineChart from '../../components/Home/LineChart/LineChart';
// import Events from '../../components/Home/Events/Events';
// import LastInvoices from './LastInvoices/LastInvoices';
// import { useTheme } from 'styled-components';
// import { getNotyfObject } from '../../shared/utility';
// import { Link } from 'react-router-dom';
// import { SupplierLink, SupplierUsersLink } from '../ReferentielFournisseur/ReferentielFournisseur.Styled';
// import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
// import {formatDateEur} from '../../shared/utility'
// import { BtnAction } from '../../styles/Common';
// import LastInvoicesClient from './LastInvoicesClient/LastInvoicesClient';

// function Home() {
//     const theme = useTheme();
//     const notyf = getNotyfObject()
//     const [value, setValue] = useState('annee');
//     const [activeSuppliers, setActiveSuppliers] = useState(0);
//     const [activeClients, setActiveClients] = useState(0);
//     const [inProgressSuppliers, setInProgressSuppliers] = useState(0);
//     const [notPaidInvoices , setNotPaidInvoices] = useState(0);
//     const [litigationInvoices, setLitigationInvoices] = useState(0);
//     const defaultTime = new Date();
//     defaultTime.setFullYear(defaultTime.getFullYear()-1);
//     const [startDate , setStartDate] = useState(formatDateEur(defaultTime.getTime()));
//     const [endDate , setEndDate] = useState(formatDateEur(new Date().getTime()));
//     const [eventData,setEventData]=useState();

//     const options = [
//         {label: 'Cette année', value: 'annee'},
//         {label: 'Ce mois', value: 'mois'}
//     ];

//     const eventsList = [
//         {color: theme.colors.success , value: 'Fournisseur 1', date: '5 minuites'},
//         {color: theme.colors.warning, value: 'Fournisseur 2', date: '5 minuites'},
//         {color: theme.colors.success, value: 'Fournisseur 3', date: '5 minuites'},
//         {color: theme.colors.warning, value: 'Fournisseur 4', date: '5 minuites'},
//         {color: theme.colors.success, value: 'Campagne 1', date: '5 minuites'},
//         {color: theme.colors.danger, value: 'Transdev', date: '5 minuites'},
//         {color: theme.colors.success, value: 'Transdev', date: '5 minuites'},
//     ]

// const interval=(date)=>{
   
//    return formatDateEur(date)
// }

//     const selectChange = (value) => {
//         const Landing = new Date();
//         const dateNow=Landing.getTime();
     
//         setValue(value);
//         switch (value) {
//             case "annee":
//                 Landing.setFullYear(Landing.getFullYear()-1);
//                 setStartDate(interval(Landing.getTime()))
//                 setEndDate(interval(dateNow))
//                 break;
//             case "mois":
//                 Landing.setDate(Landing.getDate()-30);
//                 setStartDate(interval(Landing.getTime()))
//                 setEndDate(interval(dateNow))
//                 break;
//             case "jour":
//                 Landing.setDate(Landing.getDate()-1);
//                 setStartDate(interval(Landing.getTime()))
//                 setEndDate(interval(dateNow))
//                 break;
//             default:
//                 break;
//         }
            
//     }

//     const getSuppliersCount = (status) => {
//         Axios.get("/entity//suppliersCount", {
//             params: {
//                 status: status,
//                 period:value,
//                 endDate:endDate ,
//                 startDate:startDate
//             }
//         }).then(res => {
//             if(res?.data?.suppliersCount){
//                 switch (status) {
//                     case 'active':
//                         setActiveSuppliers(res.data.suppliersCount) 
//                         break;
//                     case 'inProgress':
//                         setInProgressSuppliers(res.data.suppliersCount)
//                         break;
//                     default:
//                         break;
//                 }
//             }
//         }).catch(res => {
//             notyf.error("Une erreur s'est produite!");
//         })
//     }

//     const getClientsCount = (status) => {
//         Axios.get("/entity//clientsCount", {
//             params: {
//                 status: status,
//                 period:value,
//                 endDate:endDate ,
//                 startDate:startDate
//             }
//         }).then(res => {
//             if(res?.data?.clientsCount){
//                 switch (status) {
//                     case 'active':
//                         setActiveClients(res.data.clientsCount) 
//                         break;
//                     default:
//                         break;
//                 }
//             }
//         }).catch(res => {
//             notyf.error("Une erreur s'est produite!");
//         })
//     }

//     const getNotPaidInvoices = () => {
//         Axios.get("/invoice//notPaidInvoices",{
//             params: {
//                 period:value,
//                 endDate:endDate ,
//                 startDate:startDate
//                }
//         }).then(res => {
//             if(res?.data?.count){
//                 setNotPaidInvoices(res.data.count)
//             }
//         }).catch(res => {
//             notyf.error("Une erreur s'est produite!");
//         })
//     }

//     const getLitigationInvoices = () => {
//         Axios.get("/invoice//litigationInvoices",{
//             params: {
//                  period:value,
//                  endDate:endDate ,
//                 startDate:startDate
//                }
//         }).then(res => {
//             if(res?.data?.count){
//                 setLitigationInvoices(res.data.count)
//             }
//         }).catch(res => {
//             notyf.error("Une erreur s'est produite!");
//         })
//     }
//     const getEventEntity = () => {
//         Axios.get("/entityevent/").then(res => {
            
//                 setEventData(res.data.events)
            
//         }).catch(res => {
//             notyf.error("Une erreur s'est produite!");
//         })
//     }

//     const insertFakeInvoice = () => {
//         Axios.post("/invoice//fakeInvoice").then(res => {
//             if(res?.data?.success)
//             notyf.success("Facture créée!");
            
//         }).catch(res => {
//             notyf.error("Une erreur s'est produite!");
//         })
//     }

//     const lunchOcr = () => {
//         Axios.post("/invoice//lunchOcr").then(res => {
//             if(res?.data?.success)
//             notyf.success("succés!");
            
//         }).catch(res => {
//             notyf.error("Une erreur s'est produite!");
//         })
//     }

//     useEffect(() => {
//         getEventEntity();
//         getSuppliersCount("active");
//         getClientsCount("active");
//         // getSuppliersCount("inProgress");
//         getNotPaidInvoices()
//         getLitigationInvoices()
//     }, [value]);

//   return (
//       <>
//         <HomeContainer>
//             <HomeHeader>
//                 <HeaderInfos>
//                     <HeaderLocation>Accueil</HeaderLocation>
//                     <HeaderTitle>Portail</HeaderTitle>
//                 </HeaderInfos>
//                 <HeaderActions>
//                     <HeaderAction>
//                         <Select 
//                             options={options} 
//                             value={options.find(option => option.value === value)?.label} 
//                             onChange={selectChange} 
//                         />
//                     </HeaderAction>
//                     {/* <HeaderAction>
//                         <BtnAction
//                             onClick={lunchOcr}
//                             color={theme.colors.success}
//                         >
//                             Lancer l'OCR
//                         </BtnAction>
//                     </HeaderAction> */}
//                 </HeaderActions>
//             </HomeHeader>
//             <HomeBody>
//                 <HomeCards>
//                     <Card 
//                         icon={<ShoppingCartIcon style={{color: "#2174B9"}} />} 
//                         title="Nombre de fournisseurs actifs" 
//                         value={<SupplierLink style={{color:"#505050"}}
//                             to="/referentielFournisseur"
//                             state={{
//                                 filtersProps : {
//                                     status: 'ACTIVE',
//                                     type: 'supplier'
//                                 }
//                             }}
//                         >{activeSuppliers}</SupplierLink>}
//                     />
//                     <Card 
//                         icon={<ShoppingCartIcon style={{color: "#2174B9"}} />}  
//                         title="Nombre de clients actifs " 
//                         value={<SupplierLink style={{color:"#505050"}}
//                             to="/referentielClient"
//                             state={{
//                                 filtersProps : {
//                                     status: 'ACTIVE',
//                                     type: 'client'
//                                 }
//                             }}
//                         >{activeClients}</SupplierLink>}
//                     />
//                     <Card 
//                         icon={<ReportProblemIcon style={{color: "#F9B959"}} />} 
//                         title="Factures non payées" 
//                         value={notPaidInvoices}
//                     />
//                     <Card 
//                         icon={<ReportProblemIcon style={{color: "#F9B959"}} />} 
//                         title="Anomalies" 
//                         value={litigationInvoices}
//                     />
//                 </HomeCards>
//                 <HomeStats>
//                     <Events events={eventData} />
//                     <LineChart period={value} />
//                 </HomeStats>

//                 <HomeInvoices>
//                     <LastInvoices invoiceCheckBox ={true} entityFilter={"supplier"}/>
//                 </HomeInvoices>
//                 <HomeInvoices style={{marginTop:"10px"}}>
//                     <LastInvoicesClient  invoiceCheckBox ={true} entityFilter={"client"}/>
//                 </HomeInvoices>
                
//             </HomeBody> 
//         </HomeContainer>
//       </>
//   );
// }

// export default Home;







import React, { useEffect, useState } from 'react';
import Axios from '../../axios-proas'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { HomeContainer, HomeHeader, HeaderInfos, HeaderLocation,FirstDiv, RowDivContainer, SecendDiv, HeaderTitle, HeaderActions, HeaderAction, SearchInputContainer, SearchInput, ActionWrapper, ActionLabel, HomeBody, HomeCards, HomeStats, ISNavContainer, HomeInvoices, NavTabsLi, NavTabsLink, NavTabsUl } from './HomeSupplier.styled'
import Select from '../../components/UI/Select/Select';
import Card from '../../components/PDPDashboard/Card/Card';
import LineChart from '../../components/PDPDashboard/LineChart/LineChart';
import Events from './Events/Events';
import Tasks from './Events/Tasks';


import { useTheme } from 'styled-components';
import { getNotyfObject } from '../../shared/utility';
import { Link } from 'react-router-dom';
import { SupplierLink, SupplierUsersLink } from '../ReferentielFournisseur/ReferentielFournisseur.Styled';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { formatDateEur } from '../../shared/utility'
import { BtnAction } from '../../styles/Common';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Home.css'
import StatisticsModal from './StatisticsModal/StatisticsModal'
import SortableList, { SortableItem } from "react-easy-sort";
import {arrayMoveImmutable} from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faDolly, faPersonRays, faUserGear } from '@fortawesome/free-solid-svg-icons'
import BarChart from './BarChart/BarChart';

import LastInvoicesClient from '../Home/LastInvoicesClient/LastInvoicesClient';
import LastInvoices from '../Home/LastInvoices/LastInvoices';
function Home() {
    const theme = useTheme();
    const notyf = getNotyfObject()
    const [value, setValue] = useState('lastSevenDays');
 
    const [paidInvoices, setPaidInvoices] = useState(0);
    const [litigationInvoices, setLitigationInvoices] = useState(0);
    const [allInvoices, setAllInvoices] = useState(0);
    const [totalLitig, setTotalLitig] = useState(0);
    

    const [showStatisticsModal, setShowStatisticsModal] = useState(false);
    const defaultTime = new Date();
    defaultTime.setFullYear(defaultTime.getFullYear() - 1);
    const [startDate, setStartDate] = useState(formatDateEur(defaultTime.getTime()));
    const [endDate, setEndDate] = useState(formatDateEur(new Date().getTime()));
    const [eventData, setEventData] = useState();
    const [invoicesInValidation,setInvoicesInValidation] = useState(0);
    const [invoicesInToBePaid,setInvoicesInToBePaid] = useState(0);
    const [arrayCards,setArrayCards]=useState(["Facture_payé", "Facture_en_litige", "Taux_litige_moyen","Somme_en_litige"])
    const options = [
        { label: 'Ces 7 derniers jours', value: 'lastSevenDays' },
        { label: 'Ce mois-ci', value: 'thisMonth' },
        { label: 'Mois précédent', value: 'lastMonth' },
    ];
    const [showLastSupplpier, setShowLastSupplpier] = useState(false);  

    const interval = (date) => {

        return formatDateEur(date)
    }

    const selectChange = (value) => {
        const Landing = new Date();
        const dateNow = Landing.getTime();

        setValue(value);
        switch (value) {
            case "lastMonth":
                Landing.setFullYear(Landing.getFullYear() - 2);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            case "thisMonth":
                Landing.setDate(Landing.getDate() - 30);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            case "lastSevenDays":
                Landing.setDate(Landing.getDate() - 7);
                setStartDate(interval(Landing.getTime()))
                setEndDate(interval(dateNow))
                break;
            default:
                break;
        }

    }


    const allInvoice = () => {
        Axios.get("/invoice//allInvoice", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            if (res?.data?.total) {
                setAllInvoices(res.data.total)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }

    const litigInvoice = () => {
        Axios.get("/invoice//litigInvoice", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            if (res?.data?.total) {
                setLitigationInvoices(res.data.total)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }
  
    const payedInvoice = () => {
        Axios.get("/invoice//payedInvoice", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            if (res?.data?.total) {
                setPaidInvoices(res.data.total)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }
    const getTotalLitige = () => {
        Axios.get("/invoice//totalLitige", {
            params: {
                period: value,
                endDate: endDate,
                startDate: startDate
            }
        }).then(res => {
            setTotalLitig(res?.data?.total)
            if (res?.data?.total) {
                setTotalLitig(res?.data?.total)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })
    }

    useEffect(() => {
        getTotalLitige();
        payedInvoice();
        litigInvoice()
        allInvoice()
       
    }, [value]);

    const onChangeHandler = () => {

    }
   
    const onSortEnd = (oldIndex, newIndex) => {
        setArrayCards((array) => arrayMoveImmutable(array, oldIndex, newIndex));
        
      };
    const getTauxLitige = (allInvoices,litigationInvoices) => {
    if (allInvoices == 0) {
        return 0;
    } else {
        return (litigationInvoices / allInvoices) * 100;
    }

    }
  console.log(totalLitig)
      const getCardByItem = (item) => {
        switch (item) {
                case "Facture_payé":
                    return (  <Card
                                icon={<FontAwesomeIcon icon={faDolly} style={{ color: "#809FB8" }}/>}
                                title="Factures payées"
                                value= {paidInvoices}
                            />)
                case "Facture_en_litige":
                    return  (<Card
                                icon={<FontAwesomeIcon icon={faDolly} style={{ color: "#809FB8" }}/>}
                                title="Facture en litige"
                                value={litigationInvoices}
                            />)
                case "Taux_litige_moyen":
                    return  (<Card
                                icon={<FontAwesomeIcon icon={faDolly} style={{ color: "#809FB8" }}/>}
                                title="Taux litige moyen"
                                value={Math.round(getTauxLitige(allInvoices,litigationInvoices))+ "%"}
                            />)  
                case "Somme_en_litige":
                    return (<Card
                                icon={<FontAwesomeIcon icon={faPersonRays} style={{ color: "#809FB8" }}/>}
                                title="Somme en litige"
                                value={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Math.round(totalLitig))}
                            />)
                default:
      }}
    return (
        <>
            <HomeContainer>
                <HomeHeader>
                    <HeaderInfos>
                        <HeaderLocation>Accueil</HeaderLocation>
                        <HeaderTitle>Portail </HeaderTitle>
                    </HeaderInfos>
                    <HeaderActions>
                        <HeaderAction>
                            <Select
                                width="210px"
                                options={options}
                                value={options.find(option => option.value === value)?.label}
                                onChange={selectChange}
                            />
                        </HeaderAction>
                        {/* <HeaderAction>
                        <BtnAction
                            onClick={lunchOcr}
                            color={theme.colors.success}
                        >
                            Lancer l'OCR
                        </BtnAction>
                    </HeaderAction> */}
                    </HeaderActions>
                </HomeHeader>
                <HomeBody>
                    <RowDivContainer> 
                        <FirstDiv>
                            {/* <HomeStats> */}
                            <Tasks title={"Mes tâches"} events={eventData} others={false} />
                            <Events title={"Evènements"} events={eventData} others={true}/>
                            {/* </HomeStats> */}
                        </FirstDiv>
                        <SecendDiv>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <p style={{ textAlign: 'center', color: '#2174B9', width: "inherit", fontSize: "1rem" }}>Mes statistiques</p>
                                    {/* <PersonAddIcon className="iconStyle" onClick={(e) => setShowStatisticsModal(!showStatisticsModal)}/> */}
                                    <FontAwesomeIcon icon={faUserGear} className="iconStyle" onClick={(e) => setShowStatisticsModal(!showStatisticsModal)}/>
                            </div>
                            <HomeCards> 
                                <SortableList
                                    onSortEnd={onSortEnd}
                                    className="list"
                                    draggedItemClassName="dragged"
                                    >
                                            {/* arrayCards=["Facture_a_payer", "Facture_en_litige", "Facture_en_validation","Factures_à_régler"] */}
                                            {arrayCards.map((item) => (
                                                    <SortableItem key={item} style={{display:"flex",width:"100%"}}>
                                                        <div style={{display:"flex"}}>
                                                            
                                                            {getCardByItem(item)}
                                                        </div>
                                                    </SortableItem>)
                                               
                                              
                                            // 
                                            )}
                                    </SortableList>
                               
                            </HomeCards>
                            <div style={{ display: 'flex' }}>
                                <select
                                    className="selectForm selectStyle"
                                    name="family"
                                    onChange={e => onChangeHandler(e)}
                                // value={}
                                >
                                    {/* <option value="" key={0}>--- Selectionner un type ---</option> */}
                                    <option value="" key={1}>Statistique : Taux de litige par entité</option>
                                    <option value="" key={2}>Analyse des canaux de réception</option>
                                    <option value="" key={3}>Analyse des délais de réception facture</option>
                                    {/* <option value="" key={4}>Analyse des risques aux paiements</option>
                                    <option value="" key={5}>Analyse du taux d'extraction</option>
                                    <option value="" key={6}>Analyse des typologies</option> */}
                                </select>
                                <OpenInFullIcon className="iconStyle" />
                            </div>
                               <BarChart/>
                            {/* <div style={{ padding: "23px 5px" }}>
                                <div className="typeLine"  >
                                    <p className="space">20/11/2022</p>
                                    <p style={{ color: "#ffffff", width: "113px" }} className="label_add">2 545 €</p>
                                    <p style={{ color: "rgb(200, 176, 230)" }} className="space specElement" >Novembre : 20 810 €</p>
                                </div>
                                <div className="typeLine">
                                    <p className="space">30/11/2022</p>
                                    <p style={{ color: "#ffffff", width: "700px" }} className="label_add">18 265 €</p>
                                    <p className="space"></p>
                                </div>
                                <div className="typeLine">
                                    <p className="space">08/12/2022</p>
                                    <p style={{ backgroundColor: theme.colors.gray, color: "#505050", width: "230px" }} className="label_add ">5 435 €</p>
                                    <p className="space specElement">Décembre : 20 810 €</p>
                                </div>
                                <div className="typeLine">
                                    <p className="space">15/12/2022</p>
                                    <p style={{ backgroundColor: theme.colors.gray, color: "#505050", width:"330px" }} className="label_add">7 800 €</p>
                                    <p className="space"></p>
                                </div>
                                <div className="typeLine">
                                    <p className="space">31/12/2022</p>
                                    <p style={{ backgroundColor: theme.colors.gray, color: "#505050", width:"900px" }} className="label_add">22 376 €</p>
                                    <p className="space"></p>
                                </div>
                            </div> */}

                        </SecendDiv>
                    </RowDivContainer>

                    <HomeInvoices active={showLastSupplpier} style={{marginTop:"33px"}}>
                        <div style={{color:"#2174B9",textAlign: "center"}}>
                            <span onClick={e => setShowLastSupplpier(!showLastSupplpier)} style={{cursor:"pointer"}}>
                                Dernières factures <ExpandMoreIcon />
                            </span>
                            {
                                showLastSupplpier &&
                                <>
                                     <LastInvoices invoiceCheckBox ={true} entityFilter={"supplier"} keyPath={"invoices"} NoshowTitel={true}/>
                                     {/* <LastInvoicesClient  invoiceCheckBox ={true} entityFilter={"client"}/> */}
                                
                                </>
                            }
                        </div>
                    </HomeInvoices>

                </HomeBody>
                {
                    showStatisticsModal &&
                        <StatisticsModal 
                            show={showStatisticsModal}
                            handleClose={() => setShowStatisticsModal(false)}
                            // addRecipientsHandler={addRecipientsHandler}
                        />
                }

            </HomeContainer>
        </>
    );
}

export default Home;
        