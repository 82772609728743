import React, { useCallback, useEffect,useState } from 'react';
import TableActions from '../../components/EchangePDP/TableActions/TableActions';
import TableHead from '../../components/EchangePDP/TableHead/TableHead';
import TableBody from '../../components/EchangePDP/TableBody/TableBody';
import TableFilters from '../../components/EchangePDP/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { NoContent, SpinnerContainer, Table, TBody,SearchInput, SearchInputContainer } from '../../styles/Table.styled';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import { EMBody, EMContainer, EMTableContainer } from './EchangePDP.styled';
import { Spinner } from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';

import Axios from '../../axios-proas';



function EchangePDP(props) {
    const {
        loading, 
        entitiesData, 
        count, 
        currentPage, 
        pageSize,
        getEchangePage, 
        setEntitiesPage, 
        setEntitiesPageSize, 
        sortQuery, 
        reverse, 
        filtersQuery, 
        showFilters, 
        userRole, 
        updateShowFilters, 
        entityOwnerDetail, 
        setEntitydetail ,
        setEntitiesFilters, 
        setSortQuery, 
        setReverse
        
    } = props;
    const [echangePDP,setEchangePDP]=useState("yhuji");
    const {t} = useTranslation();
    const[density,setDensity]=useState("2.25rem")
    const[columns,setColumns]=useState( [
      
        {title: t("Type"), field: 'type', show:true, alignSelf:"left"},
        {title: t("Nature"), field: 'nature', show:true, alignSelf:"left"},
        {title: t("Emetteur"), field: 'emetteur', show:true, alignSelf:"left"},
        {title: t("Destinataire"), field: 'destinataire', show:true, alignSelf:"left"},
        {title: t("Identifiant document"), field: 'number', show:true, alignSelf:"center"},
        {title: t("Date d'initialisation"), field: 'creationDate', show:true, alignSelf:"left"},
        {title: t("Date de dernière modification"), field: 'modificationDate', show:true, alignSelf:"left"},
        {title: t("Erreur"), field: 'erreur', show:true, alignSelf:"left"},
        {title: t("Statut de l'échange"), field: 'statut', show:true, alignSelf:"left"},
        
    ])
    

    useEffect(() => {

        getEchangePage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    
    }, [currentPage, pageSize])

    const isEmptyFilters = () => {
        if(filtersQuery === null )
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if(filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }
    
    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if(!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);



    useEffect(() => {

        Axios.get('/echangepdp/').then((response) => {
           
            setEchangePDP(response.data.result);
               console.log(response.data.result) 
               
       
        
        }).catch(err => {
            console.log(err);
        })

    },[])
    
    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getEchangePage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setEntitiesPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getEchangePage(1,filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const resetFilters = () => {
        setEntitiesFilters(null);
        setEntitiesPage(1)
        getEchangePage(1, null, sortQuery, reverse, pageSize)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
        ) : (
            <NoContent>
                <span>{t("clientList:noClients")}</span>
            </NoContent>
        );

    if(!loading && entitiesData?.length > 0){
        tSpinner = null;
        tBody = ( <TableBody 
                        columns={columns}
                        clientsData={entitiesData} 
                        userRole={userRole} 
                        setEntitydetail={setEntitydetail} 
                        entityOwnerDetail={entityOwnerDetail} 
                    />)
    }

    return (
        <EMContainer>
            <TitleHelmet title={"Démo Portail | " + t("menu:entities")} />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{t("menu:Echange_ppf", "Monitoring")}</HeaderLocation>
                    <HeaderTitle>

                        Suivi des échanges PDP & PPF
                    </HeaderTitle>
                </HeaderInfos>
                <HeaderActions>

                <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''} 
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                        <SearchIcon className="ICDBlue"/>
                    </SearchInputContainer>
                    
                <TableActions 
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                    />
                    
                </HeaderActions>
            </Header>

            <EMBody>
                <EMTableContainer>
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage} 
                        pageChange={handlePageChange} 
                        totalElement={count} 
                        perPage={pageSize}
                        perPageChange={setEntitiesPageSize}
                    />
                    <Table>
                        <TableHead 
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                        />
                        <TBody height={density}>
                            {/* <TableFilters
                                columns={columns} 
                                showFilters={showFilters}
                                entitiesFilters={filtersQuery}
                                filtersChange={setEntitiesFilters}
                                applyFilters={handleApplyFiltering}
                            /> */}
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage} 
                        pageChange={handlePageChange} 
                        totalElement={count} 
                        perPage={pageSize}
                        perPageChange={setEntitiesPageSize}
                    />
                </EMTableContainer>
            </EMBody>
        </EMContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.echangePDP.loading,
    entitiesData: state.echangePDP.data,
    count: state.echangePDP.count,
    currentPage: state.echangePDP.currentPage,
    pageSize: state.echangePDP.pageSize,
    sortQuery: state.echangePDP.sortQuery,
    reverse: state.echangePDP.reverse,
    filtersQuery: state.echangePDP.filtersQuery,
    showFilters: state.echangePDP.showFilters, 
    userRole: state.auth.role,
    entityOwnerDetail: state.echangePDP.entity
 
})
const mapDispatchToProps = dispatch => ({
    getEchangePage: (page, filters, sort, reverse, pageSize) => dispatch(actions.echangePDPGetPage(page, filters, sort, reverse, pageSize)),
    setEntitiesPage: (page) => dispatch(actions.echangePDPSetPage(page)),
    setEntitiesPageSize: (pageSize) => dispatch(actions.echangePDPSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.echangePDPSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.echangePDPSetReverseSort(reverseS)),
    setEntitiesFilters: (filters) => dispatch(actions.echangePDPSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.echangePDPUpdateShowFilters(show)),
    setEntitydetail: (entity) => dispatch(actions.echangePDPdetail(entity)),
})
export default connect(mapStateToProps, mapDispatchToProps) (EchangePDP)

