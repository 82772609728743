import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import {formatDate, getStatusWithKey, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';
import { useTheme } from 'styled-components';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EastIcon from '@mui/icons-material/East';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGear, faDatabase, faDolly, faPersonRays, faBell, faHourglassHalf,faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
function StatusEvent({isCreator, eventData, isLast, userType, entityFilter,isRelance}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    const getStatusColor = (value) => {
        switch(value) {
            case 'FILED': 
            case 'NEW':
            case 'TO_BE_PAID':
            case 'PAID':
            case 'IMPORTED':
            case 'INTEGRATED':
                return theme.colors.secondary;
            case 'PENDING':
                return theme.colors.secondary;
            case 'ERROR':
                return theme.colors.danger;
            case 'LITIGATION':
                return theme.colors.danger;
            case "RELANCE":
                return theme.colors.secondary;
            default:
                return theme.colors.secondary;
        }
    }

    const getStatusIcon = (value) => {
        switch(value) {
            case 'FILED':
            case 'NEW':
            case 'TO_BE_PAID':
            case 'PAID':
            case 'IMPORTED':
            case 'INTEGRATED':
            case 'PENDING':
                // return <EastIcon style={{width: "1.2rem"}}/>;
                return <EastIcon style={{width: "1.2rem"}}/>
            case 'ERROR':
                return <WarningAmberIcon style={{width: "1.78rem", height:"2rem"}}/>
            case 'LITIGATION':
                return <CloseIcon style={{width: "1.2rem"}}/>
            case "RELANCE":
                return <FontAwesomeIcon icon={faClockRotateLeft} style={{width: "1.2rem"}} />
            default:
                return <EastIcon style={{width: "1.2rem"}}/>
        }
    }

    const statusByKey = (action, message = null) => {
        const status = {
            "RELANCE": getRelanceMessage(message),
            "PAID": "Payée",
            "CONTROLLED": "Contrôlée",
            "LITIGATION": "Litige",
            "TO_BE_PAID": "À payer",
            "TO_BE_VALIDATED": "À valider",
            "VALIDATED": "Validée",
            "TO_BE_CONTROLLED": "À contrôler",
            "DEADLINEEXCEEDED" : "Depassement delai de paiement",
            "RECEIVED": (userType == "supplier")
                ? "Prise en charge"
                : (
                    userType == "client" ? "Émise"
                        : (entityFilter == "supplier" ? "Réceptionnée" : "Émise")
                )


            // INTEGRATED : userType === 'client' ? "Reçue" : "Intégrée",
            // REFUSED : "Refusée",
            // REJECTED : "Rejetée",
            // ERROR : 'Erreur'
        }
        return status[action]
    }

    const getRelanceMessage = (message) => {
        switch (message) {
            case "l2_call":
                return "2ème relance téléphonique"
                break;
            case "l1_call":
                return "1ère relance téléphonique"

            case "l1_mail":
                return "1er email de relance"

            case "l2_mail":
                return "2ème email de relance"
            default:
                break;
        }

    }

    return (
        <>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={true} bdColor={eventData?.snapshot=="RELANCE"?"#FFF":getStatusColor(eventData?.snapshot)}color={getStatusColor(eventData?.snapshot)} 
                        style={{border: eventData?.snapshot === "ERROR" ? "none" : ""}}
                        >
                        {getStatusIcon(eventData?.snapshot)}
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={getStatusColor(eventData?.snapshot)}>
                            {(eventData?.snapshot=="RELANCE"?"":"Statut ")+statusByKey(eventData?.snapshot, eventData?.message).toLowerCase()}
                        </TETitle>
                        <TETime>
                            le {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    true && (
                        <TEContent >
                            {/* {
                                eventData?.snapshot === 'LITIGATION' && (
                                    <MessageWrapper>
                                        <MessageFrom>
                                            {t("default:reason", "Motif : ")}
                                        </MessageFrom>
                                        <MessageContent>
                                            {eventData?.message?.slice(0, 128)}
                                            {eventData?.message?.length > 128 ? '...' : null}
                                        </MessageContent>
                                    </MessageWrapper>
                                )
                            } */}
                            {
                                eventData?.comment && (
                                    <MessageWrapper isRelance={isRelance} >
                                        <MessageFrom >
                                            {eventData.message=="l2_mail" || eventData.message=="l1_mail" ? "Action automatique": 
                                            eventData.message=="l2_call" || eventData.message=="l1_call" ? "Action de "+eventData?.first_name+" "+eventData?.last_name
                                           : "Message de "+eventData?.first_name+" "+eventData?.last_name}
                                        </MessageFrom>
                                        {!isRelance&&
                                        <MessageContent>
                                            {eventData?.comment?.slice(0, 128)}
                                            {eventData?.comment?.length > 128 ? '...' : null}
                                        </MessageContent>
                                        }
                                    </MessageWrapper>
                                )
                            }
                        </TEContent>
                    )
                }
            </TEContainer>
        </>
    )
}

export default StatusEvent