import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { TableAction, TableActionLink, TAContainer } from '../../../styles/Table.styled';
import { useTheme } from 'styled-components';
import { BtnIcon, PrimaryBtnOutlineAction, PrimaryBtnOutlineLink , SearchInput, SearchInputContainer  } from "../../../styles/Common";
import SearchIcon from '@mui/icons-material/Search';
import { entitiesUpdateShowFilters } from '../../../store/actions';
function TableActions(props) {
    const {resetFilters, setShowFilters, showFilters,filtersChange,entitiesFilters,handleApplyFiltering} = props;
    const {t} = useTranslation();
    const theme = useTheme();
 
    const quickSearchChange = (quickSearchValue) => {
        console.log(quickSearchValue.target.value)
        let filtersTemp = {
            ...entitiesFilters,
            quickSearch: quickSearchValue.target.value
        }
         filtersChange(filtersTemp)
         handleApplyFiltering(filtersTemp);
    }
    return (
        <TAContainer>
            <SearchInputContainer >
                        <SearchInput
                            style={{padding:"0.3rem"}}
                            type="text"
                            placeholder="Rechercher ..."
                            autoComplete={"off"}
                            name="title"
                            // value={filtersQuery?.quickSearch || ''} 
                            onChange={(e) => quickSearchChange(e)}
                            
                        />
                        <SearchIcon className="ICDBlue"/>
                    </SearchInputContainer>
            <TableAction active={showFilters} onClick={() => setShowFilters(!showFilters)}>
                <span>{t("global:filters")} </span>
                <FilterListIcon />
            </TableAction>
         
        </TAContainer>
    )
}

export default TableActions
