import styled from "styled-components/macro";

export const FContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const FHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
`;

export const FHInfos = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.lightBlack};
`;

export const FFormWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-size: 0.9rem;
`;

export const DPContainer = styled.div`
  margin-bottom: 0.5rem;
  witdh: 30px;

  & input {
    padding: 0.5rem !important;
    color: ${({ theme }) => theme.colors.secondary};
  }

  & button {
    color: ${({ theme }) => theme.colors.secondary};
  }

  & fieldset {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  & label {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus-within {
    & input {
      color: ${({ theme }) => theme.colors.primary};
    }
    & button {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
