import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import {
  formatDate,
  getStatusWithKey,
  getTimeDate,
} from "../../../../../../../shared/utility";
import { useTranslation } from "react-i18next";
import {
  MessageContent,
  MessageFrom,
  MessageWrapper,
  TEContainer,
  TEContent,
  TEHeader,
  TEIcon,
  TEPreview,
  TETime,
  TETitle,
} from "../../Timeline.styled";
import { useTheme } from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EastIcon from "@mui/icons-material/East";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";

function StatusEvent({ isCreator, eventData, isLast, userType }) {
  const [showContent, setShowContent] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (isLast) setShowContent(true);
    else setShowContent(false);
  }, [isLast]);

  const getStatusColor = (value) => {
    switch (value) {
      case "FILED":
      case "NEW":
      case "TO_BE_PAID":
      case "PAID":
      case "IMPORTED":
      case "DEADLINEEXCEEDED":
        return theme.colors.danger;
      case "INTEGRATED":
        return theme.colors.secondary;
      case "PENDING":
        return theme.colors.secondary;
      case "ERROR":
        return theme.colors.danger;
      case "LITIGATION":
        return theme.colors.danger;
      default:
        return theme.colors.secondary;
    }
  };

  const getStatusIcon = (value) => {
    switch (value) {
      case "FILED":
      case "NEW":
      case "TO_BE_PAID":
      case "PAID":
      case "IMPORTED":
      case "INTEGRATED":
      case "DEADLINEEXCEEDED":
      case "PENDING":
        // return <EastIcon style={{width: "1.2rem"}}/>;
        return <EastIcon style={{ width: "1.2rem" }} />;
      case "ERROR":
        return (
          <WarningAmberIcon style={{ width: "1.78rem", height: "2rem" }} />
        );
      case "LITIGATION":
        return <CloseIcon style={{ width: "1.2rem" }} />;
      default:
        return <EastIcon style={{ width: "1.2rem" }} />;
    }
  };
  console.log("je sui ")

  return (
    <>
      <TEContainer>
        <TEHeader onClick={() => setShowContent(!showContent)}>
          <TEIcon
            borderBold={true}
            bdColor={getStatusColor(eventData?.snapshot)}
            color={getStatusColor(eventData?.snapshot)}
            style={{ border: eventData?.snapshot === "ERROR" ? "none" : "" }}
          >
            {getStatusIcon(eventData?.snapshot)}
          </TEIcon>
          <TEPreview>
            <TETitle color={getStatusColor(eventData?.snapshot)}>
              Statut {getStatusWithKey(eventData?.snapshot, userType)}
            </TETitle>
            <TETime>
              le {formatDate(+eventData?.creationDate)} à{" "}
              {getTimeDate(+eventData?.creationDate)}
            </TETime>
          </TEPreview>
        </TEHeader>
        {showContent && (
          <TEContent>
            {eventData?.snapshot === "LITIGATION" && (
              <MessageWrapper>
                <MessageFrom>{t("default:reason", "Motif : ")}</MessageFrom>
                <MessageContent>
                  {eventData?.message?.slice(0, 128)}
                  {eventData?.message?.length > 128 ? "..." : null}
                </MessageContent>
              </MessageWrapper>
            )}
            {eventData?.comment && (
              <MessageWrapper>
                <MessageFrom>
                  {t("default:comment", "Commentaire : ")}
                </MessageFrom>
                <MessageContent>
                  {eventData?.comment?.slice(0, 128)}
                  {eventData?.comment?.length > 128 ? "..." : null}
                </MessageContent>
              </MessageWrapper>
            )}
          </TEContent>
        )}
      </TEContainer>
    </>
  );
}

export default StatusEvent;
