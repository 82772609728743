import styled from 'styled-components/macro';

export const ESContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const ESBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const ESContent = styled.div`
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
`

export const ESForm = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.9rem;
    margin-bottom: 2rem;
`

export const ESCol = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 20%;
    margin-bottom: 0.5rem;

    @media ${({theme}) => theme.device.laptop} {
        padding-right: 5%;
    }
`

export const ToggleInput = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ESView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 16px;
    height: 850px;
    max-height: 850px;

    &.fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }
`

export const ESViewHeader = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #F5FBFF;
    margin-bottom: 1rem;

`

export const ESSubject = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
`

export const ESViewActions = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
`


export const ESTitle = styled.h4`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #505050 !important;
    margin-bottom: 2rem;
`