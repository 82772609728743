import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TableActions from '../../components/SuppliersTable/TableActions/TableActions';
import TableHead from '../../components/SuppliersTable/TableHead/TableHead';
import TableBody from '../../components/SuppliersTable/TableBody/TableBody';
import TableFilters from '../../components/SuppliersTable/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Spinner } from 'react-bootstrap';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { RFContainer, RFBody, RFTableContainer } from './ReferentielFournisseur.Styled';
import "./ReferentielFournisseur.css"

import _ from 'lodash';


function ReferentielFournisseur(props) {
    const {
        userType,
        entityFilter, 
        searchField, 
        setSearchField,
        setEntitydetail, 
        loading, 
        suppliersData, 
        count, 
        currentPage, 
        pageSize,
        getSuppliersPage, 
        setSuppliersPageSize,
        getCustomersPage, 
        setSuppliersPage, 
        sortQuery, reverse, 
        filtersQuery, 
        showFilters, 
        updateShowFilters, 
        setSuppliersFilters, 
        setSortQuery, 
        setReverse
    } = props;
    const {t} = useTranslation();
    const {state} = useLocation();
   
    const[density,setDensity]=useState("2.25rem")
    const [columns,setColumns]=useState([
        {title: t("supplier:supplierCodefournisseur"), field: 'code', show:true, alignSelf:"left"},
        {title: t("supplier:supplierNamefournisseur"), field: 'name',show:true, alignSelf:"left"},
        {title: t("supplier:vta"), field: 'vat',show:true, alignSelf:"right"},
        {title: t("supplier:status", "Statut"), field: 'status',show:true, alignSelf:"center"},
        {title: t("supplier:channel", "Canal"), field: 'channel',show:true, alignSelf:"left"},
        {title: t("supplier:creationDate"), field: 'creationDate',show:true, alignSelf:"center"}
    ])

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;
    }, [])

    useEffect(() => {
        let filtersTemp = {
            ...filtersQuery,
            type : entityFilter
        }
        let filtersProps = null;
        if(state?.filtersProps){
            filtersProps = state.filtersProps;
            filtersTemp = {
                ...filtersQuery,
                ...filtersProps
            }
            setSuppliersFilters(filtersTemp);
        }
        (entityFilter == "client") || (filtersTemp?.type && (filtersTemp?.type == "client") ) ? 
            getCustomersPage(currentPage, filtersTemp, sortQuery, reverse, searchField)
        : getSuppliersPage(currentPage, filtersTemp, sortQuery, reverse, searchField, pageSize)

        return () => {
            resetFilters();
        }
    }, [state, currentPage, entityFilter, pageSize])

    const isEmptyFilters = () => {
        if(filtersQuery === null )
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if(filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }
    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if(!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        entityFilter == "client" ? 
            getCustomersPage(currentPage, filtersQuery, field, newReverse, searchField):
        getSuppliersPage(currentPage, filtersQuery, field, newReverse, searchField, pageSize)

    }

    const handlePageChange = useCallback((newPage) => {
        setSuppliersPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        entityFilter == "client" ? 
            getCustomersPage(1,filters, sortQuery, reverse, searchField):
        getSuppliersPage(1,filters, sortQuery, reverse, searchField, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, searchField]);

    const resetFilters = () => {
        setSuppliersFilters(null);
        setSuppliersPage(1)
        entityFilter == "client" ? 
            getCustomersPage(1,null, sortQuery, reverse, searchField):
        getSuppliersPage(1, null, sortQuery, reverse, searchField, pageSize)
    }

    const findSupplier = (e) => {
        setSearchField(e.target.value)
        entityFilter == "client" ? 
            getCustomersPage(1, null, sortQuery, reverse, e.target.value):
        getSuppliersPage(1, null, sortQuery, reverse, e.target.value, pageSize)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
        ) : (
            <NoContent>
                <span>{t("supplier:noSuppliers")}</span>
            </NoContent>
        ) 

    if(!loading && suppliersData?.length > 0){
        tSpinner = null;
        tBody = ( <TableBody 
                        columns={columns}
                        setEntitydetail={setEntitydetail} 
                        suppliersData={suppliersData} 
                        formatDate={formatDate}
                        entityFilter={entityFilter}
                        userType={userType}
                    />)
    }
    
    return (
        <RFContainer>
             
            <TitleHelmet title={"Démo Portail | " + t("menu:suppliers")}  />
            <Header>
          
                <HeaderInfos>
                    <HeaderLocation>{t("menu:supplier")}</HeaderLocation>
                    <HeaderTitle>Référentiel</HeaderTitle>
                </HeaderInfos>
                <HeaderActions>
                
                    
                    <TableActions 
                        resetFilters={resetFilters}
                        showFilters={showFilters} 
                        setShowFilters={updateShowFilters} 
                        entityFilter={entityFilter} 
                        userType={userType}
                    />
                    
                </HeaderActions>
            </Header>
            <RFBody>
                <RFTableContainer>
                    
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage} 
                        pageChange={handlePageChange} 
                        totalElement={count} 
                        perPage={pageSize}
                        perPageChange={setSuppliersPageSize}
                    />
                    
                    <Table >
                        <TableHead 
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler} 
                            entityFilter={entityFilter}
                            userType={userType}
                        />
                        <TBody height= {density}>
                            <TableFilters 
                                columns={columns}
                                showFilters={showFilters}
                                suppliersFilters={filtersQuery}
                                filtersChange={setSuppliersFilters}
                                applyFilters={handleApplyFiltering}
                                entityFilter={entityFilter}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner} 
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage} 
                        pageChange={handlePageChange} 
                        totalElement={count} 
                        perPage={pageSize}
                        perPageChange={setSuppliersPageSize}
                    />
                </RFTableContainer>
            </RFBody>
        </RFContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.suppliers.loading,
    suppliersData: state.suppliers.data,
    count: state.suppliers.count,
    searchField: state.suppliers.searchInput,
    currentPage: state.suppliers.currentPage,
    pageSize: state.suppliers.pageSize,
    sortQuery: state.suppliers.sortQuery,
    reverse: state.suppliers.reverse,
    filtersQuery: state.suppliers.filtersQuery,
    showFilters: state.suppliers.showFilters,
    userType: state.auth.userType
})
const mapDispatchToProps = dispatch => ({
    getSuppliersPage: (page, filters, sort, reverse, searchField, pageSize) => dispatch(actions.suppliersGetPage(page, filters, sort, reverse, searchField, pageSize)),
    getCustomersPage: (page, filters, sort, reverse, searchField) => dispatch(actions.customersGetPage(page, filters, sort, reverse, searchField)),
    setSuppliersPage: (page) => dispatch(actions.suppliersSetPage(page)),
    setSuppliersPageSize: (pageSize) => dispatch(actions.suppliersSetPageSize(pageSize)),
    setSearchField: (field) => dispatch(actions.suppliersSetSearchInput(field)),
    setSortQuery: (sortQ) => dispatch(actions.suppliersSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.suppliersSetReverseSort(reverseS)),
    setSuppliersFilters: (filters) => dispatch(actions.suppliersSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.suppliersUpdateShowFilters(show)),
    setEntitydetail: (entity) => dispatch(actions.setEntitydetail(entity)),  

})
export default connect(mapStateToProps, mapDispatchToProps) (ReferentielFournisseur)
