import { Link } from "react-router-dom";
import styled from "styled-components";

export const EMContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const EMBody = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 3rem;
`;

export const EMTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BodyModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckContentModal = styled.div`
  display: flex;
`;

export const DateInput = styled.input`
  width: 100%;
  height: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  padding: 0.5px;
  color: ${({ theme }) => theme.colors.lightBlack};
  margin: 0.5rem;
`;

export const CheckInput = styled.input`
  margin-right: 10px;
`;

export const UMContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const UMBody = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`;

export const UserStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  border-radius: 8px;
  background-color: ${({ bg }) => (bg ? bg : "white")};
  color: ${({ color }) => (color ? color : theme.colors.lightBlack)};
`;

export const EntContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const EntHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border-bottom: 4px solid #f5fbff;
`;

export const EntHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const EntHeaderTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

export const EntHeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1;
`;

export const EntHeaderAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid ${({ bg }) => (bg ? bg : "#D9E1E7")};
  border-radius: 16px;
  color: #d9e1e7;
  cursor: pointer;
  font-size: 0.9rem;
  background-color: ${({ bg }) => (bg ? bg : "#FFFFFF")};

  & span {
    color: ${({ color }) => color || "#505050"};
  }

  &:hover {
    /* background-color: ${({ theme }) => theme.colors.btnHover}; */
  }
`;

export const NavTabsUl = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const NavTabsLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
  /* background-color: white; */
`;

export const NavTabsLink = styled(Link)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  border-bottom: ${({ active, theme }) =>
    active ? `5px solid ${theme.colors.primary}` : "5px solid white"};
  text-decoration: none;
  padding-bottom: 0.3rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: 5px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const EntContent = styled.div`
  width: 100%;
`;

export const EntFormSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1.5rem;
`;
export const EntFormList = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const EntFormItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  padding: 0.25rem;
  background-color: #2174b980;
  color: white;
  border-radius: 8px;
  margin-right: 0.5rem;

  & span {
    margin: 0 0.5rem 0 0;
  }
`;

export const EIFormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const EIFormCol = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

export const FormColModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LogContainer = styled.div`
  margin-top: 1rem;
`;

export const MySelect = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  position: absolute;
  background-color: white;
  border: 1px solid #D9E1E7;
  border-radius: 18px;
  padding: 0.8rem 1.5rem;
  margin-top: -10px;
  margin-left: -150px;
`;

export const P = styled.p`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.lightBlack};
`;

export const InputGrep = styled.input`
  border: 1px solid #D9E1E7;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.lightBlack};
`;

export const ContainerGrep = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const ContainerNumberGrep = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ContainerButtonGrep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
`;

//Button with icon validate
export const ButtonGrep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D9E1E7;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: white;
  padding: 0px 0px;
  margin-right: 0.7rem;
  margin-left: 0.7rem;

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.primary} !important;
  }
`;
