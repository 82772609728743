
import ReactModal from 'react-modal-resizable-draggable';
import Draggable from 'react-draggable';
import"./TimeLineModal.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark} from '@fortawesome/free-solid-svg-icons'  
// import  Timeline from "../../components/InvoiceDetail/InvoiceBody/InvoiceSide/Timeline/Timeline";
import TimeLine  from "./../../../components/InvoiceDetail/InvoiceBody/InvoiceSide/Timeline/TimeLine";
import ModificationsChat  from "./../../../components/InvoiceDetail/InvoiceBody/InvoiceSide/ModificationsChat/ModificationsChat";
function TimeLineModal({modalIsOpen,setModalIsOpen,openModal,closeModal}) {



        return (
         
                // <ReactModal 
                //     initWidth={800} 
                //     initHeight={400} 
                //     onFocus={() => console.log("Modal is clicked")}
                //     className={"my-modal-custom-class"}
                //     onRequestClose={closeModal} 
                //     isOpen={modalIsOpen}>
                //     <h3>My Modal</h3>
                //     <div className="body">
                //         <p>This is the modal&apos;s body.</p>
                //     </div>
                //     <button onClick={closeModal}>
                //         Close modal
                //     </button>
                // </ReactModal>
                // <Draggable>
                //     <div className="box">
                //         <div>Move me around!</div>
                //     </div>
                // </Draggable>
       <div style={{display:"flex",width:"99%"}}>
            <Draggable
                handle=".handle"
                defaultPosition={{x: 0, y: 0}}
                position={null}
                grid={[25, 25]}
                scale={1}
                // onStart={handleStart}
                // onDrag={handleDrag}
                // onStop={handleStop}
                >

                <div className="box handle">
                    <div className="entete">
                       <div className="enteteTitle">TimeLine</div> 
                       <div className="enteteClose"><FontAwesomeIcon icon={faXmark}style={{marginLeft:"5px",marginRight:"10px"}} onClick={e => closeModal()}/></div> 
                    </div>
                    <div style={{display:"flex",margin:"7px",height:"427px"}}>
                        <TimeLine/>
                    </div>
                </div>
            </Draggable>
       </div>
               
         
        );
    }


export default TimeLineModal;