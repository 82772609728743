import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  RBContainer,
  RButton,
  RContainer,
  RIContainer,
  RIText,
  RITextContainer,
  RITextLabelContainer,
} from "./research.styled";
import "./Modal.css";

function Research(props) {
  const { loadingData, invoiceData, invoiceError, onSearchHandler } = props;

  const [searchParams, setSearchParams] = useState({
    designation: "",
    reference: "",
    commandNumber: null
  })


  const onChangeHandler = (e) => {
    const name = e.target.name
    const value = e.target.value
    setSearchParams({...searchParams,
      [name] : value
    })
  }

  return (
    <>
      <RContainer>
        <RIContainer>
          <RIText placeholder="Désignation article" value={searchParams?.designation} onChange={e => onChangeHandler(e)}/>
          <RITextContainer>
            <RITextLabelContainer>
              <label>Référence </label>
              <input type="text" value={searchParams?.reference} style={{padding: "3px 5px"}} />
            </RITextLabelContainer>
            <RITextLabelContainer>
              <label>N° commande </label>
              <input type="text" value={searchParams?.commandNumber} style={{padding: "3px 5px"}}/>
            </RITextLabelContainer>
          </RITextContainer>
        </RIContainer>
        <RBContainer>
          <RButton onClick={e => onSearchHandler(searchParams)}>Rechercher</RButton>
        </RBContainer>
      </RContainer>
    </>
  );
}
const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Research);
