import { valueToPercent } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
`;

export const THead = styled.thead`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
`;

export const TBody = styled.tbody`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
`;

export const TH = styled.th`
  width: auto;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  padding-left: 0.5rem;
`;

export const TD = styled.td`
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  padding-left: 0.5rem;

  ${({ colorB }) => colorB && `background-color: ${colorB};`}
`;
