import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2'

export const FContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
`
export const EIFormCol = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
`
export const TelInput = styled(PhoneInput)`
    width: 180px;
    input {
        width: 100% !important;
    }
`
export const FormEntity = styled.span`
    border-radius: 10px;
    color: ${({theme}) => theme.colors.lightBlack}; 
    background: #F1F4F9; 
    padding: 7px 13px;
    margin-left: 13px
`


export const FormLabelSpec = styled.div`
    display: flex;
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: nowrap;
    color: ${({theme}) => theme.colors.lightBlack};
`;

export const FormEntCanal = styled.div`
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
`

export const FormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;

    &:not(:last-child) {
        margin-bottom: 0.7rem;
    }

    &.fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }

`

export const EntInfosContainer = styled.div`
    width: 100%;
    font-size: 0.9rem;
    margin-top: 1rem;
`
export const EIFormContainer = styled.div`
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
`;

export const FHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
`

export const FHInfos = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    font-size: 1rem;
    color: ${({theme}) => theme.colors.lightBlack};
`

export const FFormWrap = styled.div`
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    font-size: 0.9rem;
`

