import styled from "styled-components/macro";

export const RContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 0.8rem !important;
`;

export const RIContainer = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.8rem 1.5rem;
`;
export const RBContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.5rem;
  padding-left: 0;
`;

export const RITextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.8rem;
  ${({ theme }) => theme.button}
`;

export const RITextLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  
  & input {
    color: ${({ theme }) => theme.colors.lightBlack};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    font-size: 0.9rem;
    border-radius: 8px;
    margin-left: 0.8rem;
    width: auto;
    &:focus {
      outline: none !important;
    }
  }
  & label {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 0.9rem;
  }
`;

export const RButton = styled.button`
  width: 100%;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 16px;
  cursor: pointer;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
`;

export const RIText = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  font-size: 0.9rem;
  padding: 3px 5px;
  &:focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.lightBlack};
  }
`;

export const TitleTable = styled.h2`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.2rem;
`;
