import React, { useMemo, useState,useEffect } from 'react'
import TextEditor from '../../components/CampaignForm/TextEditor/TextEditor';
import Recipient from '../../components/CampaignForm/Recipient/Recipient';
import RepertoireModal from '../../components/CampaignForm/RepertoireModal/RepertoireModal';
import Axios from '../../axios-proas'
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Attachment from '../../components/CampaignForm/Attachment/Attachment';
import frLocale from "date-fns/locale/fr";
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useNavigate, useLocation , useParams} from 'react-router-dom';
import { convertFileToBase64, getNotyfObject } from '../../shared/utility';
import Spinner from 'react-bootstrap/Spinner'
import { CTooltip } from '../../components/UI/CTooltip/CTooltip';
import { AddRecipientsBtn, AttachementsContainer, CFBody, CFContainer, CFEditorWrapper, CFFormGroup, CFFormSection, CFFormWrapper, CFHeader, CFHeaderActions, CFHeaderTitle, CFHeaderWrapper, CFSection, CFTitle, DPContainer, ToggleIcon, ToggleLabel } from './CampaignForm.styled';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, FormInput, FormLabel } from '../../styles/Common';
import { useTranslation } from 'react-i18next';
import NavTabs from '../../components/UI/NavTabs/NavTabs';
import { useTheme } from 'styled-components';
import FileUploaderBtn from '../../components/UI/FileUploaderBtn/FileUploaderBtn';
import {Content,DivLabel,DivGlp,CBtn,Container,BtnDivContent,ContentPiecesOrDest} from '../../components/NewDocumentation/EntitiesComponent/EntitiesComponent.Styled'
import Entity from '../Entity/Entity';
import MandatoryMarker from '../../components/UI/MandatoryMarker';

function CampaignForm() {
    let {uid} = useParams(); 
    let notyf = getNotyfObject();
    let location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const theme = useTheme()
    const currentDate = new Date();
    const [selectedStartDate, setSelectedStartDate] = React.useState(currentDate);
    const [startDateError, setStartDateError] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date(new Date().setFullYear(new Date().getFullYear() , 12, 0 )));
    const [endDateError, setEndDateError] = useState(false);

    const [showRepertoireModal, setShowRepertoireModal] = useState(false)
    const [showAttachmentModal, setShowAttachmentModal] = useState(false)
    const [campaign, setCampaign] = useState({
        startDate: selectedStartDate.getTime(),
        endDate: selectedEndDate.getTime(),
        attachments: [],
        recipients: [],
        title: '',
        text: '',
        toRead : 0,
        toRelaunch : 0,
        toSign : 0
    });
    
    const [attachmentFileData, setAttachmentFileData] = useState([]);
    const [campaignData, setCampaignData] = useState([]);

    const [campaignErrors, setCampaignErrors] = useState([]);
    const [createCampaignLoading, setCreateCampaignLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("parametres");
    const tabsList = useMemo(() => [
        {title: t("global:parametres", "Paramètres"), value: "parametres"},
        {title: t("global:launch", "Lancement"), value: "launch"}
    ], [t]);

    useEffect(() => {
        if(uid !=null){
            Axios.get("campaign/"+uid+"/campaignByUid").then(response => {
                setCampaignData(response.data.result)
                setAttachmentFileData(response?.data?.attachement)
            }).catch(response => {
                notyf.error("Une erreur s'est produite")
            })
        }
    }, [])

    const formatEntities = (entities) => {
        return entities.split(',')
    }
   
    const tabClickHandler = (tab) => {
        setActiveTab(tab);
    }

    const handleStartDateChange = (date) => {
        if (date.toString() === "Invalid Date"){
            setStartDateError(true)
        } else 
        if(date.getFullYear <= 1900 || date.getFullYear > 2100){
            setStartDateError(true)
        }else {
            setStartDateError(false)
        }
        setSelectedStartDate(date);
        setCampaign({
            ...campaign,
            startDate : selectedStartDate.getTime(),
        })
    };

    const handleEndDateChange = (date) => {
        if (date.toString() === "Invalid Date"){
            setEndDateError(true)
        } else 
        if(date < selectedStartDate || date.getFullYear > 2100 ){
            setEndDateError(true)
        }else {
            setEndDateError(false)
        }
        setSelectedEndDate(date);
        setCampaign({
            ...campaign,
            endDate : selectedEndDate.getTime(),
        })
    };

    const generateMaxEndDate = () => {
        let startDate = new Date(selectedStartDate);
        return new Date( startDate.setFullYear(startDate.getFullYear() , 12, 0 ))
    }


    const addRecipientsHandler = (newRecipients) => {
        const allClients = newRecipients.find(row => row.uid === "allClients")
        const allSuppliers = newRecipients.find(row => row.uid === "allSuppliers");
        let campaignRecipientsTemp = campaign?.recipients ? [...campaign?.recipients] : []
        const newRecipientsClients = newRecipients.filter(row => row.type === "client")
        const newRecipientsSuppliers = newRecipients.filter(row => row.type === "supplier")
        const recipientsAllClientsIndex = campaignRecipientsTemp.findIndex(row => row.uid === "allClients")
        const recipientsAllSuppliersIndex = campaignRecipientsTemp.findIndex(row => row.uid === "allSuppliers")

        if(allClients){
            campaignRecipientsTemp = campaignRecipientsTemp.filter(row => row.type !== "client");
            if(recipientsAllClientsIndex < 0)
                campaignRecipientsTemp.push(allClients)
        }else {
            if(newRecipientsClients.length > 0){
                if(recipientsAllClientsIndex >= 0)
                    campaignRecipientsTemp.splice(recipientsAllClientsIndex, 1)
                let recipientsClient = newRecipients.filter(row => row.type === "client")
                recipientsClient.map(row => {
                    let existAlready = campaignRecipientsTemp.find(elmnt => elmnt.uid === row.uid)
                    if(!existAlready)
                        campaignRecipientsTemp.push(row)
                })
            }
        }
        if(allSuppliers){
            campaignRecipientsTemp = campaignRecipientsTemp.filter(row => row.type !== "supplier");
            if(recipientsAllSuppliersIndex < 0)
                campaignRecipientsTemp.push(allSuppliers)
        }else {
            if(newRecipientsSuppliers.length > 0){
                if(recipientsAllSuppliersIndex >= 0)
                    campaignRecipientsTemp.splice(recipientsAllSuppliersIndex, 1)
                let recipientsSupplier = newRecipients.filter(row => row.type === "supplier")
                recipientsSupplier.map(row => {
                    let existAlready = campaignRecipientsTemp.find(elmnt => elmnt.uid === row.uid)
                    if(!existAlready)
                        campaignRecipientsTemp.push(row)
                })
            }
        }
        setCampaign({
            ...campaign,
            recipients: campaignRecipientsTemp
        })
    }

    const deleteRecipientHandler = (recipientUid) => {
        let recipientsTemp = [...campaign?.recipients];
        let recipientIndex = recipientsTemp.findIndex(row => row.uid === recipientUid);
        if(recipientIndex >= 0)
            recipientsTemp.splice(recipientIndex, 1)
        setCampaign({
            ...campaign,
            recipients: recipientsTemp
        })
    }

    const addAttachmentHandler = (attachment) => {
        let attachmentsTemp = [...campaign?.attachments, attachment]
        setCampaign({
            ...campaign,
            attachments: attachmentsTemp
        })
    }

    const deleteAttachmentHandler = (attachmentName) => {
        let attachmentsTemp = [...campaign?.attachments];
        let attachmentIndex = attachmentsTemp.findIndex(row => row.name === attachmentName);
        if(attachmentIndex >= 0)
            attachmentsTemp.splice(attachmentIndex, 1)
        setCampaign({
            ...campaign,
            attachments: attachmentsTemp
        });
    }

    const optionChangeHandler = (name, value) => {
        // const name = e.target.name;
        // const value = e.target.value;
        setCampaign({
            ...campaign,
            [name]: value
        })
    }

    const fileChangeHandler = (e) => {
        let file = e.target.files[0]
        let fileB64 = "";
        if(file){
            let existFile = campaign?.attachments.find(row => row.name === file.name)
            if(existFile){
                notyf.error("Un fichier avec le même nom a déjà été ajouté")
            }else {
                if(file.size > 5242880)
                    notyf.error("La taille du fichier ne doit pas dépasser 5 MO")
        
                convertFileToBase64(file).then(result => {
                    fileB64 = result;
                    addAttachmentHandler({
                        name: file.name,
                        content: fileB64
                    })
                }).catch(err => {
                    notyf.error("Une erreur s'est produite, veuillez réessayer")
                })
                //console.log(file)
            }
        }
    }

    const titleChangeHandler = (e) => {
        const value = e.target.value
        setCampaign({
            ...campaign,
            title: value
        })
    }

    const addTextHandler = (text) => {
        setCampaign({
            ...campaign,
            text: text
        })
    }

    const checkCampaignForm = () => {
        let errors = []
        if(startDateError)
            errors.push("Date de début de campagne invalide")
        if(endDateError)
            errors.push("Date de fin de campagne invalide")
        if(campaign?.recipients.length === 0)
            errors.push("Veuillez désigner au moins un désitinataire")
        if(campaign?.title.length === 0)
            errors.push("Veuillez saisir le titre")
        if(campaign?.text.length === 0)
            errors.push("Veuillez saisir le contenu de la campagne")
        return errors;
    }

    const createCampaign = () => {
        setCreateCampaignLoading(true)
        Axios.post('campaign', campaign).then(response => {
            notyf.success("La campagne a bien été crée")
            setCreateCampaignLoading(false);
            // setCampaign({
            //     startDate: selectedStartDate.getTime(),
            //     endDate: selectedEndDate.getTime(),
            //     attachments: [],
            //     recipients: [],
            //     title: '',
            //     text: ''
            // })
            navigate(-1);
        }).catch(response => {
            notyf.error("Une erreur s'est produite")
            setCreateCampaignLoading(false)
        })
    }

    const createCampaignClickHandler = () => {
        let errors = checkCampaignForm()
        if(errors.length > 0)
            setCampaignErrors(errors)
        else{
            setCampaignErrors([]);
            createCampaign()
        }
    }

    
    const getAttachmentPdf = (invoiceUid) => {
     
        Axios.get(`/invoice/${invoiceUid}/attachmentPdf`)
        .then(response => {
            const linkSource = 'data:application/octet-stream;base64,'+response.data.pdfFile;
            const link = document.createElement("a");
            link.href = linkSource;
            link.download = response.data.attachmentName;
            link.click();
            if (clicked)
                clicked();
        }).catch(err => {
            notyf.error('Une erreur s\'est produite !')
           
        })
    }
           
    return (
        <>
            <CFContainer>
                <CFHeaderWrapper>
                    <CFHeader>
                        <CFHeaderTitle >{t("menu:newCampaign", "Nouvelle campagne")}</CFHeaderTitle>
                        <CFHeaderActions>
                            { !uid &&(
                                createCampaignLoading ? (
                                        <Spinner animation="border" variant="primary" />
                                ) 
                                : 
                                    <PrimaryBtnOutlineAction
                                        onClick={() => createCampaignClickHandler()}
                                    >
                                        <span>CREER</span> 
                                    </PrimaryBtnOutlineAction>)
                            }
                            <SecondaryBtnOutlineAction
                                onClick={() => navigate("/suiviCampagnes")}
                            >
                                <span>Retour</span> 
                            </SecondaryBtnOutlineAction>
                        </CFHeaderActions>
                    </CFHeader>
                    <NavTabs
                        tabsList={tabsList}
                        activeTab={activeTab}
                        onTabClick={tabClickHandler}
                    />
                </CFHeaderWrapper>

                <CFBody>
                    {
                        campaignErrors && campaignErrors.length > 0 ? (
                            <div className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                                <ul>
                                    {
                                        campaignErrors.map(error => (<li key={error}>{error}</li>))
                                    }
                                </ul>
                            </div>
                        ) : null
                    }
                    <CFSection>
                        <CFEditorWrapper>
                            <CFTitle>{campaign?.title || ''}</CFTitle>
                            <TextEditor 
                                content={campaignData?.content || ""}
                                addTextHandler={addTextHandler}
                            />
                        </CFEditorWrapper>
                        <CFFormWrapper>
                            <CFFormSection>
                                <CFFormGroup>
                                    <FormLabel htmlFor="title" >Titre <MandatoryMarker /></FormLabel>
                                    <FormInput 
                                        id="title"
                                        type="text" 
                                        placeHolder="Titre" 
                                        value={campaignData?.title||campaign?.title||""} 
                                        onChange={e => titleChangeHandler(e)} 
                                        maxLength={255}
                                        name="title" 
                                    />
                                </CFFormGroup>
                                <CFFormGroup>
                                    <FormLabel >Période de validité <MandatoryMarker /></FormLabel>
                                </CFFormGroup>
                                <CFFormGroup>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                        <DPContainer>
                                            <DatePicker
                                                id="date-picker-dialog"
                                                label="Date début campagne"
                                                format="dd/MM/yyyy"
                                                minDate={currentDate}
                                                value={uid ?new Date(+campaignData?.creationDate) : selectedStartDate}
                                                
                                                onChange={handleStartDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage="Format de date invalide"
                                                maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                                minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                                renderInput={(params) => <TextField {...params} />}
                                                // open={true} control open dialog state
                                            />
                                        </DPContainer>
                                        <DPContainer>
                                            <DatePicker
                                                id="date-picker-dialog"
                                                label="Date fin campagne"
                                                format="dd/MM/yyyy"
                                                value={uid ?new Date(+campaignData?.endDate) : selectedEndDate} 
                                                onChange={handleEndDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                minDate={selectedStartDate}
                                                maxDate={generateMaxEndDate()}
                                                invalidDateMessage="Format de date invalide"
                                                maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                                                minDateMessage="La date ne doit pas être antérieure à la date minimale"
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </DPContainer>
                                    </LocalizationProvider>
                                </CFFormGroup>
                                <CFFormGroup>
                                    <ToggleLabel active={+campaign?.toRead} >Option de lecture</ToggleLabel>
                                    <ToggleIcon active={uid?+campaignData.toRead:+campaign?.toRead}>
                                        {
                                            +campaign?.toRead ? 
                                                <ToggleOnIcon 
                                                    fontSize="small"
                                                    onClick={() => !uid&& optionChangeHandler("toRead", 0)}
                                                /> :
                                                <ToggleOffIcon 
                                                    fontSize="small"
                                                    onClick={() => !uid&& optionChangeHandler("toRead", 1)}
                                                />
                                        }
                                    </ToggleIcon>
                                </CFFormGroup>
                                <CFFormGroup>
                                    <ToggleLabel active={+campaign?.toRelaunch} >Relance si non lecture</ToggleLabel>
                                    <ToggleIcon active={uid?+campaignData.toRelaunch:+campaign?.toRelaunch}>
                                        {
                                            +campaign?.toRelaunch ? 
                                                <ToggleOnIcon 
                                                    fontSize="small"
                                                    onClick={() => !uid&& optionChangeHandler("toRelaunch", 0)}
                                                /> :
                                                <ToggleOffIcon 
                                                    fontSize="small"
                                                    onClick={() => !uid&& optionChangeHandler("toRelaunch", 1)}
                                                />
                                        }
                                    </ToggleIcon>
                                </CFFormGroup>
                                <CFFormGroup>
                                    <ToggleLabel active={+campaign?.toSign} >Signature électronique </ToggleLabel>
                                    <ToggleIcon active={uid?+campaignData.toSign: +campaign?.toSign}>
                                        {
                                            +campaign?.toSign ? 
                                                <ToggleOnIcon 
                                                    fontSize="small"
                                                    onClick={() => !uid&& optionChangeHandler("toSign", 0)}
                                                /> :
                                                <ToggleOffIcon 
                                                    fontSize="small"
                                                    onClick={() => !uid && optionChangeHandler("toSign", 1)}
                                                />
                                        }
                                    </ToggleIcon>
                                </CFFormGroup>
                                {!uid?
                                <CFFormGroup>
                                    <FormLabel >Pièces jointes</FormLabel>
                                    <AttachementsContainer>
                                        {
                                            campaign?.attachments.length > 0 ?
                                                campaign?.attachments.map(attachment => (
                                                    <Attachment
                                                        key={attachment.name}
                                                        attachment={attachment}
                                                        deleteAttachement={deleteAttachmentHandler}
                                                    />
                                                ))
                                            : null
                                        }
                                    </AttachementsContainer>
                                </CFFormGroup>:<>
                                 <FormLabel >Pièces jointes</FormLabel>
                                 <ContentPiecesOrDest>
                                     { 
                                     attachmentFileData?.map(entity =>(      
                                         <DivGlp key={entity.uid}  >
                                         <DivLabel  onClick={() => getAttachmentPdf(entity.uid)}>
                                                 {entity.attachment}
                                         </DivLabel>
                                     </DivGlp>
                                         )) 
                                     }
                                 </ContentPiecesOrDest>
                                 </>
                                }
                                {!uid&&
                                    <CFFormGroup>
                                        <FormLabel >Pièces jointes</FormLabel>
                                        <FileUploaderBtn
                                            mandatory={false}
                                            btnLabel="Importer"
                                            handleChange={fileChangeHandler}
                                            name="file"
                                            icon={<CloudUploadIcon style={{fontSize: '1.2rem', marginLeft: '0.5rem'}} />}
                                        />
                                    </CFFormGroup>
                                }

                            </CFFormSection>
                            {!uid ?
                            <CFFormSection>
                                
                                        <CFFormGroup>
                                            <FormLabel >Sélection des contacts </FormLabel>
                                        </CFFormGroup>
                                        <CFFormGroup>
                                            <FormLabel >Destinataires<MandatoryMarker /></FormLabel>
                                            <AttachementsContainer>
                                                {
                                                    campaign?.recipients?.length > 0 ? 
                                                        campaign.recipients.map(recipient => (
                                                            <Recipient 
                                                                key={recipient.uid}
                                                                recipient={recipient} 
                                                                deleteRecipient={deleteRecipientHandler}
                                                            />
                                                        ))
                                                    : null
                                                }
                                            </AttachementsContainer>
                                        </CFFormGroup>
                                        <CFFormGroup>
                                            <FormLabel >Destinataires</FormLabel>
                                            <AddRecipientsBtn onClick={() => setShowRepertoireModal(true)}>
                                                <span>Ajouter</span>
                                                <PersonAddIcon style={{fontSize: '1.2rem', marginLeft: '0.5rem'}} />
                                            </AddRecipientsBtn>
                                            {
                                                showRepertoireModal ? (
                                                    <RepertoireModal
                                                        show={showRepertoireModal}
                                                        handleClose={() => setShowRepertoireModal(false)}
                                                        addRecipientsHandler={addRecipientsHandler}
                                                    /> 
                                                )
                                                : null
                                            }
                                        </CFFormGroup>

                            </CFFormSection>
                            :
                            <CFFormSection>
                                <FormLabel >Destinataires</FormLabel>
                            <ContentPiecesOrDest>
                                { 
                                 campaignData?.destinataire? formatEntities(campaignData?.destinataire)?.map(entity =>(      
                                    <DivGlp key={Entity}  >
                                       <DivLabel>
                                            {entity}
                                       </DivLabel>
                                   </DivGlp>
                                    )) :null
                                }
                            </ContentPiecesOrDest> 
                            </CFFormSection>
                            
                            }
                        </CFFormWrapper>
                    </CFSection>
                </CFBody>
            </CFContainer>
        </>
    )
}

export default CampaignForm
