import React, { useCallback, useEffect, useState } from "react";
import Axios from "../../axios-proas";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import * as actions from "../../store/actions/index";
import { useTranslation } from "react-i18next";
import TableActions from "../../components/ConsultationLogs/TableActions/TableActions";
import TableHead from "../../components/ConsultationLogs/TableHead/TableHead";
import TableBody from "../../components/ConsultationLogs/TableBody/TableBody";
import TableFilters from "../../components/ConsultationLogs/TableFilters/TableFilters";
import TablePagination from "../../components/UI/TablePagination/TablePagination";
import TitleHelmet from "../../components/UI/TitleHelmet/TitleHelmet";
import { checkValidity, getNotyfObject } from "../../shared/utility";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useParams } from "react-router-dom";
import ModalDownload from "../../components/ConsultationLogs/TableActions/ModalDownload/ModalDownload";
import {
  NoContent,
  SpinnerContainer,
  Table,
  TBody,
  SearchInputContainer,
  SearchInput,
} from "../../styles/Table.styled";
import {
  Header,
  HeaderActions,
  HeaderInfos,
  HeaderLocation,
  HeaderTitle,
  PrimaryBtnOutlineAction,
} from "../../styles/Common";
import { UMBody, UMContainer } from "./ConsultationLogs.styled";
import SelectPeriod from "../../components/UI/SelectPeriod/SelectPeriod";

function ConsultationLogs(props) {
  let {
    setUsersManagementUpdate,
    usersManagementUpdate,
    setConfirmationUserModalShow,
    initUserModal,
    contact,
    contactEntity,
    userType,
    token,
    loading,
    usersData,
    count,
    currentPage,
    pageSize,
    setPaymentSignaturePageSize,
    setPaymentSignaturePage,
    getPaymentSignaturePage,
    setPaymentSignatureFilters,
    filtersQuery,
    filtering,
    sortQuery,
    reverse,
    setSortQuery,
    setReverse,
    updateShowFilters,
    showFilters,
    selectedPaymentSignature,
    selectPaymentSignatureRow,
    selectedAllRows,
    selectAllPaymentSignatureRows,
    emptySelectedPaymentSignature,
    setNewUserModal,
  } = props;
  const { t } = useTranslation();
  let notyf = getNotyfObject();
  let location = useLocation();
  let { uid: entityUid } = useParams();
  const [showDownloadLogsModal, setShowDownloadLogsModal] = useState(false);
  // const entityUid = location.state?.entityUid;
  const dateFilterOptions = [
    { label: "-- Période --", value: "" },
    { label: "Cette année", value: "thisYear" },
    { label: "Le mois dernier", value: "lastMonth" },
    { label: "Ce mois-ci", value: "thisMonth" },
  ];

  const localData = [
    {
      type: "Serveur d'application",
      description: "Logs du serveur d'application",
      globalInfo: "DEBUG",
    },
    {
      type: "Authentification",
      description: "Logs du système d'authentification",
      globalInfo: "ERROR",
    },
    {
      type: "OCR",
      description: "Logs des traitements OCR",
      globalInfo: "ERROR",
    },
    {
      type: "SAE",
      description: "Logs des échanges avec le coffre-fort",
      globalInfo: "ERROR",
    },
    {
      type: "Activité",
      description:
        "Logs des activités de la solution (connexion, consultation, modification, suppression, etc.)",
      globalInfo: "ALL",
    },
    {
      type: "Batch",
      description: "Logs des batchs de traitements divers",
      globalInfo: "INFO",
    },
  ];

  const columns = [
    {
      title: t("ref:cl", "Type"),
      field: "type",
      show: true,
      alignSelf: "left",
    },
    {
      title: t("ref:co", "Description"),
      field: "description",
      show: true,
      alignSelf: "left",
    },
    {
      title: t("ref:creationDate", "Niveau d'info global"),
      field: "globalInfo",
      show: true,
      alignSelf: "center",
    },
  ];

  useEffect(() => {
    updateShowFilters(false);
    let filtersTemp;
    if (entityUid)
      filtersTemp = {
        entityUid,
      };

    setPaymentSignatureFilters(filtersTemp);
    setPaymentSignaturePage(1);
    getPaymentSignaturePage(1, filtersTemp, sortQuery, reverse, pageSize);
  }, [getPaymentSignaturePage, entityUid, pageSize, usersManagementUpdate]);

  const isEmptyFilters = () => {
    const filtersList = columns.map((column) => column.field);
    let empty = true;
    filtersList.forEach((filter) => {
      if (filtersQuery[filter]) empty = false;
    });
    return Object.keys(filtersQuery).length === 0 || empty;
  };

  useEffect(() => {
    const isEmpty = isEmptyFilters();
    if (!showFilters && !isEmpty) {
      handleReset();
    }
  }, [showFilters]);

  const handleShowFilters = () => {
    updateShowFilters(!showFilters);
  };

  const formatDate = useCallback((date) => {
    if (date !== null && date.valueOf() > 0) {
      let newDate = new Date(date);
      let day =
        newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
      let month =
        newDate.getMonth() + 1 < 10
          ? "0" + (newDate.getMonth() + 1)
          : newDate.getMonth() + 1;
      return day + "/" + month + "/" + newDate.getFullYear();
    } else return null;
  }, []);

  // const handleChangePage = useCallback(
  //   (newPage) => {
  //     //e.preventDefault();
  //     setPaymentSignaturePage(newPage);
  //     getPaymentSignaturePage(
  //       newPage,
  //       filtersQuery,
  //       sortQuery,
  //       reverse,
  //       pageSize
  //     );
  //   },
  //   [filtersQuery, reverse, sortQuery, pageSize]
  // );

  const handleSort = useCallback(
    (sort, newReverse) => {
      getPaymentSignaturePage(
        currentPage,
        filtersQuery,
        sort,
        newReverse,
        pageSize
      );
    },
    [currentPage, filtersQuery, sortQuery, reverse, pageSize]
  );

  // const handleApplyFiltering = useCallback(
  //   (filters) => {
  //     getPaymentSignaturePage(1, filters, sortQuery, reverse, pageSize);
  //   },
  //   [currentPage, filtersQuery, reverse, sortQuery, pageSize]
  // );

  const handleReset = useCallback(() => {
    let filtersTemp;
    if (entityUid)
      filtersTemp = {
        entityUid,
      };
    else
      filtersTemp = {
        entityFilter: userType,
      };
    setSortQuery("creationDate");
    setReverse({ creationDate: false });
    setPaymentSignatureFilters(filtersTemp);
    setPaymentSignaturePage(1);
    emptySelectedPaymentSignature();
    getPaymentSignaturePage(
      1,
      filtersTemp,
      "creationDate",
      { creationDate: false },
      pageSize
    );
  }, []);

  // const getQuickFilterLabel = () => {
  //   return (
  //     dateFilterOptions?.find((val) => val.value === "")?.label ||
  //     dateFilterOptions[0].label
  //   );
  // };

  // const setUsersType = (type) => {
  //   let filtersTemp = {
  //     usersType: type,
  //   };
  //   setSortQuery("creationDate");
  //   setReverse({ creationDate: false });
  //   setPaymentSignatureFilters(filtersTemp);
  //   setPaymentSignaturePage(1);
  //   emptySelectedPaymentSignature();
  //   getPaymentSignaturePage(
  //     1,
  //     filtersTemp,
  //     "creationDate",
  //     { creationDate: false },
  //     pageSize
  //   );
  // };

  const enableUser = (userUid) => {
    Axios.put(`user/${userUid}/enableUser`)
      .then((response) => {
        if (response.data.hasOwnProperty("success")) {
          notyf.success(t("default:userAdded", "L'utilisateur a été activé"));
          getPaymentSignaturePage(
            currentPage,
            filtersQuery,
            sortQuery,
            reverse,
            pageSize
          );
        }
      })
      .catch((response) => {
        notyf.error(
          t("mandat:generatedErrorMessage", "Une erreur s'est produite")
        );
      });
  };

  const disableUser = (userUid) => {
    Axios.put(`user/${userUid}/disableUser`)
      .then((response) => {
        if (response.data.hasOwnProperty("success")) {
          notyf.success(
            t("default:userAdded", "L'utilisateur a été désactivé")
          );
          getPaymentSignaturePage(
            currentPage,
            filtersQuery,
            sortQuery,
            reverse,
            pageSize
          );
        }
      })
      .catch((response) => {
        notyf.error(
          t("mandat:generatedErrorMessage", "Une erreur s'est produite")
        );
      });
  };
  //console.log(showDownloadLogsModal);
  let tBody = null;
  let tSpinner = loading ? (
    <SpinnerContainer>
      <Spinner animation="border" variant="primary" />
    </SpinnerContainer>
  ) : (
    <NoContent>
      <span>Liste vide</span>
    </NoContent>
  );
  
  // if (!loading && usersData?.length > 0) {
  if (!loading) {
    tSpinner = null;
    tBody = (
      <TableBody
        columns={columns}
        data={localData}
        contact={contact}
        contactEntity={contactEntity}
        formatDate={formatDate}
        selectedUsers={selectedPaymentSignature}
        selectUserRow={selectPaymentSignatureRow}
        unselectUserRow={selectPaymentSignatureRow}
        enableUser={enableUser}
        disableUser={disableUser}
        entityUid={entityUid}
        setUsersManagementUpdate={setUsersManagementUpdate}
        usersManagementUpdate={usersManagementUpdate}
        setConfirmationUserModalShow={setConfirmationUserModalShow}
        initUserModal={initUserModal}
        setNewUserModal={setNewUserModal}
      />
    );
  }
  return (
    <UMContainer>
      <TitleHelmet title={"Demo Fournisseur | " + t("menu:userManagement")} />
      <Header>
        <HeaderInfos>
          <HeaderLocation>{t("menu:cli", "Monitoring")}</HeaderLocation>
          <HeaderTitle>Consultation des logs</HeaderTitle>
        </HeaderInfos>
        <HeaderActions>
          <PrimaryBtnOutlineAction
            onClick={(e) => setShowDownloadLogsModal(true)}
          >
            <span>{t("Télécharger tous les logs depuis...")}</span>
            {/* <AddIcon /> */}
          </PrimaryBtnOutlineAction>
          <TableActions
            // resetFilters={resetFilters}
            // setShowDownloadLogsModal={setShowDownloadLogsModal}
            // showDownloadLogsModal={showDownloadLogsModal}
            showFilters={showFilters}
            setShowFilters={handleShowFilters}
            userType={userType}
          />
        </HeaderActions>
      </Header>
      <UMBody>
        {/* <TablePagination
          currentPage={currentPage}
          pageChange={handleChangePage}
          totalElement={count}
          perPage={pageSize}
          perPageChange={setPaymentSignaturePageSize}
        /> */}
        <Table>
          <TableHead
            columns={columns}
            sortQuery={sortQuery}
            reverse={reverse}
            setSortQuery={setSortQuery}
            setReverse={setReverse}
            getData={handleSort}
            selectedAllRows={selectedAllRows}
            setSelectedAllRows={selectAllPaymentSignatureRows}
          />
          <TBody>
            {/* <TableFilters
              userType={userType}
              show={showFilters}
              formatDate={formatDate}
              filters={filtersQuery}
              filtersChange={setPaymentSignatureFilters}
              applyFilters={handleApplyFiltering}
            /> */}
            {tBody}
          </TBody>
        </Table>
        {tSpinner}
        {/* <TablePagination
          currentPage={currentPage}
          pageChange={handleChangePage}
          totalElement={count}
          perPage={pageSize}
          perPageChange={setPaymentSignaturePageSize}
        /> */}
      </UMBody>
      {/* {showDownloadLogsModal && ( */}
      {showDownloadLogsModal && (
        <ModalDownload
          handleClose={() => setShowDownloadLogsModal(false)}
          showDownloadLogsModal={showDownloadLogsModal}
          setShowDownloadLogsModal={setShowDownloadLogsModal}
        />
      )}
      {/* )} */}
    </UMContainer>
  );
}
const mapStateToProps = (state) => ({
  isAuth: state.auth.token !== null,
  token: state.auth.token,
  loading: state.paymentSignature.loading,
  usersData: state.paymentSignature.data,
  count: state.paymentSignature.count,
  currentPage: state.paymentSignature.currentPage,
  pageSize: state.paymentSignature.pageSize,
  filtersQuery: state.paymentSignature.filtersQuery,
  filtering: state.paymentSignature.filtering,
  sortQuery: state.paymentSignature.sortQuery,
  reverse: state.paymentSignature.reverse,
  selectedPaymentSignature: state.paymentSignature.selectedPaymentSignature,
  showFilters: state.paymentSignature.showFilters,
  selectedAllRows: state.paymentSignature.selectedAllRows,
  userType: state.auth.userType,
});
const mapDispatchToProps = (dispatch) => ({
  onTryAutoSignup: () => dispatch(actions.authCheckState()),
  getPaymentSignaturePage: (page, filters, sort, reverse, pageSize) =>
    dispatch(
      actions.paymentSignatureGetPage(page, filters, sort, reverse, pageSize)
    ),
  setPaymentSignaturePage: (page) =>
    dispatch(actions.paymentSignatureSetPage(page)),
  setPaymentSignaturePageSize: (pageSize) =>
    dispatch(actions.paymentSignatureSetPageSize(pageSize)),
  setPaymentSignatureFilters: (filters) =>
    dispatch(actions.paymentSignatureSetFilterQuery(filters)),
  setSortQuery: (sortQ) =>
    dispatch(actions.paymentSignatureSetSortQuery(sortQ)),
  setReverse: (reverseS) =>
    dispatch(actions.paymentSignatureSetReverseSort(reverseS)),
  updateShowFilters: (show) =>
    dispatch(actions.paymentSignatureUpdateShowFilters(show)),
  selectAllPaymentSignatureRows: (selectAll) =>
    dispatch(actions.setSelectedAllPaymentSignatureRows(selectAll)),
  selectPaymentSignatureRow: (uid) =>
    dispatch(actions.selectPaymentSignature(uid)),
  unselectPaymentSignatureRow: (uid) =>
    dispatch(actions.unselectPaymentSignature(uid)),
  getCompressedDoc: (selection) =>
    dispatch(actions.invoiceGetCompressedDocument(selection)),
  emptySelectedPaymentSignature: () =>
    dispatch(actions.emptySelectedPaymentSignature()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ConsultationLogs);
