import styled from 'styled-components';

export const ISContainer = styled.div`
    width: 50%;
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 18px;
    padding: 1rem 1rem;
    height: 700px;
    display: flex;
    flex-direction: column;
`

export const ISNavContainer = styled.div`
    width: 100%;
    margin-bottom: 1.2rem;
`

export const ISNavContent = styled.div`
    width: 100%;
    height: 90%;
    flex: 1;
`