import React, { useMemo } from 'react';
import { formatDate } from '../../../shared/utility';
import { DProgressLine, DProgressLineBar, DProgressLineItem, DProgressLineItemTitle, DProgressLineItemValue } from './DateProgressLine.styled';

function DateProgressLine({creationDate, campaignDate, endDate}) {
    const PLItems = useMemo(() => [
        {title: 'Création', value: new Date(+creationDate).setHours(0,0,0,0)},
        {title: 'Début', value: +campaignDate},
        {title: 'Fin', value: +endDate},
    ], [creationDate, campaignDate, endDate]);
    const currentDate = useMemo(() => new Date().setHours(23, 59, 59, 0), []);

  return (
    <DProgressLine>
        {
            PLItems.map((item, index) => (
                <DProgressLineItem key={index}>
                    <DProgressLineItemTitle>
                        <span>{item.title} : </span>
                        {
                            currentDate >= item.value && (
                                <DProgressLineBar 
                                    isFirst={index === 0}
                                    isCurrent={currentDate < PLItems[index + 1]?.value } 
                                ></DProgressLineBar>
                            )
                        }
                    </DProgressLineItemTitle>
                    <DProgressLineItemValue>
                        <span>{formatDate(item.value)}</span>
                        {
                            (
                                currentDate >= PLItems[index + 1]?.value
                                ||   currentDate >= endDate
                            ) && (
                                <DProgressLineBar
                                    isLast={index === PLItems.length - 1}
                                ></DProgressLineBar>
                            )
                        }
                    </DProgressLineItemValue>
                </DProgressLineItem>
            ))
        }
    </DProgressLine>
  );
}

export default DateProgressLine;
