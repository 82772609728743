import React, { useCallback, useState } from 'react'
import { formatDate,showColumn } from '../../../shared/utility';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';
import { useTranslation } from 'react-i18next';
import DateFilter from '../../UI/DateFilter/DateFilter';
import AmountFilter from '../../UI/AmountFilter/AmountFilter';
import Axios from '../../../axios-proas'
function TableFilters(props) {
    const {t} = useTranslation();
    const {showFilters, reportingFilters, filtersChange,columns,amountBTTotal} = props;
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')
    const [amountAT, setAmountAT] = useState(null)
    const [rangeShow, setRangeShow] = useState(false);
    const [dateInputValue,setDateInputValue] = useState({
        creationDate: reportingFilters?.creationDate ? convertDate(reportingFilters.creationDate) : ''
    });

    function convertDate (date){
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    const closeModalHandler = () => {
        setShow(false);
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...reportingFilters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
       // applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...reportingFilters,
            [field]: null
        }
        filtersChange(newFilters)
       // applyFilters(newFilters);
    }

    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...reportingFilters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
       // applyFilters(newFilters)
    }

    const getCreationdate = () => {
        return reportingFilters?.creationDate ? convertDate(reportingFilters.creationDate) : [];
    }
    const getInvoicingEndDate = () => {
        return reportingFilters?.invoicingEndDate ? convertDate(reportingFilters.invoicingEndDate) : [];
    }
    const getInvoicingStartDate  = () => {
        return reportingFilters?.invoicingStartDate ? convertDate(reportingFilters.invoicingStartDate) : [];
    }
 // exclusiveTaxAmountTotal invoicingEndDate invoicingStartDate  type issuingDate'
 const amountAtChanged = (startval, endval) => {
    setAmountAT({
        start: startval,
        end: endval
    })
    let newFilters = {
        ...reportingFilters,
        exclusiveTaxAmountTotal: startval + ':' + endval
    };
    filtersChange(newFilters);
    //applyFilters(newFilters);
}

const closeAmountModal = () => {
    setRangeShow(false);
}
const rangeATModalHandler = () =>{
    setRangeShow(true);
    getAmountATValues();
}

const getAmountATValues = () => {
    Axios.get("ereporting//amountATValuesReporting").then(response => {
        setAmountAT({
          start: +response.data.minAmountAT,
           end: +response.data.maxAmountAT
     })
  })

}
const getAmountAT = (field) => {
    if(reportingFilters?.exclusiveTaxAmountTotal){
        let amount = reportingFilters?.exclusiveTaxAmountTotal.split(":");
        if (field === "start")
            return amount[0]
        else return amount[1]
    }else{
        return '';
    }   
}
const amountATReset = () => {
    let newFilters = {
        ...reportingFilters,
        exclusiveTaxAmountTotal: null
    };
    filtersChange(newFilters);
    //applyFilters(newFilters);
}
    return (
        <>
            <tr className="tableFilters" style={{display: showFilters ? 'table-row' : 'none'}}>
                
                <TD style={{display: !showColumn(columns,"type") && 'none'}}>
                <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={reportingFilters?.type || ''} 
                            name="type" 
                        >
                            <Option value=""></Option>
                            <Option value="RECEIVED">Reçu</Option>
                            <Option value="SENT">Envoyé</Option>
                         
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"issuingDate") && 'none'}}>
                <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:creationDate", "Date de Création")}
                            from={getCreationdate()[0] || ''}
                            to={getCreationdate()[1] || ''}
                            name="creationDate"
                            activeReset={reportingFilters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"invoicingStartDate") && 'none'}}>
                <FilterContainer>
                        <DateFilter
                            modalTitle={t("", "date debut facturation")}
                            from={getInvoicingStartDate()[0] || ''}
                            to={getInvoicingStartDate()[1] || ''}
                            name="invoicingStartDate"
                            activeReset={reportingFilters?.invoicingStartDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.invoicingStartDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"invoicingEndDate") && 'none'}}>
                <FilterContainer>
                        <DateFilter
                            modalTitle={t("", "date fin facturation")}
                            from={getInvoicingEndDate()[0] || ''}
                            to={getInvoicingEndDate()[1] || ''}
                            name="invoicingEndDate"
                            activeReset={reportingFilters?.invoicingEndDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.invoicingEndDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"exclusiveTaxAmountTotal") && 'none'}}>
                <FilterContainer>
                        <AmountFilter
                            modalTitle={t('tableHead:exclusiveTaxAmountTotal',"Montant TTC" ) } 
                            from={getAmountAT("start") || ''}
                            to={getAmountAT("end") || ''}
                            total={amountBTTotal}
                            name="exclusiveTaxAmountTotal"
                            activeReset={reportingFilters?.exclusiveTaxAmountTotal ? true : false} 
                            resetInterval={amountATReset}
                            defaultVal={amountAT} 
                            defaultValStart={+getAmountAT("start") || amountAT?.start} 
                            defaultValEnd={+getAmountAT("end") || amountAT?.end}   
                            defaultUpdateVal={300} 
                            value={getAmountAT}
                            show={rangeShow}  
                            showModal={rangeATModalHandler} 
                            closeModal={closeAmountModal} 
                            amountChanged={amountAtChanged}
                        />
                    </FilterContainer>
                </TD>
                <td></td>
            </tr>
        </>
    )
}

export default TableFilters
