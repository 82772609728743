import React, { useRef, useState } from 'react'
import { Link} from 'react-router-dom';
import {CTooltip} from '../../UI/CTooltip/CTooltip';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { TD } from '../../../styles/Table.styled';
import { useTheme } from 'styled-components';
import { getAlignPosition } from '../../../shared/utility'
import { UserStatus } from '../../../containers/UsersManagement/UsersManagement.styled';
import CancelIcon from '@mui/icons-material/Cancel';

import Axios from '../../../axios-proas';

function TableBody(props) {
    const {
        data, 
        selectedUsers,
        selectUserRow,
        unselectUserRow,
        disableUser,
        enableUser,
        columns,
        entityUid,
        setConfirmationUserModalShow,
        setUsersManagementUpdate,
        usersManagementUpdate,
        setNewUserModal,
        initUserModal,
        DeleteUser
    } = props;
    let textRef = useRef();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [userToDisable, setUserToDisable] = useState(null)
    const {t} = useTranslation();
    const theme = useTheme();
    const [confirmationDeleteModalShow, setConfirmationDeleteModalShow] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null)

    const formatNumber = (number) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.',' ')
    }

    const getSelectedRowValue = (userUid) => {
        let isSelected = selectedUsers.findIndex(uid => uid === userUid) >= 0;
        // console.log(selectedUsers)
        // console.log(isSelected)
        return isSelected
    }

    const selectRowHandler = (e) => {
        let uid = e.target.name;
        let val = e.target.checked;
        if(val)
            selectUserRow(uid)
        else
            unselectUserRow(uid)
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        return hour + ":" + minutes
    }

    const disableUserClickHandler = (userUid, userEmail) => {
        setUserToDisable({userUid, userEmail})
        setConfirmationModalShow(true);
    }
    const deleteUserClickHandler = (userUid, userEmail) => {
        setUserToDelete({userUid, userEmail})
        setConfirmationDeleteModalShow(true);
    }
    
    const getTypeName = (type) => {
        switch (type) {
            case "owner":
                return "Interne"
                break;
            case "client":
                return "Client"
                break;
            case "supplier":
                return "Fournisseur"
                break;
            default:
                break;
        }
    }

    const showUserModal = (userUid) => {
        initUserModal(userUid)
        setConfirmationUserModalShow(true)
        setNewUserModal(false)
    }

    const getLevelName = (level) => {
        switch (level) {
            case "admin":
                return "Admin"
                break;
            case "standard":
                return "Standard"
                break;
            default:
                break;
        }
    }
    
    return (
        <>    
            {
                data.map(row => (
                    <tr key={row.id} >
                        <TD style={{textAlign:'center'}}>
                            <input 
                                type="checkbox" 
                                name={row.userUid} 
                                onChange={(e) => selectRowHandler(e)} 
                                checked={getSelectedRowValue(row?.userUid)}  
                                className="form-check-input" 
                                style={{margin: 0}} 
                                id="exampleCheck1" 
                            />
                        </TD>
                        <TD style={{textAlign: getAlignPosition(columns, 'first_name')}}>
                            {row.first_name}
                        </TD>
                        <TD style={{textAlign: getAlignPosition(columns, 'last_name')}}>
                            {row.last_name}
                        </TD>
                        <TD style={{textAlign: getAlignPosition(columns, 'login')}}>
                            {row.login}
                        </TD>
                        <TD style={{textAlign: getAlignPosition(columns, 'telephone_number')}}>
                            {row.telephone_number}
                        </TD>
                        <TD >
                            <UserStatus
                                bg={Number(row?.pending) ? theme.colors.warning : (Number(row?.active) === 1 ? theme.colors.greenLight : theme.colors.redLight)}
                                color={Number(row?.pending) ? "black" : (Number(row?.active) === 1 ? theme.colors.success : theme.colors.danger)}
                            >
                                {Number(row?.pending) ? "En attente" : (Number(row?.active) === 1 ? "Oui" : "Non")}
                            </UserStatus>
                        </TD>
                        <TD style={{textAlign: getAlignPosition(columns, 'type')}}>
                            {getTypeName(row?.type)}
                        </TD>
                        <TD style={{textAlign: getAlignPosition(columns, 'level')}}>
                            {getLevelName(row?.level)}
                        </TD>
                        <TD  >
                            <CTooltip title={row.entities} className="tableActions__icon">
                                <span ref={textRef}>
                                    {row?.entities}
                                </span>
                            </CTooltip>
                        </TD>
                        <TD className="userManag_td">
                            {/* <Link 
                                // to={`/userForm/${row.userUid}`}
                                style={{
                                    color: theme.colors.warning
                                }}
                            > */}
                                <CTooltip title={t('tableBody:edit',"Modifier" )}  className="tableActions__icon">
                                    <EditIcon className="editIcon" fontSize="small" style={{color:"#2174B9", cursor: "pointer"}} onClick={e => showUserModal(row?.userUid)}/>
                                </CTooltip>
                            {/* </Link> */}
                            {
                                Number(row?.active) === 1 ? (
                                    <CTooltip title={t('tableBody:disable',"Désactiver" )}  className="tableActions__icon">
                                        <ToggleOnIcon 
                                            style={{marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer'}} 
                                            fontSize="small"
                                            onClick={() => disableUserClickHandler(row?.userUid, row?.login)}
                                        />
                                    </CTooltip>
                                ) : 
                                    <CTooltip title={t('tableBody:enable',"Activer" )}  className="tableActions__icon">
                                        <ToggleOffIcon 
                                            style={{marginLeft: '0.25rem', cursor: 'pointer'}} 
                                            fontSize="small"
                                            onClick={() => enableUser(row?.userUid)}
                                        />
                                    </CTooltip>
                            }
                            <CancelIcon 
                                style={{marginLeft: '0.25rem', color: theme.colors.danger, cursor: 'pointer'}} 
                                fontSize="small" 
                                onClick={() => deleteUserClickHandler(row?.userUid, row?.login)}
                            />

                        </TD>
                    </tr>
                    
                ))
                
            }
            
            <ConfirmationModal 
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={() => disableUser(userToDisable?.userUid)}
                cancel={() => setConfirmationModalShow(false)}
                title={"Confirmation de désactivation"}
                message={`Êtes-vous sûr de vouloir désactiver cet utilisateur (${userToDisable?.userEmail})?`}
            />
               <ConfirmationModal 
                show={confirmationDeleteModalShow}
                modalClosed={() => setConfirmationDeleteModalShow(false)}
                confirm={() => DeleteUser(userToDelete?.userUid)}
                cancel={() => setConfirmationDeleteModalShow(false)}
                title={"Confirmation de désactivation"}
                message={`Êtes-vous sûr de vouloir supprimer cet utilisateur (${userToDelete?.userEmail})?`}
            />
        </>
    )
}

export default TableBody
