import React, { useCallback, useEffect, useState } from "react";
import Axios from "../../axios-proas";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import * as actions from "../../store/actions/index";
import { useTranslation } from "react-i18next";
import TableActions from "../../components/LogTypeDetail/TableActions/TableActions";
import TableHead from "../../components/LogTypeDetail/TableHead/TableHead";
import TableBody from "../../components/LogTypeDetail/TableBody/TableBody";
import TableFilters from "../../components/LogTypeDetail/TableFilters/TableFilters";
import TablePagination from "../../components/UI/TablePagination/TablePagination";
import TitleHelmet from "../../components/UI/TitleHelmet/TitleHelmet";
import { checkValidity, getNotyfObject } from "../../shared/utility";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  NoContent,
  SpinnerContainer,
  Table,
  TBody,
  SearchInputContainer,
  SearchInput,
} from "../../styles/Table.styled";
import {
  Header,
  HeaderActions,
  HeaderInfos,
  HeaderLocation,
  HeaderTitle,
  PrimaryBtnOutlineAction,
  SecondaryBtnOutlineAction,
} from "../../styles/Common";
import {
  ButtonGrep,
  ContainerButtonGrep,
  ContainerGrep,
  ContainerNumberGrep,
  InputGrep,
  LogContainer,
  MySelect,
  NavTabsLi,
  NavTabsLink,
  NavTabsUl,
  P,
  UMBody,
  UMContainer,
} from "./LogDetail.styled";
import SelectPeriod from "../../components/UI/SelectPeriod/SelectPeriod";
import ModalDownload from "../../components/ConsultationLogs/TableActions/ModalDownload/ModalDownload";
import ListLog from "../../components/LogDetail/ListLog";
import TableLog from "../../components/LogDetail/TableLog";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

function LogDetail(props) {
  let { filtersQuery } = props;
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [showDownloadLogsModal, setShowDownloadLogsModal] = useState(false);
  const [showGrep, setShowGrep] = useState(false);
  const [logs, setLogs] = useState([]);
  const [tmpLogs, setTmpLogs] = useState([]);
  const [partielLogs, setPartielLogs] = useState([]);
  const [nbline, setNbline] = useState(1);
  const [tmpNbline, setTmpNbline] = useState(1);

  const [navTabsList, setNavTabsList] = useState([
    { label: "Fichier partiel", value: "partiel" },
    { label: "Fichier entier", value: "all" },
    { label: "Mise en forme", value: "table" },
  ]);
  const [activeTab, setActiveTab] = useState("partiel");
  const [grepInput, setGrepInput] = useState("");
  const [tmpGrepInput, setTmpGrepInput] = useState("");

  const localData = [
    { line: "1", date: "2021-09-01 10:00:00" },
    { line: "2", date: "2021-09-01 10:00:00" },
    { line: "3", date: "2021-09-01 10:00:00" },
    { line: "4", date: "2021-09-01 10:00:00" },
    { line: "5", date: "2021-09-01 10:00:00" },
    { line: "6", date: "2021-09-01 10:00:00" },
    { line: "7", date: "2021-09-01 10:00:00" },
    { line: "8", date: "2021-09-01 10:00:00" },
  ];
  const fileData = {
    date: "09/11/2022",
    physicalPath: "C:/tomcatlogscatalina.2022-09-01.log",
    size: "217o",
  };
  const typeData = {
    type: "serveur d'application",
    description: "Logs du serveur d'application",
    globalInfo: "INFO",
  };

  const getLogs = () => {
    Axios.get("/filelogs//allLogs")
      .then((res) => {
        //console.log(res.data);
        setLogs(res.data);
        setTmpLogs(res.data);
        setPartielLogs(res.data.slice(0, 15));
      })
      .catch((res) => {
        notyf.error("Pas de logs");
      });
  };

  useEffect(() => {
    getLogs();
  }, []);

  const onGrepHandle = () => {
    setTmpGrepInput(grepInput);
    if (grepInput !== "") {
      afterGrep();
      setShowGrep(!showGrep);
    } else {
      setTmpLogs(logs);
      setPartielLogs(logs.slice(0, 15));
      setShowGrep(!showGrep);
      setNbline(logs.length);
    }
    setTmpNbline(nbline);
  };

  const afterGrep = () => {
    let indexTab = [];
    let res = [];
    if (grepInput !== "") {
      logs.forEach((log, index) => {
        if (log.message.includes(grepInput)) {
          indexTab = [...indexTab, index];
        }
      });

      indexTab.forEach((index) => {
        for (let i = 0; i <= nbline; i++) {
          if (
            (!res.message || !res.message.includes(logs[index + i].message)) &&
            logs.length > index + i
          ) {
            res.push(logs[index + i]);
          }
        }
      });
    }
    //console.log(res);
    setTmpLogs(res);
    setPartielLogs(res);
  };

  const closeGrepHandle = () => {
    setTmpLogs(logs);
    setGrepInput("");
    setTmpGrepInput("");
    setNbline(0);
    setShowGrep(!showGrep);
  };

  return (
    <UMContainer>
      <TitleHelmet title={"Demo Fournisseur | " + t("menu:userManagement")} />
      <Header>
        <HeaderInfos>
          <HeaderLocation>
            {t("menu:cli", "Logs : " + typeData?.type)}
            <p>{fileData?.updateShowFilters}</p>
          </HeaderLocation>
          <HeaderLocation>
          {fileData?.date}
          
          </HeaderLocation>
          <HeaderTitle>Chemin physique: {fileData?.physicalPath}</HeaderTitle>
      
        </HeaderInfos>
        <HeaderActions>
          <div>
            <SecondaryBtnOutlineAction onClick={(e) => setShowGrep(!showGrep)}>
              <span style={{ paddingRight: "10px" }}>Grep</span>
              <SearchIcon className="ICDBlue" />
            </SecondaryBtnOutlineAction>
            {showGrep && (
              <MySelect>
                <ContainerGrep style={{ display: "block" }}>
                  <P>Recherche Grep (Case sensitive)</P>
                  <InputGrep
                    onChange={(e) => setGrepInput(e.target.value)}
                    style={{ width: "100%" }}
                    type="text"
                    value={grepInput}
                  />
                </ContainerGrep>
                <ContainerGrep>
                  <ContainerNumberGrep>
                    <P style={{ paddingRight: "5px" }}>
                      Nombre de lignes après:
                    </P>
                    <InputGrep
                      onChange={(e) => setNbline(e.target.value)}
                      value={nbline}
                      style={{ width: "125px" }}
                      type="number"
                    />
                  </ContainerNumberGrep>
                </ContainerGrep>
                <ContainerButtonGrep>
                  <ButtonGrep>
                    <CheckIcon
                      style={{ width: "99%" }}
                      onClick={() => onGrepHandle()}
                    />
                  </ButtonGrep>
                  <ButtonGrep>
                    <CloseIcon
                      style={{ width: "99%" }}
                      onClick={() => closeGrepHandle()}
                    />
                  </ButtonGrep>
                </ContainerButtonGrep>
              </MySelect>
            )}
          </div>
          <PrimaryBtnOutlineAction
            onClick={(e) => setShowDownloadLogsModal(true)}
          >
            <span>{t("Télécharger")}</span>
            <TableActions />
          </PrimaryBtnOutlineAction>

          <SecondaryBtnOutlineAction onClick={(e) => navigate(-1)}>
            Retour
          </SecondaryBtnOutlineAction>
        </HeaderActions>
      </Header>
      <UMBody>
        <NavTabsUl>
          {navTabsList.map((t, index) => (
            <NavTabsLi key={index}>
              <NavTabsLink
                active={activeTab === t.value}
                onClick={() => setActiveTab(t.value)}
                to={`#`}
              >
                {t.label}
              </NavTabsLink>
            </NavTabsLi>
          ))}
        </NavTabsUl>
        <LogContainer>
          {activeTab === "table" ? (
            <TableLog
              logData={tmpLogs}
              nbline={tmpNbline}
              grep={tmpGrepInput}
            />
          ) : activeTab === "all" ? (
            <ListLog logData={tmpLogs} nbline={tmpNbline} grep={tmpGrepInput} />
          ) : (
            <ListLog
              logData={partielLogs}
              nbline={tmpNbline}
              grep={tmpGrepInput}
            />
          )}
        </LogContainer>
      </UMBody>
      {showDownloadLogsModal && (
        <ModalDownload
          handleClose={() => setShowDownloadLogsModal(false)}
          showDownloadLogsModal={showDownloadLogsModal}
          setShowDownloadLogsModal={setShowDownloadLogsModal}
        />
      )}
    </UMContainer>
  );
}
const mapStateToProps = (state) => ({
  isAuth: state.auth.token !== null,
  token: state.auth.token,
  loading: state.paymentSignature.loading,
  usersData: state.paymentSignature.data,
  count: state.paymentSignature.count,
  currentPage: state.paymentSignature.currentPage,
  pageSize: state.paymentSignature.pageSize,
  filtersQuery: state.paymentSignature.filtersQuery,
  filtering: state.paymentSignature.filtering,
  sortQuery: state.paymentSignature.sortQuery,
  reverse: state.paymentSignature.reverse,
  selectedPaymentSignature: state.paymentSignature.selectedPaymentSignature,
  showFilters: state.paymentSignature.showFilters,
  selectedAllRows: state.paymentSignature.selectedAllRows,
  userType: state.auth.userType,
});
const mapDispatchToProps = (dispatch) => ({
  onTryAutoSignup: () => dispatch(actions.authCheckState()),
  getPaymentSignaturePage: (page, filters, sort, reverse, pageSize) =>
    dispatch(
      actions.paymentSignatureGetPage(page, filters, sort, reverse, pageSize)
    ),
  setPaymentSignaturePage: (page) =>
    dispatch(actions.paymentSignatureSetPage(page)),
  setPaymentSignaturePageSize: (pageSize) =>
    dispatch(actions.paymentSignatureSetPageSize(pageSize)),
  setPaymentSignatureFilters: (filters) =>
    dispatch(actions.paymentSignatureSetFilterQuery(filters)),
  setSortQuery: (sortQ) =>
    dispatch(actions.paymentSignatureSetSortQuery(sortQ)),
  setReverse: (reverseS) =>
    dispatch(actions.paymentSignatureSetReverseSort(reverseS)),
  updateShowFilters: (show) =>
    dispatch(actions.paymentSignatureUpdateShowFilters(show)),
  selectAllPaymentSignatureRows: (selectAll) =>
    dispatch(actions.setSelectedAllPaymentSignatureRows(selectAll)),
  selectPaymentSignatureRow: (uid) =>
    dispatch(actions.selectPaymentSignature(uid)),
  unselectPaymentSignatureRow: (uid) =>
    dispatch(actions.unselectPaymentSignature(uid)),
  getCompressedDoc: (selection) =>
    dispatch(actions.invoiceGetCompressedDocument(selection)),
  emptySelectedPaymentSignature: () =>
    dispatch(actions.emptySelectedPaymentSignature()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LogDetail);
