import React, { useCallback, useEffect, useState } from "react";
import Axios from "../../axios-proas";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import * as actions from "../../store/actions/index";
import { useTranslation } from "react-i18next";
import { Table, TableContainer, TBody, TD, TH, THead } from "./TabLog.styled";
import theme from "../../styles/theme";

function TableLog({ logData, grep }) {
  const { t } = useTranslation();

  const color = (type) => {
    switch (type) {
      case "200": //info
        return "#00B8B8";
      case "304": //warning
        return "#FF5733";
      case "ERROR":
        return "#EE5A5A";
      case "DEBUG":
        return "#2174B9";
      case "TRACE":
        return "#00B8B8";
      case "FATAL":
        return "#EE5A5Aed";

      default:
        return "black";
    }
  };

  const colorWord = (text) => {
    // console.log(txt, "txt");
    // console.log(txt[0], "txt[0]");
    // console.log(txt[1], "txt[1]");
    if (text.includes(grep)) {
      let txt = text.split(grep);
      let lastIndex = txt.length - 1;

      return txt.map((e, index) => {
        return (
          <>
            {e}
            {index != lastIndex && (
              <span style={{ color: theme.colors.danger }}>{grep}</span>
            )}
          </>
        );
      });
    } else {
      return text;
    }
  };

  return (
    <>
      {logData ? (
        <Table style={{fontSize:"0.9rem"}}>
          <THead>
            <tr>
              <TH></TH>
              <TH style={{ textAlign: "center" }}>Timestamp</TH>
              <TH>Level</TH>
              <TH style={{ textAlign: "center", padding: "0 5px" }}>Thread</TH>
              <TH>Threadname</TH>
              <TH style={{ width: "50%" }}>Message</TH>
            </tr>
          </THead>
          <TBody>
            {logData.map((log, index) => (
              <tr key={index}>
                <TD style={{ width: "30px" }} colorB={color(log.response)}></TD>
                <TD style={{ textAlign: "center" }}>{log.date}</TD>
                <TD>{log.response === "200" ? "INFO" : "WARN"}</TD>
                <TD style={{ textAlign: "center", padding: "0" }}>ICD</TD>
                <TD>Accesslog</TD>
                <TD>{grep !== "" ? colorWord(log.message) : log.message}</TD>
              </tr>
            ))}
          </TBody>
        </Table>
      ) : (
        <p>No logs data</p>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  isAuth: state.auth.token !== null,
  token: state.auth.token,
  loading: state.paymentSignature.loading,
  usersData: state.paymentSignature.data,
  count: state.paymentSignature.count,
  currentPage: state.paymentSignature.currentPage,
  pageSize: state.paymentSignature.pageSize,
  filtersQuery: state.paymentSignature.filtersQuery,
  filtering: state.paymentSignature.filtering,
  sortQuery: state.paymentSignature.sortQuery,
  reverse: state.paymentSignature.reverse,
  selectedPaymentSignature: state.paymentSignature.selectedPaymentSignature,
  showFilters: state.paymentSignature.showFilters,
  selectedAllRows: state.paymentSignature.selectedAllRows,
  userType: state.auth.userType,
});
const mapDispatchToProps = (dispatch) => ({
  onTryAutoSignup: () => dispatch(actions.authCheckState()),
  getPaymentSignaturePage: (page, filters, sort, reverse, pageSize) =>
    dispatch(
      actions.paymentSignatureGetPage(page, filters, sort, reverse, pageSize)
    ),
  setPaymentSignaturePage: (page) =>
    dispatch(actions.paymentSignatureSetPage(page)),
  setPaymentSignaturePageSize: (pageSize) =>
    dispatch(actions.paymentSignatureSetPageSize(pageSize)),
  setPaymentSignatureFilters: (filters) =>
    dispatch(actions.paymentSignatureSetFilterQuery(filters)),
  setSortQuery: (sortQ) =>
    dispatch(actions.paymentSignatureSetSortQuery(sortQ)),
  setReverse: (reverseS) =>
    dispatch(actions.paymentSignatureSetReverseSort(reverseS)),
  updateShowFilters: (show) =>
    dispatch(actions.paymentSignatureUpdateShowFilters(show)),
  selectAllPaymentSignatureRows: (selectAll) =>
    dispatch(actions.setSelectedAllPaymentSignatureRows(selectAll)),
  selectPaymentSignatureRow: (uid) =>
    dispatch(actions.selectPaymentSignature(uid)),
  unselectPaymentSignatureRow: (uid) =>
    dispatch(actions.unselectPaymentSignature(uid)),
  getCompressedDoc: (selection) =>
    dispatch(actions.invoiceGetCompressedDocument(selection)),
  emptySelectedPaymentSignature: () =>
    dispatch(actions.emptySelectedPaymentSignature()),
});
export default connect(mapStateToProps, mapDispatchToProps)(TableLog);
