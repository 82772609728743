import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "styled-components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import {
  THead,
  TH,
  THContainer,
  TD,
  Table,
  TBody,
  RBContainer,
  RButton,
  RContainer,
  RIContainer,
  RIText,
  RITextContainer,
  RITextLabelContainer,
} from "../../InvoiceListCommand.styled";
import Axios from "../../../../../../../axios-proas";

import {
  CancelBtnOutlineAction,
  PrimaryBtnOutlineAction,
} from "../../../../../../../styles/Common";

function CommandTable(props) {
  const {
    loadingData,
    invoiceData,
    invoiceError,
    commandLineData,
    setCommandLineSelected,
  } = props;
  const theme = useTheme();
  const [columns, setColumns] = useState([
    { title: "#", field: "code", show: true, alignSelf: "center" },
    { title: "N° commande", field: "name", show: true, alignSelf: "center" },
    { title: "Ref", field: "zipCode", show: true, alignSelf: "center" },
    { title: "Désignation", field: "city", show: true, alignSelf: "center" },
    { title: "Qty", field: "channel", show: true, alignSelf: "center" },
    {
      title: "Prix Unitaire",
      field: "creationDate",
      show: true,
      alignSelf: "center",
    },
    { title: "Montant HT", field: "channel", show: true, alignSelf: "center" },
  ]);
  const [update, setUpdate] = useState(false);
  const [selectedCld, setSelectedCld] = useState([]);
  const [commandesLinesNotBilled, setCommandesLinesNotBilled] = useState([]);
  const [initSearchParams, setInitSearchParams] = useState(false);

  const [searchParams, setSearchParams] = useState({
    designation: "",
    reference: "",
    commandNumber: null,
  });

  useEffect(() => {
    getCommandesLinesNotBilled();
  }, [update]);

  const getCommandesLinesNotBilled = () => {
    Axios.get(`/invoice//commandesLinesNotBilled`, { params: searchParams })
      .then((response) => {
        //dispatch(invoiceDataSuccess(response.data))
        setCommandesLinesNotBilled(response?.data);
      })
      .catch((err) => {
        // Notyf.error("Une erreur s'est produite !");
        //dispatch(invoiceDataFail(err.response?.data?.error))
      });
  };

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchParams({ ...searchParams, [name]: value });
    console.log(searchParams);
  };

  const handleCheckboxChange = (e) => {
    let idCld = e.target.name;
    let checked = e.target.checked;
    let jsk = [];
    let index = selectedCld.findIndex((x) => x.id == idCld);
    if (index >= 0) {
      jsk = selectedCld;
      jsk[index].checked = checked;
    } else {
      jsk = [...selectedCld, { id: idCld, checked: checked }];
    }

    //console.log(jsk);
    setCommandLineSelected(jsk);
    setSelectedCld(jsk);
  };

  const getSelectedRowValue = (idCld) => {
    //return selectedCld?.includes(idCld);
  };

  const floatToText = (nb) => {
    if (nb) {
      let parts = nb.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else {
      return null;
    }
  };

  let body = (
    <>
      {commandesLinesNotBilled?.map((row, index) => (
        <tr key={index}>
          <TD>
            <input
              type="checkbox"
              name={row.uid}
              onChange={handleCheckboxChange}
              checked={getSelectedRowValue(row.id)}
              className="form-check-input"
              id={row.id}
              value={row.id}
            />
          </TD>
          <TD>{row.id}</TD>
          <TD>{row.number}</TD>
          <TD>{row.ref}</TD>
          <TD>{row.designation}</TD>
          <TD>{row.quantity}</TD>
          <TD>{floatToText(+row.price)} €</TD>
          <TD>{floatToText(+row.price * +row.quantity)} €</TD>
        </tr>
      ))}
    </>
  );
  let head = (
    <THead>
      <tr key={0.1}>
        <TH
          key={10.1}
          width="50px"
          scope="col"
          style={{ border: "1px solid {theme.colors.secondary} !important;" }}
        >
          <THContainer style={{ justifyContent: "center" }}>
            <CheckIcon onClick={(e) => selectAllRowsHandler(e)} />
          </THContainer>
        </TH>
        {columns.map((column, index) => (
          <TH
            key={index}
            scope="col"
            width={column?.width}
            onClick={() => columnClickHandler(column.field, reverse)}
          >
            <THContainer style={{ justifyContent: column.alignSelf }}>
              <span>{column.title}</span>
            </THContainer>
          </TH>
        ))}
      </tr>
    </THead>
  );

  return (
    <>
      <RContainer style={{border: "1px solid #809fb8",borderRadius: "17px"}}>
        <RIContainer>
          <RIText
            placeholder="Désignation article"
            name="designation"
            style={{ color: "#505050" }}
            value={searchParams?.designation}
            onChange={(e) => onChangeHandler(e)}
          />
          <RITextContainer>
            <RITextLabelContainer>
              <label>Référence </label>
              <input
                type="text"
                value={searchParams?.reference}
                name="reference"
                style={{ padding: "3px 5px", color: "#505050" }}
                onChange={(e) => onChangeHandler(e)}
              />
            </RITextLabelContainer>
            <RITextLabelContainer>
              <label>N° commande </label>
              <input
                type="text"
                value={searchParams?.commandNumber}
                name="commandNumber"
                style={{ padding: "3px 5px", color: "#505050" }}
                onChange={(e) => onChangeHandler(e)}
              />
            </RITextLabelContainer>
          </RITextContainer>
        </RIContainer>

        <RBContainer>
          <PrimaryBtnOutlineAction style={{width: "155px"}} onClick={(e) => setUpdate(!update)}>Rechercher</PrimaryBtnOutlineAction>
          <RButton
            onClick={(e) => {
              setSearchParams({
                designation: "",
                reference: "",
                commandNumber: "",
              });
              setUpdate(!update);
            }}
            style={{
              padding: "2px",
              marginTop: "4px",
              border: "1px solid #809FB8",
              color: "#809FB8",
              width: "135px",
              marginLeft: "1rem"
            }}
          >
            Reinitialiser
          </RButton>
        </RBContainer>
      </RContainer>
      <div style={{ width: "100%",border: "1px solid #809fb8",borderRadius: "17px",padding: "12px 23px 13px 1px",marginTop: "9px"  }}>
        <Table style={{ width: "100%" }}>
          {head}
          <TBody>{body}</TBody>
        </Table>

      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CommandTable);
