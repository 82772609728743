import styled from 'styled-components';

export const ISContainer = styled.div`
    width: 100%;
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 18px;
    padding: 0.5rem 1.5rem;
    // height: 700px;
    display: flex;
    flex-direction: column;
    margin-bottom: 9px;
    min-height: 369px;
`
export const ISDocumentContainer = styled.div`
    width: 100%;
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 18px;
    padding: 0.5rem 1.5rem;
    height: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom:10px
    
`

export const DPContainer = styled.div`

    margin-bottom: 0.5rem;

    & input {
        padding: 0.5rem !important;
        color: ${({ theme }) => theme.colors.secondary};
    }

    & button {
        color: ${({ theme }) => theme.colors.secondary};
    }

    & fieldset {
        border-color: white;
    }

    & label {
        color: ${({ theme }) => theme.colors.secondary};
    }

    &:focus-within {
        & input {
            color: ${({ theme }) => theme.colors.lightBlack};
            border-color: white;
        }
        & button {
            color: ${({ theme }) => theme.colors.primary};
            border-color: white;
        }
        & fieldset {
            border-color: white;
        }
    }
`

export const ISDivision = styled.div`
    // height: 878px;
    overflow: auto;
    width: 35%;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
`

export const ISNavContainer = styled.div`
    width: 100%;
    margin-bottom: 2rem;
`

export const ISNavContent = styled.div`
    width: 100%;
    height: 90%;
    flex: 1;
    overflow: auto;
    padding-right: 9px
`