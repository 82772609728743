import React, { useState } from 'react';

import EntityUserModal from '../EntityUserModal/EntityUserModal';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import {Content,DivLabel,DivGlp,CBtn,Container,BtnDivContent} from './EntitiesComponent.Styled'

function EntitiesComponent({show, label, radioName, radioValue, radioGender, onChangeRadio, entities, deleteEntity, addEntity, entitiesType, userType, userUid}) {
    const [showEntityModal, setShowEntityModal] = useState(false);
    return (
        <Container style={{display: show ? "block" : "none"}}>
            <div className="entitiesComponent__entitiesList_header">
                {/* <label for="last_name">{label}</label> */}
                {
                    userType === "owner" ? (
                        <div className="entitiesList_header_radiosContainer">
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={radioName}
                                    id={`${radioName}F`}
                                    checked = {radioValue != "1"}
                                    value="0"
                                    onChange={(e) => onChangeRadio(radioName, e.target.value)}
                                />
                                <label className="form-check-label" for={`${radioName}F`}>Sélection</label>
                                </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={radioName}
                                    id={`${radioName}T`} 
                                    checked = {radioValue == "1"}
                                    value="1" 
                                    onChange={(e) => onChangeRadio(radioName, e.target.value)}
                                />
                                <label className="form-check-label" for={`${radioName}T`}> 
                                    {
                                        radioGender === "M" ? "Tous" : "Toutes"
                                    }
                                </label>
                            </div>
                        </div>
                    ) : null
                }
            </div>
                
                    <Content>
                        {
                            entities?.length  > 0 ? entities.map(entity => (
                      
                                
                        <DivGlp key={entity.uid}  >
                            <DivLabel>
                                    {entity.name}
                            </DivLabel>
                            <div>
                            {
                                !userUid ? (
                                    <CloseIcon onClick={() => deleteEntity(entity)} style={{fontSize: "0.9rem"}}/>) : null
                            }
                            </div>
                        </DivGlp>
                            )) : null
                        }





                    </Content>
                   <BtnDivContent>
                        <CBtn  style={{width:"130px"}}
                             color='#2174B980'
                            onClick={() => setShowEntityModal(true)}
                            >
                            Ajouter 
                        </CBtn>
                        </BtnDivContent>
                
            {showEntityModal ? (
                    <EntityUserModal
                        handleClose = {() => setShowEntityModal(false)}
                        show = {showEntityModal}
                        addEntitiesUser={addEntity}
                        userEntities={entities}
                        entitiesType={entitiesType}
                    />
                ) : null}
        </Container>  
    )
}

export default EntitiesComponent
