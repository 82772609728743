import React, { useState,  useEffect} from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./EntitiesList.css"
import { ETRadiosContainer, FormRadioWrapper, FormRadio, RadioButtonLabel, FormLabel } from "./EntitiesList.styled"
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, PrimaryBtnOutlineActionModal, SecondaryBtnOutlineActionModal } from '../../../../../styles/Common';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EntityContainer from './EntityContainer/EntityContainer'


function EntitiesList({ selectedOwnersState, selectRowHandler, ownersChangeHandler, setSelectedOwnersState, ownersResetHandler, selectedOwnersStateCopy, owners }) {

    const [showLEntitiesList, setShowEntitiesList] = useState(false);
    const [showEntitiesListCopy, setShowEntitiesListCopy] = useState();
    const [showLEntitiesListCopie, setShowEntitiesListCopie] = useState(false);
    
    // useEffect(() => {
    //     // console.log()
    //     setSelectedOwnersLocalState(selectedOwnersState)
    // }, [selectedOwnersState])


    

    const getSelectedRowValue = () => {

    }

    return (
        <>
            <AddCircleIcon style={{ color: "#809FB8", width: "17px", marginLeft: "10px" }} onClick={e => setShowEntitiesList(true)} />
            {/* <ExpandMoreIcon style={{ color: "#2174B9" }} onClick={e => setShowEntitiesList(!showLEntitiesList)} /> */}
            {
                showLEntitiesList &&
                <div className="entitiesListType">
                    <div className="entitiesListTypeContainer">
                            {/*                         
                            //<EntityContainer 
                            //     selectRowHandler={selectRowHandler}
                            //     owners={selectedOwnersState}
                            // /> */}

                            { 
                                selectedOwnersState.map((owner, index) => {
                                    return (
                                        <>
                                            <FormRadioWrapper>
                                                <input
                                                    type="checkbox"
                                                    name={owner?.name}
                                                    onChange={(e) =>
                                                        selectRowHandler(e, index)
                                                    }
                                                    checked={owner?.selected}
                                                    className="form-check-input checkBoxInput"
                                                    id="exampleCheck1"
                                                />
                                                <FormLabel htmlFor="left">{owner?.name}</FormLabel>
                                            </FormRadioWrapper>
                                        </>
                
                                    )
                                })
                            }
                        
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: "10px" }}>
                        <PrimaryBtnOutlineActionModal
                            style={{}}
                            onClick={e => {setShowEntitiesList(false);ownersChangeHandler()}}
                        >
                            <DoneIcon />
                        </PrimaryBtnOutlineActionModal>
                        <SecondaryBtnOutlineActionModal
                            onClick={e => {setShowEntitiesList(false);ownersResetHandler(selectedOwnersStateCopy)}}
                        >
                            <CloseIcon />
                        </SecondaryBtnOutlineActionModal>
                    </div>
                </div>

            }
        </>
    )
}

export default EntitiesList