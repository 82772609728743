import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import {
  EntInfosContainer,
  documentsList,
  EIFormCol,
  EIFormContainer,
  IDHeaderContainer,
  IDHeader,
  IDHeaderTitle,
  IDInvoiceDate,
  IDInvoiceDateLabel,
  IDHeaderActions,
  ColContainer,
  HeadEnt,
  FormGroup,
  ItemTimeline,
  ItemComment,
} from "./EchangeDetail.styled";
import fr from "react-phone-input-2/lang/fr.json";

import {
  FormECILabel,
  FormECItem,
  FormECIValue,
  FormECLabel,
  FormECListContainer,
  FormEntCanal,
  FormInput,
  FormLabel,
  FormSelect,
  Header,
  PrimaryBtnOutlineAction,
  SecondaryBtnOutlineAction,
  TelInput,
} from "../../styles/Common";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import MandatoryMarker from "../../components/UI/MandatoryMarker";
import Axios from "../../axios-proas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import MessageIcon from "@mui/icons-material/Message";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  faUserGear,
  faDatabase,
  faDolly,
  faPersonRays,
  faBell,
  faHourglassHalf,
  circleArrowRight,
  faCircleArrowRight,
  faOctagonmark,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate,getNotyfObject, isToday, isYesterday, downloadDocumentClickHandler  } from "../../shared/utility";

import StatusModal from '../../components/EchangePDP/StatusModal/StatusModal';


function EchangeDetail({ entity }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [echange, setEchange] = useState();
  const [event, setEvent] = useState();
  const navigate = useNavigate();
  const [showStatusModal, setShowStatusModal] = useState(false);
  let { uid } = useParams();
  const formatDate = useCallback((date) => {
    if (date !== null && date.valueOf() > 0){
        const newDate = new Date(date);
        const day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
        const month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
        const hours = newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours();
        const minutes = newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes();
        const seconds = newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds();
        const time = " " + t("default:at", "à") + " " + hours + ":" + minutes +":" + seconds;
    return (isToday(newDate) ? t("default:today","Aujourd'hui") : (isYesterday(newDate) ? t("default:yesterday","Hier") : day + '/' + month + '/' + newDate.getFullYear())) + time;
    } else return null;    
}, [])
  useEffect(() => {
    if (uid) {
      Axios.get("/echangepdp/" + uid + "/object")
        .then((res) => {
          setEchange(res.data.echange);
        })
        .catch((err) => {
          //  notyf.error("Une erreur s'est produite!");
        });
    }
  }, [uid]);
  console.log(echange);
  const handleInputChange = (e) => {
    const key = e.target.name;
    var value = e.target.value;

    const newEchange = { ...echange, [key]: value };

    setEchange(newEchange);
  };

  useEffect(() => {
    //nom fichier/uid/fonction
    Axios.get("/echangepdp/" + uid + "/event")
      .then((response) => {
        setEvent(response.data.event);
        console.log(response.data.event);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getTypeWithKey = (key) => {
    switch (key) {
      case "TRANSMIS":
        // return "Paiement transmis ";
        return "Réceptionnée";
        break;
      case "APPROUVEE":
        return "Approuvée partiellement ";
        break;

      case "ENCAISSE":
        return "Encaissée";
        break;

      case "EN CHARGE":
        return "Pris en charge";
        break;

      case "INTEGRATION":
        return "Intégration";
        break;
      case "COMMENT":
        return "Commentaire";
        break;
      case "LITIGE":
        return <span style={{ color: "#EE5A5A" }}>Litige</span>;
        break;
      case "ERROR":
        return <span style={{ color: "#EE5A5A" }}>Erreur intégration ERP</span>;
        break;
      default:
        break;
    }
  };

  const getIconWithKey = (key, error) => {
    if(+error) return <WarningAmberIcon style={{ color: "#EE5A5A", marginRight: "13px" }} />
    switch (key) {
      //   case "TRANSMIS":
      //     return (
      //       <FontAwesomeIcon
      //         icon={faCircleArrowRight}
      //         style={{
      //           color: "#2174B9",
      //           marginLeft: "2px",
      //           fontSize: "19px",
      //           marginRight: "13px",
      //         }}
      //       />
      //     );
      //     break;
      //   case "APPROUVEE":
      //     return (
      //       <FontAwesomeIcon
      //         icon={faCircleArrowRight}
      //         style={{
      //           color: "#2174B9",
      //           marginLeft: "2px",
      //           fontSize: "19px",
      //           marginRight: "13px",
      //         }}
      //       />
      //     );
      //     break;

      //   case "ENCAISSE":
      //     return (
      //       <FontAwesomeIcon
      //         icon={faCircleArrowRight}
      //         style={{
      //           color: "#2174B9",
      //           marginLeft: "2px",
      //           fontSize: "19px",
      //           marginRight: "13px",
      //         }}
      //       />
      //     );
      //     break;
      case "LITIGE":
        return (
          <WarningAmberIcon style={{ color: "#EE5A5A", marginRight: "13px" }} />
        );
        break;
      case "ERROR":
        return (
          <HighlightOffIcon style={{ color: "#EE5A5A", marginRight: "13px" }} />
        );
        break;

      //   case "EN CHARGE":
      //     return (
      //       <FontAwesomeIcon
      //         icon={faCircleArrowRight}
      //         style={{
      //           color: "#2174B9",
      //           marginLeft: "2px",
      //           fontSize: "19px",
      //           marginRight: "13px",
      //         }}
      //       />
      //     );
      //     break;

      //   case "INTEGRATION":
      //     return (
      //       <ReportGmailerrorredIcon
      //         style={{ color: "#2174B9", marginRight: "13px" }}
      //       />
      //     );
      //     break;
      case "COMMENT":
        return (
          <MessageIcon style={{ color: "#809FB8", marginRight: "13px" }} />
        );
        break;
      default:
        return (
          <ArrowCircleRightOutlinedIcon
            style={{
              color: "#809FB8",
              marginRight: "13px",
            }}
          />
        );
        break;
    }
  };

  return (
    <>
      <EntInfosContainer>
        <Header>
          <div>
            <IDHeaderTitle>Échanges PDP & PPF : GASPAR VERBODIN SARL</IDHeaderTitle>

            <IDInvoiceDate>
              <IDInvoiceDateLabel style={{fontSize:"0.9rem"}}>Initié le : 05/11/2022&nbsp;&nbsp;&nbsp;&nbsp;Modifié le 06/11/2022</IDInvoiceDateLabel>
              {/* <IDInvoiceDateValue>{formatDate(+invoiceData?.issuingDate)}</IDInvoiceDateValue> */}
            </IDInvoiceDate>
          </div>
          <IDHeaderActions>
            <div></div>
            <PrimaryBtnOutlineAction
                onClick={() => setShowStatusModal(true)}
            >
              Actions
            </PrimaryBtnOutlineAction>

            <SecondaryBtnOutlineAction
                                        border="#D9E1E7"
                                        color="#2174B9"
                                        hov="#F1F4F9"
                                        onClick={() => navigate(-1) }>
                                        <span>Retour</span>
                                    </SecondaryBtnOutlineAction>    
          </IDHeaderActions>
        </Header>

        <EIFormContainer>
          <ColContainer>
            <HeadEnt> Informations </HeadEnt>
            <FormGroup>
              <FormLabel htmlFor="type">Type</FormLabel>
              <FormLabel style={{color:"#505050",fontWeight: "normal"}}>{echange?.type || ""}</FormLabel>
              {/* <FormInput
                id="type"
                type="text"
                name="type"
                // placeholder="Code"
                value={echange?.type || ""}
                onChange={handleInputChange}
              /> */}
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="nature">Nature</FormLabel>
              {/* <FormInput
                id="nature"
                type="text"
                name="nature"
                // placeholder="Code"
                value={echange?.nature || ""}
                onChange={handleInputChange}
              /> */}
              <FormLabel style={{color:"#505050",fontWeight: "normal"}}>{echange?.nature || ""}</FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="number">Identifiant document</FormLabel>
              {/* <FormInput
                id="number"
                type="text"
                name="number"
                // placeholder="Code"
                value={echange?.number || ""}
                onChange={handleInputChange}
              /> */}
              <FormLabel style={{color:"#505050",fontWeight: "normal"}}>{echange?.idDocument || ""}</FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="emetteur">Emetteur</FormLabel>
              {/* <FormInput
                id="emetteur"
                type="text"
                name="emetteur"
                // placeholder="Code"
                value={echange?.emetteur || ""}
                onChange={handleInputChange}
              /> */}
              <FormLabel style={{color:"#505050",fontWeight: "normal"}}>{echange?.emetteur || ""}</FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="destinataire">Destinataire</FormLabel>
              {/* <FormInput
                id="destinataire"
                type="text"
                name="destinataire"
                // placeholder="Code"
                value={echange?.destinataire || ""}
                onChange={handleInputChange}
              /> */}
              <FormLabel style={{color:"#505050",fontWeight: "normal"}}>{echange?.destinataire || ""}</FormLabel>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="creationDate">Date d'initialisation</FormLabel>
              {/* <FormInput
                id="creationDate"
                type="text"
                name="creationDate"
                // placeholder="Code"
                value={echange?.creationDate || ""}
                onChange={handleInputChange}
              /> */}
              <FormLabel style={{color:"#505050",fontWeight: "normal"}}>{ formatDate(+echange?.creationDate) || ""}</FormLabel>
            </FormGroup>

            <FormGroup  >
              <FormLabel htmlFor="statut">Statut courant </FormLabel>
              {/* <FormInput
                id="statut"
                type="text"
                name="statut"
                // placeholder="Code"
                value={getTypeWithKey(echange?.statut) || ""}
                onChange={handleInputChange}
              /> */}
              <FormLabel style={{color:"#505050",fontWeight: "normal"}}>{getTypeWithKey(echange?.statut) || ""}</FormLabel>
            </FormGroup>
            <FormGroup style={{ borderBottom:"1px solid #D9E1E7 "}} ></FormGroup>

            <FormGroup  >
              <FormLabel htmlFor="echangeFile" >Fichier échangé</FormLabel>
              {/* <FormInput
                style={{color:"#2174B9"}}
                id="echangeFile"
                type="text"
                name="echangeFile"
                // placeholder="Code"
                value={echange?.echangeFile || ""}
                onChange={handleInputChange}
              /> */}
              <FormLabel style={{color:"#2174B9",fontWeight: "normal", cursor: "pointer"}} 
                onClick={() => downloadDocumentClickHandler(echange?.exchangeFile, echange?.echangeFile, "application/text")}>
                  {echange?.echangeFile || ""}
              </FormLabel>
            </FormGroup>

            <FormGroup >
              <FormLabel htmlFor="formatEchange">Format de l'échange</FormLabel>
              {/* <FormInput
                id="formatEchange"
                type="text"
                name="formatEchange"
                // placeholder="Code"
                value={echange?.formatEchange || ""}
                onChange={handleInputChange}
              /> */}
              <FormLabel style={{color:"#505050",fontWeight: "normal"}}>{echange?.formatEchange || ""}</FormLabel>
            </FormGroup>
            <FormGroup style={{ borderBottom:"1px solid #D9E1E7"}} ></FormGroup>
            <FormGroup >
              <FormLabel htmlFor="erreur">Erreur </FormLabel>
              {/* <FormInput
                id="erreur"
                type="text"
                name="erreur"
                // placeholder="Code"
                value={echange?.erreur || ""}
                onChange={handleInputChange}
              /> */}
              <WarningAmberIcon style={{ color: "#EE5A5A", margin: "5px" }} />
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="erreurInfo">Erreur info</FormLabel>
              {/* <FormInput
                id="erreurInfo"
                type="text"
                name="erreurInfo"
                // placeholder="Code"
                value={echange?.erreurInfo || ""}
                onChange={handleInputChange}
              /> */}
              <FormLabel style={{color:"#505050",fontWeight: "normal"}}>{echange?.erreurInfo || ""}</FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="logErreur">Log de l'erreur</FormLabel>
              {/* <FormInput
                style={{color:"#2174B9", cursor:"pointer"}}
                id="logErreur"
                type="text"
                name="logErreur"
                // placeholder="Code"
                value={echange?.logErreur || ""}
              /> */}
              <FormLabel style={{color:"#2174B9",fontWeight: "normal", cursor: "pointer"}} 
                        onClick={() => downloadDocumentClickHandler(echange?.logFile, echange?.logErreur, "application/text")}>{echange?.logErreur || ""}</FormLabel>
            </FormGroup>
          </ColContainer>

          <ColContainer>
            <HeadEnt> Timeline </HeadEnt>
            {event?.map((item, index) => (
              <div >
                <ItemTimeline>
                  <div style={{ display: "flex" }}>
                    <FormLabel htmlFor="address" >
                      {" "}
                      {getIconWithKey(item?.type, item?.error)}
                    </FormLabel>
                    <FormLabel htmlFor="address" style={{ color: +item?.error ? "#EE5A5A" : "" }}>
                      {getTypeWithKey(item?.type)}
                    </FormLabel>
                  </div>
                  <FormLabel
                    htmlFor="address"
                    style={{
                      color:
                        item.type == "ERROR" || item.type == "LITIGE" || +item?.error
                          ? "#EE5A5A"
                          : null,
                    }}
                  >
                    le {formatDate(+item?.creationDate)}
                  </FormLabel>
                </ItemTimeline>
                {item.type == "COMMENT" && (
                  <ItemComment>
                    <FormLabel htmlFor="address">
                      {item?.last_name + " " + item?.first_name}
                    </FormLabel>
                    <FormLabel
                      style={{
                        whiteSpace: "pre-wrap",
                        maxWidth: "320px",
                        color: "#505050",
                      }}
                      htmlFor="address"
                    >
                      {item?.message}
                    </FormLabel>
                  </ItemComment>
                )}
              </div>
            ))}
          </ColContainer>
        </EIFormContainer>
        {
            showStatusModal ? (
                <StatusModal
                    show={showStatusModal}
                    modalClosed={() => setShowStatusModal(false)}
                    statusVal={null}
                    statusList={null}
                    editStatus={null}
                />
            ) : null
        }
      </EntInfosContainer>
    </>
  );
}

export default EchangeDetail;
