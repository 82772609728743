import styled from 'styled-components';

export const ISContainer = styled.div`
    width: ${({isCommand}) => isCommand?"100%":"35%"};
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 18px;
    padding: 0.8rem 1.5rem;  
    height:${({isCommand}) => isCommand?"fit-content":"550px"};
    display: flex;
    flex-direction: column;
    
    
`

export const ISNavContainer = styled.div`
    width: 100%;
    margin-bottom: 2rem;
    
`

export const ISNavContent = styled.div`
width: 100%;
height: 90%;
flex: 1;
overflow: auto;
  
`