// import React from 'react';

// import { Bar } from 'react-chartjs-2';

// import './BarChart.css'
// function BarChart() {

//   const dataF=[{
//     label: "fournisseur 1",
//     data: [1,2,3,4,0,6,7,8,9,0,11,12],
//     backgroundColor: 'rgba(255, 99, 132, 0.5)'
//    },
//     {
//       label: "fournisseur 2",
//     data: [1,0,3,4,5,6,7,8,9,10,0,12],
//     backgroundColor: 'rgba(53, 162, 235, 0.5)'
//     },
//     {
//       label: "fournisseur 3",
//     data: [1,2,3,0,5,6,7,8,9,10,0,12],
//     backgroundColor: 'rgba(255, 206, 86, 0.5)'
//     },
//     {
//      label: "fournisseur 4",
//     data: [1,2,3,0,5,6,7,8,0,10,11,12],
//     backgroundColor: 'rgba(75, 192, 192, 0.5)'
//     },

// ]

// const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top' ,

//     },
//     title: {
//       display: true,
//       text: 'Chart.js Bar Chart',
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

//  const data = {
//   labels,
//   datasets: dataF,
// };

//   return(
//    <Bar options={options} data={data} />
//    )
// }

// export default BarChart

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import Axios from '../../../axios-proas';
import {Titel} from '../HomeSupplier.styled'
function BarChart() {
      const dataF=[{
    label: "fournisseur 1",
    data: [1,2,3,4,0,6,7,8,9,0,11,12],
    backgroundColor: 'rgba(255, 99, 132, 0.5)'
   },
    {
      label: "fournisseur 2",
    data: [1,0,3,4,5,6,7,8,9,10,0,12],
    backgroundColor: 'rgba(53, 162, 235, 0.5)'
    },
    {
      label: "fournisseur 3",
    data: [1,2,3,0,5,6,7,8,9,10,0,12],
    backgroundColor: 'rgba(255, 206, 86, 0.5)'
    },
    {
     label: "fournisseur 4",
    data: [1,2,3,0,5,6,7,8,0,10,11,12],
    backgroundColor: 'rgba(75, 192, 192, 0.5)'
    },

]
console.log(dataF)
const labels = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
const colors = ["#2174B9", "#809FB8", "#B8B8B8", "#D9E1E7", "#F1F4F9", "#C8B0E6", "#EE5A5A", "#00B8B8", "#55EFC4", "#5FEFC4","#B9E1E7","#E9E1E7"];
    const [labelss, setLabelss] = useState([]);
    const [dataAmounts, setDataAmounts] = useState([]);
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
 const[total,setTotal]=useState(0)
 const[totalPourcentage,setTotalPourcentage]=useState(0)
    useEffect(() => {
        const date = new Date();
        const currentMonth = new Date().getMonth();
        const firstMonth = new Date(date.getFullYear() - 3, currentMonth + 1).getTime();
        const lastMonth = new Date().getTime();
        const dateInterval = firstMonth + ":" + lastMonth;
        console.log(dateInterval)
        Axios.get('/invoice//spentByMonth?caMonthInterval='+dateInterval).then((response) => {
          setTotal(response?.data?.total)
          setTotalPourcentage(response?.data?.dataPourcentage)
          console.log(response?.data?.total)
          let res=response.data.data;
       
          response?.data?.data?.map((row,index)=> {
              let temp=row?.data1;
              let newLabels = [];
              let amounts = [0,0,0,0,0,0,0,0,0,0,0,0]
              console.log(temp)
              temp?.map(row => {
                console.log("rtrhyth")
                for (const key in row) {
                 
                    let temp = +row['monthTotal'].substr(5,2) - 1;
                  
                    let pos = temp - (currentMonth + 1) >= 0 ? temp - (currentMonth ) : (temp - (currentMonth + 1)) + 12
                    if (key == 'monthTotal') {
                        //temp = +row[key].substr(5,2) - 1;
                        newLabels.push(monthList[temp]);
                    }
                    if (key == 'totalAmountBT') {
                      
                        amounts[pos] = row[key]
                    
                    }   
                  
                    
                }
               
              })
            
              res={...res,[index]:{...res[index],data:amounts,backgroundColor:colors[index]}}
              });
              console.log(Object.values(res))
              setDataAmounts(Object.values(res));
        }).catch(error => {
            console.log(error)
        })

    }, [])
  
    const calculPercentage=(value)=>{
      return Math.round((value/total)*100)
    }
    
    const data ={
        labels: labels,
        datasets: dataAmounts?dataAmounts:[],
    }

    const options = {
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        title: {
            display: false,
            text: 'ÉVOLUTION DU CA SUR LES 12 DERNIERS MOIS',
            fontSize: 20,
            fontColor: "white"
        },
        scales: {
            yAxes: [
                {
                    id: 'A',
                    position: 'left',
                    gridLines: {
                        color: "rgba(200,200,200,0.5)"
                    },
                    ticks: {
                        fontColor: 'black',
                        // min: 0,
                        // max: 6,
                        //stepSize: 1
                    }
                }
             ]
            ,
            xAxes: [{
                gridLines: {
                    color: "rgba(200,200,200,0.5)"
                },
                ticks: {
                    fontColor: "black",
                    fontSize: 14,
                },
                position: "relative"

            }]
        },
        legend: {
            position: "bottom",
            labels: {
                fontColor: 'black',
                fontSize: 15,
                padding: 10
            }
        }
    }
    return (
      <div>
        <div style={{display:"flex",justifyContent:"center",width:"100%",flexDirection: "column"}}>
           {
              totalPourcentage.length>0&& totalPourcentage?.map((row,index)=>{
              return (
                <div   style={{display:"flex",justifyContent:"center"}}>
                   <Titel >{row.name} : {row.litige}%</Titel>
                </div>
              )})
           }
        </div>
          <div style={{height:"450px"}}> 
             <Bar data={data} options={options} />
          </div>
      </div>
      
        
    )
}

export default BarChart

