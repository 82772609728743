import React, {useCallback, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter,SelectFilter,Option, TD } from '../../../styles/Table.styled';
import DateFilter from '../../UI/DateFilter/DateFilter';

function TableFilters(props) {
    const {showFilters, entitiesFilters, filtersChange,applyFilters,columns} = props;
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')
    const {t} = useTranslation();

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...entitiesFilters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters)
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])

    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    let [dateInputValue,setDateInputValue] = useState({
        creationDate: entitiesFilters?.creationDate ? convertDate(entitiesFilters.creationDate) : ''
    });

    const getCreationdate = () => {
        return entitiesFilters?.creationDate ? convertDate(entitiesFilters.creationDate) : [];
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...entitiesFilters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...entitiesFilters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }
   
    return (
        <>
            <tr style={{display: showFilters ? 'table-row' : 'none'}}>
                
                {/* <TD style={{display: !columns.filter(column => column.field=="code")[0].show == true && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:entityCode", "Code")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={entitiesFilters?.code || ''} 
                            name="code" 
                            type="text" 
                            autoComplete="off"
                        />
                    </FilterContainer>
                </TD> */}
                
                <TD style={{display: !columns.filter(column => column.field=="code")[0].show == true && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:entityCode", "Code ")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={entitiesFilters?.code || ''} 
                            name="code" 
                            type="text" 
                            autoComplete="off"
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !columns.filter(column => column.field=="name")[0].show == true && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:entityName", "Nom")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={entitiesFilters?.name || ''} 
                            name="name" 
                            type="text" 
                            autoComplete="off"
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !columns.filter(column => column.field=="siret")[0].show == true && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:siret", "siret")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={entitiesFilters?.siret || ''} 
                            name="siret" 
                            type="text" 
                            autoComplete="off"
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !columns.filter(column => column.field=="siren")[0].show == true && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:siren", "siren")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={entitiesFilters?.siren || ''} 
                            name="siren" 
                            type="text" 
                            autoComplete="off"
                        />
                    </FilterContainer>
                </TD>
                <TD style={{display: !columns.filter(column => column.field=="type")[0].show == true && 'none'}}>
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={entitiesFilters?.type  || ''} 
                        name="type" 
                    >
                        <Option value=""></Option>
                        <Option value="PPF">PPF</Option>
                        <Option value="PDF">PDF</Option>
                    
                    </SelectFilter>
                    </FilterContainer>
                </TD>
            </tr>
        </>
    )
}

export default TableFilters
