import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Axios from '../../axios-proas';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import { checkValidity, formatDate, getNotyfObject,verificationData,validatDocs } from '../../shared/utility';
import EntitiesComponent from '../../components/UI/EntitiesComponent/EntitiesComponent';
import UsersManagement from '../UsersManagement/UsersManagement';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import Download from '../../components/Download/Download';
import { connect } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { EntContainer, EntContent, EntHeader, EntHeaderAction, EntHeaderActions,EntHeaderTitle2, EntHeaderTitle,EntHeaderTitleDiv, EntHeaderWrapper, NavTabsLi, NavTabsLink, NavTabsUl} from './EntityPPF.styled'
import { useTheme } from 'styled-components';
import InfosPPF from '../../components/Entity/InfosPPF/InfosPPF';
import { BtnAction, PrimaryBtnOutlineAction, SecondaryBtnOutlineAction } from '../../styles/Common';
import Documents from '../../components/Entity/Documents/Documents';
import TableActions from '../../components/UsersManagTable/TableActions/TableActions';
import { TableActionLink } from '../../styles/Table.styled';
import axios from 'axios';
import {SpinnerContainer } from '../../styles/Table.styled';
import { Spinner } from 'react-bootstrap';
import SuspendedDocsModal from '../../components/Entity/Infos/SuspendedDocsModal/SuspendedDocsModal';
import UserFormModal from '../UserForm/UserForm'
function EntityPPF({userType}) {
    const theme = useTheme()
    let {uid} = useParams();
    const navigate = useNavigate();
    const notyf = getNotyfObject();
    const {t} = useTranslation();
    let location = useLocation(); 
    const [banquesToDelete,setBanquesToDelete] = useState([])
    const [attachmentsToDelete,setAttachmentsToDelete] = useState([])
    const [usersManagementUpdate, setUsersManagementUpdate] = useState(false);
    const [entity, setEntity] = useState({
        countryCode : "fr",
        family : "ADMINISTRATION",
        lang: "fr",
        type: location?.state?.entityType ? location?.state?.entityType : "owner",
    });
    const [navTabsList, setNavTabsList] = useState([
        {label: 'Informations Administratives', value: 'infos'},
        {label: 'Contacts', value: 'contacts'},
        {label: 'Documents Juridiques', value: 'documents'},
    ])
    const canalList = useMemo(() => ([
        {value: 'EMAIL', label: t("signin:canalEmail", "Email")},
        {value: 'CHORUS', label: t("signin:chorus", "Chorus")},
        {value: 'PORTAL', label: t("signin:accessPortal", "Portail")},
        {value: 'EDI', label: t("signin:edi", "EDI")}
    ]), [t]);
    
    const supplierCanalList = useMemo(() => ([
        {value: 'PORTAL', label: t("signin:accessPortal", "Portail")},
        {value: 'EMAIL', label: t("signin:canalEmail", "Email")},
        {value: 'PAPER', label: t("signin:edi", "Papier")},
        {value: 'EDI', label: t("signin:edi", "EDI")},
        {value: 'PDP', label: t("signin:pdp", "PDP")},
        {value: 'PPF', label: t("signin:pdp", "PPF")}
    ]), [t]);

    const [tab, setTab] = useState(localStorage.getItem("supplierActiveTab") ? localStorage.getItem("supplierActiveTab") : "infos");
    const[validate,setValidate]=useState(false)
    const[checked,setChecked]=useState(false)
    const[valideSirenError,setValideSirenError]=useState(false)
    const[docTypes,setDocTypes] =useState([
        {label: 'KBIS', value: 'KBIS', alert:false, valide:false,attent:false},
        {label: 'RIB', value: 'IBAN', alert:false, valide:false,attent:false},
        {label: 'Attestation URSSAF', value: 'URSSAF', alert:false, valide:false,attent:false},
        {label: 'Attestation d assurance', value: 'INSURANCE', alert:false, valide:false,attent:false},
        {label: 'Travailleurs étrangers', value: 'FOREIGN', alert:false, valide:false,attent:false},
    ])
    const typeUser = localStorage.getItem( "type" );
    const[update,setUpdate]=useState(false)
    const[showSpinner,setShowSpinner]=useState(false)
    const[suspendedDocs, setSuspendedDocs] = useState([]);
    const [showSuspendedDocsModal, setShowSuspendedDocsModal] = useState(false);
    const [confirmationUserModalShow, setConfirmationUserModalShow] = useState(false)
    const[userFormData, setUserFormData] = useState({
                                                type: "owner",
                                                level: "standard"
                                            })
    // const [user, setUser] = useState({
    //     type: "owner",
    //     level: "standard"
    // })
   
   
   

    
    //jai fait ca car je veux pas rafraicher useEffect avec la dependence update
    useEffect(() => { 
        return () => {
            setTab("infos")
            localStorage.removeItem("supplierActiveTab");
            localStorage.removeItem("show");
        }
    },[uid,validate,checked])

    useEffect(() => {
       const entityUid = uid; 
        if (entityUid){
            getentityObject(entityUid);
            // Axios.get('/entitypdp/'+entityUid+'/object').then((response) => {
            //     setDocTypes(validatDocs(docTypes, response?.data?.entity?.documents));
             
            //     setEntity(response.data.entity);
            //     setUpdate(false)
            // }).catch(err => {
            //     notyf.error("Une erreur s'est produite!");
            // })
        }         
    },[uid])

const getentityObject = (entityUid) => {
    Axios.get('/entitypdp/'+entityUid+'/object').then((response) => {
        setDocTypes(validatDocs(docTypes, response?.data?.entity?.documents));
     
        setEntity(response.data.entity);
        setUpdate(false)
    }).catch(err => {
        notyf.error("Une erreur s'est produite!");
    })
}

    const handleInputChange = e => {
        const key = e.target.name;
        var value = e.target.value;
        console.log(value)
        console.log(key)
        if( key == "actif"){
            e.target.checked ? value = 1 : value = 0;
        }
        const newSupplier = {...entity, [key]: value};
        setEntity(newSupplier);
    }
    const handleInputChangeType = e => {
        const key = e.target.name;
        var value = e.target.value;
        let newSupplier =null;
        switch (value) {
            case "PPF":
                newSupplier = {...entity, ppf: 1, pdp: 0};
                break;
            case "PDP":    
                newSupplier = {...entity, ppf: 0, pdp: 1};
                break;
            default:
        }
      
        setEntity(newSupplier);
    }
    const handlePhoneInputChange = e => {
        var value = e;
        const newSupplier = {...entity, telephone: value};
        setEntity(newSupplier);
    }

    const canalChangeHandler = (value) => {
        let entityTmp = {
            ...entity,
            channel : value
        };
        setEntity(entityTmp);
    }

    const supplierCanalChangeHandler = (field, value) => {
        let entityTmp = {...entity};
        supplierCanalList.forEach(canal => {
            entityTmp[canal.field] = canal.field === field ? value : 0;
        });
        setEntity(entityTmp);
    }

    

    const addEntityclickHandler = () => {
        const entityToAdd = {
            ...entity
        }
        Axios.post('entity//entity', entityToAdd).then(response => {
            if (response.data.hasOwnProperty('success')) {
                notyf.success(t("default:entityAdded", "L'entité a bien été ajoutée"))
                setEntity({
                    countryCode : "fr",
                    family : "ADMINISTRATION",
                    lang: "fr"
                });
                navigate(-1);
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    } 

    const editEntityclickHandler = () => {
        let entityToEdit = {...entity,attachmentsToDelete,banquesToDelete};
        Axios.put(`entitypdp/${entity?.uid}/entity`, entityToEdit).then(response => {
            if (response?.data.hasOwnProperty('success')) {
                notyf.success(t("default:userAdded", "L'entité a bien été modifiée"))
                getentityObject(entity?.uid);
            }
            if (response?.data.hasOwnProperty('suspendedDocs')) {
                let suspendedDocsTmp = response?.data?.suspendedDocs;
                setSuspendedDocs(suspendedDocsTmp);
                if (suspendedDocsTmp.length > 0) 
                    setShowSuspendedDocsModal(true);
            }
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
        })
    }


    const saveClickHandler = () => {
        if(entity?.id)
            editEntityclickHandler();
        else
            addEntityclickHandler();
    } 

    const deleteOwnerclickHandler = (owner) => {
        let entityTemp = {...entity}
        if(entity?.id && !owner?.new ){
            entityTemp = {
                ...entityTemp,
                ownersToDelete : entityTemp?.ownersToDelete ? [...entityTemp?.ownersToDelete, owner.uid] : [owner.uid]
            }
        }
        let entityOwners = [...entity?.owners]
        let ownerIndex = entityOwners.findIndex(ent => ent.uid === owner.uid);
        if(ownerIndex >=0 ){
            entityOwners.splice(ownerIndex, 1)
        }
        setEntity({
            ...entityTemp,
            owners: entityOwners
        })
    }

    const ownersChangeHandler = (ownersList) => {
        let entityTemp = {
            ...entity,
            owners : ownersList
        }
        setEntity(entityTemp)
    }

    


    let tSpinner = showSpinner ? (<>
        <SpinnerContainer style={{display: "flex",flexDirection: "column"}}>
            <Spinner animation="border" variant="primary"/>
           <p>verification..</p>
            </SpinnerContainer>
             </>
        ) : null;
    let jSXcontent = null;
    let jsxActions = null;

   
            jsxActions = (
                <PrimaryBtnOutlineAction 
                    onClick={saveClickHandler}
                >
                    <span> Sauvegarder</span>
                </PrimaryBtnOutlineAction>
            )
            
          
            jSXcontent = ( 
                <InfosPPF 
                    handlePhoneInputChange={handlePhoneInputChange}
                    entity={entity} 
                    entities={entity?.owners}
                    bank={entity?.banques}
                    handleInputChange={handleInputChange}
                    deleteEntity={deleteOwnerclickHandler} 
                    addEntity={ownersChangeHandler}
                    entitiesType={"owner"}
                    canalChangeHandler={canalChangeHandler}
                    canalList={canalList}
                    supplierCanalList={supplierCanalList}
                    supplierCanalChangeHandler={supplierCanalChangeHandler}
                    editEntityclickHandler={editEntityclickHandler}
                    setEntity={setEntity}
                    setAttachmentsToDelete={setAttachmentsToDelete}
                    setBanquesToDelete={setBanquesToDelete}
                    handleInputChangeType={handleInputChangeType}
               
                />
            )
   
    

    return (
        <EntContainer>
            <TitleHelmet title={"Démo Portail | " + t(entity?.type === "client" ? "clientList:client" : "supplier:supplier")} />
            <EntHeaderWrapper>
                
                    <EntHeaderTitleDiv>
                    <EntHeaderTitle>
                        Annuaire PPF : {entity?.name}
                      
                        <EntHeaderTitle2><span>Modifié le : {formatDate(+entity.updateDate)}</span></EntHeaderTitle2>
                        
                    </EntHeaderTitle>
                    </EntHeaderTitleDiv>
                    <EntHeaderActions>
                        {
                            jsxActions
                        }
                        <SecondaryBtnOutlineAction 
                            onClick={() => navigate(-1)}
                        >
                            <span>Retour</span>
                        </SecondaryBtnOutlineAction>  
                    </EntHeaderActions>
              

            
            </EntHeaderWrapper>
                {/* {
                    valideSirenError ? (
                        <div className="alert alert-danger mt-2 campaignForm__errors_container" role="alert">
                            <ul>
                                {
                                    <li >Numero de siren n'est pas valide</li>
                                }
                            </ul>
                        </div>
                    ) : null
                } */}
            <EntContent>
                {tSpinner}
                {jSXcontent}
                {
                    showSuspendedDocsModal && (
                        <SuspendedDocsModal
                            show={showSuspendedDocsModal}
                            closeModal={() => setShowSuspendedDocsModal(false)}
                            documents={suspendedDocs}
                        />
                    )
                }
            </EntContent>

            {/* <UserFormModal 
                show={confirmationUserModalShow} 
                modalClosed={() => setConfirmationUserModalShow(false)} 
                // confirm={() => disableUser(userToDisable?.userUid)}
                cancel={() => setConfirmationUserModalShow(false)}
                title={"Modification de contact"}
                message={``}
                userUid={newUserModal ? null : userFormData?.userUid}
                contact={newUserModal ? {type: "owner",level: "standard"} : userFormData}
                setContact={setUserFormData}
                contactEntity={entity}
                setUsersManagementUpdate={setUsersManagementUpdate}
                usersManagementUpdate={usersManagementUpdate}
            /> */}
        </EntContainer>
    )
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    login : state.auth.login
  })
  const mapDispatchToProps = dispatch => ({
  })
  export default connect(mapStateToProps, mapDispatchToProps) (EntityPPF)
  