import { Link } from 'react-router-dom';
import styled from 'styled-components';



export const RSTBody = styled.div`
   background: #ffffff;
    max-width: 800px;
    height: auto;
    width: 50%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 60px 5%;
    border: 1px solid #fff; 
    color: #333333;
     border-radius: 4px; 
`
export const FormLabel = styled.label`
    cursor: pointer;
   
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: nowrap;
    width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({theme}) => theme.colors.secondary};
`;
export const RSTitle = styled.h4`
    font-size: 1.2rem;
    font-weight: 500;
    margin: 40px;
    color: #000;
`

    export const RST = styled.div`
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    align-items: end;
    justify-content: start;
    margin-bottom: 50px;
`
export const FormInput = styled.input`
    /* flex: 1; */
    border: none;
    outline: none;
    width: 250px;
    text-align: right;
    font-size: 0.8rem;
    color: #505050;
`;