import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TableAction, TableActionLink, TAContainer } from '../../../styles/Table.styled.js';



function TableActions(props) {
    const {resetFilters, setShowFilters, showFilters, userType} = props;
    const {t} = useTranslation();
    return (
        <TAContainer>
                
                <TableAction onClick={() => setShowFilters(!showFilters)}>
         
                    <span> {t("global:filters")}</span>
                    <FilterListIcon className="ICDBlue"/>
                </TableAction>
                {
                    userType == "owner" ? 
                    <TableActionLink  to= "/addDocumentation">
                     
                     <span>{t('tableAction:addDocument',`Ajouter un document`)} </span>
                                    <AddIcon />
                               
                        
                    </TableActionLink > : null
                }
                
           
        </TAContainer>
    )
}

export default TableActions
