import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const UMContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`
export const UMBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const UserStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    border-radius: 8px;
    background-color: ${({bg}) => bg ? bg : "white"};
    color: ${({color}) => color ? color : theme.colors.lightBlack};
`

export const RequestLink = styled(Link)`
    color: ${({theme}) => theme.colors.lightBlack};
    text-decoration: none;
    &:hover{
        color: ${({theme}) => theme.colors.laightBlack};
    }
`

