import styled from 'styled-components/macro';

export const EntInfosContainer = styled.div`
    width: 100%;
    font-size: 0.9rem;
    margin-top: 1rem;
`;

export const EIFormCol = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
`
export const EICol = styled.div`
  width: 250px;
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 2000;
    height:fit-content ;
    background-color: white;

    /* overflow: auto; */
    border: 2px solid #D9E1E7;
    border-radius: 8px;
    padding: 0.25rem;

    &:before {
        content: "";
        position: absolute;
        top: -16px;
        left: 10px;
        transform: translateX(-50%);
        border: 7px solid;
        /* border-color: transparent transparent #706E6B transparent; */
        border-color: transparent transparent #D9E1E7 transparent;
    }
`

export const EIContainer = styled.div`
    width: 100%;
    display: flex;
    height:fit-content ;
    border: 1px solid #809FB8;
    border-radius: 12px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    flex-wrap: wrap;

`;
export const FormInputBanque = styled.input`
  /* flex: 1; */
  border: none;
  outline: none;
  flex: 1;
  /* width: 170px; */
  text-align: right;
  color: #505050;
  font-size: 0.8rem;
  ${({err,theme}) => !err&& "border-bottom: 1px solid #EE5A5A" } ;
  /* border-bottom:1px solid #EE5A5A; */
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
    font-style: italic;
  }
`;
export const EntOwners = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    
`;

export const documentsList = styled.ul`
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #809fb866;
    color: #505050;
    & li {
        font-size: 0.7rem;
        color: #505050;
    }
`;


export const EntOwnersList = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
`;

export const EntOwnersListItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    padding: 0.25rem;
    background-color: #2174B980;
    color: white;
    border-radius: 8px;
    margin-right: 0.5rem;

    & span {
        margin: 0 0.5rem 0 0;
    }
`
export const EngroupDiv= styled.div`
display: flex;
width: 100%;
justify-content: space-between;
`

export const IconDiv= styled.div`
 position: relative;
   
&:hover ${EICol} {
        display: block;
    }
`
export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.25rem;
  

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }

  &.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
  }
  
`;