import React, {useCallback} from 'react'
import Axios from '../../../axios-proas';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TD } from '../../../styles/Table.styled';
import { showColumn, getAlignPosition } from '../../../shared/utility';
import { SupplierLink } from '../../../containers/ReferentielFournisseur/ReferentielFournisseur.Styled';
import theme from '../../../styles/theme';

function TableBody(props) {
    const {clientsData, userRole,setEntitydetail,entityOwnerDetail,columns} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleBoxChecked = (event, row) => {
        Axios.put('/clientbusiness/'+row.uid+'/receivesAttachment').then(response => {
            event.target.checked = response.data;
            row.receivesAttachment = event.target.checked;
        }).catch(error => {
            console.error(error);
        })
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])


    const getTokenVisualisation = (uid) => {
        Axios.get('/clientbusiness/'+uid+'/userTokenVisualisation')
            .then(response => {
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                //window.location.reload(false);
                //Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('loginVisualisation', response.data.login)
                navigate("/userVisualisation")
            })
    }
    
    const getDematType =(client) => {
        var dematExpression = new Array()
        if(client?.edi == 1) dematExpression.push('EDI'); 
        if(client?.chorus == 1) dematExpression.push('CHORUS');
        if(client?.paper == 1) dematExpression.push('PAPER');
        if(client?.sendMail == 1) dematExpression.push('EMAIL');
        if(client?.accessPortal == 1) dematExpression.push('Accès portail'); 
        return dematExpression
    }



    const getStatusTrad = (key) => {
        switch (key) {
            case "TRANSMIS":
              return "Réceptionnée";
              break;
            case "APPROUVEE":
              return "Approuvée partiellement ";
              break;
            case "ENCAISSE":
              return "Encaissée";
              break;
            case "EN_CHARGE":
              return "Pris en charge";
              break;
            case "INTEGRATION":
              return "Intégration";
              break;
            default:
              break;
          }
    }
    
    const getStatusColor = (key) => {
        switch (key) {
            case "TRANSMIS":
              return theme?.colors.gray;
              break;
            case "APPROUVEE":
              return theme?.colors.gray;
              break;
            case "ENCAISSE":
              return theme.colors.green;
              break;      
            case "EN_CHARGE":
              return theme.colors.purple;
              break;
      
            case "INTEGRATION":
              return theme.colors.danger;
              break;
            default:
              break;
          }
    }


    return (
        <>
            {
                clientsData ? clientsData.map(row => (
                    <tr key={row.id} className="clientTableBody__tr" onClick={()=>navigate("/echangeDetail/"+row.uid)} style={{cursor:"pointer"}}>
                        <TD style={{display:  !showColumn(columns,"type") && 'none', textAlign: getAlignPosition(columns, "type") }}>{row.type}</TD>
                        <TD style={{display:  !showColumn(columns,"nature") && 'none', textAlign: getAlignPosition(columns, "nature") }}>
                          
                                {row.nature}
                         
                        </TD>
                        {/* <TD style={{display:  !showColumn(columns,"nature") && 'none', textAlign: getAlignPosition(columns, "nature") }}>
                            <SupplierLink 
                                to={`/echangeDetail/${row.uid}`}
                            >
                                {row.nature}
                            </SupplierLink>
                        </TD> */}
                        <TD style={{display:  !showColumn(columns,"emetteur") && 'none', textAlign: getAlignPosition(columns, "emetteur") }}>{row.emetteur}</TD>
                        <TD style={{display:  !showColumn(columns,"destinataire") && 'none', textAlign: getAlignPosition(columns, "destinataire")}}>{row.destinataire}</TD>
                        <TD style={{display:  !showColumn(columns,"number") && 'none', textAlign: getAlignPosition(columns, "number")}}>{ row.idDocument }</TD>
                        <TD style={{display:  !showColumn(columns,"creationDate") && 'none', textAlign: getAlignPosition(columns, "creationDate") }}>{formatDate(+row.creationDate)}</TD>
                        <TD style={{display:  !showColumn(columns,"modificationDate") && 'none', textAlign: getAlignPosition(columns, "modificationDate")}}>{formatDate(+row.modificationDate)}</TD>
                        <TD style={{display:  !showColumn(columns,"erreur") && 'none', textAlign: getAlignPosition(columns, "erreur")}}>{ row.erreur }</TD>
                        <TD style={{display:  !showColumn(columns,"statut") && 'none', textAlign: getAlignPosition(columns, "statut")}}><span style={{textAlign: "center",
    borderRadius: "5px",background: getStatusColor(row.statut)}}>{ getStatusTrad(row.statut) }</span></TD>
                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody
