import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { useTheme } from "styled-components";
import { Modal } from "react-bootstrap";
import {
  CancelBtnOutlineAction,
  PrimaryBtnOutlineAction,
} from "../../../../../../styles/Common";
import CommandTable from "../CommandTable";
import InvoiceView from "../../InvoiceViewCommands/InvoiceView";
import {
  IDContainer,
  IDContainerTable,
  MyModalContainer,
} from "../InvoiceListCommand.styled";
import Research from "./Research";
import CommandTableModal from "./table/CommandTableModal";
import Axios from "../../../../../../axios-proas";
import AdditionalLineModal from "./table/TableAdditionalLine";
import { getNotyfObject } from "../../../../../../shared/utility";
import "./Modal.css";
function CommandLineModal({
  show,
  modalClosed,
  cancel,
  invoiceData,
  commandLineData,
  uid,
  // getCommandesDataByInvoiceUid,
  update,
  setUpdate,
}) {
  const notyf = getNotyfObject;
  const theme = useTheme();
  const [navTabsList, setNavTabsList] = useState([]);
  const [commandLSelected, setCommandLSelected] = useState([]);


  const typeInvoiceToNavListe = (xml, edi, pdf, chorus) => {
    var list = [];
    if (pdf !== null) list = [...list, { label: "PDF", value: "pdf" }];
    if (xml !== null) list = [...list, { label: "XML", value: "xml" }];
    if (edi !== null) list = [...list, { label: "EDI", value: "edi" }];
    if (chorus !== null) list = [...list, { label: "CHORUS", value: "chorus" }];

    setNavTabsList(list);
  };

  const saveClickHandler = () => {
    if (commandLSelected) {
      //console.log(commandLineSelected.filter((cls) => cls.checked));
      let data = [];
      commandLSelected.map((cls) => {
        if (cls.checked) {
          data.push({ uid: cls.id, billed: true });
        }
      });
      Axios.put("/invoice/" + uid + "/addCommandLineById", data)
        .then((response) => {
          // dispatch(invoiceDataSuccess(response.data));
          // console.log(response?.data);
          // getCommandesDataByInvoiceUid(uid);
          setUpdate(!update);
          modalClosed();
        })
        .catch((err) => {
          notyf.error("Une erreur s'est produite !");
          //dispatch(invoiceDataFail(err.response?.data?.error))
        });
    }
  };



  const bodyCommandMondal = (
    <>
      <IDContainer>
        <InvoiceView
          invoiceData={invoiceData}
          navTabsList={navTabsList}
          isCommand={true}
          isModal={true}
        />
      </IDContainer>

      <IDContainer>
        {/* <IDContainerTable style={{ padding: "15px" }}>
          <Research onSearchHandler={onSearchHandler}/>
        </IDContainerTable> */}
      </IDContainer>
      <IDContainer>
        <IDContainerTable>
          <CommandTableModal
            commandLineData={commandLineData}
            setCommandLineSelected={setCommandLSelected}
          />
        </IDContainerTable>
      </IDContainer>
    </>
  );

  // const bodyAdditionalLineModal = (
  //   <>
  //     <AdditionalLineModal
  //       commandLineData={commandLineData}
  //       additionalLine={additionalLine}
  //       setAdditionalLine={setAdditionalLine}
  //     />
  //   </>
  // );

  return (
    <MyModalContainer>
      <Modal
        dialogClassName="myModal modal-dialog-centered"
        show={show}
        onHide={modalClosed}
        className="modala"
        // contentClassName="modal"
      >
        <Modal.Header closeButton style={{border:"none"}}>
          <Modal.Title className="ICDBlue">
            Ajouter des lignes de commande
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="ADMCreationModal__body"
          style={{ width: "100%", padding: "0 2rem 0.5rem 2rem" }}
        >
          {bodyCommandMondal}
        </Modal.Body>
        <Modal.Footer>
          <PrimaryBtnOutlineAction onClick={() => saveClickHandler()}>
            Ajouter des lignes
          </PrimaryBtnOutlineAction>
          <CancelBtnOutlineAction onClick={cancel}>
            Annuler
          </CancelBtnOutlineAction>
        </Modal.Footer>
      </Modal>
    </MyModalContainer>
  );
}
const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,
});
const mapDispatchToProps = (dispatch) => ({
  getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
  getInvoiceDataByToken: (invoiceToken) =>
    dispatch(actions.getInvoiceDataByToken(invoiceToken)),
  resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CommandLineModal);
