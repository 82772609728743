import React, { useEffect, useState } from 'react'
import MessageIcon from '@mui/icons-material/Message';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';

function MessageEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    return (
        <>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={false} bdColor="#fff" >
                        <MessageIcon style={{width: "1.2rem"}}/>
                    </TEIcon>
                    <TEPreview>
                        <TETitle >
                            Commentaire
                        </TETitle>
                        <TETime>
                            le {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    true && (
                        <TEContent>
                            <MessageWrapper>
                                <MessageFrom>
                                    {eventData?.comment!==null ?eventData?.comment : 
                                     "Message de " +
                                    (
                                        eventData?.first_name 
                                        || eventData?.last_name 
                                        ? 
                                        (
                                            eventData?.first_name 
                                            ? eventData?.first_name + ' ' 
                                            : null
                                        ) + (
                                            eventData?.last_name ? 
                                                eventData?.last_name 
                                            : null
                                            ) : eventData.login 
                                    )
                                        }
                                </MessageFrom>
                                <MessageContent>
                                    {eventData?.message?.slice(0, 128)}
                                    {eventData?.message?.length > 128 ? '...' : null}
                                </MessageContent>
                            </MessageWrapper>
                        </TEContent>
                    )
                }
            </TEContainer>
        </>
    )
}

export default MessageEvent