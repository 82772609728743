import React, { useState, useRef, useMemo } from 'react';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import Axios from "../../axios-proas";
import {Notyf} from "notyf";
import { Viewer, pdfjs, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import InfoModal from '../../components/UI/InfoModal/InfoModal';
import { BtnAction, FormGroup, FormInput, FormLabel, Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import {TH ,THead ,TBody ,Table} from '../../styles/Table.styled';

import { useTheme } from 'styled-components';
import { DocVisu, UIBody, UICol, UIContainer, UISection ,TR,TD,NotDiv,BodyDiv} from './UploadInvoice.styled';
import FileUploaderBtn from '../../components/UI/FileUploaderBtn/FileUploaderBtn';
import { useNavigate } from 'react-router-dom';
import DropZoneUploadInvoice from '../../components/UI/DropZone/DropZoneUploadInvoice'
import { convertFileToBase64 } from "../../shared/utility";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';


function UploadInvoice(props) {
    const theme = useTheme()
    const notyf = new Notyf();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const uploader = useRef(null);
    const uploaderAttach = useRef(null);
    const [file, setFile] = useState("")
    const [fileName, setFileName] = useState(null)
    const [pdfPages, setPdfPages] = useState(0)
    const [scrolledEnd, setScrolledEnd] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [url, setUrl] = useState('');
    const [fields, setFields] = useState({field1: "", field2: "", field3: ""});
    const [justificatiFileName, setJustificatiFileName] = useState(null)
    const [attachments,setAttachments] = useState([])
    const open_file = () => {
        document?.getElementById('getFile')?.click();
    }

    const handleFileChange = e => {
        if (e.target.files && e.target.files[0] && e.target.name === "file"){
            setUrl(URL.createObjectURL(e.target.files[0]))
            setFile(e.target.files[0])
            setFileName(e.target.files[0].name);
            // setPdfPages(e.target.files[0].numPages)
            // setScrolledEnd(e.target.files[0].numPages == 1 ? false : true)
        }
    }
    const handleFileJustificatifChange = e => {
       
        if (e.target.files && e.target.files[0] && e.target.name === "attachment"){
           // setUrlJustificatif(URL.createObjectURL(e.target.files[0]))
           // setJustificatifFile(e.target.files[0])
            setJustificatiFileName(e.target.files[0].name);
            // setPdfPages(e.target.files[0].numPages)
            // setScrolledEnd(e.target.files[0].numPages == 1 ? false : true)
        }
    }
      

    const uploadInvoiceHandler = () => {
        const formData = new FormData();
         formData.append('attachements', JSON.stringify(attachments));
       
        // formData.append('field1', fields.field1);
        // formData.append('field2', fields.field2);
        // formData.append('field3', fields.field3);
        Axios.post("/invoice", formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(response => {
            if (response.data.message) {
                notyf.success(t("invoiceList:uploadSuccess"));
                navigate(-1);
                // setShowModal(true)
            }
        }).catch(err => {
            const response = err.response.data;
            const exist = response?.hasOwnProperty('exist');
            if(exist) {
                notyf.error(t("invoiceList:exist", "Une facture avec le même nom existe déjà"));
            } else 
                notyf.error(t("invoiceList:uploadFailed"));
        }).finally(() => {
            // setFile(null);
            // setFields({field1: "", field2: "", field3: ""});
            // setUrl("");
            // uploader.current.value = "";
            // uploaderAttach.current.value = "";
        })
    }

    const confirmClickHandler = () => {
    
            uploadInvoiceHandler()
       
    }

    const inputChangeHandler = (e) => {
        const key = e.target.name;
        const newForm = {
            ...fields,
            [key]: e.target.value
        }
        setFields(newForm);
    }
    const changeFile =async (e,index) => {
        console.log(index)
        setUrl(e)
        console.log(attachments)
    
        let temp={...attachments,[index]: {...attachments[index],active:true}}
        attachments.map((item,i)=>{
            if(i!=index){
                temp={...temp,[i]: {...attachments[i],active:false}}
            }
        })
    
    await setAttachments(Object.values(temp))

    }

    const onDrop = (acceptedFiles) => {
        // console.log("ondrop")
        // console.log(acceptedFiles)
        let attachmentsTmp = []

        acceptedFiles.map(file => {
            if (file) {
                setUrl(URL.createObjectURL(file))
                setFile(file)
                let existFile =attachments?.find(row => row.name === file.path)
                if (existFile) {
                    notyf.error("Un fichier avec le même nom a déjà été ajouté")
                } else {
                    // if (file.size > 5242880)
                    convertFileToBase64(file).then(result => {
                    let  fileB64 = result;
                    let urlTemp= URL.createObjectURL(file)
                        attachmentsTmp.push({
                            name: file.name,
                            type: file.type,
                            content: fileB64,
                            docType: "",
                            size: file.size,
                            url:urlTemp,
                            file:file,
                        })
                
                        setAttachments([...attachmentsTmp, ...attachments])
                    }).catch(err => {
                        notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
                    })
                }
            }

        })
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat('fr-FR').format(number.toFixed(2)).replaceAll('.',' ')
    }

    const calculeSizeToOctet = (size) => {
        let taille = 0;
        if (size) {
            taille = size / 1024 ;
        }
        if(taille > 1024) {
            taille = taille / 1024;
            return formatNumber(taille) + " Mo";
        }else {
            return formatNumber(taille) + " Ko";
        }
        return sizeOctet;
    }
    const body=useMemo(() => attachments?.map((file, index) => (
        <TBody> 
            <TR style={{width: "100%"}}  isactive={file.url==url}> 
                        <TD onClick={()=>changeFile(file.url,index)} err={file.size < 8000000 && file.type=="application/pdf"}>
                        
                            <span >{file.name}</span>
                        </TD>
                        <TD onClick={()=>changeFile(file.url,index)} err={file.size < 8000000 && file.type=="application/pdf"}>
                        
                            <span>{calculeSizeToOctet(file.size)} </span>
                        </TD>
                        <TD>
                            {file.size < 8000000 &&file.type=="application/pdf" &&
                            <CancelIcon onClick={e => deleteAttachment(index)} style={{ color: "#EE5A5A", cursor: "pointer", fontSize: "20px" }} />

                            }
                        </TD>
            </TR>
        </TBody>  
            )), [attachments,url])









    const deleteAttachment = (index) => {

        let tempAttachments =[...attachments]
        console.log(index)
        tempAttachments.splice(index, 1)
        console.log(tempAttachments?.length)
        setUrl(tempAttachments?.length>0?tempAttachments[0].url:"")
        setAttachments(tempAttachments)
    }

    return (
        <>
            <UIContainer>
                <TitleHelmet title={"Démo Portail | Envoyer un document"}  />
                <Header>
                    <HeaderInfos>
                        <HeaderLocation>{t("global:addDocument", "Ajouter un document ")}</HeaderLocation>
                        <HeaderTitle>Suivi des factures</HeaderTitle>
                    </HeaderInfos>
                    <HeaderActions>
                    <BtnAction
                            color={"#2174B9"}
                           border={"#2174B9"}
                            onClick={() => confirmClickHandler()}
                        >
                            Confirmer le dépot
                        </BtnAction>
                        <BtnAction 
                            border={"#D9E1E7"}
                            color={"#2174B9"}
                            onClick={() => navigate(-1)}
                        >
                            <span>Retour</span>
                        </BtnAction>
                   
                    </HeaderActions>
                </Header>
                <UIBody>
                    <UISection>  
                        {
                            url ? (
                               
                                    <DocVisu >
                                    
                                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
                                            <Viewer fileUrl={url} />
                                
                                    </DocVisu>
                            
                            ) : (
                                <DocVisu>
                                    {t('global:previewArea')}
                                </DocVisu>
                            )
                        }

                        <UICol>
                    
                                <DropZoneUploadInvoice  deleteAttachment={deleteAttachment} onDrop={onDrop} accept={"pdf/*"} entity={attachments} />
                          

                            {/* <FormGroup>
                                <FileUploaderBtn
                                    border={true}
                                    label={true}
                                    btnLabel="Choisir un fichier"
                                    handleChange={handleFileChange}
                                    name="file"
                                />
                                <FormLabel>{fileName}</FormLabel>
                            </FormGroup> */}
                            {/* <FormGroup>
                                <FormLabel htmlFor="field1" >Champ 1</FormLabel>
                                <FormInput 
                                    id="field1"
                                    type="text" 
                                    placeHolder="Champ 1" 
                                    value={fields.field1} 
                                    onChange={e => inputChangeHandler(e)} 
                                    name="field1" 
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="field2" >Champ 2</FormLabel>
                                <FormInput 
                                    id="field2"
                                    type="text" 
                                    placeHolder="Champ 2"  
                                    value={fields.field2} 
                                    onChange={e => inputChangeHandler(e)} 
                                    name="field2"  
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="field3" >Champ 3</FormLabel>
                                <FormInput 
                                    id="field3"
                                    type="text" 
                                    placeHolder="Champ 3"
                                    value={fields.field3} 
                                    onChange={e => inputChangeHandler(e)} 
                                    name="field3" 
                                />
                            </FormGroup> */}
                            {/* <FormGroup>
                                <FileUploaderBtn
                                 border={true}
                                 label={true}
                                    btnLabel="Piéce jointe"
                                    handleChange={handleFileJustificatifChange}
                                    name="attachment"
                                />
                                  <FormLabel>{justificatiFileName}</FormLabel>
                                
                            </FormGroup> */}
    
                                <BodyDiv>
                                <div  style={{width: "95%"}}>
                                    <span style={{color:"#2174B9",fontSize:"1rem",alignSelf:"start"}}>Liste des fichiers déposés</span>

                                    <Table style={{marginTop: "10px"}}>
                                        <THead>
                                            <tr>
                                                <TH style={{width:"65%"}}>Nom de fichier</TH>
                                                <TH>Poids</TH>
                                                <TH></TH>
                                            </tr>
                                        </THead>
                                        {body}
                                    </Table>
                                    </div>
                                <NotDiv>
                                    <span style={{color:"#2174B9",fontSize:"1rem"}}>Notice de dépôt</span>
                                <span>Les factures doivent étre déposées au format PDF et ne doivent pas excéder un poids de 8 Mega Octets (Mo) par facture.</span>
                                <span>Elles seront visibles dans le suivi de factures 10 minutes après leur dépôt sur le portail.</span>
                                </NotDiv>
                            </BodyDiv>
                        </UICol>
                    </UISection>
                </UIBody>
                <InfoModal show={showModal} handleClose={() => setShowModal(false)} title={"Facture importée"} content={"Votre facture est en cours de traitement, vous pourrez visualiser son cycle de vie dans le suivi de vos factures."}></InfoModal>
            </UIContainer>
        </>
    )
}
export default UploadInvoice
