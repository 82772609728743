import React, { useState, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./DocumentList.css"
import { ETRadiosContainer, FormRadioWrapper, FormRadio, RadioButtonLabel, FormLabel } from "./DocumentList.styled"
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, PrimaryBtnOutlineActionModal, SecondaryBtnOutlineActionModal } from '../../../../../styles/Common';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

function DocumentList({ onDocumentChangeHandler, name, type, othertypename, documentTypeChangeHandler, inputChangeHandler, entityDocuments }) {

    const [showListDocument, setShowListDocument] = useState(false);
    const [otherTypeInput, setOtherTypeInput] = useState(null);
    const [tempDocType, setTempDocType] = useState(null)
    

    useEffect(() => {
        setOtherTypeInput(othertypename)
        setTempDocType(type)
        // console.log(name)
        // console.log(type)
        // console.log(othertypename)
    }, [])


    useEffect(() => {
        // return () => {
        //     setOtherTypeInput("")
        //     setTempDocType("")
        // } 
    }, [])

    const inputChange = (e) => {
        setOtherTypeInput(e.target.value)
    }

    return (    
        <>
            <ExpandMoreIcon style={{ color: "#2174B9" }} onClick={e => setShowListDocument(!showListDocument)} />
            {
                showListDocument &&
                <fieldset className="documentListType">
                    {/* <legend>Select a maintenance drone:</legend> */}
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "23px" }}
                            id="1" 
                            name={name} 
                            value="INSURANCE"
                            checked={tempDocType=="INSURANCE"}
                            onClick={ e => {setTempDocType("INSURANCE");setOtherTypeInput("")}}
                        // onChange={parametersChangeHandler}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="left">Attestation d'assurance</FormLabel>
                    </FormRadioWrapper>
                    {/* <div>
                            <input type="radio" id="1" name={name} value="1" className="radio-custom"/>
                            <label className="radio-custom-label">Attestation d'assurance</label>
                        </div> */}
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "23px" }}
                            id="2" 
                            name={name} 
                            value="URSSAF"
                            checked={tempDocType=="URSSAF"}
                            onClick={ e => {setTempDocType("URSSAF");setOtherTypeInput("")}}
                        // onChange={parametersChangeHandler}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="left">Attestation sociale de vigilance</FormLabel>
                    </FormRadioWrapper>
                    {/* <div>
                            <input type="radio" id="2" name={name} value="2" className="radio-custom"/>
                            <label className="radio-custom-label">Attestation sociale de vigilance</label>
                        </div> */}
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "23px" }}
                            id="3" name={name} value="KBIS"
                            checked={tempDocType=="KBIS"}
                            onClick={ e => {setTempDocType("KBIS");setOtherTypeInput("")}}
                        // onChange={parametersChangeHandler}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="left">Justificatif immatriculation KBIS</FormLabel>
                    </FormRadioWrapper>
                    {/* <div>
                            <input type="radio" id="3" name={name} value="3" className="radio-custom"/>
                            <label className="radio-custom-label">Justificatif immatriculation KBIS</label>
                        </div> */}
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "23px" }}
                            id="4" name={name} value="FOREIGN"
                            checked={tempDocType=="FOREIGN"}
                            onClick={ e => {setTempDocType("FOREIGN");setOtherTypeInput("")}}
                        // onChange={parametersChangeHandler}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="left">Liste des salariés étrangers</FormLabel>
                    </FormRadioWrapper>
                    {/* <div>
                            <input type="radio" id="4" name={name} value="4" className="radio-custom"/>
                            <label className="radio-custom-label">Liste des salariés étrangers</label>
                        </div> */}
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "23px" }}
                            id="5" name={name} value="IBAN"
                            checked={tempDocType=="IBAN"}
                            onClick={ e => {setTempDocType("IBAN");setOtherTypeInput("")}}
                        // onChange={parametersChangeHandler}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="left">Coordonnées bancaires</FormLabel>
                    </FormRadioWrapper>
                    {/* <div>
                            <input type="radio" id="5" name={name} value="5" className="radio-custom"/>
                            <label className="radio-custom-label">Coordonnées bancaires</label>
                        </div> */}
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "23px" }}
                            id="6" name={name} value="OTHER"
                            checked={tempDocType=="OTHER" && tempDocType!=null}
                            onClick={ e => setTempDocType("OTHER")}
                        // onChange={parametersChangeHandler}
                        />
                        <RadioButtonLabel />
                        {/* <FormLabel htmlFor="left"></FormLabel> */}
                        <input 
                            type="text" 
                            name="docu" 
                            value={ tempDocType=="OTHER" ? otherTypeInput : ""}
                            className="inputText" 
                            disabled={tempDocType!="OTHER"}
                            onChange={e => inputChange(e)}
                            placeholder="Saisissez votre type"
                            />
                    </FormRadioWrapper>
                    {/* <div>
                            <input type="radio" id="6" name={name} value="6" className="radio-custom"/>
                            <label className="radio-custom-label"></label>
                            <input type="text" name="docu" className="inputText"/>
                        </div> */}
                    <div style={{display: 'flex', justifyContent: 'center', marginTop:"10px"}}>
                        <PrimaryBtnOutlineActionModal
                            style={{}}
                            onClick={e => {
                                setShowListDocument(!showListDocument)
                                documentTypeChangeHandler(name, tempDocType)
                                inputChangeHandler(name, otherTypeInput)
                            }}
                        >
                            <DoneIcon />
                        </PrimaryBtnOutlineActionModal>
                        <SecondaryBtnOutlineActionModal
                            onClick={e => {
                                setTempDocType(type);   
                                setShowListDocument(!showListDocument)
                            }}
                        >
                            <CloseIcon />
                        </SecondaryBtnOutlineActionModal>
                    </div>
                </fieldset>

            }
        </>
    )
}

export default DocumentList