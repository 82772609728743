import React, {useState, useEffect, useCallback} from 'react'
import Axios from '../../axios-proas';
import { Notyf } from 'notyf';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { BtnAction, BtnIcon, PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, PrimaryBtnOutlineLink, FormGroup, FormInput, FormLabel, FormSelect, Header, HeaderActions, HeaderInfos, HeaderLocation, SearchInput, SearchInputContainer } from '../../styles/Common';
import { ESBody, ESCol, ESContainer, ESContent, ESForm, ESSubject, ESTitle, ESView, ESViewActions, ESViewHeader, ToggleInput } from './EmailsSettings.styled';
import { useTheme } from 'styled-components';
import { ToggleIcon, ToggleLabel } from '../CampaignForm/CampaignForm.styled';
import EmailEditor from '../../components/EmailsSettings/EmailEditor/EmailEditor';
import AutoComplete from '../../components/UI/AutoComplete/AutoComplete';
import { Spinner } from 'react-bootstrap';
import EmailPreview from '../../components/EmailsSettings/EmailPreview/EmailPreview';

export default function EmailsSettings() {
    const[emailTypes, setEmailTypes] = useState([]);
    const[emailTypesSuggestions, setEmailTypesSuggestions] = useState([]);
    const[emailTitles, setEmailTitles] = useState([]);
    const [email, setEmail] = useState({
        mailType: '',
        title: '',
        fr_subject: '',
        fr_value: '',
        forAdministration: 0,
        forCustomerEntity: 0,
        forIndustrie: 0,
        forService: 0,
        forSupplierEntity: 0
    });
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [viewingEmail, setViewingEmail] = useState(false);
    const notyf = new Notyf();
    const {t} = useTranslation();
    const theme = useTheme();

    const getEmailsTypes = useCallback(() => {
        Axios.get('/settingsemail//mailTypes').then(res => {
            const types = res?.data && res.data.length > 0 ? res.data : [];
            setEmailTypes(types);
        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"));
        });
    }, [])

    const getEmailsTypesSuggestions = useCallback((type) => {
        Axios.get('/settingsemail//mailTypesSuggestions', {
            params: {
                type: type
            }
        }).then(res => {
            const types = res?.data && res.data.length > 0 ? res.data : [];
            setEmailTypesSuggestions(types);
        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"));
        });
    }, [email?.mailType])
    
    const getEmailsTitles = useCallback((type) => {
        Axios.get('/settingsemail//mailTitles', {
            params: {
                mailType: type
            }
        }).then(res => {
            const titles = res?.data && res.data.length > 0 ? res.data : [];
            setEmailTitles(titles);
        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"));
        });
    }, [email?.mailType])

    const getEmailByParams = useCallback((type, title) => {
        Axios.get('/settingsemail//emailByParams', {
            params: {
                mailType: type,
                mailTitle: title
            }
        }).then(res => {
            const email = res?.data?.email  ? res.data?.email : null;
            setEmail(email);
        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"));
        });
    }, [email?.mailType, email?.title])

    const checkExistEmail = useCallback((title) => {
        Axios.get('/settingsemail//checkExistEmail', {
            params: {
                mailTitle: title
            }
        }).then(res => {
            const exist = res?.data?.exist  ? res.data?.exist : false;
            if(exist) {
                setErrors([...errors, t('global:errorTitle', "Ce titre existe déjà")]);
            } else {
                setErrors([]);
            }
        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"));
        });
    }, [email?.title])

    useEffect(() => {
        getEmailsTypes();
        setViewingEmail(false);
    }, [])

    const emailChangeHandler = (e) => {
        const {name, value} = e.target;
        setErrors([]);
        setEmail(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const emailTypeChangeHandler = (e) => {
        const {value} = e.target;
        setErrors([]);
        setEmail(prevState => ({
            ...prevState,
            mailType: value,
            title: '',
            fr_subject: '',
            fr_value: ''
        }));
        getEmailsTitles(value);
    }

    const typeACChangeHandler = (e) => {
        const {value} = e.target;
        setErrors([]);
        if(value?.length > 2){
            getEmailsTypesSuggestions(value);
        }else {
            setEmailTypesSuggestions([]);
        }
        setEmail(prevState => ({
            ...prevState,
            mailType: value
        }));
    }

    const onSelectTypeACHandler = (value) => {
        setErrors([]);
        setEmail(prevState => ({
            ...prevState,
            mailType: value
        }));
        setEmailTypesSuggestions([])
    }

    const titleChangeHandler = (e) => {
        const {value} = e.target;
        setViewingEmail(false);
        setErrors([]);
        setEmail(prevState => ({
            ...prevState,
            title: value
        }));
        getEmailByParams(email?.mailType, value);
    }

    const checkExistEmailHandler = (e) => {
        const {value} = e.target;
        if(value && value.length > 0 ) {
            checkExistEmail(value);
        }
    }

    const emailContentChangeHandler = (content) => {
        setEmail(prevState => ({
            ...prevState,
            fr_value : content
        }));
    }

    const newEmailClickHandler = () => {
        setEmail({
            new: true,
            mailType: '',
            title: '',
            fr_subject: '',
            fr_value: '',
            forAdministration: 0,
            forCustomerEntity: 0,
            forIndustrie: 0,
            forService: 0,
            forSupplierEntity: 0
        });
    }

    const addEmail = () => {
        Axios.post('/settingsemail//email', email).then(res => {
            setIsLoading(false);
            if(res?.data?.success) {
                notyf.success(t('global:success', "Email ajouté avec succès"));
                setEmail({
                    new: false,
                    mailType: '',
                    title: '',
                    fr_subject: '',
                    fr_value: '',
                    forAdministration: 0,
                    forCustomerEntity: 0,
                    forIndustrie: 0,
                    forService: 0,
                    forSupplierEntity: 0
                });
                getEmailsTypes();
            } else {
                notyf.error(t('global:error', "Une erreur s'est produite"));
            }
        }).catch(err => {
            setIsLoading(false);
            notyf.error(t('global:error', "Une erreur s'est produite"));
        });
    }

    const editEmail = () => {
        Axios.put(`/settingsemail/${email?.uid}/email`, email).then(res => {
            setIsLoading(false);
            notyf.success(t('global:success', "La modification a été effectuée avec succès"));
        }).catch(err => {
            setIsLoading(false);
            notyf.error(t('global:error', "Une erreur s'est produite"));
        });
    }

    const sendEmail = () => {
        Axios.put(`/settingsemail//sendEmail`).then(res => {
            notyf.success(t('global:success', "La modification a été effectuée avec succès"));
        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"));
        });
    }

    const saveEmailClickHandler = () => {
        let errors = checkEmailForm()
        if(errors.length > 0)
            setErrors(errors)
        else if (email?.new) {
            setErrors([]);
            setIsLoading(true);
            addEmail()
        }else {
            setIsLoading(true);
            setErrors([]);
            editEmail();
        }
    }

    const checkEmailForm = () => {
        let errors = []
        if(email?.title === '') {
            errors.push(t('global:errorTitle', "Le titre est obligatoire"));
        }
        if(email?.fr_subject === '') {
            errors.push(t('global:errorSubject', "Le sujet est obligatoire"));
        }
        if(email?.fr_value === '') {
            errors.push(t('global:errorContent', "Le contenu est obligatoire"));
        }
        if(email?.mailType === '') {
            errors.push(t('global:errorType', "Le type est obligatoire"));
        }
        return errors;
    }

    const optionsChangeHandler = (key, value) => {
        setEmail(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    return (
        <>
            <ESContainer>
                <TitleHelmet title={"Démo Portail | " + t("Emails")} />
                <Header>
                    <HeaderInfos>
                        <HeaderLocation>{t("menu:emailsSettings", "Edition des models")}</HeaderLocation>
                    </HeaderInfos>
                    <HeaderActions>
                        <PrimaryBtnOutlineAction
                            color={theme.colors.primary}
                            border={theme.colors.primary}
                            onClick={() => newEmailClickHandler()}
                        >
                            <span>Nouveau</span> 
                            <BtnIcon>
                                <AddIcon fontSize="small" />
                            </BtnIcon>
                        </PrimaryBtnOutlineAction>
                        {
                            isLoading ? 
                                <Spinner />
                            : (
                                <PrimaryBtnOutlineAction 
                                    onClick={saveEmailClickHandler}
                                >
                                    <span>Enregistrer</span>
                                </PrimaryBtnOutlineAction>
                            )
                        }
                    </HeaderActions>
                </Header>
                <ESBody>
                    <ESContent>
                        {
                            errors && errors.length > 0 ? (
                                <div className="alert alert-danger" style={{fontSize: "0.9rem"}} role="alert">
                                    <ul>
                                        {
                                            errors.map(error => (<li key={error}>{error}</li>))
                                        }
                                    </ul>
                                </div>
                            ) : null
                        }
                        <ESForm>
                            <ESCol>
                                <FormGroup>
                                    <FormLabel htmlFor="mailType" >Type de mail</FormLabel>
                                    {
                                        email?.new ? (
                                            <AutoComplete
                                                name="mailType"
                                                value={email?.mailType || ""}
                                                onChange={typeACChangeHandler}
                                                suggestions={emailTypesSuggestions}
                                                onSelect={onSelectTypeACHandler}
                                            />
                                        ) : (
                                            <FormSelect 
                                                id="mailType"
                                                name="mailType"
                                                value={email?.mailType || ""}
                                                onChange={(e) => emailTypeChangeHandler(e)}
                                            >
                                                <option value="" disabled selected>-- Selectionner un type -- </option>
                                                {
                                                    emailTypes.map((row, index) => (
                                                        <option key={index} value={row.mailType}>{row.mailType}</option>
                                                    ))
                                                }
                                            </FormSelect>
                                        )
                                    }
                                </FormGroup>
                                <FormGroup className={(email?.title || email?.new) ? 'fadeIn' : 'fadeOut'}>
                                    <FormLabel htmlFor="mailType" >Type d'entité</FormLabel>
                                    <ToggleInput>
                                        <ToggleLabel active={+email?.forSupplierEntity}> Fournisseur </ToggleLabel>
                                        <ToggleIcon active={+email?.forSupplierEntity}>
                                            {
                                                +email?.forSupplierEntity ? 
                                                    <ToggleOnIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forSupplierEntity", 0)}
                                                    /> :
                                                    <ToggleOffIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forSupplierEntity", 1)}
                                                    />
                                            }
                                        </ToggleIcon>
                                    </ToggleInput>
                                    <ToggleInput>
                                        <ToggleLabel active={+email?.forCustomerEntity}> Client </ToggleLabel>
                                        <ToggleIcon active={+email?.forCustomerEntity}>
                                            {
                                                +email?.forCustomerEntity ? 
                                                    <ToggleOnIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forCustomerEntity", 0)}
                                                    /> :
                                                    <ToggleOffIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forCustomerEntity", 1)}
                                                    />
                                            }
                                        </ToggleIcon>
                                    </ToggleInput>
                                </FormGroup>
                            </ESCol>
                            <ESCol>
                                <FormGroup className={(email?.mailType || email?.new) ? 'fadeIn' : 'fadeOut'}>
                                    <FormLabel htmlFor="emailTitle" >Titre</FormLabel>
                                    {
                                        email?.new ? (
                                            <FormInput
                                                style={{textAlign: "left"}}
                                                type="text"
                                                name="title"
                                                id="emailTitle"
                                                value={email?.title || ""}
                                                onChange={(e) => emailChangeHandler(e)}
                                                onBlur={(e) => checkExistEmailHandler(e)}
                                                autoComplete="off"
                                            />
                                        ) : (
                                            <FormSelect 
                                                id="emailTitle"
                                                name="title"
                                                value={email?.title || ""}
                                                onChange={(e) => titleChangeHandler(e)}
                                            >
                                                <option value="" disabled selected>-- Selectionner un titre -- </option>
                                                {
                                                    emailTitles.map((row, index) => (
                                                        <option key={index} value={row.title}>{row.title}</option>
                                                    ))
                                                }
                                            </FormSelect>
                                        )
                                    }
                                </FormGroup>
                                <FormGroup className={(email?.title || email?.new) ? 'fadeIn' : 'fadeOut'}>
                                    <FormLabel htmlFor="mailType" >Famille</FormLabel>
                                    <ToggleInput>
                                        <ToggleLabel active={+email?.forAdministration}> Administration </ToggleLabel>
                                        <ToggleIcon active={+email?.forAdministration}>
                                            {
                                                +email?.forAdministration ? 
                                                    <ToggleOnIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forAdministration", 0)}
                                                    /> :
                                                    <ToggleOffIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forAdministration", 1)}
                                                    />
                                            }
                                        </ToggleIcon>
                                    </ToggleInput>
                                    <ToggleInput>
                                        <ToggleLabel active={+email?.forIndustrie}> Industrie </ToggleLabel>
                                        <ToggleIcon active={+email?.forIndustrie}>
                                            {
                                                +email?.forIndustrie ? 
                                                    <ToggleOnIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forIndustrie", 0)}
                                                    /> :
                                                    <ToggleOffIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forIndustrie", 1)}
                                                    />
                                            }
                                        </ToggleIcon>
                                    </ToggleInput>
                                    <ToggleInput>
                                        <ToggleLabel active={+email?.forService}> Service </ToggleLabel>
                                        <ToggleIcon active={+email?.forService}>
                                            {
                                                +email?.forService ? 
                                                    <ToggleOnIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forService", 0)}
                                                    /> :
                                                    <ToggleOffIcon 
                                                        fontSize="small"
                                                        onClick={() => optionsChangeHandler("forService", 1)}
                                                    />
                                            }
                                        </ToggleIcon>
                                    </ToggleInput>
                                </FormGroup>
                            </ESCol>
                        </ESForm>
                        <ESView className={(email?.title || email?.new) ? 'fadeIn' : 'fadeOut'}>
                            <ESViewHeader>
                                <ESSubject >
                                    <FormLabel htmlFor="emailSubject" >Sujet</FormLabel>
                                    <FormInput 
                                        style={{textAlign: "left"}}
                                        id="emailSubject"
                                        type="text"
                                        name="fr_subject"
                                        value={email?.fr_subject || ""}
                                        onChange={(e) => emailChangeHandler(e)}
                                        autoComplete="off"
                                    />
                                </ESSubject>
                                <ESViewActions>
                                    {
                                        !viewingEmail ? (
                                            <BtnAction
                                                color={theme.colors.secondary}
                                                disabled={!email?.fr_value}
                                                onClick={() => setViewingEmail(true)}
                                            >
                                                Visualiser
                                            </BtnAction>
                                        ) : (
                                            <BtnAction
                                                color={theme.colors.secondary}
                                                disabled={!email?.fr_value}
                                                onClick={() => setViewingEmail(false)}
                                            >
                                                Editer
                                            </BtnAction>
                                        )
                                    }
                                    {/* <BtnAction color={theme.colors.secondary} onClick={() => sendEmail()}>Envoyer</BtnAction> */}
                                </ESViewActions>
                            </ESViewHeader>
                            {
                                viewingEmail ? (
                                    <EmailPreview convertedContent={email?.fr_value || ""} />
                                ) : (
                                    <EmailEditor 
                                        content={email?.fr_value || ""}
                                        addTextHandler={emailContentChangeHandler}
                                    />
                                )
                            }
                        </ESView>
                    </ESContent>
                </ESBody>
            </ESContainer>
        </>
    )
}
