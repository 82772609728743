import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { TH, THContainer, THead } from '../../../styles/Table.styled';

function TableHead(props) {
    const {columns, columnClickHandler, reverse, entityFilter} = props;
    
    return (
        <THead>
            <tr>
                {columns.filter(row=>row.show==true).map(column => (  
                    <TH 
                     
                        key={column.field} 
                        width={column.width}
                        
                        scope="col" 
                        id={column.field} 
                        onClick={() => columnClickHandler(column.field, reverse)}
                    >
                        <THContainer style={{justifyContent:column.alignSelf}}>
                            <span>
                                {column.title}
                            </span>
                            <ArrowUpwardIcon style={{fontSize: 20, display: reverse[column.field] === true ? 'block' : 'none'}} />
                            <ArrowDownwardIcon style={{fontSize: 20, display: reverse[column.field] === false ? 'block' : 'none'}}/>
                        </THContainer>     
                    </TH>
                ))}
                {
                    entityFilter != "client" ? 
                        <TH key={"column1453"} >
                            <THContainer>
                                <span>Contacts</span>
                            </THContainer>
                        </TH>
                        :null
                }
            </tr>
        </THead>
    )
}

export default TableHead
