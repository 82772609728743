import React, { useEffect, useState, useMemo, useCallback } from 'react'
import Axios from '../../../../../axios-proas'
import { FContainer, FFormWrap, FHeader, FHInfos, EntInfosContainer, EIFormContainer, EIFormCol, TelInput, FormLabelSpec, FormGroup, FormEntCanal, FormEntity } from './Fields.styled'
import {
  BtnSmOutlineAction, FormSelect, FormGroupInvInf, FormECILabel, FormECItem,
  FormECIValue, FormECLabel, FormECListContainer, SendButton, FormLabel, FormInput,
  PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, FormECListContainerAccountRequest, PrimaryBtnOutlineActionModal, SecondaryBtnOutlineActionModal
} from "../../../../../styles/Common";


import { ETRadiosContainer, FormRadioWrapper, FormRadio, RadioButtonLabel } from "../EntitiesList/EntitiesList.styled"



import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import frLocale from "date-fns/locale/fr";
import { DPContainer } from '../../../../../containers/CampaignForm/CampaignForm.styled';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { checkValidity, getNotyfObject } from '../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MandatoryMarker from '../../../../../components/UI/MandatoryMarker';
import fr from 'react-phone-input-2/lang/fr.json'
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EntitiesList from '../EntitiesList/EntitiesList'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

function Fields({ invoiceData, setInvoiceData, entity, setEntity, owners }) {
  const [showLEntitiesList, setShowEntitiesList] = useState(false);
  const [data, setData] = useState(entity.owners)
  const gtgg = useMemo(() => (entity?.owners), [])
  const theme = useTheme();
  const { t } = useTranslation();

  const supplierCanalList = useMemo(() => ([
    { value: 'PORTAL', label: t("signin:accessPortal", "Portail") },
    { value: 'EMAIL', label: t("signin:canalEmail", "Email") },
    { value: 'PAPER', label: t("signin:edi", "Papier") },
    { value: 'EDI', label: t("signin:edi", "EDI") },
    { value: 'PDP', label: t("signin:pdp", "PDP") },
    { value: 'PPF', label: t("signin:pdp", "PPF") }
  ]), [t]);

  const [selectedOwnersState, setSelectedOwnersState] = useState([])
  const [filtredSelectedOwners, setFiltredSelectedOwners] = useState(entity?.owners.filter(owner => owner.selected == true))
  useEffect(() => {
    setSelectedOwnersState(entity?.owners)
    setFiltredSelectedOwners(entity?.owners.filter(owner => owner.selected == true))
  }, [entity])

  // useEffect(() => {
  //   setFiltredSelectedOwners(selectedOwnersState.filter(owner => owner.selected == true))
  // }, [selectedOwnersState])

  const canalChangeHandler = (value) => {
    let entityTmp = {
      ...entity,
      channel: value
    };
    setEntity(entityTmp);
  }

  const ownersChangeHandler = () => {
    let entityTemp = {
      ...entity,
      owners: selectedOwnersState
    }
    setEntity(entityTemp)
  }

  const ownersResetHandler = () => {
    setSelectedOwnersState(entity?.owners)
  }

  const selectRowHandler = (e, ref) => {
    let temp = { ...selectedOwnersState[ref], selected: e.target.checked }
    temp.selected = e.target.checked
    setSelectedOwnersState(Object.values({ ...selectedOwnersState, [ref]: temp }))
  }

  const deleteEntityHandler = (ref, name) => {
    // console.log(ref)
    let temp = selectedOwnersState
    console.log(selectedOwnersState)
    // console.log(temp)
    // console.log(temp[ref])
    let elem = temp.filter(own => own.name == name)
    elem[0].selected = 0
    // console.log(elem[0])
    setSelectedOwnersState(Object.values({ ...selectedOwnersState, [ref]: elem[0] }))
    let entityTemp = {
      ...entity,
      owners: temp
    }
    setEntity(entityTemp)

  }


  const handleInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setEntity({ ...entity, [name]: value })
  }


  return (
    <>
      <EntInfosContainer>
        <EIFormContainer>
          <FormGroup>
            <FormLabel htmlFor="name" >Nom de la société</FormLabel>
            <FormInput
              id="name"
              type="text"
              name="name"
              // placeholder="Nom"
              value={entity?.name || ""}
              onChange={handleInputChange}
            />
            {/* <FormLabelSpec>{entity?.name || ""} </FormLabelSpec> */}
          </FormGroup>
          {/* <FormGroup>
                        <FormLabel htmlFor="code" >N° SIREN</FormLabel>
                        <FormInput 
                            id="code"
                            type="text"
                            name="code"
                            // placeholder="Code"
                            value={entity?.code || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup> */}
          <FormGroup>
            <FormLabel htmlFor="siret" >N° SIRET</FormLabel>
            <FormInput
              id="siret"
              type="number"
              name="siret"
              // placeholder="Identifiant 1: N° Siret"
              value={entity?.siret || ""}
              onChange={handleInputChange}
            />
            {/* <FormLabelSpec>{entity?.siret || ""} </FormLabelSpec> */}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="siren" >N° SIREN</FormLabel>
            <FormInput
              id="siren"
              type="number"
              name="siren"
              // placeholder="Identifiant 2: N° Siren"
              value={entity?.siren || ""}
              onChange={handleInputChange}
            />
            {/* <FormLabelSpec>{entity?.siren || ""} </FormLabelSpec> */}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="vat" >N° TVA</FormLabel>
            <FormInput
              id="vat"
              type="text"
              name="vat"
              // placeholder="Identifiant 3: N° TVA"
              value={entity?.vat || ""}
              onChange={handleInputChange}
            />
            {/* <FormLabelSpec>{entity?.vat || ""} </FormLabelSpec> */}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="address" >Adresse</FormLabel>
            <FormInput
              id="address"
              type="text"
              name="address"
              // placeholder="Adresse"
              value={entity?.address || ""}
              onChange={handleInputChange}
            />
            {/* <FormLabelSpec>{entity?.address || ""} </FormLabelSpec> */}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="addressComplement" >Complément</FormLabel>
            <FormInput
              id="addressComplement"
              type="text"
              name="addressComplement"
              // placeholder="Complément d'adresse"
              value={entity?.addressComplement || ""}
              onChange={handleInputChange}
            />
            {/* <FormLabelSpec>{entity?.addressComplement || ""} </FormLabelSpec> */}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="zipCode" >Code postal</FormLabel>
            <FormInput
              id="zipCode"
              type="number"
              name="zipCode"
              // placeholder="Code postal"
              value={entity?.zipCode || ""}
              onChange={handleInputChange}
            />
            {/* <FormLabelSpec>{entity?.zipCode || ""} </FormLabelSpec> */}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="city" >Ville</FormLabel>
            <FormInput
              id="city"
              type="text"
              name="city"
              // placeholder="Ville"
              value={entity?.city || ""}
              onChange={handleInputChange}
            />
            {/* <FormLabelSpec>{entity?.city || ""} </FormLabelSpec> */}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="countryCode" >Pays</FormLabel>
            <FormSelect
              id="countryCode"
              name="countryCode"
              value={entity?.countryCode || ""}
              onChange={handleInputChange}
            >
              <option value="fr">FRANCE</option>
            </FormSelect>
            {/* <FormLabelSpec>{entity?.countryCode || ""} </FormLabelSpec> */}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="entityAdmin" >Contact administrateur</FormLabel>
            {
              // entity?.id ? (
              //     <FormSelect 
              //         id="adminContact"
              //         name="adminContact"
              //         value={entity?.adminContact || ""}
              //         onChange={handleInputChange}
              //     >
              //         {
              //             entity?.admins && entity?.admins.map(admin => (
              //                 <option key={admin.id} value={admin.uid}>{admin.login}</option>
              //             ))
              //         }
              //     </FormSelect>
              // ) : (
              <FormInput
                id="entityAdmin"
                type="email"
                name="entityAdmin"
                // placeholder="Contact administrateur"
                value={entity?.login || ""}
                onChange={handleInputChange}
              />
              // )
            }
            {/* <FormLabelSpec>{entity?.adminContact || ""} </FormLabelSpec> */}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="telephone" >Téléphone du contact</FormLabel>
            {/* <TelInput
                            style={{color: "#505050 !important"}}
                            id="telephone"
                            localization={fr}
                            country={'fr'}
                            dropdownStyle={{width: "auto"}}
                            preferredCountries={['fr', 'us']}
                            enableSearch={false}
                            name="telephone"
                            inputProps={{name: "telephone"}}
                            placeholder={"33 0 00 00 00 00"}
                            value={entity?.telephone?.substr(2) || ''}
                            onChange={(phone) => handlePhoneInputChange("00" + phone)} 
                        />
                      */}
            <FormInput
              id="telephone"
              type="text"
              name="telephone_number"
              // placeholder="Téléphone"
              value={entity?.telephone_number || ""}
              onChange={handleInputChange}
            />
            {/* <FormLabelSpec>{entity?.telephone || ""} </FormLabelSpec> */}
          </FormGroup>

          <FormEntCanal>
            {/* <FormECLabel style={{marginRight:"10px"}}>{t("signin:canal", "Canal d'échange")}</FormECLabel> */}
            <FormECLabel style={{ marginRight: "10px" }}>Canaux d'échanges</FormECLabel>
            <FormECListContainer>
              {
                supplierCanalList.map((canal, index) => (
                  <FormECItem >
                    <FormECILabel>{canal?.label} </FormECILabel>
                    <FormECIValue isActive={entity?.channel === canal.value}>
                      {
                        entity?.channel === canal.value ? (
                          <ToggleOnIcon
                            style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                            fontSize="small"
                            name="edi"
                            onClick={() => canalChangeHandler(null)}
                          />
                        ) :
                          <ToggleOffIcon
                            style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                            fontSize="small"
                            name="edi"
                            onClick={() => canalChangeHandler(canal?.value)}
                          />
                      }
                    </FormECIValue>
                  </FormECItem>
                ))
              }
            </FormECListContainer>
          </FormEntCanal>
          <FormGroup>
            <FormLabel htmlFor="telephone" >Entité(s) facturée(s)

              {/* <EntitiesList
                selectedOwnersStateCopy={data}
                ownersResetHandler={ownersResetHandler}
                selectRowHandler={selectRowHandler} 
                selectedOwnersState={selectedOwnersState} 
                setSelectedOwnersState={setSelectedOwnersState} 
                ownersChangeHandler={ownersChangeHandler} 
                selectedOwners={entity?.owners} owners={owners} 
              />
             */}


              <>
                <AddCircleIcon style={{ color: "#809FB8", width: "17px", marginLeft: "10px" }} onClick={e => setShowEntitiesList(true)} />
                {/* <ExpandMoreIcon style={{ color: "#2174B9" }} onClick={e => setShowEntitiesList(!showLEntitiesList)} /> */}
                {
                  showLEntitiesList &&
                  <div className="entitiesListType">
                    <div className="entitiesListTypeContainer">

                      {
                        selectedOwnersState.map((owner, index) => {
                          return (
                            <>
                              <FormRadioWrapper>
                                <input
                                  type="checkbox"
                                  name={owner?.name}
                                  onChange={(e) =>
                                    selectRowHandler(e, index)
                                  }
                                  checked={owner?.selected}
                                  className="form-check-input checkBoxInput"
                                  id="exampleCheck1"
                                />
                                <FormLabel htmlFor="left">{owner?.name}</FormLabel>
                              </FormRadioWrapper>
                            </>

                          )
                        })
                      }

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: "10px" }}>
                      <PrimaryBtnOutlineActionModal
                        style={{}}
                        onClick={e => { setShowEntitiesList(false); ownersChangeHandler() }}
                      >
                        <DoneIcon />
                      </PrimaryBtnOutlineActionModal>
                      <SecondaryBtnOutlineActionModal
                        onClick={e => { setShowEntitiesList(false); ownersResetHandler() }}
                      >
                        <CloseIcon />
                      </SecondaryBtnOutlineActionModal>
                    </div>
                  </div>

                }
              </>
            </FormLabel>

            {/* <TelInput
                            style={{color: "#505050 !important"}}
                            id="telephone"
                            localization={fr}
                            country={'fr'}
                            dropdownStyle={{width: "auto"}}
                            preferredCountries={['fr', 'us']}
                            enableSearch={false}
                            name="telephone"
                            inputProps={{name: "telephone"}}
                            placeholder={"33 0 00 00 00 00"}
                            value={entity?.telephone?.substr(2) || ''}
                            onChange={(phone) => handlePhoneInputChange("00" + phone)} 
                        />
                         <FormInput 
                            id="telephone"
                            type="text"
                            name="telephone"
                            // placeholder="Téléphone"
                            value={entity?.telephone || ""}
                            onChange={handleInputChange}
                        />  */}
            <FormLabelSpec>
              {

                filtredSelectedOwners?.map((own, index) => {
                  return <FormEntity >
                    {own?.name}
                    <CloseIcon style={{ marginLeft: "13px", color: "#809FB8", cursor: "pointer" }} onClick={e => deleteEntityHandler(e, own.name)} />
                  </FormEntity>
                })
              }



            </FormLabelSpec>
          </FormGroup>

        </EIFormContainer>
      </EntInfosContainer>
    </>
  );
}

export default Fields