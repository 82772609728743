import React ,{useMemo}from 'react'
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import { AppBody, AppContainer, AppContent } from '../../../styles/App.styled';
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import { connect } from 'react-redux';
import DescriptionIcon from '@mui/icons-material/Description';
import Menu from '../../Menu/Menu'
import Entity from '../../Entity/Entity';
import UserForm from '../../UserForm/UserForm';
import SuiviInvoices from '../../SuiviInvoices/SuiviInvoices';
import Documentation from '../../Documentation/Documentation';
import Home from '../../Home/Home';
import ClientHome from '../../ClientHome/ClientHome'
import HomeIcon from '@mui/icons-material/Home';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';
import InvoiceDetail from '../../InvoiceDetail/InvoiceDetail';
import EditPassword from '../../EditPassword/EditPassword';
import ReferentielClient from '../../ReferentielClient/ReferentielClient';
import   DocumentationView from '../../../components/Documentation/DocumentationVisualisation/DocumentationView'
function ClientRoot({entityUid, userType}) {
  const {t} = useTranslation();
  const menuItems = useMemo(() => ([
      {isLink: true,key: 'Accueil', label: 'Accueil', pathname: '/', icon: <HomeIcon />},
      {isLink: true,key: 'Référentiel', label: 'Référentiel', pathname: `/referentiel`, icon: <BusinessIcon />},
      {isLink: true,key: 'Suivi_des_factures', label: 'Suivi des factures', pathname: '/invoices', icon: <DescriptionIcon />},
      {isLink: true,key: 'Documentation', label: 'Documentation', pathname: '/documentation', icon: <ArticleIcon />}
    
  ]), [t])
  return (
  <>
    <AppContainer>
        <Header />
        <AppBody>
        <Menu items={menuItems} userType={userType} />
          <AppContent>
            <Routes>
              <Route path="/invoiceDetail/:uid" element={<InvoiceDetail origin={"client"} />} />
              <Route path="/invoiceDetail/:uid/comments" element={<InvoiceDetail origin={"client"} />} />
              <Route path="/referentiel" element={ <ReferentielClient entityFilter={"client"} />}/>
              <Route path="/entity/:uid" element={<Entity />} />
              <Route path="/userForm/:uid" element={<UserForm />} />
              <Route path="/userForm" element={<UserForm />} />
              <Route path="/invoices" element={ <SuiviInvoices entityFilter={"client"} />}/>
              <Route path="/documentation" element={<Documentation/>} />
              <Route path="/DocumentationView" element={<DocumentationView />} />
              <Route path="/editPassword" element={<EditPassword />}/>
               <Route path="/" element={<ClientHome />} />
            </Routes>
          </AppContent>
        </AppBody>
      </AppContainer>
  </>
  )
}


const mapStateToProps = (state) => ({
  userType : state.auth.userType,
  login : state.auth.login,
  entityUid : state.auth.entityUid,

})

const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (ClientRoot)