import React, {useEffect, useState} from 'react';
import Axios from '../../../axios-proas';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { EventsContainer, EventsHeader, EventsTitle, EventsList, EventsItem, EventColor, EventValue, EventTitle, EventDate, EventAction, EventVal, EventMessage, EventFilterItem, IconsContainer } from './Events.styled';
import {formatDate, getTimeDate} from '../../../shared/utility'
import { useNavigate, useLocation , useParams} from 'react-router-dom';
import { useTheme } from 'styled-components';
import Spinner from 'react-bootstrap/Spinner'
import DropDown from '../../UI/DropDown/DropDown';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import EventsFilter from './EventsFilter/EntityFilter';
import { connect } from 'react-redux';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import EastIcon from '@mui/icons-material/East';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
// const eventsList = [
//     {color: theme.colors.success , value: 'Fournisseur 1', date: '5 minuites'},
//     {color: theme.colors.warning, value: 'Fournisseur 2', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Fournisseur 3', date: '5 minuites'},
//     {color: theme.colors.warning, value: 'Fournisseur 4', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Campagne 1', date: '5 minuites'},
//     {color: theme.colors.danger, value: 'Transdev', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Transdev', date: '5 minuites'},
// ]
function Events({userType}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [eventsList, setEventsList] = useState([]);
    const [loading , setLoading] = useState(false)
    const [filter, setFilter] = useState(["invoice","entity","parapheurs"])
    const [filteredEvents, setFilteredEvents] = useState()

    const getEventEntity = () => {
        setLoading(true)
        Axios.get("/user//userEvents").then(res => {
            const eventsData = res.data?.events ? res.data.events : [];
            setEventsList(eventsData);
            setFilteredEvents(eventsData);
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        }).finally(() => {
            setLoading(false)
        })
    }

    const getuserTypeEvents = () => {
        setLoading(true)
        Axios.get("/user//userTypeEvents").then(res => {
            const eventsData = res.data?.events ? res.data.events : [];
            setEventsList(eventsData);
            setFilteredEvents(eventsData);
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if(userType === 'client' || userType === 'supplier')
            getuserTypeEvents()
        else
            getEventEntity();
    }, [])

    const getInvoiceEventColor = (event) => {
        switch (event?.snapshot) {
            case "IMPORTED":
                return theme.colors.warning;
            case "INTEGRATED":
                return theme.colors.success;
            case "LITIGATION":
            case "ERROR":
                return theme.colors.danger;
            default:
                return theme.colors.success;
        }
    }

    const getInvoiceEventIcon = (event) => {
        switch (event?.snapshot) {
            case "IMPORTED":
                return <IconsContainer className="homeEventsIcons"><ArrowRightAltIcon style={{color:"#C8B0E6"}}/></IconsContainer>
            case "INTEGRATED":
                return <IconsContainer className="homeEventsIcons"><ArrowRightAltIcon style={{color:"#C8B0E6"}}/></IconsContainer>
            case "REFUSED":
            case "REJECTED":
            case "ERROR":
                return <WarningAmberIcon className="homeEventsIcons" style={{color:"#EE5A5A", fontSize:"37px"}}/>
                
            case "LITIGATION":
                return <IconsContainer border={"#EE5A5A"} className="homeEventsIcons">
                    <CloseIcon style={{color:"#EE5A5A"}}   />
                    </IconsContainer>
            // case "ERROR":
            //     return <HighlightOffIcon style={{color:"#EE5A5A"}} />
            // case "":
            //     return <HighlightOffIcon style={{color:"#EE5A5A"}} />
            // case "ERROR":
            //     return <HighlightOffIcon style={{color:"#EE5A5A"}} />
            default:

                return event?.type=='USER_CHAT_EVENT'
                ? <IconsContainer border={"#B8B8B8"} className="homeEventsIcons"><MessageIcon style={{color:"#B8B8B8",border:"#B8B8B8"}}/></IconsContainer> 
                : <IconsContainer className="homeEventsIcons"><ArrowRightAltIcon style={{color:"#C8B0E6"}}/></IconsContainer>;
                // return <IconsContainer className="homeEventsIcons"><ArrowRightAltIcon style={{color:"#C8B0E6"}}/></IconsContainer>
        }
    }
    

    const getEntityEventColor = (event) => {
        switch (event?.type) {
            case "STEP":
                switch (event?.message) {
                    case "INPROGRESS":
                        return theme.colors.warning;
                    case "ACTIVE":
                        return theme.colors.success;
                    default:
                        return theme.colors.warning;
                }
            case "DOCS":
                return theme.colors.warning;
            case "ERROR_FIELD":
                return theme.colors.danger;
            default:
                return theme.colors.warning;
        }
    }

    const getInvoiceEventRealTitle = (eventTmp) => {
        if(userType === 'client')
            return eventTmp?.data?.clientName ? eventTmp.data.clientName : eventTmp?.user?.entityName;
        return eventTmp?.data?.supplierName ? eventTmp.data.supplierName : eventTmp?.user?.entityName;
    }

    const getInvoiceEntityName = (event) => {
        return event?.data?.entityName
    }


    const getInvoiceEventMessage = (event) => {
        switch (event?.snapshot) {
            case "NEW":
                return event?.message ? `Facture : ${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)`  : 'Facture : ' + event?.data?.number;
                break;
            case "TO_BE_VALIDATED":
                return true ? `Facture : ${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)`  : 'Facture : ' + event?.data?.number;
                break;
            case "INTEGRATED":
                return true ? `Facture : ${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : 'Facture : ' + event?.data?.number;
                break;
            case "ERROR":
                return true? `Facture : ${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : 'Facture : ' + event?.data?.number;
                break;
            case "TO_BE_CONTROLLED":
                return true ? `Facture : ${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : 'Facture : ' + event?.data?.number;
                break;
            case "LITIGATION":
                return true? `Facture : ${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : 'Facture : ' + event?.data?.number;
            case "REFUSED":
            case "REJECTED":
                return true ? `Facture : ${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : 'Facture : ' + event?.data?.number;
                break;
            default:
                // return ( event?.snapshot)  ? event?.snapshot : "MessageVide";
                return event?.type=='USER_CHAT_EVENT'
                ? `Facture : ${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : "MessageVide";
        }
    }

    const getInvoiceEventTitle = (event) => {
        switch (event?.snapshot) {
            case "NEW":
                return event?.message ? event?.message : 'Facture n°' + event?.data?.number + ' déposée';
                break;
            case "TO_BE_CONTROLLED":
                return  'Facture n°' + event?.data?.number + ' à contrôler';
                break;
            case "TO_BE_VALIDATED":
                return  'Facture n°' + event?.data?.number + ' à valider';
                break;
            case "INTEGRATED":
                return event?.message ? event?.message : 'Facture n°' + event?.data?.number + ' intégrée';
                break;
            case "ERROR":
                return  'Facture en erreur ';
                break;
            case "PAID":
                return event?.message ? event?.message : 'Facture n°' + event?.data?.number + ' payée';
                break;
            case "LITIGATION":
                return  'Facture n°' + event?.data?.number + ' en litige';
                break;
            case "REFUSED":
                return event?.message ? event?.message : 'Facture n°' + event?.data?.number + ' refusée';
                break;
            default:
                return event?.type=='USER_CHAT_EVENT'
                ? "Nouveau commentaire sur facture en litige" : "MessageVide";
        }
    }
    

    const getEntityEventMessage = (event) => {
        switch (event?.message) {
            case "email":
                return `Champs email erroné`;
            case "INPROGRESS":
                return `Demande de validation`;
            case "ACTIVE":
                return  `Fournisseur activé`;
            case "DOCUMENT":
                return  `Nouveau document`; //${event.snapshot}`;
                // case "0":
                // return  `Nouveau document`; ;
            default:
                // return event?.type="NEW_SUPPLIER_ACCOUNT"? `Référencement : ${event?.data?.entity_id} (` + getInvoiceEventRealTitle(event) + `)`:"MessageVide";
                // return event?.message != "" ? event?.message : "MessageVide";
                return event?.type =='NEW_SUPPLIER_ACCOUNT'
                ? `Référencement : REF${event?.entity_id} (` +event.name+ `)` : "MessageVide";
        }
    }

    const updateFilterHandler = (newFitler) => {

        let filterTemp = null;
        let ft=[...filter];
        switch (newFitler) {
            case "invoice":
                if(filter?.includes("invoice")||filter==null){
                    ft.filter(function(item,index) {
                        if(item==="invoice") 
                        ft.splice(index,1);
                    });

                }
                //  ft.push("parapheurs","entity");
                 
                 else ft.push("invoice");
            break;
            case "parapheurs":
                if(filter?.includes("parapheurs")||filter==null){
                    ft.filter(function(item,index) {
                        if(item==="parapheurs") 
                        ft.splice(index,1);
                    });

                }
                else ft.push("parapheurs");

            break;
            case "entity":
                if(filter?.includes("entity")||filter==null){
                    ft.filter(function(item,index) {
                        if(item==="entity") 
                        ft.splice(index,1);
                    });

                }
              else ft.push("entity");

        break;
            default:
                filterTemp = null;
                break;
        }
        console.log(ft)
        if(ft.length===0) ft=["invoice","entity","parapheurs"];
        let filteredEventsTemp = ft ? eventsList.filter(event => ft.includes(event?.eventType) ) : null;
        setFilter(ft)
        setFilteredEvents(filteredEventsTemp)
    }

    const navigateToEntity=(uid,type)=>{
        if( type === "DOCS"){
            localStorage.setItem("show", "document");
        }
        navigate("/supplierRequestDetail/"+uid)
    }
    const actionTo=(eventTmp)=>{
        if(eventTmp?.eventType === "entity"){
            navigateToEntity(eventTmp.entityUid,eventTmp.type)
        }
        if(eventTmp?.eventType === "invoice"){
            if(eventTmp?.snapshot === "TO_BE_CONTROLLED"){
                eventTmp?.data?navigate("/invoiceCommand/"+eventTmp?.data?.invoiceUid):null
            }else
                if(eventTmp?.snapshot === "TO_BE_VALIDATED"){
                    eventTmp?.data?navigate("/invoiceIntegratedDetail/"+eventTmp?.data?.invoiceUid):null
                }else
                        {
                            eventTmp.hasOwnProperty("data") && eventTmp?.data?navigate("/invoiceDetail/"+eventTmp?.data?.invoiceUid):null
                        }
            
        }
        
        if(eventTmp?.eventType === "parapheurs"){
            eventTmp?.uidParapheur?navigate("/paymentSignatureDetail/"+eventTmp?.uidParapheur):null
        }
    }
    let eventsJsx = null;

    if(loading) 
        eventsJsx = (<Spinner />)
    else if (filteredEvents && filteredEvents?.length > 0){
        eventsJsx = filteredEvents?.map((eventTmp, index) => {
                var eventJsx = null;
                let isLast = index === 0
                let color = theme.colors.warning;
                let title = "";
                let message = "";
                let date = "";
                let action = null;

                switch (eventTmp?.eventType) {
                    case "invoice":
                        color = getInvoiceEventColor(eventTmp);
                        // title = eventTmp?.data?.supplierName ? eventTmp?.data?.supplierName : eventTmp?.user?.entityName;
                        //title = eventTmp?.message ? eventTmp?.message : 
                        title = getInvoiceEventTitle(eventTmp);
                        message = getInvoiceEventMessage(eventTmp);
                        date = formatDate(+eventTmp?.creationDate) + " " + getTimeDate(+eventTmp?.creationDate);
                        // action = (eventTmp.hasOwnProperty("data") && eventTmp?.data ) ? (
                        //     <EventAction   onClick={() => navigate("/invoiceDetail/"+eventTmp?.data?.invoiceUid)}>
                        //         <span>voir</span>
                        //         <ArrowForwardIosIcon />
                        //     </EventAction>
                        // ) : null;

                        
                        break;
                    case "entity":
                        color = getEntityEventColor(eventTmp);
                        title = eventTmp.type=="NEW_SUPPLIER_ACCOUNT"?"Demande de référencement": eventTmp?.name;
                        message = getEntityEventMessage(eventTmp);
                        date = formatDate(+eventTmp?.creationDate) + " " + getTimeDate(+eventTmp?.creationDate);
                        
                        action = (
                            <EventAction   onClick={() => navigateToEntity(eventTmp.entityUid,eventTmp.type)}>
                                <span>voir</span>
                                <ArrowForwardIosIcon />
                            </EventAction>
                        );
                        break;
                        case "parapheurs":
                            color = getEntityEventColor(eventTmp);
                            title = "Parapheur en attente de validation";
                            message =  `Parapheur ${eventTmp.paymentNumber}` ;
                            date = formatDate(+eventTmp?.creationDate) + " " + getTimeDate(+eventTmp?.creationDate);


        
                    default:
                        break;
                }
               return (
                    <EventsItem key={index} onClick={() => actionTo(eventTmp)}>
                        {getInvoiceEventIcon(eventTmp)}
                        {/* <EventColor color={color} /> */}
                        <EventValue>
                            <EventMessage >
                                {message}
                            </EventMessage>
                            <EventDate>
                                {date}
                            </EventDate>
                            <EventVal>
                                {title}
                            </EventVal>
                        </EventValue>
                        {/* {action} */}
                    </EventsItem>
               )
            })
    }
  return (
      <>
        <EventsContainer>
            <EventsHeader>
                <EventsTitle>
                    Évènements
                </EventsTitle>
                <EventsFilter
                    filter={filter}
                    updateFilter={updateFilterHandler}
                />
            </EventsHeader>
            <EventsList>
                {
                    eventsJsx
                }
            </EventsList>  
        </EventsContainer>
      </>
  );
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    login : state.auth.login
  })
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (Events)
