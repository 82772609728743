import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { useTheme } from "styled-components";
import { Modal } from "react-bootstrap";
import {
  CancelBtnOutlineAction,
  PrimaryBtnOutlineAction,
} from "../../../../../../../styles/Common";
import CommandTable from "../../CommandTable";
import InvoiceView from "../../../InvoiceView/InvoiceView";
import DropdownList from "react-widgets/DropdownList";
import "react-widgets/styles.css";
import "./TableAdditionalLine.css"
// import { faUserGear, faDatabase, 
//           faDolly, faPersonRays, 
//           faBell, faHourglassHalf,
//           faFile } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  THead,
  TH,
  THContainer,
  TD,
  TDM,
  THM,
  Table,
  TBody,
  IDContainer,
  IDContainerTable,
  MyModalContainer,
  Input,
  FormSelect,
  SelectDiv,
  SelectIcon
} from "../../InvoiceListCommand.styled";
import Research from "../Research";
import CommandTableModal from "./CommandTableModal";
import Axios from "../../../../../../../axios-proas";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "../Modal.css";


function TableAdditionalLine({ additionalLine, setAdditionalLine }) {
  const theme = useTheme();
  const [navTabsList, setNavTabsList] = useState([]);
  const [commandLineSelected, setCommandLineSelected] = useState([]);
  const [columns, setColumns] = useState([
    { title: "Ref", field: "zipCode", show: true, alignSelf: "center" },
    { title: "Désignation", field: "city", show: true, alignSelf: "center" },
    { title: "Qty", field: "channel", show: true, alignSelf: "center" },
    {
      title: "Prix Unitaire",
      field: "creationDate",
      show: true,
      alignSelf: "center",
    },
    { title: "%R", field: "status", show: true, alignSelf: "center" },
    { title: "Code TVA", field: "city", show: true, alignSelf: "center" },
    { title: "Montant TVA", field: "channel", show: true, alignSelf: "center" },
    { title: "Montant HT", field: "channel", show: true, alignSelf: "center" },
    { title: "Compte", field: "creationDate", show: true, alignSelf: "center" },
  ]);

  const [tvaRate, setTvaRate] = useState(0.05);

  const [priceTxt, setPriceTxt] = useState(null);
  const [tvaTxt, setTvaTxt] = useState(null);
  const [exclusiveTaxAmountTxt, setExclusiveTaxAmountTxt] = useState(null);


  const dataOptions = [
    { value: '6241', name: "[6241] Transport sur achat" },
    { value: '606100', name: "[606100] Fourniture non stockable" },
    { value: '611400', name: "[611400] Frais généraux" }
  ]

  useEffect(() => {
    setPriceTxt(floatToText(additionalLine?.price));
    setTvaTxt(floatToText(additionalLine?.tva));
    setExclusiveTaxAmountTxt(floatToText(additionalLine?.exclusiveTaxAmount));
  }, []);

  const floatToText = (nb) => {
    if (nb) {
      let parts = nb.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    } else {
      return null;
    }
  };

  const textToFloat = (text) => {
    if (text) {
      let number =
        Math.round(parseFloat(text.replace(",", ".").replace(" ", "")) * 100) /
        100;
      console.log(
        "text : ",
        text,
        "number : ",
        number,
        " type : ",
        typeof number
      );
      return number;
    } else {
      return null;
    }
  };

  const inputChangedHandler = (e, account = null) => {

    let name = account != null ? account : e.target.name;
    let value = account != null ? e?.value : e.target.value;

    switch (name) {
      case "codeTva":
        let tva = 0;
        if (value == 1) {
          setTvaRate(0.05);
          tva = 0.05;
        } else if (value == 2) {
          setTvaRate(0);
          tva = 0;
        } else if (value == 4) {
          setTvaRate(0.2);
          tva = 0.2;
        }
        let res =
          Math.round(tva * additionalLine?.exclusiveTaxAmount * 100) / 100;
        setAdditionalLine({
          ...additionalLine,
          [name]: value,
          tva: res,
        });
        setTvaTxt(floatToText(res));
        break;
      case "price":
        setPriceTxt(value);
        break;
      case "qty":
        if (additionalLine?.price) {
          let htValue2 =
            value * (additionalLine?.price ? additionalLine.price : 1);
          setAdditionalLine({
            ...additionalLine,
            [name]: value,
            exclusiveTaxAmount: htValue2,
            tva: Math.round(htValue2 * tvaRate * 100) / 100,
          });
          setExclusiveTaxAmountTxt(floatToText(htValue2));
          setTvaTxt(floatToText(Math.round(htValue2 * tvaRate * 100) / 100));
        } else {
          setAdditionalLine({
            ...additionalLine,
            [name]: value,
          });
        }
        break;
      case "tva":
        setTvaTxt(value);
        break;
      case "exclusiveTaxAmount":
        setExclusiveTaxAmountTxt(value);
      default:
        setAdditionalLine({ ...additionalLine, [name]: value });
    }

    console.log(additionalLine);
  };

  const onBlurPrice = (e) => {
    const { name, value } = e.target;
    let nb = textToFloat(value);
    if (typeof nb === "number") {
      let htValue =
        Math.round(
          value * (additionalLine?.qty ? additionalLine.qty : 1) * 100
        ) / 100;
      setAdditionalLine({
        ...additionalLine,
        [name]: nb,
        exclusiveTaxAmount: htValue,
        tva: htValue * tvaRate,
      });
      setExclusiveTaxAmountTxt(floatToText(htValue));
      setPriceTxt(floatToText(nb));
      setTvaTxt(floatToText(htValue * tvaRate));
    } else {
      setPriceTxt(floatToText(additionalLine.price + ""));
      notyf.error("Valeur incorrecte");
    }
  };

  const onBlurTVA = (e) => {
    const { name, value } = e.target;
    let nb = textToFloat(value);
    if (typeof nb === "number") {
      setAdditionalLine({
        ...additionalLine,
        [name]: nb,
      });
      setTvaTxt(floatToText(nb));
    } else {
      setTvaTxt(floatToText(additionalLine.tva + ""));
      notyf.error("Valeur incorrecte");
    }
  };

  const onBlurExclusiveTaxAmount = (e) => {
    const { name, value } = e.target;
    let nb = textToFloat(value);
    if (typeof nb === "number") {
      setAdditionalLine({
        ...additionalLine,
        [name]: nb,
      });
      setExclusiveTaxAmountTxt(floatToText(nb));
    } else {
      setExclusiveTaxAmountTxt(
        floatToText(additionalLine.exclusiveTaxAmount + "")
      );
      notyf.error("Valeur incorrecte");
    }
  };

  return (
    <>
      <IDContainer>
        <IDContainerTable style={{ padding: "15px" }}>
          <Table style={{ width: "100%" }}>
            <THead>
              <tr>
                {columns.map((column) => (
                  <THM
                    key={column.field}
                    scope="col"
                    width={column?.width}
                    onClick={() => columnClickHandler(column.field, reverse)}
                  >
                    <THContainer style={{ justifyContent: column.alignSelf }}>
                      <span>{column.title}</span>
                    </THContainer>
                  </THM>
                ))}
              </tr>
            </THead>
            <TBody>
              <tr>
                <TDM>
                  <Input
                    type="text"
                    name="ref"
                    value={additionalLine?.ref}
                    onChange={(e) => inputChangedHandler(e)}
                  />
                </TDM>
                <TD>
                  <Input
                    type="text"
                    name="designation"
                    value={additionalLine?.designation}
                    onChange={(e) => inputChangedHandler(e)}
                  />
                </TD>
                <TD>
                  <Input
                    type="number"
                    name="qty"
                    value={additionalLine?.qty}
                    onChange={(e) => inputChangedHandler(e)}
                  />
                </TD>
                <TD>
                  <Input
                    type="text"
                    name="price"
                    value={priceTxt}
                    onChange={(e) => inputChangedHandler(e)}
                    onBlur={(e) => onBlurPrice(e)}
                  />{" "}
                  €
                </TD>
                <TD>
                  <Input
                    type="number"
                    name="reduction"
                    value={additionalLine?.reduction}
                    onChange={(e) => inputChangedHandler(e)}
                  />
                </TD>
                <TD>
                  <FormSelect
                    style={{
                      fontSize: "0.8rem",
                      appearance: "auto",
                      textAlign:"start",
                      cursor:"pointer"
                      //borderBottom: "1px solid #505050"
                    }}
                    className="selectOpstions"
                    id="codeTva"
                    name="codeTva"
                    onChange={(e) => inputChangedHandler(e)}
                  >
                    <option value={1} style={{cursor:"pointer"}}>TVA1 (5%)</option>
                    <option  value={2}>TVA2 (0%)</option>
                    <option  value={4}>TVA4 (20%)</option>
                    </FormSelect>
                  {/* <select className="selectOptions">
                    <option value={1}>TVA1 (5%)</option>
                    <option className="option" value={2}>TVA2 (0%)</option>
                    <option className="option" value={4}>TVA4 (20%)</option>
                  </select> */}
                  {/* <Select 
                    width={"100%"}
                    close={true}
                    options={options}
                    value={fileName}
                    onChange={changefile}
                  /> */}
                  {/* <div class="form-group">
                    <label for="exampleSelect1" class="form-label mt-4">Example select</label>
                    <select class="form-select" id="exampleSelect1">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div> */}
                </TD>
                <TD>
                  <Input
                    type="text"
                    name="tva"
                    value={tvaTxt}
                    onChange={(e) => inputChangedHandler(e)}
                    onBlur={(e) => onBlurTVA(e)}
                  />{" "}
                  €
                </TD>
                <TD>
                  <Input
                    type="text"
                    name="exclusiveTaxAmount"
                    value={exclusiveTaxAmountTxt}
                    onChange={(e) => inputChangedHandler(e)}
                    onBlur={(e) => onBlurExclusiveTaxAmount(e)}
                  />{" "}
                  €
                </TD>
                <TD>
                  <DropdownList
                    value={additionalLine?.account}
                    data={dataOptions}
                    name="account"
                    dataKey="value"
                    textField="name"
                    busy
                    busySpinner={
                      <ExpandMoreIcon style={{ width: "21px" }} />
                    }
                    onChange={(value) => inputChangedHandler(value, "account")}
                  />
                  {/* <Input
                    list="account"
                    type="text"
                    name="account"
                    value={additionalLine?.account}
                    onChange={(e) => inputChangedHandler(e)}
                  />
                  <datalist id="account">
                    <label htmlFor="account">Account</label>
                    <select name="account" id="account">
                      <option value="6241">Transport sur achat</option>
                      <option value="606100">Fourniture non stockable</option>
                      <option value="611400">Frais généraux</option>
                    </select>
                  </datalist> */}
                </TD>
                {/* <TD >
                  
                </TD> */}

              </tr>
            </TBody>
          </Table>
        </IDContainerTable>
      </IDContainer>
    </>
  );
}
const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,
});
const mapDispatchToProps = (dispatch) => ({
  getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
  getInvoiceDataByToken: (invoiceToken) =>
    dispatch(actions.getInvoiceDataByToken(invoiceToken)),
  resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableAdditionalLine);
