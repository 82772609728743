import React, { useEffect, useState,useCallback, useMemo } from 'react'
import { connect } from 'react-redux';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../shared/utility';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import StatusModal from './StatusModal/StatusModal';
import { IDProgressLineContainer, IDProgressLineItem, IDProgressLineLabel, PLAction } from './IDProgressLine.styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { useTheme } from 'styled-components';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip'

function IDProgressLine(props) { 
    const {
        status,
        editStatus
    } = props
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    const theme = useTheme()
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [statusList, setStatusList] = useState([]);

    const [infoStatus, setInfoStatus] = useState("");
    const localStatusList = useMemo(() => [ 'PENDING', 'TO_VALIDATE', 'TO_CONTROL', 'ARCHIVED'], [status])
    
    useEffect(() => {
        let statusListTmp = [
                {label: "Parapheur en attente", value : "Parapheur en attente"},
                {label: "A valider", value: "A valider"},
                {label: "A contrôler", value: "A contrôler"},
                {label: "Archivé", value: "Archivé"}
            ];

        setStatusList(statusListTmp)
        
    }, [status])

    const getProgressLineIcon = (value, index) => {
        let statusIndex = null
        let tempStatusIndex = localStatusList.indexOf(status)
        if (tempStatusIndex == 0) statusIndex = 0
        if (tempStatusIndex == 1) statusIndex = 1
        if (tempStatusIndex == 2) statusIndex = 2
        if (tempStatusIndex == 3) statusIndex = 4
        // if (temStatus == 4) statusIndex = 6
        
        if(index < statusIndex) return <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
        else return <PanoramaFishEyeIcon style={{fontSize: "1.25rem"}} />
    
    }

    const getStatusColors = (value, index) => {
        let statusIndex = null
        let tempStatusIndex = localStatusList.indexOf(status)
        if (tempStatusIndex == 0) statusIndex = 0
        if (tempStatusIndex == 1) statusIndex = 1
        if (tempStatusIndex == 2) statusIndex = 2
        if (tempStatusIndex == 3) statusIndex = 4
        // if (tempStatusIndex == 4) statusIndex = 6
        if(index < statusIndex)
            return [theme.colors.secondary, " solid " + theme.colors.secondary]
        if(index === (statusIndex))
            return [theme.colors.secondary, " dashed " + theme.colors.secondary]
        return [theme.colors.secondary, " solid " + theme.colors.gray]
    }

    return ( 
        <IDProgressLineContainer>
            {
                statusList.map((item, index) => (
                    <IDProgressLineItem 
                        color={getStatusColors(item.value, index)[0]} 
                        border={getStatusColors(item.value, index)[1]} 
                    >
                        <IDProgressLineLabel>{item.label}</IDProgressLineLabel>
                        {/* <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
                        <PanoramaFishEyeIcon /> */}
                        {getProgressLineIcon(item?.value, index)}
                    </IDProgressLineItem>
                ))
            }
            {/* {
                lastEvent?.message == 2 ? 
                    <CTooltip title={status}>
                        <span style={{color:"#EE5A5A",padding:"0px 17px"}}><WarningAmberIcon style={{fontSize:"30px",margin:"0px 5px 0px 5px"}}/><small style={{fontSize:"small"}}>Refus</small></span>
                    </CTooltip>
                : null
            }   */}
        </IDProgressLineContainer>
        )      
    }
    
const mapStateToProps = (state) => ({
    userType: state.auth.userType
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(IDProgressLine)
