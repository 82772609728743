import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle, CIUsersLink } from '../ExtractionItem/ExtractionItem.styled';
import { BtnAction, PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../../styles/Common';

function ConfirmDelete({handleClose, show,item, deleteExtraction}) {
    const {t} = useTranslation();

    const updateClickHandler = () => {         
        deleteExtraction(item)
        handleClose();
    }

    const cancelClickHandler = () => { 
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title className="ICDBlue">{t('default:alert', "Alerte")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body" style={{width: "650px"}}>
                    <p>
                       Voulez-vous vraiment supprimer cette extraction ?
                    </p>
                </Modal.Body>
                <Modal.Footer>
               


                <PrimaryBtnOutlineAction    style={{fontSize: "1.3rem"}}
                                onClick={()=>updateClickHandler()}
                                color="#809FB8" border="#809FB8">
                             {t('default:confirm', "Confirmer")}
                </PrimaryBtnOutlineAction>
                <CancelBtnOutlineAction style={{fontSize: "1.3rem"}}
                             onClick={cancelClickHandler}
                              >
                             {t('default:cancel', "Annuler")} 
                </CancelBtnOutlineAction>

                   

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConfirmDelete
