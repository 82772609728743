import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './StatusModal.css';
import { Button, Modal } from 'react-bootstrap';
import { getStatusWithKey } from '../../../../shared/utility';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction,invoiceIntegratedDetail } from '../../../../styles/Common';

function StatusModalInvoiceToValidate(props) {
    const {show, modalClosed, id, statusVal, editStatus, statusList} = props;
    // const statusList = useMemo(() => ["NEW", "TO_BE_PAID", "PAID", "LITIGATION"], [])
    const [statusListNew, setStatusListNew] = useState([...statusList]);
    const [statusValue, setStatusVal] = useState(statusVal)
    const [reason, setReason] = useState(null);
    const [comment, setComment] = useState(null);
    const [error, setError] = useState(null);

    // useEffect(() =>{
    //     let existLitige = statusListNew.find(item => item.value === "LITIGATION");
    //     if(!existLitige){
    //         setStatusListNew([...statusListNew, {label: "Litige", value: "LITIGATION"}])
    //     }
    // }, [])

    const inputChangeHandler = (e) => {     
        let value = e.target.value;
        setStatusVal(value)
        
    }
   

    const confirmClickHandler = () => {
        setError(null)
        if(statusVal !== statusValue){
            if(statusValue === "LITIGATION" && (!reason || !comment)){
                setError("Le motif et le commentaire sont obligatoire pour mettre cette facture en litige")
            }else {
                editStatus("ACTION",statusValue, comment, reason) 
                modalClosed()
            }
        }else{
            setError("Veuillez choisir un statut différent du statut actuel")
        }
    }

    return (
        <div>
            <Modal show={show} onHide={modalClosed} >
                <Modal.Header closeButton>
                <Modal.Title className="ICDBlue">
                    
                Actions sur la facture 
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body">
                    {
                        error && (
                            <div className="alert alert-danger" role="alert">
                                <p>
                                    {error}
                                </p>
                            </div>)
                    }
                    <div className="statusModal__status_conatiner">
                        <label for="status" className="statusModal__status_label">Action </label>
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={statusValue || ''} name="status" id="status">
                        <option value={""}> {""}</option>
                            {
                                
                                statusListNew.map(s => <option value={s.value}>
                                    {s.label}
                                </option>)
                            }
                        </select>
                    </div>

                    <div className="statusModal__reason_container">
                        {   
                            statusValue === "LITIGATION" ? (
                                <div className="statusModal__motif_container">
                                    <label className="statusModal__status_label" for="motif">Motif</label>
                                    <select 
                                        className="form-control " 
                                        id="motif"
                                        value={reason || ''}
                                        onChange={(e) => setReason(e.target.value)}
                                    >
                                        <option value="">
                                            Sélectionnez un motif
                                        </option>
                                        <option value="Numéro de commande incomplet">
                                            Numéro de commande incomplet
                                        </option>
                                        <option value="Ecart quantité">
                                            Ecart quantité
                                        </option>
                                        <option value="Article non référencé">
                                            Article non référencé
                                        </option>
                                        <option value="Montant erroné">
                                            Montant erroné
                                        </option>
                                    </select>
                                </div>
                            ) : null
                        }
                        <div className="statusModal_comment_container">
                            <label className="statusModal__status_label" for="comment" >{"Commentaire" + (statusValue === "LITIGATION" ? " (obligatoire)" : "")}</label>
                            <textarea 
                                className="form-control" 
                                id="comment" 
                                value={comment || ''}
                                onChange={(e) => setComment(e.target.value)}
                                rows="3"></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <PrimaryBtnOutlineAction 
                    variant="primary" 
                    onClick={() => confirmClickHandler()}
                    disabled={statusValue === "LITIGATION" && !comment}
                >
                    Confirmer
                </PrimaryBtnOutlineAction>
                <CancelBtnOutlineAction variant="secondary" onClick={modalClosed}>
                    Annuler
                </CancelBtnOutlineAction>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default StatusModalInvoiceToValidate;
