import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TimeLine from './Timeline/TimeLine';
import ModificationsChat from './ModificationsChat/ModificationsChat';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import {FormInput} from '../../../../styles/Common'
import { NavTabsLi, NavTabsLink, NavTabsUl } from '../../../../containers/Entity/Entity.styled';
import { useParams, useLocation } from 'react-router-dom';
import { ISContainer, ISNavContainer, ISNavContent, ISDivision, ISDocumentContainer, DPContainer } from './InvoiceSide.styled';
import Fields from './Fields/Fields';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import frLocale from "date-fns/locale/fr";
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TextField from '@mui/material/TextField'
import ConfirmationModal from '../../../UI/ConfirmationModal/ConfirmationModal';
import { convertFileToBase64, formatDate } from "../../../../shared/utility"
 
function InvoiceSide(props) {
    const {
        setPaymentSignature,
        paymentSignature,
        addEventHandler,
        eventUpdate,
        paymentSignatureInvoices,
        setInvoiceDocument,
        selectRowHandler,
        status,

        tabSideActive,
        setTabSideActive,
        userType,
        userLevel
    } = props
    let notyf = getNotyfObject();
    const {t} = useTranslation();
    const uploader = useRef(null);
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const documentTypeList = {
        "IBAN" : "Coordonnées bancaires",
        "FOREIGN" : "Liste des salariés étrangers",
        "KBIS" : "Justificatif immatriculation KBIS",
        "URSSAF" : "Attestation sociale de vigilance",
        "INSURANCE" : "Attestation d'assurance"
    }

    const documentClickHandler = (ref) => {
        setInvoiceDocument(paymentSignatureInvoices[ref])
    }   
    
    // const inputChangeHandler = (offset, value) => {
    //     let tempEntityDocument = entityDocuments[offset]
    //     tempEntityDocument.othertypename = value
    //     setEntityDocuments(Object.values({...entityDocuments, [offset] : tempEntityDocument}))
    // }

    // const documentTypeChangeHandler = (offset, type) => {
    //     let tempEntityDocument = entityDocuments[offset]
    //     tempEntityDocument.type = type
    //     setEntityDocuments(Object.values({...entityDocuments, [offset] : tempEntityDocument}))
    //     setEntity({...entity, documents: Object.values({...entityDocuments, [offset] : tempEntityDocument})})
    // }
    
    // const downloadDocument = (offset) => {
    //     const linkSource = (!entityDocuments[offset].new ? 'data:application/octet-stream;base64,' : '') + entityDocuments[offset].pdfFile;
    //     const link = document.createElement("a");
    //     link.href = linkSource;
    //     link.download = entityDocuments[offset].document;
    //     link.click();
    // }

    // const addDateHandler = (offset, value) => {
    //     let tempEntityDocument = entityDocuments[offset]
    //     tempEntityDocument.expirationDate = value
    //     setEntityDocuments(Object.values({...entityDocuments, [offset] : tempEntityDocument}))
    // }

    // const deleteDocumentModal = (offset) => {
    //     setConfirmationModalShow(true)
    //     setTempDocumentToDelete(offset)
    // }
 
    const deleteDocument = () => {
        setConfirmationModalShow(false)
        // deleteDocumentHandler(tempDocumentToDelete)
    }

    const formatNumber = (number) => {
        return new Intl.NumberFormat('fr-FR').format(parseFloat(number).toFixed(2) )
    }


    return (
        <ISDivision>
            <ISContainer>  
            
                <ISNavContainer>
                    <NavTabsUl isCommand={true}>
                        {
                            <NavTabsLi>
                                <NavTabsLink 
                                    active={tabSideActive === "fields"} 
                                    onClick={() => setTabSideActive("fields")}
                                    to="#"
                                >
                                    {t("mandat:fields", "Informations")} 
                                </NavTabsLink>
                            </NavTabsLi>
                        }
                        <NavTabsLi>
                            <NavTabsLink 
                                active={tabSideActive === "comments"} 
                                onClick={() => setTabSideActive("comments")}
                                to="#"
                                >
                                    {t('mandat:comments', "Commentaires")} 
                            </NavTabsLink>
                        </NavTabsLi>
                        <NavTabsLi>
                            <NavTabsLink 
                                active={tabSideActive === "timeline"} 
                                onClick={() => setTabSideActive("timeline")}
                                to="#"
                            >
                                    {t("mandat:timeline", "TimeLine")} 
                            </NavTabsLink>
                        </NavTabsLi>
                        
                    </NavTabsUl>
                </ISNavContainer>
                
                <ISNavContent>
                    {tabSideActive === "fields" && (
                        <Fields  
                            formatNumber={formatNumber}
                            setPaymentSignature={setPaymentSignature}
                            paymentSignature={paymentSignature}
                        />
                    )}
                    {tabSideActive === "timeline" && 
                            <TimeLine paymentSignatureUid={paymentSignature?.uid} eventUpdate={eventUpdate}/>
                    }
                    {tabSideActive === "comments" && 
                            <ModificationsChat addEventHandler={addEventHandler} />
                    }
                </ISNavContent>
            
            </ISContainer>

            <ISDocumentContainer>
                <div className="row" style={{color:"#809FB8", fontSize: "0.9rem",marginBottom: "7px"}}>
                    <div className="col-md-2" style={{padding: "8px 10px"}}>N° facture</div>
                    <div className="col-md-3" style={{padding: "8px 10px"}}>Fournisseur</div>
                    <div className="col-md-2" style={{textAlign: "center"}}>Date de péremption</div>
                    <div className="col-md-2" style={{padding: "8px 0px", textAlign: "center"}}>Montant</div>
                    <div className="col-md-2" style={{padding: "8px 0px", textAlign: "center"}}>Montant réglement</div>
                    <div className="col-md-1" style={{padding: "8px 3px"}}>A payer</div>
                </div>
        
                <div style={{height: "143px", minHeight: "143px", overflow: "hidden auto"}}>
                {
                    paymentSignatureInvoices?.map((invoice, index) => {
                        return (
                            <>
                                <div className="row" style={{color:"#505050", fontSize: "0.9rem"}}>
                                    <div className="col-md-2">
                                        <p style={{cursor: "pointer", textOverflow: "ellipsis",width: "72px",overflow: "hidden"}} onClick={e => documentClickHandler(index)}>{invoice?.number}</p>
                                    </div>
                                    <div className="col-md-3">
                                        {invoice?.supplierName}
                                    </div>
                                    <div className="col-md-2" style={{textAlign: "center"}}>
                                        {formatDate(+invoice?.dueDate)}
                                    </div>
                                    <div className="col-md-2" style={{textAlign: "center"}}>
                                        {formatNumber(+invoice?.inclusiveTaxAmount)} €
                                    </div>
                                    <div className="col-md-2" style={{textAlign: "center"}}>
                                        {formatNumber(+invoice?.inclusiveTaxAmount)} €
                                    </div>
                                    <div className="col-md-1">
                                        {
                                            (userLevel!="superAccountant" || (!'PENDING, TO_VALIDATE'.includes(status) && userLevel=="superAccountant")) 
                                            ?
                                                (+invoice?.tobepaid == 1) ? 
                                                    <span style={{textAlign: "center"}}> Oui </span> 
                                                : <span style={{textAlign: "center"}}> Non </span>
                                            :
                                                (  
                                                    <input
                                                        style={{textAlign: "center"}}
                                                        type="checkbox"
                                                        name={invoice?.number}
                                                        onChange={(e) => selectRowHandler(e, invoice?.linkUid)}
                                                        checked={+invoice?.tobepaid}
                                                        className="form-check-input checkBoxInput"
                                                    />
                                                )  
                                        }
                                        
                                    </div>
                                </div>
                                {/* <hr color="#D9E1E7"/> */}
                            </>
                        )
                    })
                }
                </div>


            </ISDocumentContainer>

            
            <ConfirmationModal
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={() => deleteDocument()}
                cancel={() => setConfirmationModalShow(false)}
                title={"Confirmation"}
                message={`Voullez-vous supprimer ce document?`}
            />
        </ISDivision>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    userLevel: state.auth.userLevel,
    tabSideActive: state.supplierRequestDetail.tabSideActive,
    supplierRequestData: state.supplierRequestDetail.supplierRequestData,
})
const mapDispatchToProps = dispatch => ({
    setTabSideActive: (tabSideActive) => dispatch(actions.setSupplierRequestTabSideActive(tabSideActive)),
    setSupplierRequestData: (supplierRequestData) => dispatch(actions.supplierRequestDataSuccess(supplierRequestData))
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSide)
