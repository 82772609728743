import React, { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { formatDate } from '../../../shared/utility'
import DateRange from '../../UI/DateRange/DateRange'
import { CardContainer, CardHeader, CardInfos, CardIcon, DPercentage, CardPercentage, CardPercentageIcon, CardPercentageValue, CardTitle, CardBody, CardValue } from '../../Home/Card/Card.Styled';

function Card({ icon, isIncreasing, percentage, title, value, dateStart, dateEnd, handleFilters }) {
    const [client, setClientFilter] = useState(null);
    const [region, setRegionFilter] = useState(null);
    const [dateInterval, setDateInterval] = useState(null)
    const [showDRange, setShowDRange] = useState(false)

    const showCaModel = () => {
        setShowDRange(true);
    }
    const closeModalHandler = () => {
        setShowDRange(false);
    }

    const dateChangeHandler = (date) => {
        setDateInterval({
            startDate: date.startDate.getTime(),
            endDate: date.endDate.getTime()
        })
    }
    useEffect(() => {
        if (handleFilters)
            handleFilters(dateInterval, client, region);
    }, [dateInterval, client, region])
    return (
        <CardContainer>
            <CardHeader>
                <CardInfos>
                    <CardIcon onClick={() => dateStart ? showCaModel() : null}>
                        {icon}
                    </CardIcon>
                    <DPercentage>
                        {dateStart && (<div style={{ fontSize: "0.8rem", color: "#809FB8" }}>
                            <p style={{ margin: "-5px" }}>debut:{formatDate(dateStart)}</p>
                            <p style={{ margin: "-5px" }}>fin:{formatDate(dateEnd)}</p> </div>
                        )}
                    </DPercentage>
                </CardInfos>
                <CardTitle style={{marginLeft: dateStart ? '20px' : ""}}>
                    {title}



                </CardTitle>
            </CardHeader>
            <CardBody>
                <CardValue>
                    {value}
                </CardValue>
                {
                    percentage && (
                        <DPercentage>
                                    <CardPercentage isIncreasing={isIncreasing}>
                                        <CardPercentageIcon isIncreasing={isIncreasing}>
                                            {/* {
                                    isIncreasing ? 
                                        <ArrowUpwardIcon style={{fontSize: "0.8rem"}} />
                                    :
                                        <ArrowDownwardIcon style={{fontSize: "0.8rem"}} />
                                } */}
                                </CardPercentageIcon>
                                <CardPercentageValue>
                                    {isIncreasing ? '+' : '-'}
                                    {percentage}
                                    <span>%</span>
                                </CardPercentageValue>
                            </CardPercentage>
                        </DPercentage>
                    )
                }

            </CardBody>
            {/* <div className="element__progressBar">
                        <span style={{width: percentage?percentage + '%':100 +'%'}} ></span>
                    </div> */}
            <DateRange
                format={formatDate}
                show={showDRange}
                clicked={closeModalHandler}
                changed={(date) => dateChangeHandler(date)}
                field={"ca"} />
        </CardContainer>
    );
}

export default Card;

            // reset={resetInterval} 
