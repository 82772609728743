import React, {useEffect, useMemo, useState} from 'react';
import { ThemeProvider } from 'styled-components';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import {HelmetProvider} from 'react-helmet-async';
import GlobalStyles from './styles/Global';
import theme from './styles/theme';
import darkTheme from './styles/DarkTheme';
import clientTheme from './styles/clientTheme';
import supplierTheme from './styles/supplierTheme'
import Auth from './containers/Auth/Auth';
import AccountRequest from './components/AccountRequest/AccountRequest';
import NewSupplierAccountProgress from './containers/NewSupplierAccountProgress/NewSupplierAccountProgress'
import ResetPassword from './components/Signin/ResetPassword/ResetPassword';
import ForgotPassword from './components/Signin/ForgottenPassword/ForgotPassword';
import OwnerRoot from './containers/AccesRoots/OwnerRoot/OwnerRoot';
import SupplierRoot from './containers/AccesRoots/SupplierRoot/SupplierRoot';
import ClientRoot from './containers/AccesRoots/ClientRoot/ClientRoot';
import InvoiceDetail from './containers/InvoiceDetail/InvoiceDetail';
import InvoiceToken from './containers/InvoiceToken/InvoiceToken';
import ContractSignatureMessage from  './components/ContractMessageSignature/ContractMessageSignature'
import "./App.css"

function App(props) {
  const {currentTheme, onTryAutoSignup, isAuthenticated, userType, userLevel, authRedirectPath, loading} = props;
  const accessTokenList = ["document", "forgotPassword", "account", "campaign", "invoiceToken", "supplierAccountProgress"];
  let location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    onTryAutoSignup()
    console.log("fgfhgh")
  }, []);

  useEffect(() => {
    const accessToken = accessTokenList.includes(location.pathname.split("/")[1]);
    if(authRedirectPath && !accessToken) 
      navigate('/');
  }, [authRedirectPath]);

  let routes;

  if(isAuthenticated) {
    switch (userType) {
      case "supplier":
        routes = (
          <SupplierRoot />
        )
        break;
      case "client":
        routes = (
          <ClientRoot />
        )
        break;
      case "owner":
        routes = (
          <OwnerRoot userLevel={userLevel} userType={userType}/>
        )
        break;
    
      default:
        break;
    }
  } else {
    routes = (
      <>
        <Routes>
          <Route path="/newAccountRequest" element={<AccountRequest />}/>
          <Route path="/supplierAccountProgress/:token" element={<NewSupplierAccountProgress />}/>
          <Route path="/forgotPassword" element={<ForgotPassword />}/>
          <Route path="/invoiceToken/:token" element={<InvoiceToken />}/>
          <Route path="/contractSignature/:token" element={<ContractSignatureMessage />}/>
          
          <Route path="/account/:token" element={<ResetPassword />}/>
          <Route path="/" element={<Auth />} />
        </Routes>
      </>
    )
  }

  const getTheme = () => {
    if(currentTheme === "dark") {
      return darkTheme;
    }
    switch (userType) {
      case "client":
        return  clientTheme;
      case "supplier":
        return supplierTheme;
      case "owner":
        return theme;
      default:
        return theme;
    }
  }

  return (
    <ThemeProvider theme={getTheme()}>
      <HelmetProvider>
        <GlobalStyles />
        <div className="app">
          {routes}
        </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    currentTheme: state.app.theme,
    // isAuthenticated: localStorage.getItem('token') !== null,
    isAuthenticated: state.auth.token !== null,
    loading : state.auth.loading,
    userType: state.auth.userType,
    userLevel: state.auth.userLevel,
    authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}
export default connect(mapStateToProps, mapDispatchProps)(App);
