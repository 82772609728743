import React, { Fragment, useState } from 'react'
import DraftsIcon from '@mui/icons-material/Drafts';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { CTooltip } from '../../UI/CTooltip/CTooltip'
import { useTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import pdfIcon from '../../../assets/images/pdf.png';
import xmlIcon from '../../../assets/images/xml.png';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Axios from '../../../axios-proas'
import { Link } from 'react-router-dom';
import StatusReasonModal from './StatusReasonModal/StatusReasonModal';
import { getNotyfObject, getStatusWithKey, getChannelWithKey, showColumn, getAlignPosition } from '../../../shared/utility';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { InvoiceLink, SelectStatus, StatusOption, TD } from '../../../styles/Table.styled';
import CodeIcon from '@mui/icons-material/Code';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
function TableBody(props) {
    const { data, formatDate, setViewed, selectedRows, setSelectedRows, userType, userLevel, editStatus, disableStatus, entityFilter, columns, statusFilter } = props;

    const { t } = useTranslation();
    let notyf = getNotyfObject();
    const [showStatusReasonModal, setShowStatusReasonModal] = useState(false)
    const [statusToEditData, setStatusToEditData] = useState(null);
    const [invoiceToEdit, setInvoiceToEdit] = useState(null);

    //console.log("Entity Filter: " + entityFilter);

    const formatNumber = (number) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.', ' ')
    }

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e) => {
        let id = e.target.name;
        let val = e.target.checked;
        setSelectedRows(id, val)
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        return hour + ":" + minutes
    }

    // const clickedHandler = (e, uid) => {
    //     e.preventDefault()
    //     props.getDocumentFile(uid)
    // }
    // const clickedHandlerEdi = (e, uid) => {
    //     e.preventDefault()
    //     props.getinvoiceGetDocumentEdi(uid)
    // }


    // const clickedHandlerXml = (e, uid) => {
    //     e.preventDefault()
    //     props.getinvoiceGetDocumentXml(uid)
    // }

    // const clickedHandlerChorus = (e, uid) => {
    //     e.preventDefault()
    //     props.getinvoiceGetDocumentChorus(uid)
    // }
    const clickedHandler = (e, row) => {
        e.preventDefault()

        if (row.uid != null) {
            Axios.get("invoice/" + row.uid + "/commandePdf")
                .then((response) => {
                    if (response.status === 200) {
                        console.log( response.data)
                        const linkSource = 'data:application/octet-stream;base64,' + response?.data.pdfFile;
                        const link = document.createElement("a");
                        link.href = linkSource;
                        link.download = row?.docFile;
                        link.click();
                        // if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
                        //     let byteCharacters = atob(response?.data?.pdfFile);
                        //     let byteNumbers = new Array(byteCharacters.length);
                        //     for (var i = 0; i < byteCharacters.length; i++) {
                        //         byteNumbers[i] = byteCharacters.charCodeAt(i);
                        //     }
                        //     let byteArray = new Uint8Array(byteNumbers);
                        //     let blob = new Blob([byteArray], { type: 'application/pdf' });
                        //     window.navigator.msSaveOrOpenBlob(blob, row?.docFile);
                        // }
                        // else { // much easier if not IE
                        //     //________Direct Download_____
                        //     let blob = new Blob([response?.data.pdfFile], { type: 'application/pdf' })
                        //     const downloadUrl = URL.createObjectURL(blob)
                        //     let a = document.createElement("a");
                        //     a.href = "data:application/pdf;base64," + downloadUrl;
                        //     a.download = row?.docFile;
                        //     document.body.appendChild(a);
                        //     a.click();
                        // }
                    } else {
                        console.log("Archive vide ou non valide");
                        notyf.error("Une erreur s'est produite")

                    }
                })
                .catch((response) => {
                    console.log("Une erreur s'est produite");
                    notyf.error("Une erreur s'est produite")

                });
        }
    }

    const clickAttachmentHandler = (e, uid) => {
        e.preventDefault()
        Axios.get('/invoice/' + uid + '/attachmentFile', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.request.getResponseHeader("Content-Type") })
                let filename = "";
                const disposition = response.request.getResponseHeader('Content-Disposition')
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches !== null && matches[1])
                        filename = matches[1].replace(/['"]/g, '');
                }
                const downloadUrl = URL.createObjectURL(blob)
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            } else
                notyf.error("Pièce jointe non trouvée");
        }).catch(err => {
            notyf.error('Une erreur s\'est produite !')
        })
    }

    const inputChangeStatus = (status, invoiceUid) => {
        if (status && !disableStatus) {
            if (status === "LITIGATION") {
                setInvoiceToEdit(invoiceUid)
                setShowStatusReasonModal(true);
            } else
                editStatus(invoiceUid, status)
        }
    }

    const markAsRead = (invoiceUid, firstAccessDate = null) => {
        if (!firstAccessDate) {
            Axios.put('/invoice/' + invoiceUid + '/setInvoiceAsViewed',
                {
                }).then(response => {
                    if (response?.data?.success)
                        notyf.success("Facture marquée comme lue")
                }).catch(err => {
                    notyf.error("Une erreur s'est produite")
                })
        }
    }
    const getStatus = (status, uid) => {
        if (!uid) {
            return "Livrée & Non facturée"
        } else {
            switch (status) {

                case 'FILED':
                case 'NEW':
                case 'RECEIVED':
                case 'TO_BE_PAID':
                case 'TO_BE_VALIDATED':
                case 'TO_BE_CONTROLLED':
                case 'LITIGATION':
                case 'PENDING':
                case 'IMPORTED':
                case 'INTEGRATED':
                case 'ERROR':
                case 'REJECTED':
                case 'REFUSED':
                case 'DEADLINEEXCEEDED':
                case 'CONTROLLED':
                    return "Livrée & Attente de paiement";
                    break;
                case 'PAID':
                    return "Livrée & Payée";
                    break;
                default:
                    break;
            }
        }


    }
    return (
        <>
            {

                data.map(row => (
                    <tr key={row.id} >
                        <TD>
                            <input type="checkbox" name={row.uid} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row.uid)} className="form-check-input" id="exampleCheck1" />
                        </TD>
                        {/* <TD title={row.documentType} style={{display: !showColumn(columns,"type") && 'none', textAlign: getAlignPosition(columns, "type")}}>
                            {t("invoiceList:" + (row.documentType === "INV" ? "invoice" : (row.documentType === "CRN" ? "creditNote" : "")))}
                        </TD> */}
                        <TD style={{ display: !showColumn(columns, "number") && 'none', textAlign: getAlignPosition(columns, "number") }}>
                            <InvoiceLink
                                to={"/commandeDetail/" + row.uid}
                                state={row.invoiceUid}
                                status={row?.status}
                                onClick={() => markAsRead(row.uid, +row.firstAccessDate)}
                            >
                                <span title={row.number} >{row.number}</span>
                                {
                                    row.unreadMessages && parseInt(row.unreadMessages) != 0 ?
                                        <div className="unreadMessage_icon">
                                            <CTooltip title={"Nouveaux commentaires"} className="tableActions__icon" style={{ color: '#022f67', fontSize: "20px" }}>
                                                <TextsmsIcon fontSize="small" />
                                            </CTooltip>
                                        </div>
                                        :
                                        null
                                }
                            </InvoiceLink>
                        </TD>


                        <TD style={{ display: !showColumn(columns, "issuingDate") && 'none', textAlign: getAlignPosition(columns, "issuingDate") }}>{formatDate(+row.issuingDate)}</TD>
                        <TD style={{ display: !showColumn(columns, "creationDate") && 'none', textAlign: getAlignPosition(columns, "creationDate") }}>{formatDate(+row.creationDate)}</TD>


                        <TD style={{ display: !showColumn(columns, "clientName") && 'none', textAlign: getAlignPosition(columns, "clientName") }} title={row.clientName}>{row.clientName}</TD>




                        <TD style={{ display: !showColumn(columns, "channel") && 'none', textAlign: getAlignPosition(columns, "code") }}>
                            {getChannelWithKey(row?.channel)}
                        </TD>

                        <TD style={{ display: !showColumn(columns, "exclusiveTaxAmount") && 'none', textAlign: getAlignPosition(columns, "exclusiveTaxAmount") }}>
                            {formatNumber(row.exclusiveTaxAmount)}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "inclusiveTaxAmount") && 'none', textAlign: getAlignPosition(columns, "inclusiveTaxAmount") }}>
                            {formatNumber(row.inclusiveTaxAmount)}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "currency") && 'none', textAlign: getAlignPosition(columns, "currency") }}>
                            {row.currency}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "status") && 'none', textAlign: getAlignPosition(columns, "status") }} title={getStatus(row?.statusFacture, row?.invoiceUid)} >
                            {getStatus(row?.statusFacture, row?.invoiceUid)}
                            {/* {userType === "supplier" ? getStatusWithKey(row?.status) :
                                <SelectStatus 
                                    value={row?.status} 
                                    status={row?.status}
                                    onChange={(e) => inputChangeStatus(e.target.value,row?.uid)}  
                                    name="status"
                                    disabled={disableStatus}
                                >
                                    <StatusOption value={row?.status} >{getStatusWithKey(row?.status, userType)}</StatusOption>
                                    {row?.status!=="NEW"&&       <StatusOption   value="NEW">En cours</StatusOption>}
                                    {row?.status!=="IMPORTED"&&       <StatusOption   value="FILED">Importée</StatusOption>}
                                    {row?.status!=="TO_BE_PAID"&&<StatusOption   value="TO_BE_PAID">À payer</StatusOption>}
                                    {row?.status!=="LITIGATION"&&<StatusOption   value="LITIGATION">Litige</StatusOption>}
                                    {row?.status!=="PAID"&&      <StatusOption  value="PAID">Payée</StatusOption>}                       
                                    {row?.status!=="INTEGRATED"&&      <StatusOption  value="INTEGRATED">Intégrée</StatusOption>}                       
                                    {row?.status!=="ERROR"&&      <StatusOption  value="ERROR">Erreur</StatusOption>}                       
                                    {row?.status!=="REJECTED"&&      <StatusOption  value="REJECTED">Rejetée</StatusOption>}                       
                                    {row?.status!=="REFUSED"&&      <StatusOption  value="REFUSED">Refusée</StatusOption>}                       
                                </SelectStatus>  
                            } */}
                        </TD>
                        <TD style={{ display: "flex", alignSelf: "center", justifyContent: "start" }}>
                            <div style={{ display: "flex", alignSelf: "center", justifyContent: "start" }} title="Télécharger">
                                {
                                    (row.docFile || row.xmlFile || row.ediFile || row.chorusFile) &&
                                    <InsertDriveFileIcon onClick={(e) => clickedHandler(e, row)} style={{ fontSize: "1.2rem", cursor: "pointer", color: "#2174B9" }} />
                                }

                            </div>
                        </TD>
                    </tr>
                ))

            }
            {
                showStatusReasonModal &&
                <StatusReasonModal
                    show={showStatusReasonModal}
                    handleClose={() => setShowStatusReasonModal(false)}
                    invoiceToEdit={invoiceToEdit}
                    editStatus={editStatus}
                />
            }
        </>
    )
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = dispatch => ({
    getDocumentFile: (uid) => dispatch(actions.invoiceGetDocumentFile(uid)),
    getinvoiceGetDocumentXml: (uid) => dispatch(actions.invoiceGetDocumentXml(uid)),
    getinvoiceGetDocumentEdi: (uid) => dispatch(actions.invoiceGetDocumentEdi(uid)),
    getinvoiceGetDocumentChorus: (uid) => dispatch(actions.invoiceGetDocumentChorus(uid)),
})
export default connect(mapStateToProps, mapDispatchToProps)(TableBody)
