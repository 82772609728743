import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const EMContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const EMBody = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 3rem;
`;

export const EMTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BodyModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const CheckContentModal = styled.div`
  display: flex;
`;

export const DateInput = styled.input`
  width: 100%;
  height: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  padding: 0.5px;
  color: ${({ theme }) => theme.colors.lightBlack};
  margin: 0.5rem;

  ::-webkit-datetime-edit-fields-wrapper {
    color: ${({ theme }) => theme.colors.gray};
    font-style: italic;
  }
  ::-webkit-calendar-picker-indicator {
    margin-left: 0.5rem;
    color: red;
  }
  &:svg {
    color: red;
    stroke: red;
  }
  &:fill {
    color: red;
    stroke: red;
  }
  &::-webkit-calendar-picker-indicator {
    background: url(https://img.icons8.com/ios/50/2174B9/calendar--v3.png)
      center/80% no-repeat;
    color: red !important;
    stroke: red !important;
  }
`;

export const CheckInput = styled.input`
  margin-right: 10px;
`;

export const UMContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const UMBody = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`;

export const UserStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  border-radius: 8px;
  background-color: ${({ bg }) => (bg ? bg : "white")};
  color: ${({ color }) => (color ? color : theme.colors.lightBlack)};
`;
export const DPContainer = styled.div`

    margin-bottom: 0.5rem;
     min-width: 99%;
    & input {
        padding: 0.5rem !important;
        color: #505050 ;
    }

    & button {
      color: #809FB8 !important;
    }

    & fieldset {
        border-color: ${({ theme }) => theme.colors.secondary};

    }
  

   
    
`