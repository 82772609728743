import React, { Fragment, useState } from 'react'
import DraftsIcon from '@mui/icons-material/Drafts';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { CTooltip } from '../../UI/CTooltip/CTooltip'
import { useTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import pdfIcon from '../../../assets/images/pdf.png';
import xmlIcon from '../../../assets/images/xml.png';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Article from '@mui/icons-material/Article';
import Axios from '../../../axios-proas'
import { Link } from 'react-router-dom';
import StatusReasonModal from './StatusReasonModal/StatusReasonModal';
import { getNotyfObject, getStatusWithKey, getChannelWithKey, showColumn, getAlignPosition, owner_invoiceStatus, user_invoiceStatus, getStatusBgColor, getUserStatusBgColor } from '../../../shared/utility';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { InvoiceLink, SelectStatus, StatusOption, TD,SupplierStatusSpan} from '../../../styles/Table.styled';
import CodeIcon from '@mui/icons-material/Code';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';


function TableBody(props) {
    const { data, formatDate, setViewed, selectedRows, setSelectedRows, userType, userLevel, editStatus, disableStatus, entityFilter, columns, statusFilter, invoiceCheckBox } = props;
    console.log(data)
    const { t } = useTranslation();
    let notyf = getNotyfObject();
    const [showStatusReasonModal, setShowStatusReasonModal] = useState(false)
    const [statusToEditData, setStatusToEditData] = useState(null);
    const [invoiceToEdit, setInvoiceToEdit] = useState(null);

    const formatNumber = (number) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.', ' ')
    }

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e) => {
        let id = e.target.name;
        let val = e.target.checked;
        setSelectedRows(id, val)
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        return hour + ":" + minutes
    }

    const clickedHandler = (e, row) => {
        e.preventDefault()

        {/*if (row.docFile) {
            props.getDocumentFile(row.uid)
        }
        if (row.xmlFile) {
            props.getinvoiceGetDocumentXml(row.uid)
        }
        if (row.ediFile) {
            props.getinvoiceGetDocumentEdi(row.uid)
        }
        if (row.chorusFile) {
            props.getinvoiceGetDocumentChorus(row.uid)
        }*/}



        if (row.uid != null) {
            Axios.get("invoice/" + row.uid + "/DownloadextractionByUid", {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        let blob = new Blob([response.data], { type: 'application/zip' })
                        const downloadUrl = URL.createObjectURL(blob)
                        let a = document.createElement("a");
                        a.href = downloadUrl;
                        a.download = "invoice(s).zip";
                        document.body.appendChild(a);
                        a.click();

                    } else {
                        console.log("Archive vide ou non valide");
                        notyf.error("Une erreur s'est produite")

                    }
                })
                .catch((response) => {
                    console.log("Une erreur s'est produite");
                    notyf.error("Une erreur s'est produite")

                });
        }
    }
    const clickedHandlerEdi = (e, uid) => {
        e.preventDefault()
        props.getinvoiceGetDocumentEdi(uid)
    }


    const clickedHandlerXml = (e, uid) => {
        e.preventDefault()
        props.getinvoiceGetDocumentXml(uid)
    }

    const clickedHandlerChorus = (e, uid) => {
        e.preventDefault()
        props.getinvoiceGetDocumentChorus(uid)
    }

    const clickAttachmentHandler = (e, uid) => {
        e.preventDefault()
        Axios.get('/invoice/' + uid + '/attachmentFile', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.request.getResponseHeader("Content-Type") })
                let filename = "";
                const disposition = response.request.getResponseHeader('Content-Disposition')
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches !== null && matches[1])
                        filename = matches[1].replace(/['"]/g, '');
                }
                const downloadUrl = URL.createObjectURL(blob)
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            } else
                notyf.error("Pièce jointe non trouvée");
        }).catch(err => {
            notyf.error('Une erreur s\'est produite !')
        })
    }

    const inputChangeStatus = (status, invoiceUid) => {
        if (status && !disableStatus) {
            if (status === "LITIGATION") {
                setInvoiceToEdit(invoiceUid)
                setShowStatusReasonModal(true);
            } else
                editStatus(invoiceUid, status)
        }
    }

    const markAsRead = (invoiceUid, firstAccessDate = null) => {
        if (!firstAccessDate) {
            Axios.put('/invoice/' + invoiceUid + '/setInvoiceAsViewed',
                {
                }).then(response => {
                    if (response?.data?.success)
                        notyf.success("Facture marquée comme lue")
                }).catch(err => {
                    notyf.error("Une erreur s'est produite")
                })
        }
    }

    return (
        <>
            {

                data.map(row => (
                    <tr key={row.id} >
                        {
                            !invoiceCheckBox &&
                            <TD>
                                <input type="checkbox" name={row.uid} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row.uid)} className="form-check-input" id="exampleCheck1" />
                            </TD>
                        }
                        <TD title={row.documentType} style={{ display: !showColumn(columns, "type") && 'none', textAlign: getAlignPosition(columns, "type") }}>
                            {t("invoiceList:" + (row.documentType === "INV" ? "invoice" : (row.documentType === "CRN" ? "creditNote" : "")))}
                        </TD>
                        <TD style={{ color: "#fff", display: !showColumn(columns, "number") && 'none', textAlign: getAlignPosition(columns, "number") }}>
                            <InvoiceLink
                                to={statusFilter == "TO_BE_VALIDATED" ? "/invoiceIntegratedDetail/" + row.uid : "/invoiceDetail/" + row.uid}
                                state={{ entityFilter }}
                                status={ userType=="supplier" ? getUserStatusBgColor(row?.status) : getStatusBgColor(row?.status)}
                                onClick={() => markAsRead(row.uid, +row.firstAccessDate)}
                            >
                                <span title={row.number} style={{ color: row?.status == "LITIGATION" && "#fff" }} >{row.number}</span>
                                {
                                    row.unreadMessages && parseInt(row.unreadMessages) != 0 ?
                                        <div className="unreadMessage_icon">
                                            <CTooltip title={"Nouveaux commentaires"} className="tableActions__icon" style={{ color: '#fff', fontSize: "20px" }}>
                                                <TextsmsIcon fontSize="small" />
                                            </CTooltip>
                                        </div>
                                        :
                                        null
                                }
                            </InvoiceLink>
                        </TD>
                        {entityFilter === "client" ?

                            <TD style={{ display: !showColumn(columns, "firstAccessDate") && 'none', textAlign: getAlignPosition(columns, "firstAccessDate") }}>
                                {+row.firstAccessDate > 0 ?
                                    <CTooltip title={formatDate(+row.firstAccessDate) + " " + getTimeDate(+row.firstAccessDate)} className="tableActions__icon" style={{ color: '#022f67', fontSize: "20px" }}>
                                        <DraftsIcon style={{ color: "#B8B8B8", fontSize: "20px" }} />
                                    </CTooltip> :
                                    <MarkunreadIcon className="tableActions__icon" style={{ color: "#505050", fontSize: "20px" }} />}
                            </TD> :
                            (entityFilter === "supplier" &&
                                <TD style={{ display: !showColumn(columns, "attachmentFile") && 'none', textAlign: getAlignPosition(columns, "attachmentFile") }}>
                                    {row.attachmentFile &&
                                        <a
                                            download={row.attachmentFile}
                                            href="#"
                                            onClick={(e) => clickAttachmentHandler(e, row.uid)}
                                            style={{ color: "#ea5e0b" }}
                                        >
                                            {row.attachmentFile}
                                        </a>}
                                </TD>
                            )}

                        <TD style={{ display: !showColumn(columns, "issuingDate") && 'none', textAlign: getAlignPosition(columns, "issuingDate") }}>{formatDate(+row.issuingDate)}</TD>
                        <TD style={{ display: !showColumn(columns, "dueDate") && 'none', textAlign: getAlignPosition(columns, "dueDate") }}>{formatDate(+row.dueDate)}</TD>
                        {userType === 'client' ? (
                            <TD style={{ display: !showColumn(columns, "supplierName") && 'none', textAlign: getAlignPosition(columns, "supplierName") }} title={row.supplierName}>{row.supplierName}</TD>
                        ) : null}
                        {userType === 'supplier' ? (
                            <TD style={{ display: !showColumn(columns, "clientName") && 'none', textAlign: getAlignPosition(columns, "clientName") }} title={row.clientName}>{row.clientName}</TD>
                        ) : null}
                        {userType === 'owner' ? (
                            <Fragment>
                                <TD style={{ display: !showColumn(columns, "supplierName") && 'none', textAlign: getAlignPosition(columns, "supplierName") }} title={row.supplierName}>{row.supplierName}</TD>
                                <TD style={{ display: !showColumn(columns, "clientName") && 'none', textAlign: getAlignPosition(columns, "clientName") }} title={row.clientName}>{row.clientName}</TD>
                            </Fragment>
                        ) : null}
                        {/* {("TO_BE_VALIDATED,TO_BE_CONTROLLED".includes(statusFilter))?
								//   
                                null: */}
                        <TD style={{ display: !showColumn(columns, "status") && 'none', textAlign: getAlignPosition(columns, "status") }}>
                            {userType === "supplier" 

                                ? 
                                <SupplierStatusSpan status = {getUserStatusBgColor(row?.status)} >{getStatusWithKey(row?.status, "supplier")}</SupplierStatusSpan> 
                                
                                :
                                <SelectStatus
                                    value={"IMPORTED, FILED, INTEGRATED, NEW, ERROR".includes(row?.status) ? "RECEIVED" : row?.status}
                                    status={getStatusBgColor(row?.status)}
                                    onChange={(e) => inputChangeStatus(e.target.value, row?.uid)}
                                    name="status"
                                    disabled={disableStatus || statusFilter == "TO_BE_VALIDATED"}
                                >
                                    {
                                        owner_invoiceStatus.map(state => {
                                            if (!"IMPORTED, FILED, INTEGRATED, NEW, ERROR".includes(state.name))
                                                return <StatusOption value={state.name}>{state.label}</StatusOption>
                                        })
                                    }

                                </SelectStatus>
                            }
                        </TD>
                        {/* } */}

                        <TD style={{ display: !showColumn(columns, "channel") && 'none', textAlign: getAlignPosition(columns, "code") }}>
                            {getChannelWithKey(row?.channel)}
                        </TD>

                        <TD style={{ display: !showColumn(columns, "exclusiveTaxAmount") && 'none', textAlign: getAlignPosition(columns, "exclusiveTaxAmount") }}>
                            {formatNumber(row.exclusiveTaxAmount)}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "inclusiveTaxAmount") && 'none', textAlign: getAlignPosition(columns, "inclusiveTaxAmount") }}>
                            {formatNumber(row.inclusiveTaxAmount)}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "currency") && 'none', textAlign: getAlignPosition(columns, "currency") }}>
                            {row.currency}
                        </TD>
                        {userType === "owner" && statusFilter == "TO_BE_CONTROLLED" ?
                            (<TD style={{ display: !showColumn(columns, "command") && 'none', textAlign: getAlignPosition(columns, "command") }}>
                                <InvoiceLink
                                    to={"/invoiceCommand/" + row.uid}
                                >
                                    <Article style={{ fontSize: "1.2rem", cursor: "pointer", color: "#2174B9" }} />
                                </InvoiceLink>
                            </TD>) : (
                                null
                            )
                        }
                        <TD style={{ display: "flex", alignSelf: "center", justifyContent: "center" }}>
                            <div style={{ display: "flex", alignSelf: "center", justifyContent: "center" }} title="Télécharger">
                                {
                                    (row.docFile || row.xmlFile || row.ediFile || row.chorusFile) &&
                                    <InsertDriveFileIcon onClick={(e) => clickedHandler(e, row)} style={{ fontSize: "1.2rem", cursor: "pointer", color: "#2174B9" }} />
                                }
                                {/* {row.xmlFile &&
                                        <InsertDriveFileIcon             
                                            style={{fontSize: "1.2rem", cursor: "pointer", color: "#2174B9"}}  
                                            onClick={(e) => clickedHandlerXml(e, row.uid)}
                                        />
                                    }
                                    {row.ediFile &&
                                        <InsertDriveFileIcon             
                                            style={{fontSize: "1.2rem", cursor: "pointer", color: "#2174B9"}}  
                                             onClick={(e) => clickedHandlerEdi(e, row.uid)}
                                        />
                                    }
                                     {row.chorusFile &&
                                    
                                        <InsertDriveFileIcon            
                                            style={{fontSize: "1.2rem", cursor: "pointer", color: "#2174B9"}}  
                                             onClick={(e) => clickedHandlerChorus(e, row.uid)}
                                        />
                                    
                                    } */}
                            </div>
                        </TD>
                    </tr>
                ))

            }
            {
                showStatusReasonModal &&
                <StatusReasonModal
                    show={showStatusReasonModal}
                    handleClose={() => setShowStatusReasonModal(false)}
                    invoiceToEdit={invoiceToEdit}
                    editStatus={editStatus}
                />
            }
        </>
    )
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = dispatch => ({
    getDocumentFile: (uid) => dispatch(actions.invoiceGetDocumentFile(uid)),
    getinvoiceGetDocumentXml: (uid) => dispatch(actions.invoiceGetDocumentXml(uid)),
    getinvoiceGetDocumentEdi: (uid) => dispatch(actions.invoiceGetDocumentEdi(uid)),
    getinvoiceGetDocumentChorus: (uid) => dispatch(actions.invoiceGetDocumentChorus(uid)),
})
export default connect(mapStateToProps, mapDispatchToProps)(TableBody)
