import styled from "styled-components/macro";

export const SelectDiv = styled.div`
  width:200px;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display: none

`;

export const SelectIcon = styled.div`
  // width:200px;
  display:flex;
  position: relative;
  &:hover ${SelectDiv}{
    display:block;
  }
`;

export const RContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  // padding-top: 0.8rem !important;
`;

export const RIContainer = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.8rem 1.5rem;
`;
export const RBContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.5rem;
  padding-left: 0;
`;

export const RITextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.8rem;
  ${({ theme }) => theme.button}
`;

export const RITextLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;

  & input {
    color: ${({ theme }) => theme.colors.lightBlack};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    font-size: 0.9rem;
    border-radius: 8px;
    margin-left: 0.8rem;
    width: auto;
    &:focus {
      outline: none !important;
    }
  }
  & label {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 0.9rem;
  }
`;

export const RButton = styled.button`
  width: 100%;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 16px;
  cursor: pointer;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
`;

export const RIText = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  font-size: 0.9rem;
  padding: 3px 5px;
  &:focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.lightBlack};
  }
`;

export const TitleTable = styled.h2`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.2rem;
`;

export const MyModalContainer = styled.div`
  display: flex;
  align-items: center;
  & .modal.show.modal-dialog {
    transform: none;
    min-height: 80%;
    width: 80%;
    max-width: 80%;
  }
`;
export const IDContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  // padding: 17px;
`;
export const IDContainerTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  // padding: 10px;
  // border: 1px solid #809fb8;
  border-radius: 16px;
  // overflow: auto;
`;

export const IDHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  border-bottom: 4px solid #f5fbff;
`;

export const IDHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 2rem;

  @media ${({ theme }) => theme.device.laptop} {
    margin-right: 1rem;
  }
`;

export const IDHeader = styled.div`
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  align-items: flex-end;
`;

export const IDHeaderTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.colors.primary};
  @media ${({ theme }) => theme.device.laptop} {
    font-size: 1.5rem;
  }
`;
export const IDHeaderInvoiceDate = styled.h5`
  font-size: 1rem;
  margin-bottom: 0.1rem;
  color: ${({ theme }) => theme.colors.primary};
  @media ${({ theme }) => theme.device.laptop} {
    font-size: 1rem;
  }
`;

export const IDProgressLineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: -7px;
`;

export const IDInvoiceDate = styled.div`
  display: flex;
  font-size: 0.9rem;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 0 1.6rem 0rem;
  color: ${({ theme }) => theme.colors.secondary};

  @media ${({ theme }) => theme.device.laptop} {
    padding: 0 0 1.6rem 0rem;
  }
`;

export const IDInvoiceDateLabel = styled.h4`
  font-size: 1.2rem;
  margin: 0 0.25rem 0 0;
  white-space: nowrap;

  @media ${({ theme }) => theme.device.laptop} {
    font-size: 0.9rem;
  }
`;

export const IDInvoiceDateValue = styled.h4`
  font-size: 1.2rem;
  margin: 0;
  white-space: nowrap;
  @media ${({ theme }) => theme.device.laptop} {
    font-size: 0.9rem;
  }
`;

export const IDHeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const IDBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isCommand }) => (isCommand ? "column" : "row")};
  justify-content: ${({ accessToken }) =>
    accessToken ? "center" : "space-between"};
  align-items: flex-start;
  margin-top: 2rem;
`;

export const ButtonCommand = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;

export const MySelect = styled.div`
  display: block;
  position: absolute;
  background-color: white;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 18px;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
`;
export const ActionButton = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
`;

export const Table = styled.table`
  width: 100%;
  /* height: 100%; */
  table-layout: fixed;

  @media (min-width: ${({ theme }) => theme.size.tablet}) and (max-width: ${({
      theme,
    }) => theme.size.laptop}) {
    width: auto;
  }
`;

export const TDM = styled.td`
  padding: 0.25rem;
  text-align: center;
  border: 2px solid ${({ theme }) => theme.colors.secondary} !important;

  & > span {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
  }
`;

export const TD = styled.td`
  padding: 0.25rem;
  text-align: center;
  &:not(:first-child) {
    border: 2px solid ${({ theme }) => theme.colors.secondary} !important;
  }

  & > span {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
  }
`;

export const TBody = styled.tbody`
  width: 100%;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.lightBlack};

  tr {
    border-bottom: 0rem solid white;
  }

  & ${TD} {
    width: 100%;
    vertical-align: middle;
    height: ${({ height }) => (height ? height : "100%")};
  }
`;

export const THead = styled.thead`
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.9rem;
  background-color: #ffffff;
`;

export const THM = styled.th`
  width: ${({ width }) => width || "auto"};
  padding: 0.2rem;
  cursor: default;
  border: 2px solid ${({ theme }) => theme.colors.secondary} !important;
`;

export const TH = styled.th`
  width: ${({ width }) => width || "auto"};
  padding: 0.2rem;
  cursor: default;
  &:not(:first-child) {
    border: 2px solid ${({ theme }) => theme.colors.secondary} !important;
  }
`;

export const THContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: default;
  font-weight: normal;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    font-weight: normal;
  }
`;

export const Input = styled.input`
  width: 80%;
  border: none !important;
  &:focus {
    outline: none !important;
  }
`;

export const FormSelect = styled.select`
  border: none;
  outline: none;
  /* min-width: 170px; */
  flex: 1;
  text-align: right;
  color: #212529;
  font-size: 0.9rem;
  appearance: none;
`;
