import React, { useCallback, useEffect, useState } from "react";
import Axios from "../../axios-proas";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import * as actions from "../../store/actions/index";
import { useTranslation } from "react-i18next";
import theme from "../../styles/theme";

function ListLog({ logData, grep }) {
  const { t } = useTranslation();
  const colorWord = (text) => {
    // console.log(txt, "txt");
    // console.log(txt[0], "txt[0]");
    // console.log(txt[1], "txt[1]");
    if (text.includes(grep)) {
      let txt = text.split(grep);
      let lastIndex = txt.length - 1;

      return txt.map((e, index) => {
        return (
          <>
            {e}
            {index != lastIndex && (
              <span style={{ color: theme.colors.danger }}>{grep}</span>
            )}
          </>
        );
      });
    } else {
      return text;
    }
  };
  return (
    <>
      {logData ? (
        logData.map((log, index) => (
          <p key={index} style={{ fontSize: "0.9rem" }}>
            {grep !== "" ? colorWord(log.allLog) : log.allLog}
          </p>
        ))
      ) : (
        <p>No logs data</p>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  isAuth: state.auth.token !== null,
  token: state.auth.token,
  loading: state.paymentSignature.loading,
  usersData: state.paymentSignature.data,
  count: state.paymentSignature.count,
  currentPage: state.paymentSignature.currentPage,
  pageSize: state.paymentSignature.pageSize,
  filtersQuery: state.paymentSignature.filtersQuery,
  filtering: state.paymentSignature.filtering,
  sortQuery: state.paymentSignature.sortQuery,
  reverse: state.paymentSignature.reverse,
  selectedPaymentSignature: state.paymentSignature.selectedPaymentSignature,
  showFilters: state.paymentSignature.showFilters,
  selectedAllRows: state.paymentSignature.selectedAllRows,
  userType: state.auth.userType,
});
const mapDispatchToProps = (dispatch) => ({
  onTryAutoSignup: () => dispatch(actions.authCheckState()),
  getPaymentSignaturePage: (page, filters, sort, reverse, pageSize) =>
    dispatch(
      actions.paymentSignatureGetPage(page, filters, sort, reverse, pageSize)
    ),
  setPaymentSignaturePage: (page) =>
    dispatch(actions.paymentSignatureSetPage(page)),
  setPaymentSignaturePageSize: (pageSize) =>
    dispatch(actions.paymentSignatureSetPageSize(pageSize)),
  setPaymentSignatureFilters: (filters) =>
    dispatch(actions.paymentSignatureSetFilterQuery(filters)),
  setSortQuery: (sortQ) =>
    dispatch(actions.paymentSignatureSetSortQuery(sortQ)),
  setReverse: (reverseS) =>
    dispatch(actions.paymentSignatureSetReverseSort(reverseS)),
  updateShowFilters: (show) =>
    dispatch(actions.paymentSignatureUpdateShowFilters(show)),
  selectAllPaymentSignatureRows: (selectAll) =>
    dispatch(actions.setSelectedAllPaymentSignatureRows(selectAll)),
  selectPaymentSignatureRow: (uid) =>
    dispatch(actions.selectPaymentSignature(uid)),
  unselectPaymentSignatureRow: (uid) =>
    dispatch(actions.unselectPaymentSignature(uid)),
  getCompressedDoc: (selection) =>
    dispatch(actions.invoiceGetCompressedDocument(selection)),
  emptySelectedPaymentSignature: () =>
    dispatch(actions.emptySelectedPaymentSignature()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ListLog);
