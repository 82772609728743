import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const EntContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const EntHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`;

export const EntHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const EntHeaderTitle = styled.h4`
    font-size: 1.5rem;
   
    /* font-weight: bold; */
    color: ${({theme}) => theme.colors.primary};
`

export const EntHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
`

export const EntHeaderAction = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
    height: 50px;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border: 2px solid ${({bg}) => bg ? bg : '#D9E1E7'};
    border-radius: 16px;
    color: #D9E1E7;
    cursor: pointer;
    font-size: 0.9rem;
    background-color: ${({bg}) => bg ? bg : '#FFFFFF'};

    & span {
        color: ${({color}) => color || '#505050'};
    }

    &:hover {
        /* background-color: ${({theme}) => theme.colors.btnHover}; */
    }
`;

export const NavTabsUl = styled.ul`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content:${({isCommand}) => isCommand?" start": 'space-between'};
`;

export const NavTabsLi = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3rem;
    /* background-color: white; */
    `

export const NavTabsLink = styled(Link)`
    font-weight: 500;
    color: ${({theme,color}) => color?color: theme.colors.primary};
    border-bottom: ${({active, theme}) => active ? `5px solid #2174B9` : '5px solid white'};
    text-decoration: none;
    padding-bottom: 0.3rem;

    &:hover {
        color: ${({theme}) => theme.colors.primary};
        border-bottom: 5px solid ${({theme}) => theme.colors.primary};
    }
`

export const EntContent = styled.div`
    width: 100%;
`;

export const EntFormSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.5rem;
`;
export const EntFormList = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
`;

export const EntFormItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    padding: 0.25rem;
    background-color: #2174B980;
    color: white;
    border-radius: 8px;
    margin-right: 0.5rem;

    & span {
        margin: 0 0.5rem 0 0;
    }
`

export const EIFormContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding:5px ;
`;

export const EIFormCol = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
`

export const FormColModal = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

// export const EIFormGroup = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-end;
//     padding-bottom: 0.25rem;
//     margin-bottom: 1rem;
//     border-bottom: 2px solid #F5FBFF;
// `

// export const EIFormLabel = styled.label`
//     margin: 0;
//     padding: 0;
//     color: ${({theme}) => theme.colors.secondary};
// `;

// export const EIFormInput = styled.input`
//     /* flex: 1; */
//     border: none;
//     outline: none;
//     width: 170px;;
// `;

// export const EIFormSelect = styled.select`
//     border: none;
//     outline: none;
//     width: 170px;
// `;