import React, { useState } from 'react'
import Axios from '../../../../axios-proas';
import { Button, Modal } from 'react-bootstrap';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from 'styled-components';
import { SDItem, SDItems, SDItemTitle, SDListWrap } from './SuspendedDocsModal.styled';
import { getNotyfObject } from '../../../../shared/utility';
import { SpinnerContainer } from '../../../../styles/Table.styled';
import Spinner from 'react-bootstrap/Spinner'
import { BtnAction, PrimaryBtnOutlineAction, SecondaryBtnOutlineAction } from '../../../../styles/Common';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

function SuspendedDocsModal({show, closeModal, documents}) {
    const theme = useTheme();
    const notyf = getNotyfObject();
    const {t} = useTranslation();
    const [suspendedDocs, setSuspendedDocs] = useState(documents);
    const [loading, setLoading] = useState(false);

    const sendInvoiceByMail = (uid, isLast) => {
        let docs = [...suspendedDocs];
        let index = docs.findIndex(doc => doc.uid === uid);
        docs[index].isSending = 1;
        setSuspendedDocs(docs);
        Axios.put(`invoice/${uid}/sendInvoiceByMail`).then(response => {
            let success = response?.data.hasOwnProperty('success') && response?.data?.success;
            if (success) {
                docs[index].emailed = 1;
            }else {
                notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            }
            docs[index].isSending = 0;
            setSuspendedDocs(docs);
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
            docs[index].isSending = 0;
            setSuspendedDocs(docs);
        }).finally(() => {
            if (isLast) {
                setLoading(false);
            }
        })
    }

    const sendInvoicesClick = () => {
        setLoading(true);
        let notSentDocs = suspendedDocs.filter(doc => parseInt(doc.emailed) === 0);
        notSentDocs.map((doc, index) => {
            let isLast = index === notSentDocs.length - 1;
            sendInvoiceByMail(doc.uid, isLast);
        })
    }

    const validateButton = () => {
        if(loading)
            return <Spinner animation="border" variant="primary" />
        let notSentDocs = suspendedDocs.filter(doc => parseInt(doc.emailed) === 0);
        if(notSentDocs.length > 0)
            return <PrimaryBtnOutlineAction 
                        onClick={() => sendInvoicesClick()}
                    >
                        Envoyer les facture
                    </PrimaryBtnOutlineAction>
        return null
    }

  return (
    <Modal show={show} onHide={closeModal} >
        <Modal.Header closeButton>
        <Modal.Title className="ICDBlue">
            Factures en attente d'envoi
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SDListWrap>
                <SDItems>
                    {
                        suspendedDocs?.length > 0 ? suspendedDocs.map((doc, index) => (
                            <SDItem key={index}>
                                <SDItemTitle>{doc.docFile}</SDItemTitle>
                                {
                                    doc?.isSending === 1 ? (
                                        <>
                                            <Spinner animation="grow" variant="info" size="sm" />
                                        </>
                                    ) : parseInt(doc?.emailed) === 1 ? (
                                        <CheckIcon style={{color: theme.colors.success}}/>
                                    ) : (
                                        <CloseIcon style={{color: theme.colors.danger}}/>
                                    )
                                }
                            </SDItem>
                        )) : null
                    }
                </SDItems>
            </SDListWrap>
        </Modal.Body>
        <Modal.Footer>
        <SecondaryBtnOutlineAction 
            // bg={theme.colors.greenLight}
            // color={theme.colors.success}
            onClick={() => closeModal()}
        >
            Fermer
        </SecondaryBtnOutlineAction>
        {
            validateButton()
        }
        </Modal.Footer>
    </Modal>
  )
}

export default SuspendedDocsModal