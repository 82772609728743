import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
       display: flex;
       width: 100%;
    flex-direction: column;
    margin-bottom: 1rem;
    height: 30%;
`;

export const CITitle = styled.h5`
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
`
export const Input = styled.input`
     border: none;
     outline: none;
     width: 91%;
     text-align:start;
     color: ${({theme}) => theme.colors.nightBlack};
     border-bottom: 2px solid #809FB8;
     font-size:0.8rem;
     &:hover {
         border-bottom: 2px solid ${({theme}) => theme.colors.primary};
     }

    &:focus {
        border-bottom: 2px solid ${({theme}) => theme.colors.primary};
    }
 `;
export const Content = styled.div`
     display: flex;
    flex-wrap: wrap;
    margin: 9px;

`
export const ContentPiecesOrDest = styled.div`
     display: flex;
    flex-wrap: wrap;
    margin: 9px;
    max-height:145px;
    overflow: auto;
    

`
export const BtnDivContent = styled.div`
     display: flex;
    flex-wrap: wrap;
    justify-content: center

`
export const DivLabel = styled.div`
    max-width: 130px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
export const DivGlp = styled.div`
  padding: 0.25rem 0.5rem;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 90px;
    background-color: #2174B980;
    border-radius: 8px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
`

export const CBtn = styled.button`
    display: flex;
    justify-content: center ;
    align-items: center;
    margin-left: 1rem;
    /* padding: 0.5rem; */
    border: 1px solid ${({color, theme}) => color ? color : theme.colors.redLight};
    border-radius: 8px;
    color: ${({color, theme}) => color || theme.colors.redLight};
    cursor: pointer;
    font-size: 0.9rem;
    background-color: #FFFFFF;

    & span {
        color: ${({color, theme}) => color || theme.colors.redLight};
    }

    &:hover {
        background-color: ${({color,theme}) =>color || theme.colors.redLight};
        color: #FFFFFF;
    }
`;


