import React from 'react'
import './EmailPreview.css'
import emailLogo from '../../../assets/images/emailTemplate/insight.png'
import faCircle from "../../../assets/images/emailTemplate/circle-facebook.png"
import faCirclewhite from "../../../assets/images/emailTemplate/white-facebook.png"
import twCircle from "../../../assets/images/emailTemplate/circle-twitter.png"
import twCirclewhite from "../../../assets/images/emailTemplate/white-twitter.png"
import ytCircle from "../../../assets/images/emailTemplate/circle-youtube.png"
import ytCirclewhite from "../../../assets/images/emailTemplate/white-youtube.png"
import lkCircle from "../../../assets/images/emailTemplate/circle-linkedin.png"
import lkCirclewhite from "../../../assets/images/emailTemplate/white-linkedin.png"
import inCircle from "../../../assets/images/emailTemplate/circle-instagram.png"
import inCirclewhite from "../../../assets/images/emailTemplate/white-instagram.png"
import banner from "../../../assets/images/emailTemplate/banner.jpg"
import support from "../../../assets/images/emailTemplate/picto-support.png" 
import formation from "../../../assets/images/emailTemplate/picto-formation.png" 
import accompagn from "../../../assets/images/emailTemplate/picto-accompagnement.png" 
import references from "../../../assets/images/emailTemplate/references.JPG"
import logoWhite from "../../../assets/images/emailTemplate/insight-white.png"
import DOMPurify from 'dompurify';

function EmailPreview({convertedContent}) {
    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
      }

  return (
      <>
        <div className="EPBody">
        <center class="wrapper">
            <table class="main" width="100%" >
                 {/* BLUE BORDER  */}
                    <tr>
                        <td height="8" style={{backgroundColor: "#289dcf"}}></td>
                    </tr>
                
                {/* LOGO SECTION */}
                    <tr>
                        <td style={{padding: "14px 0 4px"}}>
                            <table width="100%">
                                <tr>
                                    <td class="two-columns">
                                        <table class="column">
                                            <tr >
                                                <td style={{padding: '0 62px 10px'}}>
                                                    <a href="https://w3newbie.com/email-course/">
                                                        <img src={emailLogo} alt="Logo" width="180" title="Logo"/>
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>

                                        <table class="column">
                                            <tr>
                                                <td style={{padding: '10px 68px'}}>
                                                    <a href="#">
                                                        <img src={faCircle} alt="facebook" width="32"/>
                                                    </a>
                                                    <a href="#">
                                                        <img src={twCircle} alt="twitter" width="32"/>
                                                    </a>
                                                    <a href="#">
                                                        <img src={ytCircle} alt="youtube" width="32"/>
                                                    </a>
                                                    <a href="#">
                                                        <img src={lkCircle} alt="linkedin" width="32"/>
                                                    </a>
                                                    <a href="#">
                                                        <img src={inCircle} alt="instagram" width="32"/>
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                
                {/* BANNER IMAGE  */}
                    <tr>
                        <td>
                            <a href="#">
                                <img src={banner} alt="Banner" width="600" style={{maxWidth: '100%'}}/>
                            </a>
                        </td>
                    </tr>
                
                 {/* TITLE, TEXT & BUTTON */}
                    <tr>
                        <td style={{padding: '5px 0 50px'}}>
                            <table width="100%">
                                <tr>
                                    <td style={{textAlign: 'center',padding: '15px'}}>
                                        <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
                                        {/* <p style={{fontSize: '20px',fontWeight: 'bold'}}>
                                            HTML Email Template <strong style={{color: '#289dcf'}}>Course</strong> 
                                        </p>
                                        <p style={{fontSize: '15px',lineHeight: '23px',padding: '5px 0 15px'}}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                            Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. 
                                            Praesent interdum ligula eu enim. Vestibulum sed felis.
                                        </p>
                                        <a href="#" class="button">View Course</a> */}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                
                 {/* BLUE BORDER  */}
                    <tr>
                        <td height="2" style={{backgroundColor: '#a6d8ee'}}></td>
                    </tr>
                {/* THREE COLUMN SECTION  */}
                    <tr style={{backgroundColor: '#2489B5', color: "#FFF"}}>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td class="three-columns">
                                        <table class="column">
                                            <tr>
                                                <td class="padding">
                                                    <table class="content">
                                                        <tr>
                                                            <td>
                                                                <a href="#">
                                                                    <img 
                                                                        src={support}
                                                                        alt="ecommerce" 
                                                                        width="110px" 
                                                                        style={{maxWidth: '110px',borderRadius: '55px'}}/>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{padding: '10px'}}>
                                                                <p style={{fontWeight: 'bold',fontSize: '17px'}}>
                                                                    Conseil
                                                                </p>
                                                                <p>
                                                                    Partager nos expertises pour développer votre projet
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>

                                        <table class="column">
                                            <tr>
                                                <td class="padding">
                                                    <table class="content">
                                                        <tr>
                                                            <td>
                                                                <a href="#">
                                                                    <img 
                                                                        src={formation}
                                                                        alt="ecommerce" 
                                                                        width="110px" 
                                                                        style={{maxWidth: '110px',borderRadius: '55px'}}/>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{padding: '10px'}}>
                                                                <p style={{fontWeight: 'bold',fontSize: '17px'}}>
                                                                    Formation
                                                                </p>
                                                                <p>
                                                                    Rendre les équipes autonomes, monter en compétence
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>

                                        <table class="column">
                                            <tr>
                                                <td class="padding">
                                                    <table class="content">
                                                        <tr>
                                                            <td>
                                                                <a href="#">
                                                                    <img 
                                                                        src={accompagn}
                                                                        alt="ecommerce" 
                                                                        width="110px" 
                                                                        style={{maxWidth: '110px',borderRadius: '55px'}}/>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{padding: '10px'}}>
                                                                <p style={{fontWeight: 'bold',fontSize: '17px'}}>
                                                                    Support
                                                                </p>
                                                                <p>
                                                                    Garantir une performance opérationnelle continue
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                
                 {/* BLUE BORDER  */}
                    <tr>
                        <td height="2" style={{backgroundColor: '#a6d8ee'}}></td>
                    </tr>
                {/* TWO COLUMN SECTION  */}
                    <tr>
                        <td>
                            <table width="100%">
                                <tr>
                                    <td class="two-columns last">

                                        <table class="column">
                                            <tr>
                                                <td class="padding">
                                                    <table class="content">
                                                        <tr>
                                                            <td>
                                                                <a href="#">
                                                                    <img 
                                                                        src={references}
                                                                        alt="" 
                                                                        width="260"
                                                                        style={{maxWidth: '260px'}}
                                                                    />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>

                                        <table class="column">
                                            <tr>
                                                <td class="padding">
                                                    <table class="content">
                                                        <tr>
                                                            <td>
                                                                <p style={{fontSize: '17px',fontWeight: 'bold'}}>
                                                                    Clients
                                                                </p>
                                                                <p>
                                                                    clients actifs dans 24 pays utilisent les solutions ICD International pour développer la compétitivité de leur entreprise.
                                                                </p>
                                                                {/* <p style={{paddingBottom: '15px'}}>
                                                                    Responsive HTML Email Template Course to learn how to build a modern ecommerce website.
                                                                </p> */}
                                                                <a href="#" class="button">Plus de références</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                
                
                 {/* FOOTER SECTION */}
                    <tr>
                        <td style={{backgroundColor: '#565656',color: '#FFFFFF'}}>
                            <table width="100%">
                                <tr>
                                    <td style={{textAlign: 'center',padding: '45px 20px'}}>
                                        <a href="#">
                                            <img src={logoWhite} alt="" width="160"/>
                                        </a>
                                        <p style={{padding: '10px'}}>
                                            Accélérez votre compétitivité avec ICD International
                                        </p>
                                        <p style={{padding: '10px'}}>
                                        17-19 Rue Jeanne Braconnier  92360, MEUDON LA FORET
                                        </p>
                                        <a href="#">
                                            <img src={faCirclewhite} alt="facebook" width="30"/>
                                        </a>
                                        <a href="#">
                                            <img src={twCirclewhite} alt="twitter" width="30"/>
                                        </a>
                                        <a href="#">
                                            <img src={ytCirclewhite} alt="youtube" width="30"/>
                                        </a>
                                        <a href="#">
                                            <img src={lkCirclewhite} alt="linkedin" width="30"/>
                                        </a>
                                        <a href="#">
                                            <img src={inCirclewhite} alt="instagram" width="30"/>
                                        </a>

                                        <p style={{padding: '10px'}}>
                                            Suivez-nous
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td height="10" style={{backgroundColor: '#289dcf'}}>

                        </td>
                    </tr>
            </table>

        </center>
        </div>
      </>
  )
}

export default EmailPreview