import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CTooltip } from "../../UI/CTooltip/CTooltip";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModal from "../../UI/ConfirmationModal/ConfirmationModal";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { TD } from "../../../styles/Table.styled";
import { useTheme } from "styled-components";
import { getAlignPosition, formatDate } from "../../../shared/utility";
import {
  UserStatus,
  RequestLink,
} from "../../../containers/UsersManagement/UsersManagement.styled";
import CancelIcon from "@mui/icons-material/Cancel";

import Axios from "../../../axios-proas";

function TableBody(props) {
  const {
    data,
    selectedUsers,
    selectUserRow,
    unselectUserRow,
    disableUser,
    enableUser,
    columns,
    entityUid,
    setConfirmationUserModalShow,
    setUsersManagementUpdate,
    usersManagementUpdate,
    setNewUserModal,
    initUserModal,
  } = props;
  let textRef = useRef();
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [userToDisable, setUserToDisable] = useState(null);
  const { t } = useTranslation();
  const theme = useTheme();

  const statusEventMessage = {
    PENDING: "Parapheur en attente",
    TO_VALIDATE: "A valider",
    TO_CONTROL: "A contrôler",
    ARCHIVED: "Archivé",
  };

  // const formatNumber = (number) => {
  //   return new Intl.NumberFormat("de-DE", {
  //     style: "currency",
  //     currency: "EUR",
  //   })
  //     .format(number)
  //     .replaceAll(".", " ");
  // };

  // const statusToLabel = {
  //     "SAVED_REQUEST": "Demande enregistrée"
  // }

  // const getSelectedRowValue = (userUid) => {
  //   let isSelected = selectedUsers.findIndex((uid) => uid === userUid) >= 0;
  //   return isSelected;
  // };

  // const selectRowHandler = (e) => {
  //   let uid = e.target.name;
  //   let val = e.target.checked;
  //   if (val) selectUserRow(uid);
  //   else unselectUserRow(uid);
  // };

  // const getTimeDate = (date) => {
  //   let newDate = new Date(date);
  //   let hour =
  //     newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
  //   let minutes =
  //     newDate.getMinutes() < 10
  //       ? "0" + newDate.getMinutes()
  //       : newDate.getMinutes();
  //   return hour + ":" + minutes;
  // };

  // const disableUserClickHandler = (userUid, userEmail) => {
  //   setUserToDisable({ userUid, userEmail });
  //   setConfirmationModalShow(true);
  // };

  // const getTypeName = (type) => {
  //   switch (type) {
  //     case "owner":
  //       return "Interne";
  //       break;
  //     case "client":
  //       return "Client";
  //       break;
  //     case "supplier":
  //       return "Fournisseur";
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // const showUserModal = (userUid) => {
  //   initUserModal(userUid);
  //   setConfirmationUserModalShow(true);
  //   setNewUserModal(false);
  // };

  // const getLevelName = (level) => {
  //   switch (level) {
  //     case "admin":
  //       return "Admin";
  //       break;
  //     case "standard":
  //       return "Standard";
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      {data.map((row) => (
        <tr key={row.id}>
          {/* <TD style={{textAlign:'center'}}>
                            <input 
                                type="checkbox" 
                                name={row.userUid} 
                                onChange={(e) => selectRowHandler(e)} 
                                checked={getSelectedRowValue(row?.userUid)}  
                                className="form-check-input" 
                                style={{margin: 0}} 
                                id="exampleCheck1" 
                            />
                        </TD> */}
          {/* <TD style={{textAlign: getAlignPosition(columns, 'first_name')}}>
                            {row.first_name}
                        </TD> */}
          {/* <TD style={{textAlign: getAlignPosition(columns, 'last_name')}}>
                            <UserStatus style={{margin: "auto", width: "100%"}}
                                bg= {row.status == "ARCHIVED" ? theme.colors.purple : ( row.status == "REFUSED" ? theme.colors.danger : theme.colors.gray ) }
                                color={ theme.colors.lightBlack}
                            >
                                <RequestLink
                                    to={`/paymentSignatureDetail/${row.uid}`}
                                >
                                    {row.paymentNumber}
                                </RequestLink>
                            </UserStatus>
                        </TD> */}
          {/* <TD style={{textAlign: getAlignPosition(columns, 'login')}}>
                            {row.login}
                        </TD> */}
          <TD style={{ textAlign: "left" }}>
            <RequestLink to={`/logTypeDetail`}>{row?.type}</RequestLink>
          </TD>
          <TD style={{ textAlign: "left" }}>{row?.description}</TD>
          <TD style={{ textAlign: "center" }}>{row?.globalInfo}</TD>
        </tr>
      ))}

      <ConfirmationModal
        show={confirmationModalShow}
        modalClosed={() => setConfirmationModalShow(false)}
        confirm={() => disableUser(userToDisable?.userUid)}
        cancel={() => setConfirmationModalShow(false)}
        title={"Confirmation de désactivation"}
        message={`Êtes-vous sûr de vouloir désactiver cet utilisateur (${userToDisable?.userEmail})?`}
      />
    </>
  );
}

export default TableBody;
