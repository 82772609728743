import React, { useCallback, useRef, useState } from "react";
import Axios from "../../../axios-proas";
import FilterListIcon from "@mui/icons-material/FilterList";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ReplayIcon from "@mui/icons-material/Replay";
import { CTooltip } from "../../UI/CTooltip/CTooltip";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  TableAction,
  TableActionLink,
  TAContainer,
} from "../../../styles/Table.styled";
import { useTheme } from "styled-components";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { PrimaryBtnOutlineAction } from "../../../styles/Common";
import UserFormModal from "../../../containers/UserForm/UserForm";

function TableActions(props) {
  const {
    setShowFilters,
    showFilters,
    selectedUsers,
    entityUid,
    setConfirmationUserModalShow,
    setNewUserModal,
    showDownloadLogsModal,
    setShowDownloadLogsModal,
  } = props;
  const [csvData, setCsvData] = useState();
  const csvBtn = useRef();
  const { t } = useTranslation();
  const theme = useTheme();

  // const showFilters = () => {
  //     console.log(!showFilters)
  //     setShowFilters(!filterState)
  // }

  const csvHeaders = [
    { label: "Prénom", key: "first_name" },
    { label: "Nom", key: "last_name" },
    { label: "Email", key: "login" },
    { label: "Télephone", key: "telephone_number" },
    { label: "Active", key: "active" },
    { label: "Type", key: "type" },
    { label: "Rôle", key: "level" },
    { label: "Clients", key: "entities" },
  ];
  const exportSelectionCsv = useCallback(() => {
    let csvDataTemp = null;
    Axios.get("/user//csvCompressedDocument", {
      params: {
        selection: selectedUsers.toString(),
      },
    })
      .then((response) => {
        csvDataTemp = response.data.content.map((row) => {
          return {
            ...row,
            // issuingDate: formatDate(+row.issuingDate),
            // dueDate: formatDate(+row.dueDate),
            active: row.active === "1" ? "O" : "N",
          };
        });
        setCsvData(csvDataTemp);
        csvBtn.current.link.click();
      })
      .catch((err) => console.log(err.data));
  }, [selectedUsers]);

  const showCsvExport = () => {
    return selectedUsers.length > 0;
  };

  return (
    <TAContainer>
      {/* {csvData ? (<CSVLink
                    ref={csvBtn}
                    data={csvData}
                    filename={"UtilisateursCsv.csv"}
                    headers={csvHeaders}>
                </CSVLink>) : null} */}
    </TAContainer>
  );
}

const mapStateToProps = (state) => ({
  selectedUsers: state.users.selectedUsers,
  filterState: state.users.showFilters,
});
const mapDispatchToProps = (dispatch) => ({
  updateShowFilters: (show) => dispatch(actions.usersUpdateShowFilters(show)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TableActions);
