import React, { useEffect, useState,useCallback, useMemo } from 'react'
import { connect } from 'react-redux';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../shared/utility';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import StatusModal from './StatusModal/StatusModal';
import { IDProgressLineContainer, IDProgressLineItem, IDProgressLineLabel, PLAction } from './IDProgressLine.styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { useTheme } from 'styled-components';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip'

function IDProgressLine(props) { 
    const {
        userType,
        entityFilter,
        status,
        editStatus,
        accessToken,
        error
    } = props
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    const theme = useTheme()
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [statusList, setStatusList] = useState([]);
    // const statusList = useMemo(() => [
    //     {label: status === 'ERROR' ? 'Erreur' : "Importée", value: status === 'ERROR' ? 'ERROR' : "IMPORTED"},
    //     {label: "Controlée", value: "CONTROLLED"},
    //     {label: "Intégrée", value: "INTEGRATED"},
    //     {label: "A payer", value: "TO_BE_PAID"},
    //     {label: "Payée", value: "PAID"}
    // ], [status])
    const [infoStatus, setInfoStatus] = useState("");
    const dangerStatus = useMemo(() => [ 'ERROR', 'REJECTED', 'REFUSED', 'LITIGATION'], [])
    
    useEffect(() => {
        let statusListTmp = [];
        if(userType === 'client'){
            statusListTmp = [
                {label: "Importée", value : "IMPORTED"},
                {label: "Controlée", value: "CONTROLLED"},
                {label: "Intégrée", value: "INTEGRATED"},
                {label: "A payer", value: "TO_BE_PAID"},
                {label: "Payée", value: "PAID"}
            ];
        }else{
            statusListTmp = [
                {label: "Importée", value : "IMPORTED"},
                {label: "Controlée", value: "CONTROLLED"},
                {label: "Intégrée", value: "INTEGRATED"},
                {label: "A payer", value: "TO_BE_PAID"},
                {label: "Payée", value: "PAID"}
            ];
        }
        switch (status) {
            case 'ERROR':
                setInfoStatus("NEW");
                break;
            case 'REJECTED':
                setInfoStatus("CONTROLLED");
                break;
            case 'REFUSED':
            case 'LITIGATION':
                setInfoStatus("INTEGRATED");
                break;
            default:
                setInfoStatus(status);
                break;
        }
        setStatusList(statusListTmp)
    }, [status])
    let actionButton = null;

    const getProgresseLineIcon = (value, index) => {
        // if(value = "IMPORTED" ) 
        const statusIndex = statusList.findIndex(item => item.value === infoStatus)
        // console.log(value)
        // console.log(index)
        // console.log(statusIndex)
        // console.log(status)

        if(index <= statusIndex) return <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
        else return <PanoramaFishEyeIcon style={{fontSize: "1.25rem"}} />
    }

    const getStatusColors = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value === infoStatus)
        // if(dangerStatus.includes(status)) {
        //     // if(value === status) 
        //     //     return [theme.colors.secondary, " dashed " + theme.colors.secondary]
        //     if(index <= statusIndex)
        //         return [theme.colors.secondary, " solid " + theme.colors.secondary]
        //     if(index === (statusIndex + 1))
        //         return [theme.colors.secondary, " dashed " + theme.colors.secondary]
        // }
            
        // if(value === status) 
        //     return [theme.colors.secondary, " dashed " + theme.colors.secondary]
            // return [theme.colors.seconday, theme.colors.warning]
        if(index <= statusIndex)
            return [theme.colors.secondary, " solid " + theme.colors.secondary]
        if(index === (statusIndex + 1))
            return [theme.colors.secondary, " dashed " + theme.colors.secondary]
        return [theme.colors.secondary, " solid " + theme.colors.gray]
    }

    // if((userType === "client" || userType === "owner") && !accessToken && !['ERROR', 'REJECTED', 'REFUSED'].includes(status) ) {
    //     actionButton = (
    //         <PLAction onClick={() => setShowStatusModal(true)} >
    //             <span>
    //                 {"(Modifier le statut)"}
    //             </span>
    //             <EditIcon style={{fontSize: "1.25rem"}}/>
    //         </PLAction>
    //     )
    // }  

    return ( 
        <IDProgressLineContainer>
            {
                statusList.map((item, index) => (
                    <IDProgressLineItem 
                        color={getStatusColors(item.value, index)[0]} 
                        border={getStatusColors(item.value, index)[1]} 
                    >
                        <IDProgressLineLabel>{item.label}</IDProgressLineLabel>
                        {/* <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
                        <PanoramaFishEyeIcon /> */}
                        {getProgresseLineIcon(item?.value, index)}
                    </IDProgressLineItem>
                ))
            
            }
            {
                // error ? 
                //     <CTooltip title={"Raison erreur"}>
                //         <span style={{color:"#EE5A5A",padding:"0px 17px"}}><WarningAmberIcon style={{fontSize:"30px",margin:"0px 5px 0px 5px"}}/><small style={{fontSize:"small"}}>Erreur</small></span>
                //     </CTooltip>
                // : 
                "REJECTED, LITIGATION, REFUSED".includes(status) ? 
                    <CTooltip title={status}>
                        <span style={{color:"#EE5A5A",padding:"0px 17px"}}><WarningAmberIcon style={{fontSize:"30px",margin:"0px 5px 0px 5px"}}/><small style={{fontSize:"small"}}>Erreur</small></span>
                    </CTooltip>
                : null

            } 
            {actionButton}
            {
                showStatusModal ? (
                    <StatusModal
                        show={showStatusModal}
                        modalClosed={() => setShowStatusModal(false)}
                        statusVal={status}
                        statusList={statusList}
                        editStatus={editStatus}
                    />
                ) : null
            }
        </IDProgressLineContainer>
        )      
    }
    
const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // mandatUid: state.mandat.admData.uid,
    // status : state.mandat?.admData?.status,
    // connectedUser : state.mandat?.admData?.connectedUser,
    // pdfFile: state.mandat.admData?.pdfFile || null,
    // admData: state.mandat.admData,
    // admUpdate: state.mandat.admUpdate
})
const mapDispatchToProps = dispatch => ({
    // getMandatData: (mandatUid) => dispatch(actions.getMandatData(mandatUid)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    // createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive))
})
export default connect(mapStateToProps, mapDispatchToProps)(IDProgressLine)
