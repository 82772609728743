import React, { useMemo, useState } from 'react';
import DateProgressLine from '../DateProgressLine/DateProgressLine';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle, CIUsersLink, UpdateLink } from './ExtractionItem.styled';
import GroupIcon from '@mui/icons-material/Group';
import { formatDate } from '../../../shared/utility';
//en premiere
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
//en derniere 
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BtnIcon, PrimaryBtnOutlineAction, PrimaryBtnOutlineLink } from '../../../styles/Common';
import ConfirmDelete from '../ExtractionModal/ConfirmDelete';
import Axios from '../../../axios-proas'
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTheme } from 'styled-components';
import { checkValidity, getNotyfObject } from '../../../shared/utility';

function ExtractionItem({ data, itemIndex }) {
    const [showModal, setShowModal] = useState(false)
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
    const theme = useTheme();
    let notyf = getNotyfObject();
    // console.log("campagnItem")

    // const formatCombinedDate = (date) => {
    //     if(date){
    //         const [start, end] = date.split(':')
    //         let startDate = formatDate(+start)
    //         let endDate = formatDate(+end)
    //         return `${startDate}-${endDate}`
    //     }else 
    //         return ''
    // }
    const formatCombinedDateStart = (date) => {
        if (date) {
            const [start, end] = date.split(':')
            let startDate = +start

            return startDate
        } else
            return ''
    }
    const formatCombinedDateEnd = (date) => {
        if (date) {
            const [start, end] = date.split(':')
            // let startDate = formatDate(+start)
            let endDate = +end
            return endDate
        } else
            return ''
    }
    const deleteExtraction = (item) => {
        Axios.delete('extraction/' + item + '/deleteExtractionByUid').then(response => {

        }).catch(response => {
            notyf.error("Une erreur s'est produite")
        })

    }


    const downloadExtraction = (datUid) => {
        // setCursor("wait")
        if (datUid != null) {
            Axios.get("extraction/" + datUid + "/DownloadextractionByUid", {
                responseType: 'arraybuffer', headers: { 'Content-Type': 'multipart/form-data' }
            }).then((response) => {
                if (response.status === 200) {
                    let blob = new Blob([response.data], { type: 'application/zip' })
                    const downloadUrl = URL.createObjectURL(blob)
                    let a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = "extraction.zip";
                    document.body.appendChild(a);
                    a.click(); 
                    // setCursor("pointer")
                } else {
                    console.log("Archive vide ou non valide");
                    notyf.error("Une erreur s'est produite")
                    // setCursor("pointer")
                }
            }).catch((response) => {
                console.log("Une erreur s'est produite"); notyf.error("Une erreur s'est produite")
                // setCursor("pointer")
            });
        }
    }




    return (
        <CIContainer style={{ display: "flex" }}>
            <div style={{
                padding: "4px 0px",
                fontSize: "22px",
                margin: "auto 14px auto 0px",
                width: "61px",
                border: "1px solid #809FB8",
                borderRadius: "18px",
                textAlign: "center",
                color: "#505050"
            }}>
                <p style={{ color: "#505050" }}>{itemIndex + 1}</p>
            </div>
            <CIContent >
                <CIData>
                    <CITitle>
                        Extraction : {(data?.titel)}
                    </CITitle>
                    <DateProgressLine
                        titel={data?.titel}
                        creationDate={+data?.creationDate}
                        campaignDate={formatCombinedDateStart(data?.invoiceCreationDate)}
                        endDate={formatCombinedDateEnd(data?.invoiceCreationDate)}
                    />
                </CIData>
                {/* <CIActions>
                    <CIDeleteBtn color="#809FB8" border="#809FB8" >
                        Télecharger
                    </CIDeleteBtn>

                    <CIDeleteBtn onClick={() => setShowModal(true)} >
                        Supprimer
                    </CIDeleteBtn>

                    {
                        showModal ? (
                            <ConfirmDelete handleClose={() => setShowModal(false)}
                                show={showModal}
                                item={data?.uid}
                                deleteExtraction={deleteExtraction} />
                        ) : null
                    }
                    <PrimaryBtnOutlineLink
                        style={{ height: "0px" }}
                        color="#06152B"
                        border="#FFF"
                        to={"/extractionForm/" + data.uid}
                        state={{ extractionData: data }}
                    >
                        Voir  <ArrowRightIcon />

                    </PrimaryBtnOutlineLink>

                </CIActions> */}
                <div style={{ display: "flex", justifyContent: "space-between", width: "81px", marginTop: "13px" }}>

                    {/* <FileDownloadIcon style={{ color: theme.colors.primary, cursor: 'pointer' }} 
                        onClick={() => downloadExtraction(data?.uid)}/> */}
                    <CancelIcon style={{ color: theme.colors.danger, cursor: 'pointer' }} onClick={() => setShowModal(true)}>
                        Supprimer
                    </CancelIcon>
                    {
                        showModal ? (
                            <ConfirmDelete handleClose={() => setShowModal(false)}
                                show={showModal}
                                item={data?.uid}
                                deleteExtraction={deleteExtraction} />
                        ) : null
                    }
                    <UpdateLink
                        to={"/extractionForm/" + data?.uid}
                    >
                        <EditIcon style={{ color: theme.colors.primary, cursor: 'pointer' }} />
                    </UpdateLink>

                </div>
            </CIContent>
        </CIContainer>
    );
}

export default ExtractionItem;
