import styled from 'styled-components/macro';

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
`;
export const ETRadiosContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const FormLabel = styled.label`
    cursor: pointer;
    display: flex;
    margin: 0 0.5rem 0 0;
    padding: 0;
    white-space: pre-wrap;
    color: #505050;
`;

export const FormRadio = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 10px;
//   &:hover ~ ${RadioButtonLabel} {
//     // background: #1E85D9;
//     border: 1px solid #2196F3;
//     &::after {
//         content: '';
//        display: flex;
//        width: 18px;
//       height: 18px;
//        -moz-border-radius: 7.5px;
//        -webkit-border-radius: 7.5px;
//        border-radius: 7.5px;
//        background-color: #fff;
//     }
//   }
  &:checked + ${RadioButtonLabel} {
    // background: #2196F3;
    border: 1px solid #2196F3;
    &::after {
        content: '✓';
        display: flex;
        width: 15px;
        height: 0px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: #fff;
        font-size: 15px;
        font-weight: bold;
        margin: -2px 0px 0px 1px;
    }
  }
`;
export const LogoContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    align-self: center;
    /* justify-content: center; */
    width: 100%;
    height: 100%;
    margin: 0.5rem;
    margin-left: 22px;
`;
export const FormRadioWrapper = styled.div`
    display: flex;
    align-items: baseline;
    position: relative;
    border-radius: 2px;
`;