import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TimeLine from './Timeline/TimeLine';
import ModificationsChat from './ModificationsChat/ModificationsChat';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { NavTabsLi, NavTabsLink, NavTabsUl } from '../../../../containers/Entity/Entity.styled';
import { useParams, useLocation } from 'react-router-dom';
import { ISContainer, ISNavContainer, ISNavContent } from './InvoiceSide.styled';
import Fields from './Fields/Fields';
import Information from './Information/Information';
function CommandeSide(props) {
    const {
        tabSideActive,
        setTabSideActive,
        invoiceData,
        commandeData,
        setInvoiceData,
        invoiceIntegratedDetail,
        userType,
        isFull
    } = props
    let notyf = getNotyfObject();
    const {t} = useTranslation();

    useEffect(() => {
    
            setTabSideActive("information")
                
    
    }, [invoiceData])

  
    return (
        <ISContainer isFull={isFull}>  
            <ISNavContainer>
                <NavTabsUl isCommand={true} >
                 
                    {
                     <NavTabsLi>
                        <NavTabsLink 
                            active={tabSideActive === "information"} 
                            onClick={() => setTabSideActive("information")}
                            to="#"
                            >
                                {t('mandat:information', "Informations")} 
                        </NavTabsLink>
                    </NavTabsLi>
                 }
                    <NavTabsLi>
                        <NavTabsLink 
                            active={tabSideActive === "comments"} 
                            onClick={() => setTabSideActive("comments")}
                            to="#"
                            >
                                {t('mandat:comments', "Commentaires")} 
                        </NavTabsLink>
                    </NavTabsLi>
                    <NavTabsLi>
                        <NavTabsLink 
                            active={tabSideActive === "timeline"} 
                            onClick={() => setTabSideActive("timeline")}
                            to="#"
                        >
                                {t("mandat:timeline", "TimeLine")} 
                        </NavTabsLink>
                    </NavTabsLi>
                    
                </NavTabsUl>
            </ISNavContainer>
            <ISNavContent>
               
                {tabSideActive === "information" && 
                        <Information  
                        commandeData={commandeData}
                        invoiceData={invoiceData}
                       />
                }
                {tabSideActive === "timeline" && 
                        <TimeLine  commandeData={commandeData}/>
                }
                {tabSideActive === "comments" && 
                        <ModificationsChat />
                }
            </ISNavContent>
        </ISContainer>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    tabSideActive: state.invoiceDetail.tabSideActive,
    invoiceData: state.invoiceDetail.invoiceData,
    commandeData : state.commande.commandeData,
})
const mapDispatchToProps = dispatch => ({
    setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    setInvoiceData: (invoiceData) => dispatch(actions.invoiceDataSuccess(invoiceData))
})
export default connect(mapStateToProps, mapDispatchToProps)(CommandeSide)
